import { fromPairs, isEqual, keys } from 'lodash';

/**
 * Funcion que retorna si todos los parametros son undefined.
 * @param obj - Objeto de entrada.
 * @returns - Boolean.
 */
export function areAllParametersUndefined<T = any>(obj: T): boolean {
  const undefinedObj = fromPairs(keys(obj).map(key => [key, undefined]));
  return isEqual(obj, undefinedObj);
}
