import { ObservableInput, timer, skipWhile, take, switchMap } from 'rxjs';
/**
 * Operator para inicial un observable cuando se cumpla una condicion despues de varios intentos.
 * @param condition - Condicion para continuar con el observable.
 * @param project - Observable.
 * @param config - Configuracion.
 * @returns - Observable.
 */
export function repeatUntil<O extends ObservableInput<any>>(
  condition: () => boolean,
  project: (value: number, index: number) => O,
  config?: RepeatUntilConfig
) {
  return timer(config?.initial_time || 0, config?.time || 100).pipe(
    skipWhile(attempt => !condition() && attempt < (config?.attempts || 100)),
    take(1),
    switchMap(project)
  );
}

/**
 * Interfaz de configuracion.
 */
interface RepeatUntilConfig {
  /**
   * Tiempo inicial de ejecución en ms.
   */
  initial_time?: number;
  /**
   * Intervalo de tiempo en ms.
   */
  time?: number;
  /**
   * Intentos maximos.
   */
  attempts?: number;
}
