import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, Observable } from 'rxjs';
import { selectIsAuth } from '../screens/dashboard/store/selectors/session.selectors';

/**
 * Autenticacion guard.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  /**
   * Crea una instancia de AuthGuard.
   * @param store - Tienda del estado.
   * @param router - Rutas.
   */
  public constructor(private store: Store, private router: Router) {}

  /**
   * Verifica si esta autenticada la app.
   * @returns Observable booleano.
   */
  public canActivate(): Observable<boolean> {
    return this.store.select(selectIsAuth).pipe(
      filter(IsAuth => IsAuth !== undefined),
      map(isAuth => {
        if (isAuth) {
          return isAuth;
        }
        this.router.navigate(['login']);
        return isAuth || false;
      })
    );
  }
}

/**
 * Guard del login.
 */
@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  /**
   * Crea una instancia de LoginGuard.
   * @param store - Tienda del estado.
   */
  public constructor(private store: Store) {}

  /**
   * Verifica si el usuario puede ir al login.
   * @returns Observable booleano.
   */
  public canActivate(): Observable<boolean> {
    return this.store?.select(selectIsAuth).pipe(
      map(isAuth => {
        if (!isAuth) {
          return true;
        }
        window.history.back();
        return false;
      })
    );
  }
}
