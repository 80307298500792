import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { WorkflowService } from '@wp-back-office/core/workflow-sdk';
import {
  dialogMessageDefaultConfig,
  DynamicDialogMessageService,
} from '@wp-back-office/shared/dynamic-components';
import { mergeMap, map, catchError, of, withLatestFrom } from 'rxjs';
import { ResponseStart } from '../../../../process/models';
import { createSelectorInject } from '../../../../root/store/app.reducers';
import { RequestRiskAnalysisEdit } from '../../../models/request.models';

import * as actionsEffects from '../../actions';
import { AnalysisState } from '../../analysis.state.reducers';

/**
 *
 */
@Injectable()
export class RequestEffects {
  /**
   * Crea una instancia StartProcessEffects.
   * @param actions$ - Actions.
   * @param workflowService - Servicio work flow.
   * @param storeAnalysis - Reducer análisis de riesgo.
   * @param dynamicDialogMessageService - Servicio de parámetros.
   * @param router - Enrutador.
   */
  constructor(
    private actions$: Actions,
    private workflowService: WorkflowService,
    private storeAnalysis: Store<AnalysisState>,
    public dynamicDialogMessageService: DynamicDialogMessageService,
    private router: Router
  ) {}

  /**
   * Radicacion del analisis de credito.
   */
  public RadicationAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.RadicationAnalysis),
      mergeMap(({ procesData }) =>
        this.workflowService.successTask(procesData).pipe(
          map((object: ResponseStart) =>
            actionsEffects.RadicationAnalysisSuccess({ object })
          ),
          catchError(error =>
            of(actionsEffects.RadicationAnalysisError({ error }))
          )
        )
      )
    )
  );

  /**
   * Radicacion de edicion de informacion analisis de credito.
   */
  public RadicationAnalysisEdit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.RadicationAnalysisEdit),
      withLatestFrom(
        this.storeAnalysis.select(createSelectorInject('Request', 'Analysis'))
      ),
      mergeMap(([{ procesData }, schems]) => {
        const interveners: any[] | undefined =
          schems?.schemaModify?.interveners?.map(
            (intervener: any) => intervener.taskResult.modifiedFields
          );
        const products =
          schems?.schemaModify?.product?.taskResult?.modifiedFields;
        products;

        const intervenersUsedForm: any[] | undefined =
          schems?.schemaModify?.interveners?.map(
            (intervener: any) => intervener.taskResult.usedForms
          );
        const productsUsedForm =
          schems?.schemaModify?.product?.taskResult?.usedForms;
        products;

        const radicationTaskPost: RequestRiskAnalysisEdit = {
          idProcess: procesData.idProcess,
          process: procesData.process,
          date: procesData.date?.toString(),
          taskToken: procesData.taskToken,
          publisher: false,
          taskResult: {
            analistResult: procesData?.taskResult?.analistResult,
            modifiedFields:
              products !== undefined
                ? [products, ...(interveners || [])]
                : [...(interveners || [])],
            usedForms:
              products !== undefined
                ? [productsUsedForm, ...(intervenersUsedForm || [])]
                : [...(intervenersUsedForm || [])],
          },
        };

        return this.workflowService.radicationSuccess(radicationTaskPost).pipe(
          map((object: ResponseStart) => {
            this.dynamicDialogMessageService.open({
              ...dialogMessageDefaultConfig,
              type: 'success',
              header: {
                text: 'Tarea completada correctamente',
                textAlign: 'text-center',
              },
            });
            this.router.navigate(['../../dashboard/home']);
            return actionsEffects.RadicationAnalysisEditSuccess({ object });
          }),
          catchError(error => {
            this.dynamicDialogMessageService.open({
              ...dialogMessageDefaultConfig,
              type: 'error',
              header: {
                text: 'Ha ocurrido un error',
                textAlign: 'text-center',
              },
            });
            return of(actionsEffects.RadicationAnalysisEditError({ error }));
          })
        );
      })
    )
  );
}
