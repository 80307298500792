import { Service, ServiceInput, ServiceDetail } from '../models/service.model';

export class ServiceMapper {
  /**
   * Mapea desde el formato AWS a nuestro modelo.
   * @param param - Parametros desde el backend.
   * @returns - Objeto de nuestro modelo.
   */
  public mapFrom(param: ServiceDetail): Service {
    return {
      code: param.code,
      description: param.description,
      automatic_protection: param.automatic_protection?.toString(),
      commission: param.commission ? param.commission.toString() : '0',
      created_date: param.created_date,
      enabled: param.enabled,
      iva: param.iva ? param.iva.toString() : '0',
      maximum_age: param.maximum_age ? param.maximum_age.toString() : '0',
      minimum_age: param.minimum_age ? param.minimum_age.toString() : '0',
      preselect: param.preselect,
      service_type: param.service_type,
      tariff: param.tariff.toString(),
      type_persons: param.type_persons.map(item => ({
        code: item.code_type_person.toString(),
        description: item.description,
      })),
      updated_date: param.updated_date,
      business_expenses: param.business_expenses.toString(),
      points: param.points.toString(),
      economic_activities: param.economic_activities.map(activity => ({
        code: activity.economic_activity?.toString(),
        description: activity.description,
      })),
      comissions_receiveds: param.comissions_receiveds.map(comission => ({
        code: comission.code_insurrance.toString(),
        commission_gift: comission.commission_gift,
        commission_sell: comission.commission_sell,
        description: comission.name_insurrance,
        is_gift: {
          code: String(comission.is_gift),
          description: comission.is_gift ? 'Si' : 'No',
        },
      })),
    };
  }
  /**
   * Mapea desde el formato AWS a nuestro modelo.
   * @param param - Parametros desde el backend.
   * @returns - Objeto de nuestro modelo.
   */
  public mapTo(param: Service): ServiceInput {
    return {
      description: param.description,
      enabled: param.enabled,
      service_type: param.service_type,
      maximum_age: Number(param.maximum_age),
      minimum_age: Number(param.minimum_age),
      tariff: Number(param.tariff),
      automatic_protection: param.automatic_protection
        ? Number(param.automatic_protection)
        : undefined,
      iva: Number(param.iva),
      commission: Number(param.commission),
      preselect: param.preselect,
      type_persons: param.type_persons.map(item => Number(item.code)),
      economic_activities: param.economic_activities.map(item =>
        Number(item.code)
      ),
      business_expenses: Number(param.business_expenses),
      points: Number(param.points),
      comissions_receiveds: param.comissions_receiveds.map(comission => ({
        code_insurrance: Number(comission.code),
        commission_gift: comission.commission_gift,
        commission_sell: comission.commission_sell,
        is_gift: JSON.parse(comission.is_gift.code),
      })),
    };
  }
}

