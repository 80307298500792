import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'apps/back-office/src/environments/environment';
import { errorHandler } from '@wp-back-office/app/global-information';
import {
  ReasingTaskNewUser,
  UsersReasingTask,
} from '../models/reasing-task.model';

/**
 * Servicio para procesos.
 */
@Injectable()
export class ProcessService {
  /**
   * Constante url.
   */
  private URL = environment.endpoints.url;
  /**
   * Constate tenant.
   */
  private TENANT_ID = environment.common.tenantId;

  /**
   * Crea una intancia de ProcessService.
   * @param http - Servidor cliente.
   */
  constructor(private http: HttpClient) {}

  /**
   * Actualizar tareas para grupos de usuarios.
   * @param group - Grupo.
   * @param user - Usuario.
   * @param id - Identificador.
   * @returns Observable.
   */
  public updateTaskGroupUser(
    group: string,
    user: string,
    id: string
  ): Observable<any> {
    return this.http
      .post(`${this.URL}/${this.TENANT_ID}/tasks/pending/group/assigned/user`, {
        id,
        group,
        user,
      })
      .pipe(catchError(errorHandler));
  }

  /**
   * Obtener los permisos del proceso por grupo.
   * @returns Observable.
   */
  public getProcessPermissions(): Observable<any> {
    return this.http
      .get(`${this.URL}/${this.TENANT_ID}/rba/process`)
      .pipe(catchError(errorHandler));
  }

  /**
   * Obtener los usuarios para reasignarle una tarea.
   * @param params - Parametros del servicio.
   * @returns Observable.
   */
  public getUsersReasingTask(params: UsersReasingTask): Observable<any> {
    return this.http
      .post(
        `${this.URL}/${this.TENANT_ID}/task-reassignment/task-available-users`,
        {
          ...params,
        }
      )
      .pipe(catchError(errorHandler));
  }

  /**
   * Reasignar una tarea a un nuevo usuario.
   * @param params - Parametros del servicio.
   * @returns Observable.
   */
  public reasingTaskNewUser(params: ReasingTaskNewUser): Observable<any> {
    return this.http
      .put(`${this.URL}/${this.TENANT_ID}/task-reassignment/user`, {
        ...params,
      })
      .pipe(catchError(errorHandler));
  }
}
