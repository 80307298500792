import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Caberceras dinamicas para tablas.
 */
@Component({
  selector: 'wp-back-office-dynamic-header-table',
  templateUrl: './dynamic-header-table.component.html',
  styleUrls: ['./dynamic-header-table.component.scss'],
})
export class DynamicHeaderTableComponent {
  /**
   * Evento cuando se crea una instancia.
   */
  @Output()
  public create: EventEmitter<boolean> = new EventEmitter();
  /**
   * Evento cuando se sube un archivo.
   */
  @Output()
  public upload: EventEmitter<boolean> = new EventEmitter();
  /**
   * Evento cuando se sube un archivo.
   */
    @Output()
    public update: EventEmitter<void> = new EventEmitter();
  /**
   * Titulo.
   */
  @Input()
  public title: string;
  /**
   * Titulo.
   */
  @Input()
  public subtitle: string;
  /**
   * Bandera para habilitar boton de crear.
   */
  @Input()
  public isCreate: boolean;

  /**
   * Bandera para habilitar boton de crear.
   */
  @Input()
  public isUpload: boolean;

  /**
   * Bandera para habilitar boton de crear.
   */
  @Input()
  public disableButton: boolean;
  /**
   * Bandera para habilitar boton de crear.
   */
  @Input()
  public titleButtonCreate: string;
  /**
   * Bandera para habilitar boton de subir.
   */
  @Input()
  public titleButtonUpload: string;
  /**
   * Icono.
   */
  @Input()
  public icon: string;

  /**
   * Icono.
   */
  @Input()
  public iconUpload: string;

  /**
   * Actualizar tabla.
   */
  @Input()
  public updateButton!: boolean;

  /**
   * Crea una instancia de DynamicHeaderTableComponent.
   */
  constructor() {
    this.title = 'No hay titulo';
    this.subtitle = '';
    this.isCreate = false;
    this.isUpload = false;
    this.icon = 'add';
    this.iconUpload = 'upload_file';
    this.disableButton = false;
    this.titleButtonCreate = 'Añadir';
    this.titleButtonUpload = 'Importar archivo';
  }

  /**
   * Evento del boton crear.
   */
  public onCreate() {
    this.create.emit(true);
  }

  /**
   * Evento del boton subir archivo.
   */
  public onUpload() {
    this.upload.emit(true);
  }
}
