import { Action, createReducer, on } from '@ngrx/store';
import { Catalogue } from '@wp-back-office/core/commons-backoffice';
import { ErrorHttp } from '../../../../models/errors.models';

import {
  KeyValueProductCategory,
  KeyValueProductCategoryError,
  KeyValueProductCategorySuccess,
} from '../../actions';

import { resetInitialState } from '../../../../root/store/actions/contents/reset-initial-state.actions';

/**
 * Estado llave valor.
 */

export interface KeyValueProductCategoryState {
  /**
   * Catalogo.
   */
  catalogue?: Catalogue[];
  /**
   * Bandera de cargado.
   */
  loaded: boolean;
  /**
   * Bandera de cargando.
   */
  loading: boolean;
  /**
   * Errores.
   */
  error?: ErrorHttp;
}

export const ProductCategoryInitialState: KeyValueProductCategoryState = {
  catalogue: undefined,
  loaded: false,
  loading: false,
  error: undefined,
};

const featureReducer = createReducer(
  ProductCategoryInitialState,
  on(resetInitialState, () => ({ ...ProductCategoryInitialState })),
  on(KeyValueProductCategory, state => ({ ...state, loading: true })),
  on(KeyValueProductCategorySuccess, (state, { keyValue }) => ({
    ...state,
    loading: false,
    loaded: true,
    catalogue: [...keyValue],
  })),
  on(KeyValueProductCategoryError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

/**
 * Reductor de .
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function keyValueProductCategoryReducer(
  state: KeyValueProductCategoryState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
