<div
  class="container-fluid dynamic-dialog"
  [ngClass]="'' + dialogConfig?.type + ''">
  <div class="row">
    <ng-container *ngIf="dialogConfig?.type" [ngSwitch]="dialogConfig?.type">
      <mat-icon
        [ngClass]="['loader-icon', 'pulse-rotation', 'p-0']"
        *ngSwitchCase="'error'">
        cancel
      </mat-icon>
      <mat-icon
        [ngClass]="['loader-icon', 'pulse-rotation', 'p-0']"
        *ngSwitchCase="'success'">
        check_circle
      </mat-icon>
      <mat-icon
        [ngClass]="['loader-icon', 'pulse-rotation', 'p-0']"
        *ngSwitchCase="'info'">
        info
      </mat-icon>
      <mat-icon
        [ngClass]="['loader-icon', 'pulse-rotation', 'p-0']"
        *ngSwitchCase="'warning'">
        warning
      </mat-icon>
      <mat-icon
        [ngClass]="['loader-icon', 'pulse-rotation', 'p-0']"
        *ngSwitchCase="'question'">
        help
      </mat-icon>
      <div
        *ngSwitchCase="'loading'"
        class="la-3x la-ball-fall w-fit-content d-block m-auto mb-3">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </ng-container>
  </div>
  <div
    class="row text-center dialog-header"
    *ngIf="dialogConfig?.header || false">
    <h1 [ngClass]="dialogConfig?.header?.textAlign || 'text-center'">
      {{ messageCode || dialogConfig?.header?.text }}
    </h1>
  </div>
  <div class="row dialog-body" *ngIf="dialogConfig?.body || false">
    <p
      *ngIf="dialogConfig?.body?.text"
      [ngClass]="dialogConfig?.body?.textAlign || 'text-justify'">
      {{ messageCode || dialogConfig?.body?.text }}
    </p>
    <div
      class="d-flex align-items-center px-2 py-1"
      *ngIf="dialogConfig?.body?.html"
      [ngClass]="dialogConfig?.body?.textAlign || 'text-justify'"
      id="htmlDiv"></div>
  </div>
  <div class="row dialog-footer" *ngIf="dialogConfig?.footer || false">
    <p
      *ngIf="dialogConfig?.footer?.text"
      [ngClass]="dialogConfig?.footer?.textAlign || 'text-justify'">
      {{ messageCode || dialogConfig?.footer?.text }}
    </p>
    <div
      class="d-flex align-items-center px-2 py-1"
      *ngIf="dialogConfig?.footer?.html"
      [innerHTML]="dialogConfig?.footer?.html"
      [ngClass]="dialogConfig?.footer?.textAlign || 'text-justify'"></div>
  </div>

  <mat-dialog-actions
    align="center"
    class="dialog-message-actions"
    *ngIf="dialogConfig!.type !== 'loading'">
    <ng-container
      *ngIf="!dialogConfig!.actions || dialogConfig!.actions!.length === 0">
      <ng-container *ngIf="dialogConfig!.type === 'question'">
        <button mat-flat-button [mat-dialog-close]="false">no</button>
        <button mat-flat-button class="pricipal" [mat-dialog-close]="true">
          si
        </button>
      </ng-container>
      <ng-container *ngIf="dialogConfig!.type !== 'question'">
        <button mat-flat-button class="principal" [mat-dialog-close]="true">
          ok
        </button>
      </ng-container>
    </ng-container>

    <ng-container
      *ngIf="dialogConfig!.actions && dialogConfig!.actions!.length > 0">
      <ng-container *ngIf="dialogConfig!.type">
        <ng-container
          *ngIf="dialogConfig!.actions && dialogConfig!.actions!.length > 0">
          <button
            mat-flat-button
            *ngFor="let button of dialogConfig!.actions"
            [mat-dialog-close]="button.actionValue"
            [class.pricipal]="button.principal">
            {{ button.text }}
            <mat-icon *ngIf="button.icon">{{ button.icon }}</mat-icon>
          </button>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!dialogConfig!.type">
        <ng-container
          *ngIf="dialogConfig!.actions && dialogConfig!.actions!.length > 0">
          <button
            mat-flat-button
            *ngFor="let button of dialogConfig!.actions"
            [color]="button.color"
            [mat-dialog-close]="button.actionValue">
            {{ button.text }}
            <mat-icon *ngIf="button.icon">{{ button.icon }}</mat-icon>
          </button>
        </ng-container>
      </ng-container>
    </ng-container>
  </mat-dialog-actions>
</div>
