import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueLegalreviewApprovalStatus = createAction(
  '[KeyValueLegalreviewApprovalStatus] Cargar Catalogos',
  props<{
    /**
     * Ruta.
     */
    path: CatalogueType;
  }>()
);

export const KeyValueLegalreviewApprovalStatusSuccess = createAction(
  '[KeyValueLegalreviewApprovalStatus] Crear Catalogos Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueLegalreviewApprovalStatusError = createAction(
  '[KeyValueLegalreviewApprovalStatus] Cargar Catalogos Error',
   props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
