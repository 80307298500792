import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { AddTabComponent } from './components/dialogs/add-tab/add-tab.component';
import { FactoryTabDirective } from './directives/factory-tab.directive';
import { DynamicTabsGeneralComponent } from './components/dynamic-tabs-general/dynamic-tabs-general.component';
import { DynamicTabsComponent } from './components/dynamic-tabs/dynamic-tabs.component';
import { FactoryTabGeneralDirective } from './directives/factory-tab-general.directive';
import { DynamicFormModule } from '../dynamic-form';
import { DynamicButtonModule } from '../dynamic-button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicHeaderTableModule } from '../dynamic-header-table';
import { MatTooltipModule } from '@angular/material/tooltip';

/**
 * Modulo de tabulacion.
 */
@NgModule({
  declarations: [
    DynamicTabsComponent,
    DynamicTabsGeneralComponent,
    AddTabComponent,
    FactoryTabDirective,
    FactoryTabGeneralDirective,
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatDividerModule,
    MatSelectModule,
    MatDialogModule,
    DynamicFormModule,
    DynamicButtonModule,
    DynamicHeaderTableModule,
    MatTooltipModule
  ],
  exports: [
    DynamicTabsComponent,
    DynamicTabsGeneralComponent,
    AddTabComponent,
    FactoryTabDirective,
  ],
})
export class DynamicTabsModule {}
