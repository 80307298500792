<ng-template [ngIf]="controlConfig && controlConfig.key && controlConfig.type">
  <mat-form-field
    [appearance]="controlConfig.appearance || 'outline'"
    class="{{ controlConfig.classes || '' }}">
    <mat-label>
      {{ controlConfig.label || '' }}
    </mat-label>
    <input
      matInput
      type="datetime-local"
      [formControl]="_formControl"
      [id]="uniqueIdForm + controlConfig.key"
      placeholder="Fecha de vigencia" />
    <mat-error
      [innerHTML]="
        dynamicFormService.errorMessage(_formControl, controlConfig)
      ">
    </mat-error>
  </mat-form-field>
</ng-template>
