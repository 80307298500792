/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WorkflowService } from '@wp-back-office/core/workflow-sdk';
import { map, catchError, of, withLatestFrom, mergeMap } from 'rxjs';

import * as actionsEffects from '../../actions';
import { createSelectorInject, ProcessState } from '@wp-back-office/core/store';
import { Store } from '@ngrx/store';
import { RadicationTaskPost } from 'libs/core/workflow-sdk/src/lib/models/post-data.model';

/**
 * Efecto de inciar tarea.
 */
@Injectable()
export class StepProcessEffects {
  /**
   * Crea una instancia de StepProcessEffects.
   * @param actions$ - Acciones.
   * @param workflowService - Servicio workflow.
   * @param storeProcess - Tienda del proceso.
   */
  constructor(
    private actions$: Actions,
    private workflowService: WorkflowService,
    private storeProcess: Store<ProcessState>
  ) {}

  /**
   * Iniciar Tarea.
   */
  public Radication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.RadicationProcess),
      withLatestFrom(
        this.storeProcess.select(createSelectorInject('Payload', 'Process'))
      ),
      // filter(([, state]) => {
      //   const item = state.taskSchema;
      //   if (
      //     (!item.loaded && item.loading) ||
      //     (item.loaded && !item.loading && item.error)
      //   ) {
      //     return false;
      //   }

      //   return true;
      // }),
      mergeMap(
        ([, { params, basicInformation, product, detailInformation }]) => {
          const radicationTaskPost: RadicationTaskPost = {
            idProcess: params.idExecution,
            process: params.process,
            date: params.date,
            publisher: true,
            taskToken: params.taskToken,
            taskResult: {
              basicInformation,
              product,
              detailInformation,
            },
          };
          return this.workflowService
            .radicationSuccess(radicationTaskPost)
            .pipe(
              map((response: any) => {
                return actionsEffects.RadicationProcessSuccess({ response });
              }),
              catchError(error => {
                return of(actionsEffects.RadicationProcessErrors({ error }));
              })
            );
        }
      )
    )
  );
}

/**
 * Efecto de consultar tipos de documentos.
 */
@Injectable()
export class DocumentTypesEffects {
  /**
   * Crea una instancia de DocumentTypesEffects.
   * @param actions$ - Acciones.
   * @param workflowService - Servicio workflow.
   */
  constructor(
    private actions$: Actions,
    private workflowService: WorkflowService
  ) {}

  /**
   * Efecto para obtener lista interna.
   */
  public Documents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.DocumentTypes),
      mergeMap(() =>
        this.workflowService.documentTypes().pipe(
          map((object: any) =>
            actionsEffects.DocumentTypesSuccess({ response: object })
          ),
          catchError(err =>
            of(actionsEffects.DocumentTypesErrors({ error: err }))
          )
        )
      )
    )
  );
}

/**
 * Efecto de crear documentos.
 */
@Injectable()
export class CreateDocumentTypesEffects {
  /**
   * Crea una instancia de CreateDocumentTypesEffects.
   * @param actions$ - Acciones.
   * @param workflowService - Servicio workflow.
   */
  constructor(
    private actions$: Actions,
    private workflowService: WorkflowService
  ) {}

  /**
   * Efecto para obtener lista interna.
   */
  public Documents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.CreateDocumentTypes),
      mergeMap(params =>
        this.workflowService.createDocumentTypes(params.formData).pipe(
          map((object: any) =>
            actionsEffects.CreateDocumentTypesSuccess({ response: object })
          ),
          catchError(err =>
            of(actionsEffects.CreateDocumentTypesErrors({ error: err }))
          )
        )
      )
    )
  );
}

/**
 * Efecto de eliminar documentos.
 */
@Injectable()
export class DeleteDocumentTypesEffects {
  /**
   * Crea una instancia de DeleteDocumentTypesEffects.
   * @param actions$ - Acciones.
   * @param workflowService - Servicio workflow.
   */
  constructor(
    private actions$: Actions,
    private workflowService: WorkflowService
  ) {}

  /**
   * Efecto para obtener lista interna.
   */
  public Documents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.DeleteDocumentTypes),
      mergeMap(params =>
        this.workflowService
          .deleteDocumentTypes(params.documentName, params.taskToken)
          .pipe(
            map((object: any) =>
              actionsEffects.DeleteDocumentTypesSuccess({ response: object })
            ),
            catchError(err =>
              of(actionsEffects.DeleteDocumentTypesErrors({ error: err }))
            )
          )
      )
    )
  );
}
