import { Action, createReducer, on } from '@ngrx/store';
import { AuditDetail } from 'libs/app/dashboard/src/lib/models/audit-detail.model';
import { ErrorHttp } from '../../../../models/errors.models';
import { auditSummaryActions, resetAuditProcess } from '../../actions';
import { TaskExecution } from 'libs/core/workflow-sdk/src/lib/models/task-execution.model';

/**
 * Estado de temas.
 */
export interface DataProcess {
  /**
   * Nombre del proceso.
   */
  process?: string;
  /**
   * Identificador de la tarea.
   */
  idTask?: string;
  /**
   * Nombre de la tarea.
   */
  taskName?: string;
  /**
   * Fecha de creación.
   */
  personaNatural?: string;
  /**
   * Fecha de creación.
   */
  saleChannel?: string;
  /**
   * Identificador externo.
   */
  externalId?: string;
  /**
   * Fecha de creación.
   */
  dateCreated?: string;
  /**
   * Token de la tarea.
   */
  taskToken?: string;
  /**
   * Respuesta.
   */
  response?: any;
}

/**
 * Reducer para obtener el detalle de la auditoria.
 */
export interface AuditDetailStateNew {
  /**
   * Estado.
   */
  status?: string;
  /**
   * Objeto.
   */
  data?: AuditDetail;
  /**
   * Cargó el estado.
   */
  loaded: boolean;
  /**
   * Cargando el estado.
   */
  loading: boolean;
  /**
   * Error.
   */
  error?: ErrorHttp;
}

export const getDetailProcess: AuditDetailStateNew = {
  data: undefined,
  loaded: false,
  loading: false,
  error: undefined,
  status: undefined,
};

const featureReducer = createReducer(
  getDetailProcess,
  on(resetAuditProcess, () => ({ ...getDetailProcess })),
  on(auditSummaryActions.getDetailProcessAuditReset, () => ({
    ...getDetailProcess,
  })),
  on(auditSummaryActions.getDetailProcessAudit, state => ({
    ...state,
    object: undefined,
    loading: true,
    loaded: false,
    error: undefined,
  })),
  on(auditSummaryActions.getDetailProcessAuditSuccess, (state, response) => ({
    ...state,
    ...response,
    loaded: true,
    loading: false,
  })),
  on(auditSummaryActions.getDetailProcessAuditError, (state, { error }) => ({
    ...state,
    loaded: true,
    loading: false,
    error,
  }))
);

/**
 * Reductor iniciar proceso.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function AuditDetailReducerNew(
  state: AuditDetailStateNew | undefined,
  action: Action
) {
  return featureReducer(state, action);
}

//////////////

/**
 * Estado de temas.
 */
export interface ProcessInformation {
  /**
   * Formularios.
   */
  data?: DataProcess;
  /**
   * Cargado.
   */
  loaded?: boolean;
  /**
   * Cargando.
   */
  loading?: boolean;
}

/**
 * Estado de temas.
 */
export interface PayloadAuditState {
  /**
   * Configuracion temas.
   */
  information?: ProcessInformation;
}

export const initialPayloadAuditState: PayloadAuditState = {};

const payloadFeatureReducer = createReducer(
  initialPayloadAuditState,
  on(resetAuditProcess, () => ({
    ...initialPayloadAuditState,
  })),
  on(auditSummaryActions.getProcessData, state => ({
    ...state,
    information: {
      data: undefined,
      loaded: false,
      loading: true,
    },
  })),
  on(auditSummaryActions.getProcessDataSuccess, (state, { response }) => ({
    ...state,
    information: {
      data: {
        process: response.process,
        idTask: response.id,
        taskName: response.taskName,
        personaNatural: response?.taskInformation?.personaNatural,
        saleChannel: response.taskInformation.saleChannel,
        externalId: response.externalId,
        dateCreated: response.dateCreated,
        taskToken: response?.taskInformation?.executionData?.Task?.Token,
        response: response,
        applicationFiling: response.taskInformation.applicationData
          .applicationFiling.Payload
          ? response.taskInformation.applicationData.applicationFiling.Payload
          : response.taskInformation.applicationData.applicationFiling,
      },
      loaded: true,
      loading: false,
    },
  })),
  on(auditSummaryActions.getProcessDataError, state => ({
    ...state,
    initialPayloadAuditState,
  }))
);

/**
 * Reductor autenticación.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function PayloadAuditReducer(
  state: PayloadAuditState | undefined,
  action: Action
) {
  return payloadFeatureReducer(state, action);
}

/**
 * Estado inicial.
 */
export interface AuditProcessExecutionState {
  /**
   * Informacion de l tarea.
   */
  information?: InformationProcessExecutionState;
}
/**
 * Estado de la informacion.
 */
export interface InformationProcessExecutionState {
  /**
   * Data.
   */
  data?: TaskExecution;
  /**
   * Bandera de cargado.
   */
  loaded: boolean;
  /**
   * Bandera de cargando.
   */
  loading: boolean;
}

export const initialAuditProcessExecutionState: AuditProcessExecutionState = {};

const auditProcessExecutionFeatureReducer = createReducer(
  initialAuditProcessExecutionState,
  on(resetAuditProcess, () => ({
    ...initialAuditProcessExecutionState,
  })),
  on(auditSummaryActions.getAuditProcessExecutionReset, () => ({
    ...initialAuditProcessExecutionState,
  })),
  on(auditSummaryActions.getAuditProcessExecution, state => ({
    ...state,
    information: {
      data: undefined,
      loaded: false,
      loading: true,
    },
  })),
  on(
    auditSummaryActions.getAuditProcessExecutionSuccess,
    (state, { response }) => ({
      ...state,
      information: {
        data: response,
        loaded: true,
        loading: false,
      },
    })
  ),
  on(auditSummaryActions.getAuditProcessExecutionError, state => ({
    ...state,
    initialAuditProcessExecutionState,
  }))
);

/**
 * Reductor.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function auditProcessExecutionReducer(
  state: AuditProcessExecutionState | undefined,
  action: Action
) {
  return auditProcessExecutionFeatureReducer(state, action);
}
