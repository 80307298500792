<div class="contianer-fluid">
  <h2 mat-DynamicDialogMessageService-title class="text-center">
    {{ title }}
  </h2>
  <mat-divider class="mb-4"></mat-divider>
  <mat-dialog-content class="pt-2 pb-2">
    <wp-back-office-dynamic-form #formID [controls]="controls || []">
    </wp-back-office-dynamic-form>
  </mat-dialog-content>
  <mat-divider class="mt-4"></mat-divider>
  <mat-dialog-actions align="end">
    <button
      mat-flat-button
      color="accent"
      mat-dialog-close
      *ngIf="dialogData?.onlyClose">
      Cerrar
    </button>

    <ng-container *ngIf="!dialogData?.onlyClose">
      <button mat-flat-button color="accent" (click)="close()">Cancelar</button>
      <button
        mat-flat-button
        color="primary"
        (click)="submit()"
        [disabled]="formPepComponent?.formGroupGeneric?.invalid || false">
        {{ this.dialogData.value ? 'Modificar' : 'Agregar' }}
      </button>
    </ng-container>
  </mat-dialog-actions>
</div>
