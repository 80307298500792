import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueTypeService = createAction(
  '[KeyValueTypeService] Cargar tipos de servicio',
  props<{
    /**
     * Ruta.
     */
    path: CatalogueType;
  }>()
);

export const KeyValueTypeServiceSuccess = createAction(
  '[KeyValueTypeService] Cargar tipo de servicio exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueTypeServiceError = createAction(
  '[KeyValueTypeService] Cargar tipos de servicio Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
