import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as reducers from './reducers';

/**
 * Estado de listas internas.
 */
export interface DashBoardState {
  /**
   * Estado de grupos.
   */
  queqe: reducers.QueqeState;
  /**
   * Estado de los temas.
   */
  theme: reducers.ThemeState;
  /**
   * Estado de los temas.
   */
  session: reducers.SessionState;
  /**
   * Obtener las tareas de un usuario.
   */
  GetUserTasks?: reducers.StateDataTasks;
  /**
   * Reasignar tarea de un usuario a otro.
   */
  PutReasingTaskUser?: reducers.StateDataTasks;
  /**
   * Obtener los usuarios a los que se puede reasignar una tarea.
   */
  GetTaskUsers?: reducers.StateDataTasks;
  /**
   * Reducer para obtener imagen de perfil.
   */
  GetUserProfile?: reducers.StateDataUserProfile;
  /**
   * Tareas del usuario.
   */
  UserProcess: reducers.UserProcessState;
  /**
   * Tareas.
   */
  Task: reducers.TaskPendingState;
}

export const dashBoardReducers: ActionReducerMap<DashBoardState> = {
  /**
   * Reducers grupos.
   */
  queqe: reducers.QueqeReducer,
  /**
   * Reducer temas.
   */
  theme: reducers.ThemeReducer,
  /**
   * Estado de los temas.
   */
  session: reducers.SessionReducer,
  /**
   * Obtener las tareas de un usuario.
   */
  GetUserTasks: reducers.getUserTasksReducer,
  /**
   * Actualizar tarea.
   */
  PutReasingTaskUser: reducers.putReasingTaskUserReducer,
  /**
   * Obtener los usuarios a los cuales se puede reasignar una tarea.
   */
  GetTaskUsers: reducers.getTaskUsersReducer,
  /**
   * Reducer para obtener imagen de perfil.
   */
  GetUserProfile: reducers.getUserProfileReducer,
  /**
   * Tareas del usuario.
   */
  UserProcess: reducers.UserProcessReducer,
  /**
   * Tareas.
   */
  Task: reducers.TaskPendingReducer,
};

/**
 * Selector de token de acesso.
 * @param state - Estado.
 * @returns Token.
 */
export const featureDashBoard =
  createFeatureSelector<DashBoardState>('dashboard');
