/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ControlConfig,
  DynamicFormComponent,
} from 'libs/shared/dynamic-components/src/dynamic-form';
import { CatalogueList } from '../../../models/tabs-config.model';
import { Catalogue } from '@wp-back-office/core/commons-backoffice';

/**
 * Componente Adicion tab.
 */
@Component({
  selector: 'wp-back-office-add-tab',
  templateUrl: './add-tab.component.html',
  styleUrls: ['./add-tab.component.scss'],
})
export class AddTabComponent {
  /**
   * Formulario.
   */
  public form!: ControlConfig[];
  /**
   * Opciones.
   */
  public options: any[];
  /**
   * Form control del campo.
   */
  public selectControl: FormControl;
  /**
   * Componente de informacion de contacto.
   */
  @ViewChild('formID', {
    static: false,
    read: DynamicFormComponent,
  })
  public formComponent?: DynamicFormComponent;
  /**
   * Crea una instancia de FormWorkAddApplicantComponent.
   * @param dialogRef - Referencia del Dialog actual.
   * @param data - Data que recibe el Dialog desde el componente.
   */
  constructor(
    private dialogRef: MatDialogRef<AddTabComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.options = this.data.options.filter(
      (item: Catalogue) => item !== undefined
    );
    this.selectControl = new FormControl('');
    if (data.catalogueList) {
      const catalogueList: CatalogueList[] = data.catalogueList;
      this.form = catalogueList
        .filter(item => item !== undefined)
        .map(value => {
          return {
            label: value.label,
            type: 'DropDown',
            key: value.key,
            options: value.catalogue,
            validators: {
              required: true,
            },
            col: {
              xxl: 12,
              xl: 12,
              lg: 12,
              md: 12,
              sm: 12,
              xs: 12,
            },
          };
        });
    }
  }

  /**
   * Envia la data del formulario al componente.
   * @param select - Seleccion.
   */
  public onChangeForm(select: any): void {
    this.dialogRef.close(select.value);
  }
}
