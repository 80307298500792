import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DecisionTreesService } from '@wp-back-office/core/workflow-sdk';
import { mergeMap, map, catchError, of } from 'rxjs';
import * as actionsEffects from '../../actions';

/**
 *
 */
@Injectable()
export class DecisionTreesEffects {
  /**
   * Crea una instancia DecisionTreesEffects.
   * @param actions$ - Actions.
   * @param decisionTreesService - Servicio workflow.
   */
  constructor(
    private actions$: Actions,
    private decisionTreesService: DecisionTreesService
  ) {}

  /**
   * Efecto para obtener lista de vehiculos.
   */
  public ListDecisionTrees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.decisionTreesList),
      mergeMap(() =>
        this.decisionTreesService.getDecisionTrees().pipe(
          map((object) =>
            actionsEffects.decisionTreesListSuccess({ object })
          ),
          catchError(err =>
            of(actionsEffects.decisionTreesListError({ err: err }))
          )
        )
      )
    )
  );
}
