/**
 * Convierte array a tipo Map o Diccionario.
 * @param array - Arreglo a convertir.
 * @param uniqueKey - Funcion que retorne la clave unica.
 * @returns - Map.
 */
export function convertArrayToMap<T>(
  array: T[],
  uniqueKey: (element: T) => string
) {
  return array.reduce((map, obj) => {
    map.set(uniqueKey(obj), obj);
    return map;
  }, new Map<string, T>());
}
