import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueListInsurers = createAction(
  '[KeyValueListInsurers] Cargar Catalogos',
  props<{
    /**
     * Payload.
     */
    path: CatalogueType;
  }>()
);

export const KeyValueListInsurersSuccess = createAction(
  '[KeyValueListInsurers] Crear Catalogos Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueListInsurersError = createAction(
  '[KeyValueListInsurers] Cargar Catalogos Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
