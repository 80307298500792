import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import {
  Mask,
  TableConfig,
  TableSizes,
  TypeOf,
} from '../models/table-config.model';
import { Utils, convertUnixDate } from '@wp-back-office/app/global-information';

/**
 * Servicio de tablas dinamicas.
 */
@Injectable()
export class DynamicTableService {
  /**
   * Servicio de tablas dinamicas.
   * @param utils - Utilidades.
   */
  constructor(private utils: Utils) {}
  /**
   * Crear un id unico.
   * @returns String.
   */
  private createId(): string {
    return (
      '_' +
      Math.random().toString(36).substring(2, 9) +
      Math.random().toString(36).substring(2, 9)
    );
  }

  /**
   * Obtener el index del array de datos de la tabla con el id.
   * @param appTableConfig - Configuración actual de la tabla.
   * @param id - Id del row.
   * @returns Number.
   */
  public getTableIndex(appTableConfig: TableConfig, id: any) {
    const index: number = this.getTableConfigValue(
      appTableConfig
    ).data.findIndex((x: any) => x.backOfficeTableRowId === id);
    return index;
  }

  /**
   * Obtener el valor de la configuracion de la tabla.
   * @param appTableConfig - Configuración actual de la tabla.
   * @returns TableConfig.
   */
  public getTableConfigValue(appTableConfig: TableConfig | undefined) {
    if (appTableConfig) {
      return JSON.parse(JSON.stringify(appTableConfig));
    }
    return undefined;
  }

  /**
   * Obtener el los rows de la tabla.
   * @param appTableConfig - Configuración actual de la tabla.
   * @returns TableConfig.
   */
  public getTableData(appTableConfig: TableConfig | undefined) {
    return appTableConfig
      ? this.getTableConfigValue(appTableConfig).data
      : undefined;
  }

  /**
   * Obtener un row de la tabla con el id.
   * @param appTableConfig - Configuración actual de la tabla.
   * @param id - Id del row.
   * @returns Object.
   */
  public getTableItem(appTableConfig: TableConfig | undefined, id: any) {
    return appTableConfig
      ? this.getTableConfigValue(appTableConfig).data.find(
          (x: any) => x.backOfficeTableRowId === id
        )
      : undefined;
  }

  /**
   * Agrega un row a la tabla.
   * @param appTableConfig - Configuración actual de la tabla.
   * @param row - Row de la tabla.
   * @returns TableConfig.
   */
  public addRow(appTableConfig: TableConfig | undefined, row: any) {
    if (appTableConfig) {
      const object = {
        backOfficeTableRowId: this.createId(),
        ...row,
      };
      const newRow = {
        ...this.getTableConfigValue(appTableConfig),
        data: [
          ...(this.getTableConfigValue(appTableConfig).data || []),
          object,
        ],
      };
      return newRow;
    }
    return undefined;
  }

  /**
   * Elimina un row de la tabla con el id.
   * @param appTableConfig - Configuración actual de la tabla.
   * @param id - Id del row.
   * @returns TableConfig.
   */
  public removeData(appTableConfig: TableConfig | undefined, id: any) {
    if (appTableConfig) {
      const tableData: any[] = this.getTableConfigValue(appTableConfig).data;
      const index: number = this.getTableIndex(appTableConfig, id);
      tableData.splice(index, 1);
      const data = {
        ...this.getTableConfigValue(appTableConfig),
        data: tableData,
      };
      return data;
    }
    return undefined;
  }

  /**
   * Modifica un row de la tabla.
   * @param appTableConfig - Configuración actual de la tabla.
   * @param id - Id del row.
   * @param newRow - Nuevo row.
   * @returns TableConfig.
   */
  public editRow(
    appTableConfig: TableConfig | undefined,
    id: any,
    newRow: any
  ) {
    if (appTableConfig) {
      const object = {
        id,
        ...newRow,
      };
      const modify = this.getTableConfigValue(appTableConfig);
      const index = this.getTableIndex(appTableConfig, id);
      modify.data[index] = object;
      return modify;
    }
    return undefined;
  }

  /**
   * Modifica la data de la tabla.
   * @param appTableConfig - Configuración actual de la tabla.
   * @param newData - Data nueva de la tabla.
   * @returns TableConfig.
   */
  public editData(appTableConfig: TableConfig | undefined, newData: any) {
    const modify = this.getTableConfigValue(appTableConfig);
    modify.data = newData;
    return modify;
  }

  /**
   * Metodo para validar un tipo de dato y retornar siempre string.
   * @param data - Celda de la tabla.
   * @param mask - Mascara de la celda.
   * @param filter - Valida si la tabla está filtrando para retorar un string con el json.
   * @returns Object || string.
   */
  public getValue(data: any, mask?: Mask, filter: boolean = false): any {
    switch (this.typeOf(data)) {
      case 'null' || 'undefined':
        return 'No Aplica.';
      case 'array':
        if (data.length > 0 && data[0].name) {
          const names = data.map((data: any) => `${data.name} `);
          return names.toString();
        } else {
          return data;
        }
      case 'object':
        if ('description' in data) {
          return data.description || '';
        } else {
          return JSON.stringify(data);
        }
      case 'boolean':
        if (filter) {
          return data;
        } else {
          if (mask?.booleanChips?.falseValue) {
            return mask?.booleanChips?.falseValue;
          } else if (mask?.booleanChips?.trueValue) {
            return mask?.booleanChips?.trueValue;
          } else {
            return JSON.stringify(data);
          }
        }
      case 'string':
        if (mask?.currency) {
          return '$' + Number(data).toLocaleString('de-DE').toString();
        } else if (data === '0000-00-00T00:00:00.000Z') {
          return 'No Aplica.';
        } else if (mask?.date === 'TIMEZONE') {
          return moment(data).format('DD/MM/YYYY HH:MM:SS').toString();
        } else {
          return data;
        }
      case 'number':
        if (mask?.currency) {
          return '$' + data.toLocaleString('de-DE').toString();
        } else if (mask?.date === 'UNIXTIMESTAMP') {
          return convertUnixDate(data);
        } else {
          return data;
        }
      case 'date':
        return moment(data).format('DD/MM/YYYY HH:MM:SS').toString();
      default:
        return data;
    }
  }

  /**
   * Metodo para validar el tipo de dato.
   * @param data - Dato a validar.
   * @returns TypeOf.
   */
  public typeOf(data: any): TypeOf {
    if (data instanceof Date) {
      return 'date';
    } else if (Array.isArray(data)) {
      if (data?.length && data.length > 0 && data[0].name) {
        return 'fileArray';
      } else {
        return 'array';
      }
    } else if (data === null) {
      return 'null';
    } else {
      return typeof data;
    }
  }

  /**
   * Metodo para validar si un texto coincide con otro, util para los filtros.
   * @param text1 - Texto filtrado.
   * @param text2 - Texto a filtrar.
   * @returns Boolean.
   */
  public existIn(text1: string, text2: string): boolean {
    return text1.toLowerCase().includes(text2.toLowerCase());
  }

  /**
   * Metodo para cambiar el texto de los rangos del paginador.
   * @param page - Pagina.
   * @param pageSize - Tamaño de pagina.
   * @param length - Tamaño del rango.
   * @returns String.
   */
  public getRangeLabel = (
    page: number,
    pageSize: number,
    length: number
  ): string => {
    if (length === 0 || pageSize === 0) {
      return `0 de ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} hasta ${endIndex} de ${length}`;
  };

  // eslint-disable-next-line jsdoc/require-returns-check
  /**
   * Obtener el valor de un input.
   * @param e - Event.
   * @param type - Tipo.
   * @returns Valor.
   */
  public getInputValue(e: any, type: string) {
    switch (type) {
      case 'input':
        return e.target.value;

      case 'select':
        return e.value;

      case 'checkbox':
        return e.checked;
      default:
        break;
    }
  }
}
