import { createAction, props } from '@ngrx/store';
import { CatalogueSBS } from '@wp-back-office/core/commons-backoffice';
import { CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueSBSGender = createAction(
  '[KeyValueSBSGender] Cargar generos por id',
  props<{
    /**
     * Identificador.
     */
    path: CatalogueType;
  }>()
);

export const KeyValueSBSGenderSuccess = createAction(
  '[KeyValueSBSGender] Crear generos Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: CatalogueSBS[];
  }>()
);

export const KeyValueSBSGenderError = createAction(
  '[KeyValueSBSGender] Cargar generos Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
