import { createAction, props } from '@ngrx/store';
import { SignInInformation } from '@wp-back-office/app/global-information';

export const Loggin = createAction(
  '[Loggin] Iniciar sesión',
  props<{
    /**
     *
     */ signInInformation: SignInInformation;
  }>()
);

export const LogginSuccess = createAction(
  '[Loggin] Iniciar sesión Exitoso',
  props<{
    /**
     *
     */ isLoggin: boolean;
  }>()
);

export const LogginError = createAction(
  '[Loggin] Iniciar sesión Error',
  props<{
    /**
     *
     */ payload: any;
  }>()
);
