import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueUnitQuota = createAction(
  '[KeyValueUnitQuota] Cargar unidad quota por id',
  props<{
    /**
     * Identificador.
     */
    id: CatalogueType;
  }>()
);

export const KeyValueUnitQuotaSuccess = createAction(
  '[KeyValueUnitQuota] Crear unidad quota Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueUnitQuotaError = createAction(
  '[KeyValueUnitQuota] Cargar unidad quota Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
