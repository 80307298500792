<ng-template [ngIf]="controlConfig && controlConfig.key && controlConfig.type">
  <div class="file-button text-center mb-3">
    <div
      class="cropper-container"
      *ngIf="controlConfig.fileOptions?.showImagePreview">
      <div id="img-preview" [hidden]="!hideCropper">
        <div class="input-spinner" *ngIf="loading">
          <mat-spinner color="primary" [diameter]="50"> </mat-spinner>
        </div>
        <mat-icon>add_photo_alternate</mat-icon>
        <ng-container *ngIf="controlConfig.fileOptions!.cropper">
          <div
            class="image-crop"
            *ngIf="imageChangedEvent"
            (click)="hideCropper = false">
            <mat-icon>crop</mat-icon>
          </div>
        </ng-container>
      </div>
      <image-cropper
        *ngIf="controlConfig.fileOptions!.cropper"
        [class.hidden]="hideCropper"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="1 / 1"
        [roundCropper]="true"
        [containWithinAspectRatio]="true"
        [resizeToWidth]="500"
        [resizeToHeight]="500"
        format="png"
        (imageCropped)="imageCropped($event)"
        (startCropImage)="startCropImage()"
        (loadImageFailed)="loadImageFailed()">
      </image-cropper>
      <button
        *ngIf="controlConfig.fileOptions!.cropper"
        [class.hidden]="hideCropper"
        mat-flat-button
        mat-icon-button
        class="p-0"
        color="accent"
        (click)="hideCropper = true">
        <mat-icon>done</mat-icon>
      </button>
    </div>

    <ng-container
      *ngIf="
        controlConfig.fileOptions?.dragAndDrop &&
        !controlConfig.fileOptions?.showImagePreview
      ">
      <div
        id="drop_zone"
        (drop)="dropHandler($event)"
        (dragover)="dragOverHandler($event)">
        <div class="d-flex">
          <div class="d-block">
            <mat-icon class="accent-text"> backup </mat-icon>
            <div class="d-flex">
              <a (click)="file.click()" class="mx-1"
                ><u class="fw-700">Da clic para cargar tu archivo</u></a
              >
              <p>o arrástralo y suéltalo aquí.</p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!controlConfig.fileOptions?.dragAndDrop">
      <div class="d-flex p-relative">
        <button
          class="mx-auto text-center mb-3"
          mat-flat-button
          type="submit"
          color="accent"
          matTooltip="Seleccionar {{
            controlConfig.validators?.multiple ? 'archivos' : 'archivo'
          }}"
          matBadgePosition="after"
          (click)="file.click()">
          <mat-icon class="iconStart"> file_upload </mat-icon>
          {{ controlConfig.label || '' }}
          <mat-icon class="iconEnd">
            {{ controlConfig.icon ? controlConfig.icon : '' }}
          </mat-icon>
        </button>

        <mat-icon
          *ngIf="controlConfig?.tooltip"
          class="subtitle tooltip-icon"
          [matTooltip]="controlConfig.tooltip || ''"
          >info</mat-icon
        >
      </div>
    </ng-container>

    <mat-list class="file-list">
      <mat-list-item
        disabled
        *ngIf="(_formControl.value || []).length === 0"
        [matTooltip]="file.name">
        <mat-icon matListItemIcon>attach_file_add</mat-icon>
        <div matListItemTitle class="mx-2">No hay archivos seleccionados.</div>
      </mat-list-item>
      <mat-list-item
        *ngFor="let file of _formControl.value || []"
        [matTooltip]="file.name">
        <mat-icon matListItemIcon class="accent-text">attach_file</mat-icon>
        <div matListItemTitle class="mx-2">
          {{ file.name }}
        </div>
      </mat-list-item>
    </mat-list>

    <mat-progress-bar
      class="my-2"
      *ngIf="controlConfig.fileOptions?.progressBar"
      [color]="'accent'"
      [mode]="controlConfig.fileOptions?.dragAndDrop?.mode || 'indeterminate'"
      [value]="controlConfig.progressValue || 0">
    </mat-progress-bar>

    <input
      mat-flat-button
      type="file"
      [accept]="
        controlConfig.validators?.accept
          ? controlConfig.validators?.accept
          : controlConfig.fileOptions?.showImagePreview
          ? 'image/*'
          : '*'
      "
      [required]="controlConfig.validators?.required ? true : false"
      #file
      hidden
      [multiple]="controlConfig.validators?.multiple"
      id="choose-file"
      name="choose-file"
      (change)="fileChangeEvent($event)" />
  </div>
</ng-template>
