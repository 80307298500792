import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueTypeCustomers = createAction(
  '[KeyValueTypeCustomers] Cargar tipo de clientes por id',
  props<{
    /**
     * Identificador.
     */
    id: CatalogueType;
  }>()
);

export const KeyValueTypeCustomersSuccess = createAction(
  '[KeyValueTypeCustomers] Crear tipo de clientes Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueTypeCustomersError = createAction(
  '[KeyValueTypeCustomers] Cargar tipo de clientes Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
