import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { FileUploadComponent } from './components/file-upload/file-upload.component';

/**
 * Modulo de entidades.
 */
@NgModule({
  declarations: [FileUploadComponent],
  imports: [CommonModule, HttpClientModule],
  exports: [FileUploadComponent],
})
export class UtilitiesBackofficeModule {}
