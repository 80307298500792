import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Catalogue } from '@wp-back-office/core/store';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';

export const officesSegmentationActions = createActionGroup({
  source: 'Office for Segmentation',
  events: {
    Reset: emptyProps(),
    'Get List': props<{
      /**
       * Codigo de red concesionario.
       */
      code: string;
      /**
       * Pagina.
       */
      page?: number;
      /**
       * Tamaño de la pagina.
       */
      pageSize?: number;
    }>(),
    Success: props<{
      /**
       * Caracteristicas.
       */
      object: Catalogue[];
    }>(),
    Error: props<{
      /**
       * Respuesta error Http.
       */
      error: ErrorHttp;
    }>(),
  },
});
