import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  HttpErrorConstant,
  messagesGeneric,
  HttpErrorInternalCode,
} from '@wp-back-office/app/global-information';
import { DynamicDialogMessageService } from '@wp-back-office/shared/dynamic-components';
import { StorageService } from '@wp-back-office/core/workflow-sdk';

/**
 * Servicio que intercepta las peticiones HTTP.
 */
@Injectable()
export class HttpErrorInterceptorService implements HttpInterceptor {
  /**
   *Instancia del servicio de interceptor.
   * @param dynamicDialogMessageService - Servicio de dialogs.
   * @param storageService - Servicio de storage.
   */
  public constructor(
    private dynamicDialogMessageService: DynamicDialogMessageService,
    private storageService: StorageService
  ) {}
  /**
   * Identifies and handles a given HTTP request.
   *
   * @param req - The outgoing request object to handle.
   * @param next - The next interceptor in the chain.
   * @returns - The observable of the request.
   */
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        let errorMessage = '';
        let internalError = '';

        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case HttpErrorConstant.OthersError:
              this.storageService.setItem('getNewToken', 'true');
              setTimeout(() => {
                this.dynamicDialogMessageService.closeAllLoaders();
              }, 3000);
              errorMessage = messagesGeneric.errorOthersError;
              break;
            case HttpErrorConstant.BadRequest:
              errorMessage = error.error.message;
              break;
            case HttpErrorConstant.Unauthorized:
              this.storageService.setItem('getNewToken', 'true');
              setTimeout(() => {
                this.dynamicDialogMessageService.closeAllLoaders();
              }, 3000);
              break;
            case HttpErrorConstant.NotFound:
              errorMessage = error.error.message;
              break;
            case HttpErrorConstant.InvalidBusinessRule:
              errorMessage = error.error.message;
              internalError = error.error.internal_code;
              break;
            case HttpErrorConstant.InternalServerError:
              switch (error.error.internal_code) {
                case HttpErrorInternalCode.systemError: {
                  errorMessage = messagesGeneric.errorSystemError;
                  internalError = error.error.internal_code;
                  break;
                }

                case HttpErrorInternalCode.internalServerError: {
                  errorMessage = messagesGeneric.errorInternalServerError;
                  internalError = error.error.internal_code;
                  break;
                }

                default: {
                  errorMessage = error?.error?.message;
                  internalError = error.error.internal_code;
                  break;
                }
              }
              break;
            default:
              errorMessage = messagesGeneric.errorDefault;
              break;
          }
        }
        return throwError(() => ({
          message: errorMessage,
          status: error.status,
          internalError: internalError,
        }));
      })
    );
  }
}
