import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';
import { Catalogue } from '@wp-back-office/core/commons-backoffice';
import { VehicleDetail } from 'libs/core/workflow-sdk/src/lib/models/vehicle-detail.model';

export const referencesByTypePriceActions = createActionGroup({
  source: 'References by TypePrice',
  events: {
    Reset: emptyProps(),
    'Get List': props<{
      /**
       * Id del tipo de precio.
       */
      typePriceId: number;
      /**
       *
       */
      code_price?: string;
      /**
       * Id del tipo de precio.
       */
      versions?: Catalogue[];
      /**
       * Pagina.
       */
      page?: number;
      /**
       * Tamaño de la pagina.
       */
      pageSize?: number | string;
    }>(),
    'Get Detail Vehicle Bulk': props<{
      /**
       * Id del tipo de precio.
       */
      typePriceId: number;
      /**
       * Lista de codigos de salas.
       */
      codes?: string[];
      /**
       * Pagina, (si ni se envia el paginado reinicia la respuesta).
       */
      page?: number;
      /**
       * Tamaño de pagina.
       */
      pageSize: number;
    }>(),
    //   /**
    //    * Id del tipo de precio.
    //    */
    //   typePriceId: number;
    // }>(),
    // 'Get Servicies By Type Price': props<{
    //   /**
    //    * Id del tipo de precio.
    //    */
    //   typePriceId: number;
    // }>(),
    // 'Get Families By Type Price': props<{
    //   /**
    //    * Id del tipo de precio.
    //    */
    //   typePriceId: number;
    // }>(),
    // 'Get Versions By Type Price': props<{
    //   /**
    //    * Id del tipo de precio.
    //    */
    //   typePriceId: number;
    // }>(),
    // 'Success Get Models By Type Price': props<{
    //   /**
    //    * Id del tipo de precio.
    //    */
    //   object: OptionsVehicleFinancialPlan;
    // }>(),
    // 'Success Get Services By Type Price': props<{
    //   /**
    //    * Id del tipo de precio.
    //    */
    //   object: OptionsVehicleFinancialPlan;
    // }>(),
    // 'Success Get Families By Type Price': props<{
    //   /**
    //    * Id del tipo de precio.
    //    */
    //   object: OptionsVehicleFinancialPlan;
    // }>(),
    // 'Success Get Versions By Type Price': props<{
    //   /**
    //    * Id del tipo de precio.
    //    */
    //   object: OptionsVehicleFinancialPlan;
    // }>(),
    Success: props<{
      /**
       * Caracteristicas.
       */
      object: VehicleDetail[];
    }>(),
    Error: props<{
      /**
       * Respuesta error Http.
       */
      error: ErrorHttp;
    }>(),
  },
});
