import { CheckContactInfoEffects } from './contents/contact-info-check.effects';
import { StartProcessEffects } from './contents/start-process-effects';
import { StartTaskEffects } from './contents/start-task.effects';
import {
  CreateDocumentTypesEffects,
  DeleteDocumentTypesEffects,
  DocumentTypesEffects,
  StepProcessEffects,
} from './contents/step-process.effects';
import { TaskSuccessEffects } from './contents/task-success.effects';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EffectsProcessArray: any[] = [
  StartProcessEffects,
  TaskSuccessEffects,
  StartTaskEffects,
  StepProcessEffects,
  DocumentTypesEffects,
  CreateDocumentTypesEffects,
  DeleteDocumentTypesEffects,
  CheckContactInfoEffects
];
