import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';
import {
  FinancialPlan,
  FinancialPlanItem,
  FinancialPlanOutput,
} from 'libs/core/workflow-sdk/src/lib/models/financial-plan.model';
import { ResponseAWS } from 'libs/core/workflow-sdk/src/lib/models/response-aws.model';
import { SuccessResponse } from 'libs/core/workflow-sdk/src/lib/models/service.model';

export const financialPlansActions = createActionGroup({
  source: 'Financial Plans',
  events: {
    Reset: emptyProps(),
    'Get List': props<{
      /**
       *
       */
      page?: number;
      /**
       *
       */
      pageSize?: number;
      /**
       *
       */
      code_financial_plan?: number;
      /**
       *
       */
      name?: string;
      /**
       *
       */
      description?: string;
    }>(),
    Get: props<{
      /**
       * Codigo del plan financiero.
       */
      code: string;
    }>(),
    log: props<{
      /**
       * Codigo del plan financiero.
       */
      financialPlan: FinancialPlan;
    }>(),
    Create: props<{
      /**
       * Codigo del plan financiero.
       */
      financialPlan: FinancialPlan;
    }>(),
    Update: props<{
      /**
       * Codigo.
       */
      code: string;
      /**
       * Codigo del plan financiero.
       */
      financialPlan: FinancialPlan;
    }>(),
    Success: props<{
      /**
       * Caracteristicas.
       */
      list?: FinancialPlanItem[];
      /**
       * Caracteristicas.
       */
      view?: FinancialPlan;
      /**
       * Caracteristicas.
       */
      create?: SuccessResponse<FinancialPlanOutput>;
      /**
       * Caracteristicas.
       */
      update?: SuccessResponse<undefined>;
      /**
       * Caracteristicas.
       */
      import?: SuccessResponse<undefined>;
      /**
       * Caracteristicas.
       */
      importState?: any;
    }>(),
    Error: props<{
      /**
       * Respuesta error Http.
       */
      error: ErrorHttp;
    }>(),
    Import: props<{
      /**
       * Importar planes financieros.
       */
      file: any;
    }>(),
    'Import Success': props<{
      /**
       * Importar planes financieros success.
       */
      object: any;
    }>(),
    'Import error': props<{
      /**
       * Importar planes financieros error.
       */
      error: any;
    }>(),
    'Get Import Status': emptyProps(),
    'Get Import Status Success': props<{
      /**
       * Importar planes financieros status success.
       */
      status: any;
    }>(),
  },
});
