import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { errorHandler } from '@wp-back-office/app/global-information';

import { environment } from 'apps/back-office/src/environments/environment';
import { AuditList } from '../models/audit-list.model';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';
import { buroViality } from '../models/buro.models';
import {
  CalculateExpensesPayload,
  CashBalanceExpenses,
} from 'libs/process/s-credit-decision-pn/src/lib/store/actions';

/**
 * Servicio de backoffice.
 */
@Injectable()
export class BackOfficeService implements OnDestroy {
  /**
   * Constante url.
   */
  private URL = environment.endpoints.url;
  /**
   * Constate tenant.
   */
  private TENANT_ID = environment.common.tenantId;
  /**
   * Destructor sujeto.
   */
  private destroy$ = new Subject();

  /**
   * Crea una intancia de BackOfficeService.
   * @param http - Cliente o servidor.
   * @param enviroment - Variables de entorno.
   * @param router - Rutas.
   */
  public constructor(
    private http: HttpClient,
    private enviroment: Enviroment,
    public router: Router
  ) {}

  /**
   * A callback method that performs custom clean-up, invoked immediately before a directive, pipe, or service instance is destroyed.
   */
  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }
  /**
   * Enviar auditoria de procesos.
   * @param filterType - Tipo de filtro.
   * @param filter - Valor a filtrar.
   * @param pageSize - Tamaño de la pagina.
   * @param paginationToken - Token para la paginacion.
   * @returns - Observable.
   */
  public postAuditProcess(
    filterType: 'documentNumber' | 'executionId',
    filter: string,
    pageSize?: number,
    paginationToken?: string
  ): Observable<AuditList> {
    const url = `${this.URL}/${this.TENANT_ID}/audit-process/${filterType}/${filter}`;
    const params: HttpParams = new HttpParams();
    if (pageSize) {
      params.set('pageSize', pageSize);
    }
    if (paginationToken) {
      params.set('paginationToken', paginationToken);
    }
    return this.http
      .get<AuditList>(url, {
        params,
      })
      .pipe(catchError(errorHandler));
    // .pipe(delay(1000));
  }

  /**
   * Obtener detalle de auditorias de procesos.
   * @param process - Proceso.
   * @param id - Identificador.
   * @returns Observable.
   */
  public getAuditDetailProcess(process: any, id: any): Observable<any> {
    return this.http
      .get(`${this.URL}/search/execution/details/${process}/${id}`)
      .pipe(catchError(errorHandler));
  }

  /**
   * Obtener documentos descargados.
   * @param process - Proceso.
   * @param id - Identificador.
   * @param tenantId - Tenant.
   * @param stateProcess - Inicio del proceso.
   * @returns Observable.
   */
  public getDownloadDocuments(
    process: any,
    id: any,
    tenantId: any,
    stateProcess: any
  ): Observable<any> {
    return this.http
      .get(
        `${this.URL}/search/execution/documents/${tenantId}/${process}/${stateProcess}/${id}`
      )
      .pipe(catchError(errorHandler));
  }

  /**
   * Busqueda de tareas pendientes.
   * @param options - Opciones.
   * @param options.size - Tamaño.
   * @param options.target - Objetivo.
   * @param options.process - Proceso.
   * @param options.filtro - Filtro.
   * @param options.id - Id del proceso.
   * @param options.lastKey - Ulltima llave.
   * @returns Observable.
   */
  public searchTaskPending(options: {
    /**
     * Tamaño.
     */
    size: number;
    /**
     * Objetivo.
     */
    target: string;
    /**
     * Proceso.
     */
    process: string;
    /**
     * Filtro.
     */
    filtro?: string;
    /**
     * Id.
     */
    id?: string;
    /**
     * LastKey.
     */
    lastKey?: string;
  }): Observable<any> {
    if (!options.target || !options.process) {
      return of();
    }
    const url = `${this.URL}/${
      this.TENANT_ID
    }/tasks/pending/group/${options.target?.toUpperCase()}/${options.process?.toUpperCase()}`;

    return this.http
      .get(url, {
        params: {
          page_size: options.size || 0,
          process: options.id || '',
          filter: options.filtro || '',
          last_key: options.lastKey || '',
        },
      })
      .pipe(catchError(errorHandler));
  }

  /**
   * Busqueda de tareas pendientes.
   * @param options - Opciones.
   * @param options.size - Tamaño.
   * @param options.target - Objetivo.
   * @param options.lastKey - Key.
   * @param options.filter - Filter.
   * @param options.process - Proceso.
   * @returns Observable.
   */
  public searchUserProcess(options: {
    /**
     * Tamaño.
     */
    size: number;
    /**
     * Objetivo.
     */
    target: string;
    /**
     * Token para la paginacion.
     */
    lastKey?: string;
    /**
     * Valor a filtrar.
     */
    filter?: string;
    /**
     * Proceso.
     */
    process: string;
  }): Observable<any> {
    const url =
      options.target === 'completed'
        ? `${this.URL}/${
            this.TENANT_ID
          }/tasks/completed/process/${options.process.toUpperCase()}`
        : options.process && options.process !== ''
        ? `${this.URL}/${
            this.TENANT_ID
          }/tasks/pending/process/${options.process.toUpperCase()}`
        : `${this.URL}/${
            this.TENANT_ID
          }/tasks/pending/user/${options.target.toLowerCase()}`;

    return this.http
      .get(url, {
        params: {
          page_size: options.size,
          last_key: options.lastKey || '',
          filter: options.filter || '',
        },
      })
      .pipe(catchError(errorHandler));
  }

  /**
   * Busqueda de tareas pendientes.
   * @param options - Opciones.
   * @param options.size - Tamaño.
   * @param options.target - Objetivo.
   * @param options.process - Proceso.
   * @param options.nextKey - Siguiente llave.
   * @returns Observable.
   */
  public searchTaskPendingPagination(options: {
    /**
     * Tamaño.
     */
    size: number;
    /**
     * Objetivo.
     */
    target: string;
    /**
     * Proceso.
     */
    process: string;
    /**
     * Siguiente llave.
     */
    nextKey: string;
  }): Observable<any> {
    const url = `${this.URL}/${this.TENANT_ID}/tasks/pending/user/${options.process}`;
    const findKey: any = {};
    findKey.lastKey = options.nextKey;

    return this.http
      .get(url, {
        params: {
          page_size: options.size,
          ...findKey,
        },
      })
      .pipe(catchError(errorHandler));
  }
  /**
   * Busqueda de tareas pendientes.
   * @param options - Opciones.
   * @param options.size - Tamaño.
   * @param options.target - Objetivo.
   * @param options.process - Proceso.
   * @param options.nextKey - Siguiente llave.
   * @returns Observable.
   */
  public searchUserProcessPagination(options: {
    /**
     * Tamaño.
     */
    size: number;
    /**
     * Objetivo.
     */
    target: string;
    /**
     * Proceso.
     */
    process: string;
    /**
     * Siguiente llave.
     */
    nextKey: string;
  }): Observable<any> {
    const url = `${this.URL}/${this.TENANT_ID}/tasks/pending/user/${options.process}`;
    const findKey: any = {};
    findKey.lastKey = options.nextKey;

    return this.http
      .get(url, {
        params: {
          page_size: options.size,
          ...findKey,
        },
      })
      .pipe(catchError(errorHandler));
  }

  /**
   *  Busqueda de tarea pendiente.
   * @param options - Opciones.
   * @param type - Tipo.
   * @returns Observable.
   */
  public searchTypeTaskPending(options: any, type: string): Observable<any> {
    const findKey: any = {};

    if (options.startKey !== undefined) findKey.firstKey = options.startKey;
    if (options.lastKey !== undefined) findKey.lastKey = options.lastKey;
    const url = `${this.URL}/${this.TENANT_ID}/tasks/pending/${type}/${options.process}`;

    return this.http
      .get(url, {
        params: {
          page_size: options.size,
          ...findKey,
        },
      })
      .pipe(catchError(errorHandler));
  }

  /**
   * Busqueda de tareas pendientes para eliminarlas por id.
   * @param key - Llave para eliminar la tarea.
   * @returns Observable.
   */
  public deleteTaskPending(key: string): Observable<any> {
    return this.http
      .delete(`${this.URL}/tasks/pending/${this.TENANT_ID}/${key}`)
      .pipe(catchError(errorHandler));
  }
  /**
   * Busqueda de tareas pendientes para eliminarlas por id.
   * @param key - Llave para eliminar la tarea.
   * @returns Observable.
   */
  public deleteUserProcess(key: string): Observable<any> {
    return this.http
      .delete(`${this.URL}/tasks/pending/${this.TENANT_ID}/${key}`)
      .pipe(catchError(errorHandler));
  }

  /**
   * Busqueda de tareas pendientes para actualizarlas por id.
   * @param key - Llave.
   * @param status - Estado.
   * @returns Observable.
   */
  public updateTaskPending(key: string, status: string): Observable<any> {
    return this.http
      .put(`${this.URL}/tasks/pending/${this.TENANT_ID}/${key}`, { status })
      .pipe(catchError(errorHandler));
  }

  /**
   * Busqueda de tareas pendientes para actualizarlas por id.
   * @param key - Llave.
   * @param status - Estado.
   * @returns Observable.
   */
  public updateUserProcess(key: string, status: string): Observable<any> {
    return this.http
      .put(`${this.URL}/tasks/pending/${this.TENANT_ID}/${key}`, { status })
      .pipe(catchError(errorHandler));
  }
  /**
   * Busqueda de tareas pendientes para actualizarlas por id.
   * @param group - Grupo.
   * @param taskToken - Token de la tarea.
   * @param date - Fecha.
   * @param process - Proceso.
   * @returns - Observable.
   */
  public updateUserReassing(
    group: string,
    taskToken: string,
    date: number,
    process: string
  ): Observable<any> {
    return this.http
      .put(`${this.URL}/${this.TENANT_ID}/task-reassignment/group`, {
        group,
        taskToken,
        date,
        process,
      })
      .pipe(catchError(errorHandler));
  }

  /**
   * Obtener el historico.
   * @param processName - Nombre del proceso.
   * @param idExecution - Identificador de la ejecucion.
   * @returns Oservable.
   */
  public getProcessDetails(
    processName: string,
    idExecution: string
  ): Observable<any> {
    return this.http
      .get(
        `${this.URL}/${this.TENANT_ID}/audit-process/${processName}/${idExecution}/details`
      )
      .pipe(catchError(errorHandler));
  }

  /**
   * Obtener el historico.
   * @param processName - Nombre del proceso.
   * @param idExecution - Identificador de la ejecucion.
   * @returns Oservable.
   */
  public getTaskHistory(
    processName: string,
    idExecution: string
  ): Observable<any> {
    return this.http
      .get(`${this.URL}/${this.TENANT_ID}/audit-process/${idExecution}/history`)
      .pipe(catchError(errorHandler));
  }

  /**
   * Busqueda de tareas pendientes para actualizarlas por id.
   * @param typeDocument - Llave.
   * @param numberDocument - Estado.
   * @returns Observable.
   */
  public searchActiveExecution(
    typeDocument: string,
    numberDocument: string
  ): Observable<any> {
    return this.http
      .get(
        `${this.URL}/${this.TENANT_ID}/audit-process/get-open/${numberDocument}/${typeDocument}`
      )
      .pipe(catchError(errorHandler));
  }

  /**
   * Enpoint para el calculo de balance de caja Incomes.
   * @param payload - Payload.
   * @returns Observable.
   */
  public calculateCashBalanceIncomes(payload: any): Observable<any> {
    return this.http
      .post(
        `${this.enviroment.endpoints.backofficeRules}/rules/ingress`,
        payload
      )
      .pipe(catchError(errorHandler));
  }

  /**
   * Enpoint para el calculo de balance de caja PayrollDeductions.
   * @param payload - Payload.
   * @returns Observable.
   */
  public calculateCashBalancePayrollDeductions(payload: any): Observable<any> {
    return this.http
      .post(
        `${this.enviroment.endpoints.backofficeRules}/rules/payroll-deduction`,
        payload
      )
      .pipe(catchError(errorHandler));
  }

  /**
   * Enpoint para el calculo de balance de caja Expenses.
   * @param payload - Payload.
   * @returns Observable.
   */
  public calculateCashBalanceExpenses(
    payload: CalculateExpensesPayload
  ): Observable<CashBalanceExpenses> {
    return this.http
      .post<CashBalanceExpenses>(
        `${this.enviroment.endpoints.backofficeRules}/rules/v2/expenses`,
        payload
      )
      .pipe(catchError(errorHandler));
  }

  /**
   * Enpoint para el calculo de balance de caja PaymentCapacity.
   * @param payload - Payload.
   * @returns Observable.
   */
  public calculateCashBalancePaymentCapacity(payload: any): Observable<any> {
    return this.http
      .post(
        `${this.enviroment.endpoints.backofficeRules}/rules/payment-capacity`,
        payload
      )
      .pipe(catchError(errorHandler));
  }

  /**
   * Verificación de buro credito.
   * @param buroData - Codigo a validar.
   * @returns Observable.
   */
  public buroViality(buroData: buroViality): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.backofficeRules}/rules/buro-viability`,
      {
        valorAdvance: buroData.valorAdvance,
        creditVision: buroData.creditVision,
        tenantId: this.TENANT_ID,
      }
    );
  }
}
