import { SegmentationOption } from 'libs/core/workflow-sdk/src/lib/models/segmentation-option.model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';

export const segmentationOptionsActions = createActionGroup({
  source: 'Segmentation Options',
  events: {
    Reset: emptyProps(),
    'Get List': props<{
      /**
       * Pagina.
       */
      page?: number;
      /**
       * Tamaño de la pagina.
       */
      pageSize?: number | string;
    }>(),
    Success: props<{
      /**
       * Opciones de segmentacion.
       */
      object: SegmentationOption[];
    }>(),
    Error: props<{
      /**
       * Respuesta error Http.
       */
      error: ErrorHttp;
    }>(),
  },
});
