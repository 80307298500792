import { NgModule } from '@angular/core';

import { FactoryHostDirective } from './directive/factory-host.directive';
import { DynamicFormService } from '../dynamic-form/services/dynamic-form.service';
import { SpaceMayusPipe } from './pipes/pipe/space-mayus.pipe';
import { CommonModule } from '@angular/common';
import { DynamicFileDownloaderService } from './services/dynamic-file-downloader.service';

/**
 * Modulo componentes dynamicos.
 */
@NgModule({
  declarations: [FactoryHostDirective, SpaceMayusPipe],
  imports: [CommonModule],
  exports: [FactoryHostDirective, SpaceMayusPipe],
  providers: [DynamicFormService, DynamicFileDownloaderService],
})
export class SharedDynamicComponentsModule {}
