import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueFundingType = createAction(
  '[KeyValueFundingType] Cargar tipo de fondeo por id',
  props<{
    /**
     * Identificador.
     */
    path: CatalogueType;
  }>()
);

export const KeyValueFundingTypeSuccess = createAction(
  '[KeyValueFundingType] Crear tipo de fondeo Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueFundingTypeError = createAction(
  '[KeyValueFundingType] Cargar tipo de fondeo Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
