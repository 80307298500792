import { createActionGroup, props } from '@ngrx/store';
import { Catalogue } from '../../../models/contents/catalogue.models';
import { CatalogueType } from '../../../models/contents/catalogue.models';

/**
 * Acciones para obtener catalogos.
 */
export const catalogueLegalizationDocumentsActions = createActionGroup({
  source: 'catalogueLegalizationDocumentsActions',
  events: {
    /**
     * Obtener documentos de legalizacion.
     */
    'Get Legalization Documents': props<{
      /**
       * Ruta.
       */
      path: CatalogueType;
    }>(),
    'Get Legalization Documents Success': props<{
      /**
       * Respuesta exitosa.
       */
      keyValue: Catalogue[];
    }>(),
    'Get Legalization Documents Error': props<{
      /**
       * Error.
       */
      payload: any;
    }>(),
  },
});
