import { Action, createReducer, on } from '@ngrx/store';
import { ErrorHttp } from '../../../../models/errors.models';
import { resetInitialState } from '../../../../root/store/actions';

import {
  RadicationProcess,
  RadicationProcessSuccess,
  RadicationProcessErrors,
  DocumentTypes,
  DocumentTypesSuccess,
  DocumentTypesErrors,
  CreateDocumentTypes,
  CreateDocumentTypesSuccess,
  CreateDocumentTypesErrors,
  DeleteDocumentTypesErrors,
  DeleteDocumentTypesSuccess,
  DeleteDocumentTypes,
  DocumentTypesCrudReset,
} from '../../actions';

/**
 * Estado de la radicacion.
 */
export interface StepProcessState {
  /**
   * Objeto.
   */
  response?: Radication;
  /**
   * DocumentTypes.
   */
  documents?: Documents;
}
/**
 * Radicatición.
 */
export interface Radication {
  /**
   * Estado de la radicacion.
   */
  data?: any;
  /**
   * Bandera de cargado.
   */
  loaded?: boolean;
  /**
   * Bandera de cargando.
   */
  loading?: boolean;
  /**
   * Errores.
   */
  error?: ErrorHttp;
}
/**
 * Documentos.
 */
export interface Documents {
  /**
   * Estado de la radicacion.
   */
  get?: Radication;
  /**
   * Estado de la radicacion.
   */
  create?: Radication;
  /**
   * Estado de la radicacion.
   */
  delete?: Radication;
}

export const stepProcessInitialState: StepProcessState = {
  documents: undefined,
  response: undefined,
};

const featureReducer = createReducer(
  stepProcessInitialState,
  on(resetInitialState, () => ({ ...stepProcessInitialState })),
  on(RadicationProcess, state => ({
    ...state,
    response: {
      data: undefined,
      loaded: false,
      loading: true,
      error: undefined,
    },
  })),
  on(RadicationProcessSuccess, (state, { response }) => ({
    ...state,
    response: {
      data: response,
      loaded: false,
      loading: true,
      error: undefined,
    },
  })),
  on(RadicationProcessErrors, (state, { error }) => ({
    ...state,
    response: {
      data: undefined,
      loaded: false,
      loading: true,
      error: error,
    },
  })),
  on(DocumentTypes, state => ({
    ...state,
    documents: {
      ...state.documents,
      get: {
        data: undefined,
        loaded: false,
        loading: true,
        error: undefined,
      },
    },
  })),
  on(DocumentTypesCrudReset, state => ({
    ...state,
    documents: {
      ...state.documents,
      get: {
        data: undefined,
        loaded: true,
        loading: false,
        error: undefined,
      },
      create: {
        data: undefined,
        loaded: true,
        loading: false,
        error: undefined,
      },
      delete: {
        data: undefined,
        loaded: true,
        loading: false,
        error: undefined,
      },
    },
  })),
  on(DocumentTypesSuccess, (state, { response }) => ({
    ...state,
    documents: {
      ...state.documents,
      get: { data: response, loaded: true, loading: false, error: undefined },
    },
  })),
  on(DocumentTypesErrors, (state, { error }) => ({
    ...state,
    documents: {
      ...state.documents,
      get: { data: undefined, loaded: true, loading: false, error: error },
    },
  })),

  on(CreateDocumentTypes, state => ({
    ...state,
    documents: {
      ...state.documents,
      create: {
        data: undefined,
        loaded: false,
        loading: true,
        error: undefined,
      },
    },
  })),
  on(CreateDocumentTypesSuccess, (state, { response }) => ({
    ...state,
    documents: {
      ...state.documents,
      create: {
        data: response,
        loaded: true,
        loading: false,
        error: undefined,
      },
    },
  })),
  on(CreateDocumentTypesErrors, (state, { error }) => ({
    ...state,
    documents: {
      ...state.documents,
      create: { data: undefined, loaded: true, loading: false, error: error },
    },
  })),

  on(DeleteDocumentTypes, state => ({
    ...state,
    documents: {
      ...state.documents,
      delete: {
        data: undefined,
        loaded: false,
        loading: true,
        error: undefined,
      },
    },
  })),
  on(DeleteDocumentTypesSuccess, (state, { response }) => ({
    ...state,
    documents: {
      ...state.documents,
      delete: {
        data: response,
        loaded: true,
        loading: false,
        error: undefined,
      },
    },
  })),
  on(DeleteDocumentTypesErrors, (state, { error }) => ({
    ...state,
    documents: {
      ...state.documents,
      delete: {
        data: undefined,
        loaded: true,
        loading: false,
        error: error,
      },
    },
  }))
);

/**
 * Reductor iniciar proceso.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function StepProcessReducer(
  state: StepProcessState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
