<ng-template [ngIf]="controlConfig && controlConfig.key && controlConfig.type">
  <mat-form-field
    [appearance]="controlConfig.appearance || table ? 'fill' : 'outline'"
    class="{{ controlConfig.classes || '' }}"
    [attr.table]="table"
    [class.readonly]="
      this.controlConfig.validators?.disabled ||
      this.controlConfig.validators?.readonly
        ? true
        : false
    "
    ngDefaultControl>
    <mat-label [attr.for]="controlConfig.key || ''">
      {{ controlConfig.label || '' }}
    </mat-label>
    <input
      matInput
      [id]="uniqueIdForm + controlConfig.key"
      aria-readonly="true"
      [formControl]="_formControl"
      type="text"
      placeholder="Buscar"
      (click)="openPanel()"
      (focus)="onFocus($event)"
      (focusout)="onFocusOut($event)"
      [readonly]="controlConfig.validators?.readonly || false"
      [required]="controlConfig.validators?.required || false"
      [matAutocomplete]="auto" />
    <mat-autocomplete
      autoActiveFirstOption
      #auto="matAutocomplete"
      (optionSelected)="onSelect($event, controlConfig.key || '')"
      [displayWith]="displayFn">
      <div
        class="autocomplete-scroll"
        (scroll)="onScroll($event, controlConfig.options, controlConfig.key)">
        <ng-container
          *ngIf="!controlConfig?.autocompleteConfig?.internalFilter">
          <!-- <mat-option
            *ngIf="controlConfig.validators?.dropDownUndefinedOption"
            [value]="undefined"
            [disabled]="this.controlConfig.validators?.readonly ? true : false"
            >Ninguno
          </mat-option> -->

          <ng-container *ngIf="(controlConfig.options || []).length <= 50">
            <mat-option
              *ngFor="let opt of controlConfig.options || []"
              [value]="opt"
              [disabled]="
                this.controlConfig.validators?.disabled ||
                this.controlConfig.validators?.readonly
                  ? true
                  : false
              "
              >{{ opt?.description }}
            </mat-option>
          </ng-container>

          <ng-container *ngIf="(controlConfig.options || []).length > 50">
            <cdk-virtual-scroll-viewport
              style="height: 247px"
              minBufferPx="247"
              maxBufferPx="500"
              itemSize="39"
              class="example-viewport"
              (scroll)="
                onScroll($event, controlConfig.options, controlConfig.key)
              ">
              <mat-option
                *cdkVirtualFor="let opt of controlConfig.options || []"
                [value]="opt"
                [disabled]="
                  this.controlConfig.validators?.disabled ||
                  this.controlConfig.validators?.readonly
                    ? true
                    : false
                ">
                {{ opt?.description }}
              </mat-option>
            </cdk-virtual-scroll-viewport>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="controlConfig?.autocompleteConfig?.internalFilter">
          <ng-container *ngIf="(controlConfig.options || []).length <= 50">
            <mat-option
              *ngFor="let opt of (filteredOptions | async) || []"
              [value]="opt"
              [disabled]="
                this.controlConfig.validators?.disabled ||
                this.controlConfig.validators?.readonly
                  ? true
                  : false
              ">
              {{ opt?.description }}
            </mat-option>
          </ng-container>

          <ng-container *ngIf="(controlConfig.options || []).length > 50">
            <cdk-virtual-scroll-viewport
              style="height: 247px"
              minBufferPx="247"
              maxBufferPx="500"
              itemSize="39"
              class="example-viewport"
              (scroll)="
                onScroll($event, controlConfig.options, controlConfig.key)
              ">
              <mat-option
                *cdkVirtualFor="let opt of (filteredOptions | async) || []"
                [value]="opt"
                [disabled]="
                  this.controlConfig.validators?.disabled ||
                  this.controlConfig.validators?.readonly
                    ? true
                    : false
                ">
                {{ opt?.description }}
              </mat-option>
            </cdk-virtual-scroll-viewport>
          </ng-container>
        </ng-container>

        <!-- <mat-option
          disabled
          [ngStyle]="
            calculateStyle(
              controlConfig.autocompleteConfig?.internalFilter
                ? (filteredOptions | async) || null
                : controlConfig.options || null
            )
          ">
        </mat-option> -->
      </div>
    </mat-autocomplete>
    <mat-error
      *ngIf="!table"
      [innerHTML]="
        dynamicFormService.errorMessage(_formControl, controlConfig)
      ">
    </mat-error>

    <mat-error
      *ngIf="
        !table &&
        _formControl.hasError('incorrect') &&
        !_formControl.hasError('required') &&
        !_formControl.hasError('pattern')
      ">
      Seleccione una opción de la lista
    </mat-error>
    <mat-icon matSuffix *ngIf="controlConfig?.icon">
      {{ controlConfig.icon || '' }}
    </mat-icon>
  </mat-form-field>
  <div
    class="input-spinner"
    *ngIf="controlConfig.validators?.loading || controlConfig?.loading">
    <mat-spinner color="primary" [diameter]="25"> </mat-spinner>
  </div>
</ng-template>
