import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatBadgePosition } from '@angular/material/badge';
import { ThemePalette } from '@angular/material/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { buttonSelector } from '../../dynamic-snackbar/models/snackbar-config.models';

/**
 * Componente de botone dinamicos.
 */
@Component({
  selector: 'wp-back-office-dynamic-button',
  templateUrl: './dynamic-button.component.html',
})
export class DynamicButtonComponent {
  /**
   * Clases del boton 'class-1 class-2'.
   */
  @Input()
  public class!: string;

  /**
   * Estilos del boton { [klass: string]: any } | null.
   */
  @Input()
  public ngStyle!: { [klass: string]: any } | null;

  /**
   * Tipo del boton.
   */
  @Input()
  public type!: string;

  /**
   * Selector del boton.
   */
  @Input()
  public buttonSelector!: buttonSelector;

  /**
   * Color del boton de tipo ThemePalette = 'primary' | 'accent' | 'warn' | undefined.
   */
  @Input()
  public color!: ThemePalette;

  /**
   * Texto del boton.
   */
  @Input()
  public text!: string;

  /**
   * Icono inicial string 'home' aqui puedes encontrar más https://fonts.google.com/icons?selected=Material+Icons.
   */
  @Input()
  public iconStart!: string;

  /**
   * Icono final string 'home' aqui puedes encontrar más https://fonts.google.com/icons?selected=Material+Icons.
   */
  @Input()
  public iconEnd!: string;

  /**
   * Mensaje mostrado al hacer hover en el boton 'string'.
   */
  @Input()
  public tooltip!: string;

  /**
   * Tiempo de retraso para ocultar el tooltip de tipo 'number'.
   */
  @Input()
  public tooltipHideDelay!: number;

  /**
   * Posicion del Tooltip de tipo TooltipPosition = 'left' | 'right' | 'above' | 'below' | 'before' | 'after'.
   */
  @Input()
  public tooltipPosition!: TooltipPosition;

  /**
   * Tiempo de retraso para mostrar el tooltip de tipo 'number'.
   */
  @Input()
  public tooltipShowDelay!: number;

  /**
   * Notificacion insignia en el boton 'string' | 'number'.
   */
  @Input()
  public badge!: string | number;

  /**
   * Posicion de la insignia  MatBadgePosition = 'above after' | 'above before' | 'below before' | 'below after' | 'before' | 'after' | 'above' | 'below'.
   */
  @Input()
  public badgePosition!: MatBadgePosition;

  /**
   * Color de la insignia de tipo ThemePalette = 'primary' | 'accent' | 'warn' | undefined.
   */
  @Input()
  public badgeColor!: ThemePalette;

  /**
   * Estado del boton tipo boolean.
   */
  @Input()
  public disabled: boolean;

  /**
   * Evento click del boton retorna MouseEvent.
   */
  @Output()
  public ButtonClick = new EventEmitter<MouseEvent>();

  /**
   * Crea una instancia de la clase.
   */
  constructor() {
    this.disabled = false;
  }

  /**
   * Emite el evento click del boton, retorna al componente MouseEvent.
   * @param event - MouseEvent.
   */
  public clickedButton(event: MouseEvent): void {
    this.ButtonClick.emit(event);
  }
}
