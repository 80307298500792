import { AmortizationCredit } from 'libs/core/workflow-sdk/src/lib/models/amortization-credit.model';
import { FeatureValue } from 'libs/core/workflow-sdk/src/lib/models/feature.model';
import {
  DetailFinancialPlan,
  FeatureFinancialPlan,
  ServiceForm,
  TractForm,
} from 'libs/core/workflow-sdk/src/lib/models/financial-plan.model';
import { PriceType } from 'libs/core/workflow-sdk/src/lib/models/price-type.model';
import { Segment } from 'libs/core/workflow-sdk/src/lib/models/segment.model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';
import { Catalogue } from '@wp-back-office/core/commons-backoffice';

export const formFinancialPlanActions = createActionGroup({
  source: 'Financial Plan Form',
  events: {
    Reset: emptyProps(),
    'Add Tracts': props<{
      /**
       * Tramos a adicional al formulario.
       */
      tracts: TractForm[];
    }>(),
    'Update Tract': props<{
      /**
       * Indice del tramo a actualizar.
       */
      index: number;
      /**
       * Tramo a actualizar.
       */
      tract: TractForm;
    }>(),
    'Add Detail': props<{
      /**
       * Detalle del plan financiero.
       */
      detail: DetailFinancialPlan;
    }>(),
    'Add Type Price': props<{
      /**
       * Tipo de precio del plan financiero.
       */
      type_price: Catalogue;
    }>(),
    'Add Vehicles Options': props<{
      /**
       * Tipo de precio del plan financiero.
       */
      models: string;
      /**
       * Tipo de precio del plan financiero.
       */
      vh_type_services: string;
      /**
       * Tipo de precio del plan financiero.
       */
      families: string;
      /**
       * Tipo de precio del plan financiero.
       */
      versions: string;
    }>(),
    'Add Features': props<{
      /**
       * Caracteristicas a adicionar.
       */
      features: FeatureValue[];
      /**
       * Es valido.
       */
      is_valid: boolean;
    }>(),
    'Add Amortization Credit': props<{
      /**
       * Amortizacion seleccionada.
       */
      amortization: Catalogue;
    }>(),
    'Add Services': props<{
      /**
       * Servicios seleccionados.
       */
      services: ServiceForm[];
    }>(),
    'Update Service': props<{
      /**
       * Indice del servicio a actualzar.
       */
      index: number;
      /**
       * Servicio a actualizar.
       */
      service: ServiceForm;
    }>(),
    'Delete Service': props<{
      /**
       * Indice del servicio a eliminar.
       */
      index: number;
    }>(),
    'Delete All Services': emptyProps(),
    'Delete All Segments': emptyProps(),
    'Add Segments': props<{
      /**
       * Segmentos seleccionados.
       */
      segments: Segment[];
    }>(),
    'Delete Segment': props<{
      /**
       * Indice del segmento a eliminar.
       */
      index: number;
    }>(),
    Success: props<{
      /**
       * Servicios.
       */
      services?: ServiceForm[];
      /**
       * Segmentos.
       */
      segments?: Segment[];
      /**
       * Tramos.
       */
      tracts?: TractForm[];
      /**
       * Amortización.
       */
      amortization?: Catalogue;
      /**
       * Caracteristicas.
       */
      features?: FeatureFinancialPlan;
    }>(),
    Error: props<{
      /**
       * Respuesta error Http.
       */
      error: ErrorHttp;
    }>(),
  },
});
