import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';
import { Catalogue } from '@wp-back-office/core/commons-backoffice';

export const vehicleServicesByTypePriceActions = createActionGroup({
  source: 'Vehicle Services by Type Price',
  events: {
    Reset: emptyProps(),
    'Get List': props<{
      /**
       * Id del tipo de precio.
       */
      typePriceId: number;
      /**
       * Id del tipo de precio.
       */
      vehicles?: Catalogue[];
      /**
       * Pagina.
       */
      page?: number;
      /**
       * Tamaño de la pagina.
       */
      pageSize?: number | string;
    }>(),
    Success: props<{
      /**
       * Servicios.
       */
      object: Catalogue[];
    }>(),
    Error: props<{
      /**
       * Respuesta error Http.
       */
      error: ErrorHttp;
    }>(),
  },
});
