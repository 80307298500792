import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Catalogue, Enviroment } from '@wp-back-office/core/commons-backoffice';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseAWS } from '../models/response-aws.model';
/**
 * Servicio de planes financieros.
 */
@Injectable()
export class ServiceTypesService {
  /**
   * Crea una instancia de WorkflowService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}
  /**
   * Obtiene la lista de planes financieros.
   * @param description - Descripción.
   * @param page - Pagina.
   * @param pageSize - Tamaño de la pagina.
   * @returns - Observable.
   */
  public getServiceTypesList(
    description?: string,
    page?: number,
    pageSize?: number | string
  ): Observable<Catalogue[]> {
    let params = {};
    if (description !== undefined) {
      params = { ...params, description };
    }
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    this.enviroment.endpoints.url;
    return this.http
      .get<ResponseAWS<Catalogue>>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/service-type`,
        {
          params: params,
        }
      )
      .pipe(
        map(response => {
          return response.items.map(item => ({
            code: item.code.toString(),
            description: item.description,
          }));
        })
      );
  }
}
