import { Validators } from '@angular/forms';

const numberPattern = '[0-9]{8}';

export const RequiredValidators = Validators.required;
export const EmailValidators = Validators.email;
export const MaxValidators = Validators.maxLength(10);
export const MinValidators = Validators.minLength(8);

export const docNumberValidator = Validators.compose([
  Validators.required,
  Validators.maxLength(10),
  Validators.minLength(8),
  Validators.pattern(numberPattern),
]);

export const ccTypeValidator = Validators.compose([
  Validators.required,
  Validators.maxLength(10),
  Validators.pattern('[0-9]{8,10}'),
]);

export const ceTypeValidator = Validators.compose([
  Validators.required,
  Validators.maxLength(6),
  Validators.pattern('[0-9]{6}'),
]);
