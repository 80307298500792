import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueTypePlan = createAction(
  '[KeyValueTypePlan] Cargar tipos de mercado',
  props<{
    /**
     * Ruta.
     */
    path: CatalogueType;
  }>()
);

export const KeyValueTypePlanSuccess = createAction(
  '[KeyValueTypePlan] Cargar tipo de plan exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueTypePlanError = createAction(
  '[KeyValueTypePlan] Cargar tipos de plan Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
