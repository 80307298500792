import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';
import {
  SuccessTask,
  InputProcess,
  TaskStart,
} from '../models/start-proccess.models';
import { PendingTaskPost } from '../models/post-data.model';
import {
  SendOtpCode,
  VerificationOtpCode,
  ContactInfoCheck,
  ScoreTransunion,
  ScoreExperian,
  SendOtpResponse,
  VerificationOtpResponse,
} from '@wp-back-office/core/store';
import { ProviderProcess } from '../models/provider-process.model';
import { TaskExecution } from '../models/task-execution.model';
import { ResponseData } from '../models/response-aws.model';
import * as mock from './workflow.json';

/**
 * Servicio de workflow.
 */
@Injectable({
  providedIn: 'root',
})
export class WorkflowService {
  /**
   * Crea una instancia de WorkflowService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}

  /**
   * Tarea completada.
   * @param resultTask - Resultado.
   * @returns Observable.
   */
  public successTask(resultTask: SuccessTask): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.workFlow}/${this.enviroment.common.tenantId}/task/success`,
      {
        ...resultTask,
        publisher: true,
      }
    );
  }

  /**
   * Inicia la tarea de mvp-credit-origination.
   * @param startObject - Payload.
   * @param success - Validacion para la url.
   * @returns Observable.
   */
  public startTask(
    startObject?: TaskStart,
    success?: boolean
  ): Observable<any> {
    return this.http.post(
      success
        ? `${this.enviroment.endpoints.workFlow}/${this.enviroment.common.tenantId}/backoffice/task/success`
        : `${this.enviroment.endpoints.workFlow}/${this.enviroment.common.tenantId}/backOffice/process/m--individual-credit-origination/start`,
      startObject
    );
  }

  /**
   * Tarea fallida.
   * @param id - Identificador.
   * @param causeError - Caso de error.
   * @returns Obserable.
   */
  public failureTask(id: string, causeError: any): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.workFlow}/${this.enviroment.common.tenantId}/task/failure`,
      { id, causeError }
    );
  }

  /**
   * Iniciar tarea.
   * @param data - Datos.
   * @param process - Procesos.
   * @returns Observable.
   */
  public startProcess(data: InputProcess, process: string): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.workFlow}/${this.enviroment.common.tenantId}/backOffice/process/${process}/start`,
      {
        taskInformation: {
          applicants: [{ ...data }],
        },
        publisher: true,
      }
    );
  }

  /**
   * Obtener la data para subir los archivos.
   * @param id - Identificador.
   * @param process - Proceso.
   * @param document - Documentos.
   * @returns Observable.
   */
  public getDataUploadFile(
    id: string,
    process: string,
    document: any
  ): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.urlDocuments}/documents/upload`,
      {
        documents: [document],
        execution: id,
        process: process,
        tenantId: this.enviroment.common.tenantId,
      }
    );
  }

  /**
   * Subir archivo.
   * @param data - Datos.
   * @returns Observable.
   */
  public uploadFile(data: any) {
    return this.http.post(data.url, data.body, { observe: 'response' });
  }

  /**
   * Se encarga de obtener los formularios.
   * @param data - Datos.
   * @param type - Ver tarea completada.
   * @returns Observable.
   */
  public getPendingTask(
    data?: PendingTaskPost,
    type?: 'get-complete' | 'get-complete-audit' | 'get-pending'
  ): Observable<any> {
    if (data)
      return this.http.post(
        `${this.enviroment.endpoints.url}/${
          this.enviroment.common.tenantId
        }/tasks/${type ? type : 'get-pending'}`,
        data
      );
    return new Observable();
  }

  /**
   * Obtiene los calculos de balance de caja previos.
   * @param executionId - Id de ejecucion.
   * @returns Observable.
   */
  public getPreviousCashBalance(executionId: string): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.workFlow}/${this.enviroment.common.tenantId}/cash-balance/${executionId}`
    );
  }

  /**
   * Se encarga de obtener los formularios.
   * @param executionId - Datos.
   * @returns Observable.
   */
  public getExecutionTask(executionId?: string): Observable<TaskExecution> {
    return this.http.get<TaskExecution>(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/audit-process/execution/data/${executionId}`
    );
  }

  /**
   * Se encarga de obtener los formularios.
   * @param data - Datos.
   * @returns Observable.
   */
  public getFormTask(data?: PendingTaskPost): Observable<any> {
    if (data)
      return this.http.post(
        `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/tasks/get-pending`,
        data
      );
    return new Observable();
  }

  /**
   * Subir archivo.
   * @param data - Datos.
   * @returns Observable.
   */
  public radicationSuccess(data: any): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.workFlow}/${this.enviroment.common.tenantId}/backoffice/task/success`,
      data
    );
  }

  /**
   * Buscar por id modificaciones.
   * @param process - Proceso.
   * @param nameProcess - Nombre del proceso.
   * @returns Observable.
   */
  public changesProcessById(
    process: string,
    nameProcess: string
  ): Observable<any> {
    if (nameProcess)
      return this.http.get(
        `${this.enviroment.endpoints.workFlow}/${this.enviroment.common.tenantId}/backoffice/task/changes-permission/${process}/${nameProcess}`
      );
    return new Observable();
  }

  /**
   * Generar documento.
   * @param idTemplate - Id Template.
   * @param documentName - Document Name.
   * @param data - Data.
   * @param process - Process Name.
   * @param executionId - Execution Id.
   * @param idMarket - Id de mercado.
   * @returns Observable.
   */
  public generateDocuments(
    idTemplate: string,
    documentName: string,
    data: any,
    process: string,
    executionId: string,
    idMarket: number,
    editableFields: boolean,
    secondStyle: boolean
  ): Observable<any> {
    const payload = {
      documents: [
        {
          idTemplate: idTemplate,
          name: documentName,
          inputData: data,
        },
      ],
      editableFields: editableFields,
      secondStyle: secondStyle,
      idMarket: idMarket,
      process: process,
      executionId: executionId,
      options: {
        locale: 'es',
        currency: 'COP',
      },
    };
    return this.http.post(
      `${this.enviroment.endpoints.urlDocuments}/${this.enviroment.common.tenantId}/documents`,
      payload
    );
  }

  /**
   * Obtener tipos de documentos.
   * @returns Observable.
   */
  public documentTypes(): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.urlDocuments}/${this.enviroment.common.tenantId}/documents/type`
    );
  }

  /**
   * Obtener lista de documentos.
   * @param idProcess - Id del proceso.
   * @returns Observable.
   */
  public documentsList(idProcess: string): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.urlDocuments}/${this.enviroment.common.tenantId}/documents/task/${idProcess}`
    );
  }

  /**
   * Obtener documento por id.
   * @param idProcess - Id del proceso.
   * @param idDocument - Id del documento.
   * @returns - Observables.
   */
  public documentById(idProcess: string, idDocument: string): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.urlDocuments}/${this.enviroment.common.tenantId}/documents/task/document/${idProcess}/${idDocument}`
    );
  }

  /**
   * Crear tipos de documentos.
   * @param data - Datos.
   * @returns Observable.
   */
  public createDocumentTypes(data: FormData): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.urlDocuments}/${this.enviroment.common.tenantId}/documents/task`,
      data,
      { observe: 'response' }
    );
  }

  /**
   * Crear tipos de documentos.
   * @param data - Datos.
   * @returns Observable.
   */
  public uploadDocumentTask(data: FormData): Observable<any> {
    const req = new HttpRequest(
      'POST',
      `${this.enviroment.endpoints.urlDocuments}/${this.enviroment.common.tenantId}/documents/task`,
      data,
      {
        reportProgress: true,
      }
    );

    return this.http.request(req).pipe(
      map((event: any) => ({
        body: event.body,
        percent: this.getEventMessage(event),
      })),
      catchError((err: any) => {
        return of({ error: err });
      })
    );
  }

  /**
   * Crear documentos etapa legalizacion.
   * @param data - Datos.
   * @returns Observable.
   */
  public createLegalizationDocuments(data: FormData): Observable<any> {
    const req = new HttpRequest(
      'POST',
      `${this.enviroment.endpoints.urlDocuments}/${this.enviroment.common.tenantId}/documents/legalization`,
      data,
      {
        reportProgress: true,
      }
    );

    return this.http.request(req).pipe(
      map((event: any) => ({
        body: event.body,
        percent: this.getEventMessage(event),
      })),
      catchError((err: any) => {
        return of({ error: err });
      })
    );
  }

  /**
   * Porcentaje de subida del documento.
   * @param event - Evento http.
   * @returns String.
   */
  private getEventMessage(event: any) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return `${
          event.total ? Math.round((100 * event.loaded) / event.total) : 0
        }`;

      case HttpEventType.Response:
        return `100`;

      default:
        if (event?.ok === true) {
          return `100`;
        } else if (event?.ok === false) {
          return `${event.status}`;
        } else {
          return `0`;
        }
    }
  }

  /**
   * Elimina un documento.
   * @param documentName - Nombre del documento.
   * @param taskToken - Token.
   * @returns Observable.
   */
  public deleteDocumentTypes(
    documentName: string,
    taskToken: string
  ): Observable<any> {
    return this.http.delete(
      `${this.enviroment.endpoints.urlDocuments}/${this.enviroment.common.tenantId}/documents/task`,
      {
        headers: { 'Content-Type': 'application/json' },
        body: {
          documentName: documentName,
          taskToken: taskToken,
        },
      }
    );
  }

  /**
   * Verifica la informacion de contacto.
   * @param data - Datos a validar.
   * @returns Observable.
   */
  public checkContactInfo(data: ContactInfoCheck): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/internal-list/rejection/check`,
      data
    );
  }

  /**
   * Envia el codigo OTP.
   * @param data - Información del contacto.
   * @returns Observable.
   */
  public sendCodeOtp(
    data: SendOtpCode
  ): Observable<ResponseData<SendOtpResponse>> {
    return this.http.post<ResponseData<SendOtpResponse>>(
      `${this.enviroment.endpoints.urlIntegrations}/masivian2/${this.enviroment.common.tenantId}/backoffice/otp/generate`,
      data
    );
  }

  /**
   * Verifica el codigo OTP.
   * @param data - Codigo a validar.
   * @returns Observable.
   */
  public codeOtpVerification(
    data: VerificationOtpCode
  ): Observable<VerificationOtpResponse> {
    return this.http.post<VerificationOtpResponse>(
      `${this.enviroment.endpoints.urlIntegrations}/masivian2/${this.enviroment.common.tenantId}/backoffice/otp/verify`,
      data
    );
  }
  /**
   * Obtiene ell historial de un proceso.
   * @param process - Proceso.
   * @param idExecution - Ejecucion.
   * @returns Observable.
   */
  public getProcessHistory(
    process: string,
    idExecution: string
  ): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/audit-process/${process}/${idExecution}/details`
    );
  }

  /**
   * Guarda la pre info de la radicacion.
   */
  public savePreInfo(idExecution: string, input: any): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/audit-process/pre-information`,
      {
        idExecution: idExecution,
        input: input,
      }
    );
  }

  /**
   * Tarea completada.
   * @param resultTask - Resultado.
   * @returns Observable.
   */
  public taskSuccess(resultTask: SuccessTask): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.workFlow}/${this.enviroment.common.tenantId}/backoffice/task/success`,
      {
        ...resultTask,
      }
    );
  }

  /**
   * Verificación de buro credito.
   * @param data - Request del servicio.
   * @returns Observable.
   */
  public getScoreTransunion(data: ScoreTransunion): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.urlIntegrations}/transunion/${this.enviroment.common.tenantId}/transunion/manual-score`,
      {
        lastName: data.lastName,
        identification: data.identification,
        identificationType: data.identificationType,
        chanel: this.enviroment.production ? 'production' : 'test',
        forceRequest: false, //FALSE PARA OMITIR EL SERVICIO
      }
    );
  }

  /**
   * Verificacion buro credito experian.
   * @param data - Request del servicio.
   * @returns Observable.
   */
  public getScoreExperian(data: ScoreExperian): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.urlIntegrations}/experian/${this.enviroment.common.tenantId}/experian/credit-history`,
      {
        lastName: data.lastName,
        documentNumber: data.documentNumber,
        documentType: data.documentType,
        forceRequest: false,
      }
    );
  }

  /**
   * Verificación de buro credito.
   * @param dateExecution - Fecha de ejecucion.
   * @param process - Proceso.
   * @param executionId - Id de ejecucion.
   * @param cause - Causa.
   * @returns Observable.
   */
  public stopApplicationCredit(
    dateExecution: any,
    process: any,
    executionId: any,
    cause: any = 'BuroFail'
  ): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.workFlow}/${this.enviroment.common.tenantId}/backOffice/process/${process}/${executionId}/stop`,
      {
        cause: cause,
        dateExecution,
      }
    );
  }

  /**
   * Obtiene el cliente prospecto.
   * @param documentNumber - Documento.
   * @param documentType - Tipo de documento.
   * @returns Observable.
   */
  public prospectiveClient(
    documentNumber: string,
    documentType: string
  ): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/task/previous-application/${documentNumber}/${documentType}`
    );
  }

  /**
   * Servicio ARUS.
   * @param documentType - Tipo de documento.
   * @param documentNumber - Documento.
   * @param periodoCount - Periodo.
   * @returns Observable.
   */
  public aplClient(
    documentType: string,
    documentNumber: string,
    periodoCount: number = 24
  ) {
    const payload = {
      tipo_documento: documentType,
      num_documento: documentNumber,
      periodo_count: periodoCount,
    };
    return this.http.post(
      `${this.enviroment.endpoints.urlApl}/${this.enviroment.common.tenantId}/apl/advancedQuery/`,
      payload
    );
  }

  /**
   * Servicio ARUS.
   * @param documentType - Tipo de documento.
   * @param documentNumber - Documento.
   * @returns Observable.
   */
  public arusClient(documentType: string, documentNumber: string) {
    const payload = {
      tipo_documento: documentType,
      num_documento: documentNumber,
    };
    return this.http.post(
      `${this.enviroment.endpoints.urlArus}/${this.enviroment.common.tenantId}/arus/consult`,
      payload
    );
  }

  /**
   * Servicio Trans Union Historial de credito.
   * @param lastName - Segundo nombre.
   * @param documentNumber - Numero de documento.
   * @param documentType - Tipo de documento.
   * @param chanel - Canal.
   * @param forceRequest - Forzar peticion.
   * @returns Observable.
   */
  public creditHistoryTransunion(
    lastName: string,
    documentNumber: string,
    documentType: string,
    chanel: string,
    forceRequest: boolean = false
  ) {
    const payload = {
      lastName: lastName,
      identification: documentNumber,
      identificationType: documentType,
      chanel: chanel,
      forceRequest: forceRequest,
    };
    return this.http.post(
      `${this.enviroment.endpoints.urlIntegrations}/transunion/${this.enviroment.common.tenantId}/transunion/credit-history`,
      payload
    );
  }

  /**
   * Servicio Trans Union Historial de credito.
   * @param lastName - Segundo nombre.
   * @param documentNumber - Numero de documento.
   * @param documentType - Tipo de documento.
   * @param chanel - Canal.
   * @param forceRequest - Forzar peticion.
   * @returns Observable.
   */
  public incomeEstimatorTransunion(
    lastName: string,
    documentNumber: string,
    documentType: string,
    chanel: string,
    forceRequest: boolean = false
  ) {
    const payload = {
      lastName: lastName,
      identification: documentNumber,
      identificationType: documentType,
      chanel: chanel,
      forceRequest: forceRequest,
    };
    return this.http.post(
      `${this.enviroment.endpoints.urlIntegrations}/transunion/${this.enviroment.common.tenantId}/transunion/income-estimator`,
      payload
    );
  }

  /**
   * Obtiene los bancos del catalogo.
   * @param description - Descripcion.
   * @param page - Pagina.
   * @param pageSize - Tamano de pagina.
   * @returns Observable.
   */
  public getBanks(
    description?: string,
    page?: number,
    pageSize?: number
  ): Observable<any> {
    let params = {};
    if (description) {
      params = { ...params, description };
    }
    if (page) {
      params = { ...params, page };
    }
    if (pageSize) {
      params = { ...params, pageSize };
    }
    return this.http.get(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/bank`,
      { params }
    );
  }

  /**
   * Servicio Trans Union Historial de credito.
   * @param plateNumber - Numero de placa.
   * @returns Observable.
   */
  public getInformationRunt(plateNumber: string) {
    const payload = {
      plateNumber: plateNumber,
      requestType: 'PLACA',
      chanel: this.enviroment.production ? 'production' : 'test',
      forceRequest: false,
    };

    return this.http.post(
      `${this.enviroment.endpoints.urlIntegrations}/intempo/${this.enviroment.common.tenantId}/intempo/consult-vehicle-data`,
      payload
    );
  }

  /**
   * Servicio Certicamara para consultar la URL donde se realizara la autenticacion de huella digital.
   * @param executionId - Id Execution.
   * @param employeeData - Datos de empleado.
   * @param debtor - Deudores.
   * @param guarantor - Avalistas.
   * @param promissoryNote - Pagare.
   * @param fieldsPromissoryNote - Campos adicionales pagare.
   * @param documentData - Datos documentos.
   * @returns Observable.
   */
  public consultingCerticamaraURI(
    executionId: string,
    employeeData: any,
    debtor: any,
    guarantor: any,
    promissoryNote: any,
    fieldsPromissoryNote: any,
    documentData: any
  ) {
    const payload = {
      Id_Execution: executionId,
      ProcessTypeEnum: this.enviroment.bodyCerticamara.ProcessTypeEnum,
      datosEmpleado: employeeData,
      deudores: debtor,
      avalistas: guarantor,
      pagare: promissoryNote,
      camposAdicionalesPagare: fieldsPromissoryNote,
      documentsData: documentData,
    };
    return this.http.post(
      `${this.enviroment.endpoints.urlCerticamara}/${this.enviroment.common.tenantId}/certicamara/consultingCerticamaraURl`,
      payload
    );
  }

  /**
   * Servicio para obtener la respuesta de certicamara.
   * @param executionId - Id Execution.
   * @param idAuthentication - Id autenticacion.
   * @returns Observable.
   */
  public getMessageCerticamara(executionId: string, idAuthentication: string) {
    const payload = {
      idExecution: executionId,
      idAuthenticacion: idAuthentication,
    };
    return this.http.post(
      `${this.enviroment.endpoints.urlCerticamara}/${this.enviroment.common.tenantId}/certicamara/getMessageCerticamara`,
      payload
    );
  }

  /**
   * Servicio para obtener la informacion del proceso de autenticacion.
   * @param executionId - Id Execution.
   * @param status - Estado.
   * @returns Observable.
   */
  public getProcessAuthentication(
    executionId: string,
    status: string
  ): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.urlCerticamara}/${this.enviroment.common.tenantId}/process/authentication/${executionId}/${status}`
    );
  }

  /**
   * Elimina un proceso de autenticacion.
   * @param executionId - Id Execution.
   * @param idAuthentication - Id autenticacion.
   * @returns Observable.
   */
  public deleteProcessAuthentication(
    executionId: string,
    idAuthentication: string
  ): Observable<any> {
    return this.http.delete(
      `${this.enviroment.endpoints.urlCerticamara}/${this.enviroment.common.tenantId}/process/authentication/${executionId}/${idAuthentication}`
    );
  }

  /**
   * Servicio consulta regla validación persona jurídica.
   * @param datarulevalidation - Tenan Id.
   * @returns Observable.
   */
  public consultingRuleValidationPj(datarulevalidation: any) {
    return this.http.post(
      `${this.enviroment.endpoints.urlRuleValidationPj}/rules/guarantor-validation`,
      datarulevalidation
    );
  }

  /**
   * Servicio calculo limite sugerido.
   * @param data - Data.
   * @returns Observable.
   */
  public calculateSuggestedLimit(data: any) {
    const payload = {
      variables: data.variables,
      operations: data.operations,
    };
    return this.http.post(
      `${this.enviroment.endpoints.workFlow}/operation/run`,
      payload
    );
  }

  /**
   * Servicio calculo limite sugerido.
   * @param data - Data.
   * @returns Observable.
   */
  public calculateScoreCard(data: any) {
    const payload = {
      ...data,
    };
    return this.http.post(
      `${this.enviroment.endpoints.backofficeRules}/rules/score-card`,
      payload
    );
  }

  /**
   * Obtener lista de proveedores.
   * @param idExecution - Id de ejecución.
   * @returns Observable.
   */
  public providersList(idExecution: string): Observable<ProviderProcess[]> {
    return this.http.get<ProviderProcess[]>(
      `${this.enviroment.endpoints.urlDocuments}/${this.enviroment.common.tenantId}/documents/process/providers/${idExecution}`
    );
  }
}
