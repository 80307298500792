import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueIncomeFrequency = createAction(
  '[KeyValueIncomeFrequency] Cargar ingresos frecuencia',
  props<{
    /**
     * Ruta.
     */
    path: CatalogueType;
  }>()
);

export const KeyValueIncomeFrequencySuccess = createAction(
  '[KeyValueIncomeFrequency] Crear ingresos frecuencia Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueIncomeFrequencyError = createAction(
  '[KeyValueIncomeFrequency] Cargar ingresos frecuencia Error',
   props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
