<div [formGroup]="formGroupDependence" class="row" *ngIf="controls">
  <div
    *ngFor="let item of controls"
    class="p-relative"
    [class.input-readonly]="item?.validators?.readonly || false"
    [hidden]="
      hideDisabled &&
      formGroupDependence.controls[item.key?.trim() || ''].disabled
    "
    [ngClass]="
      'col-xxl-' +
      (item.col?.xxl || '12') +
      (' col-xl-' + (item.col?.xl || '12')) +
      (' col-lg-' + (item.col?.lg || '12')) +
      (' col-md-' + (item.col?.md || '12')) +
      (' col-sm-' + (item.col?.sm || '12')) +
      (' col-xs-' + (item.col?.xs || '12'))
    ">
    <ng-container [ngSwitch]="item.type">
      <ng-container *ngSwitchCase="'AutoComplete'">
        <wp-back-office-form-control-autocomplete
          [getCatalogue]="false"
          [formControlName]="item?.key?.trim() || ''"
          [uniqueIdForm]="uniqueIdForm"
          [value]="
            formGroupDependence.value[item.key?.trim() || ''] || undefined
          "
          [control]="item"></wp-back-office-form-control-autocomplete>
      </ng-container>

      <ng-container *ngSwitchCase="'DropDown'">
        <wp-back-office-form-control-drop-down
          [getCatalogue]="false"
          [formControlName]="item?.key?.trim() || ''"
          [uniqueIdForm]="uniqueIdForm"
          [value]="
            formGroupDependence.value[item.key?.trim() || ''] || undefined
          "
          [control]="item">
        </wp-back-office-form-control-drop-down>
      </ng-container>
    </ng-container>

    <div class="input-spinner" *ngIf="item.validators?.loading || false">
      <mat-spinner color="primary" [diameter]="25"> </mat-spinner>
    </div>
  </div>
</div>
