import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';
import { RoomSegmentation } from 'libs/core/workflow-sdk/src/lib/models/market.model';
import { ResponseAWSData } from 'libs/core/workflow-sdk/src/lib/models/response-aws.model';
import { Room } from 'libs/core/workflow-sdk/src/lib/models/rooms.model';

/**
 * Acciones de la tabla de salas y sus filtros.
 */
export const SegmentationBranchActions = createActionGroup({
  source: 'SegmentationBranch',
  events: {
    Reset: emptyProps(),
    'Reset Rooms': emptyProps(),
    'Get Detail Rooms Bulk': props<{
      /**
       * Lista de codigos de salas.
       */
      codes?: string[];
      /**
       * Pagina, (si ni se envia el paginado reinicia la respuesta).
       */
      page?: number;
      /**
       * Tamaño de pagina.
       */
      pageSize: number;
    }>(),
    'get Rooms': props<{
      /**
       * Id del tipo de precio.
       */
      city?: string;
      /**
       * Departamento.
       */
      state?: string;
      /**
       * Mercado.
       */
      market?: string;
      /**
       * Grupo de concesionario.
       */
      officeGroup?: string;
      /**
       * Concesionario.
       */
      office?: string;
      /**
       * Pagina, (si ni se envia el paginado reinicia la respuesta).
       */
      page?: number;
      /**
       * Tamaño de pagina.
       */
      pageSize?: number;
    }>(),
    'get Rooms Success': props<{
      /**
       * Id del tipo de precio.
       */
      object: ResponseAWSData<RoomSegmentation>;
      /**
       * Pagina, (si ni se envia el paginado reinicia la respuesta).
       */
      page?: number;
    }>(),
    'get Rooms error': props<{
      /**
       * Id del tipo de precio.
       */
      error: ErrorHttp;
    }>(),

    'Get Offices Group': props<{
      /**
       * Pagina, (si ni se envia el paginado reinicia la respuesta).
       */
      page?: number;
      /**
       * Tamaño de la pagina.
       */
      pageSize?: string | number;
      /**
       * Codigo de mercado.
       */
      codeMarket?: string;
    }>(),
    'Get Offices Group Success': props<{
      /**
       * Caracteristicas.
       */
      object: any;
      /**
       * Pagina, (si ni se envia el paginado reinicia la respuesta).
       */
      page?: number;
    }>(),
    'Get Offices Group Error': props<{
      /**
       * Respuesta error Http.
       */
      error: ErrorHttp;
    }>(),
    'Get Offices': props<{
      /**
       * Codigo de red concesionario.
       */
      code: string;
      /**
       * Pagina, (si ni se envia el paginado reinicia la respuesta).
       */
      page?: number;
      /**
       * Tamaño de la pagina.
       */
      pageSize?: number;
    }>(),
    'Get Offices Success': props<{
      /**
       * Caracteristicas.
       */
      object: any;
      /**
       * Pagina, (si ni se envia el paginado reinicia la respuesta).
       */
      page?: number;
    }>(),
    'Get Offices Error': props<{
      /**
       * Respuesta error Http.
       */
      error: ErrorHttp;
    }>(),

    'Get Markets': props<{
      /**
       * Pagina, (si ni se envia el paginado reinicia la respuesta).
       */
      page?: number;
      /**
       * Tamaño de la pagina.
       */
      pageSize?: number;
    }>(),
    'Get Markets Success': props<{
      /**
       * Caracteristicas.
       */
      object: any;
      /**
       * Pagina, (si ni se envia el paginado reinicia la respuesta).
       */
      page?: number;
    }>(),
    'Get Markets Error': props<{
      /**
       * Respuesta error Http.
       */
      error: ErrorHttp;
    }>(),
  },
});
