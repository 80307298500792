import { createAction, props } from '@ngrx/store';
import { Catalogue } from '../../../models/contents/catalogue.models';
import { CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueCitiesCo = createAction(
  '[KeyValueCitiesCo] Cargar ciudades por id',
  props<{
    /**
     * Identificador.
     */
    path: CatalogueType;
  }>()
);

export const KeyValueCitiesCoSuccess = createAction(
  '[KeyValueCitiesCo] Crear ciudades Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueCitiesCoError = createAction(
  '[KeyValueCitiesCo] Cargar ciudades Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
