import {
  AfterViewInit,
  Component,
  DoCheck,
  Input,
  KeyValueDiffers,
  OnInit,
} from '@angular/core';
import { BasicConfig, DialogConfig } from '../models/dynamic-dialog.model';

/**
 * Componente de dialog dinamicos.
 */
@Component({
  selector: 'wp-back-office-dynamic-dialog',
  templateUrl: './dynamic-dialog-message.component.html',
  styleUrls: ['./dynamic-dialog-message.component.scss'],
})
export class DynamicDialogMessageComponent
  implements OnInit, AfterViewInit, DoCheck
{
  /**
   * Entrada configuracion del modal.
   */
  @Input()
  public dialogConfig?: DialogConfig;
  /**
   * Codigo del mensaje.
   */
  public messageCode?: string;
  /**
   * Identificador diferente.
   */
  public differ: any;

  /**
   * Crea una instancia de DynamicdialogMessageComponent.
   * @param differs - Diferentes.
   */
  public constructor(private differs: KeyValueDiffers) {
    this.differ = this.differs.find({}).create();
  }

  /**
   * Validacion de tareas.
   * @param template - Plantilla de configuracion basica modal.
   */
  private valideMessageCode(template: BasicConfig | undefined) {
    switch (template?.messageCode) {
      case 'CREDIT_ANALYSIS':
        this.messageCode = 'Su solicitud esta siendo revisada por un analista.';
        break;
      case 'UNAUTHORIZED_ACCESS':
        this.messageCode =
          'No tienes los permisos suficientes para seguir con la solicitud.';
        break;
      case 'NEXT_TASK':
        this.messageCode = 'Tarea finalizada correctamente.';
        break;
      case 'PROCESSING':
        this.messageCode = 'Su solicitud se esta procesando, por favor espere.';
        break;
      default:
        this.messageCode = undefined;
        break;
    }
  }

  /**
   * Se ejecuta cuando se carga la vista.
   */
  public ngOnInit(): void {
    this.updateMessage();
  }

  /**
   * Alc.
   */
  public ngAfterViewInit(): void {
    if (this.dialogConfig?.body?.html) {
      const div = document.createElement('div');
      div.textContent = this.dialogConfig?.body?.html || '';
      const myDiv = document.getElementById('htmlDiv');

      if (myDiv) {
        myDiv.appendChild(div);
      }
    }

    if (this.dialogConfig?.body?.script) {
      const prev = document.getElementById(this.dialogConfig?.body?.script);
      if (prev) {
        prev.remove();
      }
      const script = document.createElement('script');
      script.id = this.dialogConfig?.body?.script;
      script.textContent = this.dialogConfig?.body?.script;
      const myDiv = document.getElementById('htmlDiv');

      if (myDiv) {
        myDiv.appendChild(script);
      }
    }

    const element = document.getElementById('htmlDiv');
    if (element) {
      element.innerHTML = this.dialogConfig?.body?.html || '';
    }
  }

  /**
   * Lifecycle interface 'DoCheck' should be implemented for method 'ngDoCheck'.
   */
  public ngDoCheck() {
    const change = this.differ.diff(this);
    if (change) {
      change.forEachChangedItem(() => {
        this.updateMessage();
      });
    }
  }

  /**
   * Actualiza el mensaje.
   */
  public updateMessage() {
    this.messageCode = undefined;
    if (this.dialogConfig?.header?.messageCode) {
      this.valideMessageCode(this.dialogConfig?.header);
    }
    if (this.dialogConfig?.body?.messageCode) {
      this.valideMessageCode(this.dialogConfig?.body);
    }
    if (this.dialogConfig?.footer?.messageCode) {
      this.valideMessageCode(this.dialogConfig?.footer);
    }
  }
}
