/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WorkflowService } from '@wp-back-office/core/workflow-sdk';
import { mergeMap, map, catchError, of } from 'rxjs';

import * as actionsEffects from '../../actions';
import { ResponseStart } from '@wp-back-office/process/mvp-origination';

/**
 * Efecto de iniar tarea.
 */
@Injectable()
export class StartTaskEffects {
  /**
   * Crea una instancia de  StartTaskEffects.
   * @param actions$ - Acciones.
   * @param workflowService - Servicio workflow.
   */
  constructor(
    private actions$: Actions,
    private workflowService: WorkflowService
  ) {}

  /**
   * Iniciar Tarea.
   */
  public StartTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.StartTask),
      mergeMap(params =>
        this.workflowService
          .startTask(params.startTask, params.success || false)
          .pipe(
            map((object: ResponseStart) =>
              actionsEffects.StartTaskSuccess({ object })
            ),
            catchError(err =>
              of(actionsEffects.StartTaskError({ payload: err }))
            )
          )
      )
    )
  );
}
