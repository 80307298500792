import { Action, createReducer, on } from '@ngrx/store';
import { IsAuth, IsAuthSuccess, IsAuthError } from '../../actions';
import { resetInitialState } from '../../../../root/store/actions/contents/reset-initial-state.actions';

/**
 * Estado de autenticación.
 */
export interface IsAuthState {
  /**
   * Bandera de autenticación.
   */
  isAuthenticated: boolean;
  /**
   * Bandera de inicialización.
   */
  isInitializing: boolean;
}

export const isAuthState: IsAuthState = {
  isAuthenticated: false,
  isInitializing: true,
};

const featureReducer = createReducer(
  isAuthState,
  on(resetInitialState, () => ({ ...isAuthState })),
  on(IsAuth, state => ({ ...state })),
  on(IsAuthSuccess, (state, { isAuthenticated }) => ({
    ...state,
    isAuthenticated,
    isInitializing: false,
  })),
  on(IsAuthError, (state, { payload }) => ({
    ...state,
    isAuthenticated: false,
    isInitializing: false,
    ...payload,
  }))
);

/**
 * Reductor autenticación.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function isAuthReducer(state: IsAuthState | undefined, action: Action) {
  return featureReducer(state, action);
}
