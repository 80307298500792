import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';

export const envsMulticlientActions = createActionGroup({
  source: 'envsMulticlientActions',
  events: {
    Reset: emptyProps(),
    Get: emptyProps(),
    Success: props<{
      /**
       * Variables.
       */
      object: any;
    }>(),
    Error: props<{
      /**
       * Respuesta error Http.
       */
      error: ErrorHttp;
    }>(),
  },
});
