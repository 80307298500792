import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';
import { map, Observable } from 'rxjs';
import { ServiceMapper } from '../mappers/services.mapper';
import { ResponseAWS } from '../models/response-aws.model';
import {
  Service,
  ServiceDetail,
  SuccessResponse,
} from '../models/service.model';
/**
 * Servicio de control de acceso.
 */
@Injectable()
export class ProductsServicesService {
  /**
   * Mapeador.
   */
  private mapper = new ServiceMapper();
  /**
   * Crea una instancia de WorkflowService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}
  /**
   * Obtiene la lista de servicios.
   * @param description - Descripción del servicio.
   * @param pageSize - Tamaño de la pagina.
   * @param paginationToken - Token para la paginacion.
   * @returns - Observable.
   */
  public getServicesList(
    description?: string,
    pageSize?: number,
    paginationToken?: number
  ): Observable<ResponseAWS<Service>> {
    let params = {};
    let paginationCounter = 0;
    if (description) {
      params = { ...params, description };
    }
    if (pageSize) {
      params = { ...params, pageSize };
    }
    if (paginationToken !== undefined) {
      paginationCounter = paginationToken + 1;
      params = { ...params, page: paginationCounter };
    }
    this.enviroment.endpoints.url;
    return this.http
      .get<ResponseAWS<ServiceDetail>>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/service`,
        {
          params: params,
        }
      )
      .pipe(
        map(response => {
          return {
            ...response,
            items: response.items.map(item => this.mapper.mapFrom(item)),
            paginationToken:
              response.count === 0
                ? paginationToken || 0
                : paginationToken !== undefined
                ? paginationCounter
                : 1,
          };
        })
      );
  }
  /**
   * Habilita/Deshabilita el servicio.
   * @param code - Codigo del servicio.
   * @param isEnabled - Estado.
   * @returns Observable.
   */
  public enableDisableService(
    code: number,
    isEnabled: boolean
  ): Observable<Service> {
    return this.http
      .patch<NonNullable<SuccessResponse<ServiceDetail>>>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/service/${code}?enabled=${isEnabled}`,
        {}
      )
      .pipe(map(response => this.mapper.mapFrom(response.body)));
  }
  /**
   * Obtiene los datos del servicio.
   * @param code - Codigo del servicio.
   * @returns Observable.
   */
  public viewService(code: number): Observable<Service> {
    return this.http
      .get<ServiceDetail>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/service/${code}`
      )
      .pipe(map(response => this.mapper.mapFrom(response)));
  }
  /**
   * Actualiza los datos del servicio.
   * @param service - Servicio.
   * @returns Observable.
   */
  public updateService(
    service: Service
  ): Observable<SuccessResponse<undefined>> {
    return this.http.put<SuccessResponse<undefined>>(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/service/${service.code}`,
      { ...this.mapper.mapTo(service), code: undefined }
    );
  }
}
