import { Action, createReducer, on } from '@ngrx/store';
import { ErrorHttp } from '../../../../models/errors.models';
import { resetInitialState } from '../../../../root/store/actions';

import {
  TaskSuccess,
  TaskSuccessError,
  TaskSuccessSuccess,
} from '../../actions';

/**
 * Estado de tareas exitosas.
 */
export interface TaskSuccessState {
  /**
   * Objecto.
   */
  object?: any;
  /**
   * Bandera de cargado.
   */
  loaded: boolean;
  /**
   * Bandera de cargando.
   */
  loading: boolean;
  /**
   * Errores.
   */
  error?: ErrorHttp;
}

export const taskSuccessInitialState: TaskSuccessState = {
  object: undefined,
  loaded: false,
  loading: false,
  error: undefined,
};

const featureReducer = createReducer(
  taskSuccessInitialState,
  on(resetInitialState, () => ({ ...taskSuccessInitialState })),
  on(TaskSuccess, state => ({ ...state, loading: true })),
  on(TaskSuccessSuccess, (state, { object }) => ({
    ...state,
    loading: false,
    loaded: true,
    object,
  })),
  on(TaskSuccessError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

/**
 * Reductor tareas exitosas.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function TaskSuccessReducer(
  state: TaskSuccessState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
