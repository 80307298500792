import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Catalogue, Enviroment } from '@wp-back-office/core/commons-backoffice';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseAWS } from '../models/response-aws.model';
/**
 * Servicio de planes financieros.
 */
@Injectable()
export class EconomicActivityService {
  /**
   * Crea una instancia de WorkflowService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}
  /**
   * Obtiene la lista de planes financieros.
   * @param typesPerson - Tipos de persona.
   * @param description - Descripción.
   * @param page - Pagina.
   * @param pageSize - Tamaño de la pagina.
   * @returns - Observable.
   */
  public getEconomicActivityList(
    typesPerson?: Catalogue[],
    description?: string,
    page?: number,
    pageSize?: number | string
  ): Observable<Catalogue[]> {
    let params = {};
    if (description !== undefined) {
      params = { ...params, description };
    }
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    if (typesPerson !== undefined) {
      params = {
        ...params,
        'codes_type_persons[]': typesPerson.map(type => Number(type.code)),
      };
    }
    this.enviroment.endpoints.url;
    return this.http
      .get<ResponseAWS<Catalogue>>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/economic_activity`,
        {
          params: params,
        }
      )
      .pipe(
        map(response => {
          return (
            typesPerson === undefined || typesPerson.length > 0
              ? response.items
              : []
          ).map((item: any) => ({
            code: item.code.toString(),
            code_catalog: item.code_catalog,
            description: item.description,
          }));
        })
      );
  }
}
