import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { FormatedTask } from '@wp-back-office/app/global-information';
/**
 * Accciones de tareas.
 */
export const userProcessActions = createActionGroup({
  source: 'userProcess',
  events: {
    /**
     * Obtener tareas.
     */
    'user Process': props<{
      /**
       * Tamaño.
       */
      size: number;
      /**
       * Objetivo.
       */
      target: string;
      /**
       * Token para la paginacion.
       */
      lastKey?: string;
      /**
       * Valor a filtrar.
       */
      filter?: string;
      /**
       * Proceso.
       */
      process: string;
    }>(),

    /**
     * Obtener tareas pendientes Exitoso.
     */
    'user Process Success': props<{
      /**
       * Token para la paginacion.
       */
      lastKey?: string;
      /**
       * Tarea pendiente.
       */
      userProcess: FormatedTask[];
    }>(),

    /**
     * Obtener tarea pendiente Error.
     */
    'user Process Error': props<{
      /**
       * Payload.
       */
      payload: any;
    }>(),

    /**
     * Eliminar tarea pendiente.
     */
    'user Process Delete': props<{
      /**
       * Identificador.
       */
      id: string;
    }>(),

    /**
     * Eliminar tarea pendiente Exitoso.
     */
    'user Process Delete Success': props<{
      /**
       * Datos.
       */
      data: any;
    }>(),

    /**
     * Eliminar tarea pendiente Error.
     */
    'user Process Delete Error': props<{
      /**
       * Payload.
       */
      payload: any;
    }>(),

    /**
     * Buscar por id tarea pendiente.
     */
    'user Process Find Id': props<{
      /**
       * Identificador.
       */
      id: string;
    }>(),

    /**
     * Buscar por id tarea pendiente Exitoso.
     */
    'user Process Find Id Success': props<{
      /**
       * Datos.
       */
      data: any;
    }>(),

    /**
     * Buscar por id tarea pendiente Error.
     */
    'user Process Find Id Error': props<{
      /**
       * Payload.
       */
      payload: any;
    }>(),

    /**
     * Obtener tareas de la pagina siguiente.
     */
    'user Process Next': props<{
      /**
       * Tamaño.
       */
      size: number;
      /**
       * Objetivo.
       */
      target: string;
      /**
       * Proceso.
       */
      process: string;
    }>(),

    /**
     * Obtener tareas de la pagina siguiente Exitoso.
     */
    'user Process Next Success': props<{
      /**
       * Tareas pendientes.
       */
      userProcess: FormatedTask[];
      /**
       * Siguiente llave.
       */
      nextKey: string;
    }>(),

    /**
     * Obtener tareas de la pagina siguiente Error.
     */
    'user Process Next Error': props<{
      /**
       * Payload.
       */
      payload: any;
    }>(),

    /**
     * Actualizar por id tarea pendiente.
     */
    'user Process Update': props<{
      /**
       * Identificador.
       */
      id: string;
      /**
       * Estado,.
       */
      status: string;
    }>(),

    /**
     * Actualizar por id tarea pendiente Exitoso.
     */
    'user Process Update Success': props<{
      /**
       * Datos.
       */
      data: any;
    }>(),

    /**
     * Actualizar por id tarea pendiente Error.
     */
    'user Process Update Error': props<{
      /**
       * Payload.
       */
      payload: any;
    }>(),

    /**
     * Reasignar tarea a usuario.
     */
    'user Process Reassign': props<{
      /**
       * Grupo.
       */
      group: string;
      /**
       * Token de la tarea.
       */
      taskToken: string;
      /**
       * Fecha.
       */
      date: number;
      /**
       * Proceso.
       */
      process: string;
    }>(),

    /**
     * Reasignar tarea a usuario Exitoso.
     */
    'user Process Reassign Success': props<{
      /**
       * Datos.
       */
      data: any;
    }>(),

    /**
     * Reasignar tarea a usuario Error.
     */
    'user Process Reassign Error': props<{
      /**
       * Payload.
       */
      payload: any;
    }>(),

    /**
     * Reset tareas.
     */
    'user Process Reset': emptyProps(),
  },
});
