import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';
import { Room } from 'libs/core/workflow-sdk/src/lib/models/market.model';
import { RoomsOfficeId } from 'libs/core/workflow-sdk/src/lib/models/rooms.model';

export const roomsSegmentationActions = createActionGroup({
  source: 'Rooms by Segmentation',
  events: {
    Reset: emptyProps(),
    'Get List': props<{
      /**
       * Codigo de concesionario.
       */
      code: string;
      /**
       * Pagina.
       */
      page?: number;
      /**
       * Tamaño de la pagina.
       */
      pageSize?: number;
    }>(),
    Success: props<{
      /**
       * Caracteristicas.
       */
      object: RoomsOfficeId<Room>[];
    }>(),
    Error: props<{
      /**
       * Respuesta error Http.
       */
      error: ErrorHttp;
    }>(),
  },
});
