import { Room, RoomInput } from '../models/rooms.model';

export class RoomsMapper {
  /**
   * Mapea desde el formato AWS a nuestro modelo.
   * @param param - Parametros desde el backend.
   * @returns - Objeto de nuestro modelo.
   */
  public mapFrom(param: RoomInput): Room {
    return {
      city: param?.city,
      code_office: param?.code_office,
      code_office_group: param?.office_group_code,
      code_room: param?.code_room,
      description_group: param?.office_group_description,
      description_markets: param?.markets
        .map(market => market.market_description)
        .join(', '),
      description_office: param?.office_description,
      description_room: param?.description,
      state: param?.state,
    };
  }
}
