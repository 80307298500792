import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Catalogue, Enviroment } from '@wp-back-office/core/commons-backoffice';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FinancialPlanMapper } from '../mappers/financial-plan.mapper';
import {
  FinancialPlan,
  FinancialPlanDetail,
} from '../models/financial-plan.model';
import { ResponseAWS } from '../models/response-aws.model';
/**
 * Servicio de planes financieros.
 */
@Injectable()
export class LogsService {
  /**
   * Mapeador.
   */
  private mapper = new FinancialPlanMapper();
  /**
   * Crea una instancia de WorkflowService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}
  /**
   * Obtiene la lista de planes financieros.
   * @param code - Codigo de concesionario.
   * @param page - Pagina.
   * @param pageSize - Tamaño de la pagina.
   * @returns - Observable.
   */
  public getLogsList(
    code: number,
    userFilter?: string,
    pageSize?: number,
    paginationToken?: string
  ): Observable<LogModel<any>[]> {
    let params = {};
    if (userFilter !== undefined) {
      params = { ...params, userFilter };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    if (paginationToken !== undefined) {
      params = { ...params, paginationToken };
    }
    return this.http
      .get<ResponseAWS<LogModel<FinancialPlanDetail>>>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/financial-plan/logs/${code}`,
        {
          params: params,
        }
      )
      .pipe(
        map(response => {
          return response.items.map(item => {
            return {
              ...item,
              financialPlan: this.mapper.mapFrom(
                item.financialPlan || item.financialPlan2
              ),
            };
          });
        })
      );
  }
}
/**
 *{AGREGUE DOCUMENTACION}.
 */
export interface LogModel<T> {
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  id: number;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  full_name_user: string;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  date: Date;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  user_id: string;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  ip_client?: string;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  user_email: string;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  financialPlan: T;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  financialPlan2?: T;
}
