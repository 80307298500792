import { createAction, props } from '@ngrx/store';
import {
  Catalogue,
  CatalogueType,
} from '../../../models/contents/catalogue.models';

export const getCatalogueGeneral = createAction(
  '[getCatalogueGeneral] Cargar Catalogos',
  props<{
    /**
     * Ruta.
     */
    path: string;
  }>()
);

export const getCatalogueGeneralSuccess = createAction(
  '[getCatalogueGeneral] Crear Catalogos Exitoso',
  props<{
    /**
     * Ruta.
     */
    path: string;
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const getCatalogueGeneralError = createAction(
  '[getCatalogueGeneral] Cargar Catalogos Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
