import { KeyValueTypeOfPhoneEffects } from './contents/catalogue-type-of-phone.effects';
import { KeyValueApplicantTypeEffects } from './contents/catalogue-applicant-type.effects';
import { KeyValueBanksEffects } from './contents/catalogue-banks.effects';
import { KeyValueCitiesEffects } from './contents/catalogue-cities-effects';
import { KeyValueDepartmentsEffects } from './contents/catalogue-departments.effects';
import { KeyValueDocumentsApprovalStatusEffects } from './contents/catalogue-documents-approval-status.effects';
import { KeyValueDocumentsReturnReasonsEffects } from './contents/catalogue-documents-return-reasons.effects';
import { KeyValueFamilyRelationshipsEffects } from './contents/catalogue-family-relationships.effects';
import { KeyValueGenderEffects } from './contents/catalogue-gender.effects';
import { KeyValueLegalreviewApprovalStatusEffects } from './contents/catalogue-legalreview-approval-status.effects';
import { KeyValueLevel1ApprovalStatusEffects } from './contents/catalogue-level1-approval-status.effects';
import { KeyValueProductCategoryEffects } from './contents/catalogue-product-category.effects';
import { KeyValueProductsEffects } from './contents/catalogue-products.effects';
import { KeyValueReferenceReturnReasonsEffects } from './contents/catalogue-reference-return-reasons.effects';
import { KeyValueTermsEffects } from './contents/catalogue-terms.effects';
import { KeyValueTypeAddressEffects } from './contents/catalogue-type-address.effects';
import { KeyValueTypeBankAccountsEffects } from './contents/catalogue-type-bank-accounts.effects';
import { KeyValueTypeOfReferencesEffects } from './contents/catalogue-type-of-references.effects';
import { KeyValueVerificationkycApprovalStatusEffects } from './contents/catalogue-verificationkyc-approval-status.effects';
import { KeyValueCountriesEffects } from './contents/catalogue-countries.effects';
import { KeyValueCurrencyTypeEffects } from './contents/catalogue-currency-type.effects';
import { KeyValueIncomeFrequencyEffects } from './contents/catalogue-income-frequency.effects';
import { KeyValueLevelOfSchoolingEffects } from './contents/catalogue-level-of-schooling.effects';
import { KeyValueMaritalStatusEffects } from './contents/catalogue-marital-status.effects';
import { KeyValueTypeCustomersEffects } from './contents/catalogue-type-customers.effects';
import { KeyValueTypeDocumentsEffects } from './contents/catalogue-type-documents.effects';
import { KeyValueTypeOfWellingEffects } from './contents/catalogue-type-of-welling.effects';
import { KeyValueAssetTypeEffects } from './contents/catalogue-asset-type.effects';
import { KeyValueTypeJobEffects } from './contents/catalogue-type-job.effects';
import { KeyValueTypeOfEgressEffects } from './contents/catalogue-type-of-egress.effects';
import { KeyValueTypeOfLiabilitiesEffects } from './contents/catalogue-type-of-liabilities.effects';
import { KeyValueLoanApplicantEffects } from './contents/catalogue-loan-applicant.effects';
import { KeyValueAmortizationCreditsEffects } from './contents/catalogue-amortization-credits.effects';
import { KeyValueVehicleSegmentEffects } from './contents/catalogue-vehicle-segment.effects';
import { KeyValueTypePersonEffects } from './contents/catalogue-type-person.effects';
import { KeyValueCustomerClassificationEffects } from './contents/catalogue-customer-classification.effects';
import { KeyValueFundingTypeEffects } from './contents/catalogue-funding-type.effects';
import { KeyValueReferenceRateEffects } from './contents/catalogue-reference-rate.effects';
import { KeyValueIncomeTypeEffects } from './contents/catalogue-income-type.effects';
import { KeyValueFirstExtraQuoteEffects } from './contents/catalogue-first-extra-quote.effects';
import { KeyValueTypePerson2Effects } from './contents/catalogue-type-person-2.effects';
import { KeyValueUnitAmountEffects } from './contents/catalogue-unit-amount.effects';
import { KeyValueUnitQuotaEffects } from './contents/catalogue-unit-quota.effects';
import { KeyValueTypeServiceEffects } from './contents/catalogue-type-service.effects';
import { KeyValueTypePlanEffects } from './contents/catalogue-type-plan.effects';
import { KeyValueTypeMarketEffects } from './contents/catalogue-type-market.effects';
import { KeyValueCitiesCoEffects } from './contents/catalogue-cities-co.effects';
import {
  KeyValueContractTypeEffects,
  KeyValueEconomicActivitiesEffects,
  KeyValueEpsEffects,
  KeyValueFormPaymentEffects,
  KeyValueIdentificationTypeEffects,
  KeyValueKindSocietyEffects,
  KeyValuePensionFundsEffects,
  KeyValueRegimeTypeEffects,
  KeyValueRegionEffects,
  KeyValueResponseEffects,
  KeyValueSocialSecurityEffects,
  KeyValueStatusEffects,
  KeyValueTypeContributorEffects,
  KeyValueTypeSaleEffects,
  KeyValueUserTypeEffects,
} from './contents/catalogue-query.effects';
import { KeyValueLegalizationDocumentEffects } from './contents/catalogue-legalization-documents.effects';
import { KeyValueSBSCitiesEffects } from './contents/catalogue-sbs-cities.effects';
import { KeyValueSBSGenderEffects } from './contents/catalogue-sbs-gender.effects';
import { KeyValueScoreBuroEffects } from './contents/catalogue-score-buro.effects';
import { KeyValueArmoreLevelEffects } from './contents/catalogue-armore-level.effects';
import { KeyValueDefaultValuesInsurersEffects } from './contents/catalogue-default-values-insurers.effects';
import { KeyValueListInsurersEffects } from './contents/catalogue-list-insurers.effects';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EffectsCatalogueArray: any[] = [
  KeyValueTypeDocumentsEffects,
  KeyValueTypeCustomersEffects,
  KeyValueCountriesEffects,
  KeyValueLevelOfSchoolingEffects,
  KeyValueTypeOfWellingEffects,
  KeyValueMaritalStatusEffects,
  KeyValueIncomeFrequencyEffects,
  KeyValueCurrencyTypeEffects,
  KeyValueAssetTypeEffects,
  KeyValueTypeJobEffects,
  KeyValueTypeOfEgressEffects,
  KeyValueTypeOfLiabilitiesEffects,
  KeyValueTypeOfPhoneEffects,
  KeyValueTypeBankAccountsEffects,
  KeyValueBanksEffects,
  KeyValueDocumentsApprovalStatusEffects,
  KeyValueDocumentsReturnReasonsEffects,
  KeyValueDepartmentsEffects,
  KeyValueCitiesEffects,
  KeyValueFamilyRelationshipsEffects,
  KeyValueGenderEffects,
  KeyValueLegalreviewApprovalStatusEffects,
  KeyValueLevel1ApprovalStatusEffects,
  KeyValueReferenceReturnReasonsEffects,
  KeyValueTypeAddressEffects,
  KeyValueVerificationkycApprovalStatusEffects,
  KeyValueApplicantTypeEffects,
  KeyValueTypeOfReferencesEffects,
  KeyValueProductsEffects,
  KeyValueTermsEffects,
  KeyValueProductCategoryEffects,
  KeyValueLoanApplicantEffects,
  KeyValueAmortizationCreditsEffects,
  KeyValueVehicleSegmentEffects,
  KeyValueTypePersonEffects,
  KeyValueCustomerClassificationEffects,
  KeyValueFundingTypeEffects,
  KeyValueReferenceRateEffects,
  KeyValueIncomeTypeEffects,
  KeyValueFirstExtraQuoteEffects,
  KeyValueTypePerson2Effects,
  KeyValueUnitAmountEffects,
  KeyValueUnitQuotaEffects,
  KeyValueTypeServiceEffects,
  KeyValueTypePlanEffects,
  KeyValueTypeMarketEffects,
  KeyValueIdentificationTypeEffects,
  KeyValueEconomicActivitiesEffects,
  KeyValueResponseEffects,
  KeyValueContractTypeEffects,
  KeyValueSocialSecurityEffects,
  KeyValueUserTypeEffects,
  KeyValueStatusEffects,
  KeyValueRegionEffects,
  KeyValueRegimeTypeEffects,
  KeyValueTypeSaleEffects,
  KeyValueKindSocietyEffects,
  KeyValueTypeContributorEffects,
  KeyValueFormPaymentEffects,
  KeyValueLegalizationDocumentEffects,
  KeyValueSBSCitiesEffects,
  KeyValueSBSGenderEffects,
  KeyValueCitiesCoEffects,
  KeyValueScoreBuroEffects,
  KeyValueArmoreLevelEffects,
  KeyValuePensionFundsEffects,
  KeyValueEpsEffects,
  KeyValueDefaultValuesInsurersEffects,
  KeyValueListInsurersEffects,
];
