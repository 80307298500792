import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';
import {
  Segment,
  SegmentDetail,
} from 'libs/core/workflow-sdk/src/lib/models/segment.model';

export const segmentationActions = createActionGroup({
  source: 'Segmentation',
  events: {
    Reset: emptyProps(),
    'Get List': props<{
      /**
       * Pagina.
       */
      page?: number;
      /**
       * Tamaño de la pagina.
       */
      pageSize?: number | string;
      /**
       * Codigo de tipo de precio.
       */
      typePriceCode: string;
      /**
       * Descripción.
       */
      description?: string;
    }>(),
    Get: props<{
      /**
       * Id del segmento.
       */
      code: string;
    }>(),
    Create: emptyProps(),
    Success: props<{
      /**
       * Lista.
       */
      list?: Segment[];
      /**
       * Ver.
       */
      view?: any;
      /**
       * Crear.
       */
      create?: SegmentDetail;
    }>(),
    Error: props<{
      /**
       * Respuesta error Http.
       */
      error: ErrorHttp;
    }>(),
  },
});
