import { createSelector } from '@ngrx/store';
import { featureDashBoard } from '../dashboard.reducers';
import { SessionState } from '../reducers';

export const selectSession = createSelector(
  featureDashBoard,
  state => state?.session
);

export const selectAttributes = createSelector(
  selectSession,
  ({ attributes }: SessionState) => attributes
);

export const selectAccessToken = createSelector(
  selectSession,
  ({ signInUserSession }: SessionState) => signInUserSession.accessToken
);

export const selectIdToken = createSelector(
  selectSession,
  ({ signInUserSession }: SessionState) => signInUserSession.idToken
);

export const selectIdTokenPayload = createSelector(
  selectSession,
  ({ signInUserSession }: SessionState) => signInUserSession.idToken
);

export const selectRefreshToken = createSelector(
  selectSession,
  ({ signInUserSession }: SessionState) => signInUserSession.refreshToken
);

export const selectSignInUserSession = createSelector(
  selectSession,
  ({ signInUserSession }: SessionState) => signInUserSession
);

export const selectIsAuth = createSelector(
  selectSession,
  state => state?.isAuth
);
