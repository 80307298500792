import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';
import { OptionsVehicleFinancialPlan } from 'libs/core/workflow-sdk/src/lib/models/vehicle-detail.model';

export const optionsVehicleForFinancialPlan = createActionGroup({
  source: 'Options Vehicle For Financial Plan',
  events: {
    Reset: emptyProps(),
    'Get Models By Type Price': props<{
      /**
       * Id del tipo de precio.
       */
      typePriceId: number;
    }>(),
    'Get Servicies By Type Price': props<{
      /**
       * Id del tipo de precio.
       */
      typePriceId: number;
    }>(),
    'Get Families By Type Price': props<{
      /**
       * Id del tipo de precio.
       */
      typePriceId: number;
    }>(),
    'Get Versions By Type Price': props<{
      /**
       * Id del tipo de precio.
       */
      typePriceId: number;
    }>(),
    'Success Get Models By Type Price': props<{
      /**
       * Id del tipo de precio.
       */
      object: OptionsVehicleFinancialPlan;
    }>(),
    'Success Get Services By Type Price': props<{
      /**
       * Id del tipo de precio.
       */
      object: OptionsVehicleFinancialPlan;
    }>(),
    'Success Get Families By Type Price': props<{
      /**
       * Id del tipo de precio.
       */
      object: OptionsVehicleFinancialPlan;
    }>(),
    'Success Get Versions By Type Price': props<{
      /**
       * Id del tipo de precio.
       */
      object: OptionsVehicleFinancialPlan;
    }>(),
    Success: props<{
      /**
       * Caracteristicas.
       */
      object: OptionsVehicleFinancialPlan;
    }>(),
    Error: props<{
      /**
       * Respuesta error Http.
       */
      error: ErrorHttp;
    }>(),
  },
});
