import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseAWS } from '../models/response-aws.model';
import { SegmentationOption } from '../models/segmentation-option.model';
/**
 * Servicio de planes financieros.
 */
@Injectable()
export class SegmentationOptionsService {
  /**
   * Crea una instancia de WorkflowService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}
  /**
   * Obtiene la lista de planes financieros.
   * @param page - Pagina.
   * @param pageSize - Tamaño de la pagina.
   * @returns - Observable.
   */
  public getSegmentationOptionsList(
    page?: number,
    pageSize?: number | string
  ): Observable<SegmentationOption[]> {
    let params = {};
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize: pageSize };
    }
    return this.http
      .get<ResponseAWS<SegmentationOption>>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/typeOption-segmentation`,
        {
          params: params,
        }
      )
      .pipe(
        map(response => {
          return response.items;
        })
      );
  }
}
