import { createSelector } from '@ngrx/store';
import { featureAuditProcess } from '../../audit-process.reducers';

export const selectAuditProccess = createSelector(
  featureAuditProcess,
  state => state.AuditProcesses
);

export const selectAuditDetail = createSelector(
  featureAuditProcess,
  state => state.AuditDetail
);

export const selectAuditPayload = createSelector(
  featureAuditProcess,
  state => state.payload?.information?.data
);

export const selectAuditProcessExecution = createSelector(
  featureAuditProcess,
  state => state.AuditProcessExecution?.information?.data
);
