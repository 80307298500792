import { ActionReducerMap } from '@ngrx/store';
import * as reducers from './reducers';

/**
 * Modelo estado para analisis de credito.
 */
export interface AnalysisState {
  /**
   * Esquemas para el proceso.
   */
  Schemas: reducers.SchemaAnalysisState;
  /**
   * Peticiones para el proceso.
   */
  Request: reducers.RequestAnalysisState;
  /**
   * Datos de carga inicial.
   */
  Payload: reducers.PayloadAnalysisState;
}

export const AnalysisReducers: ActionReducerMap<AnalysisState> = {
  Schemas: reducers.SchemaAnalysisReducer,
  Request: reducers.RequestAnalysisReducer,
  Payload: reducers.PayloadAnalysisReducer,
};
