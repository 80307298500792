import { createActionGroup, emptyProps, props } from '@ngrx/store';
/**
 * Accciones de tareas.
 */
export const tasksActionsDashBoard = createActionGroup({
  source: 'tasksActionsDashBoard',
  events: {
    /**
     * Obtener tareas de un usuario.
     */
    'Get User Tasks ': props<{
      /**
       * Tamaño.
       */
      size: number;
      /**
       * Objetivo.
       */
      target: string;
      /**
       * Token para la paginacion.
       */
      lastKey?: string;
      /**
       * Valor a filtrar.
       */
      filter?: string;
      /**
       * Proceso.
       */
      process: string;
      /**
       * Id.
       */
      id?: string;
    }>(),
    /**
     * Obtener tareas de un usuario Exitoso.
     */
    'Get User Tasks Success': props<{
      /**
       * Respuesta del store.
       */
      object: any;
    }>(),
    /**
     * Obtener tareas de un usuario Error.
     */
    'Get User Tasks Error': props<{
      /**
       * Respuesta erronea del store.
       */
      err: any;
    }>(),

    /**
     * Obtener tareas de un usuario Error.
     */
    'Get User Tasks Reset': emptyProps(),

    /**
     * Obtener los usuarios a los que se puede asignar una tarea.
     */
    'Get Task Users': props<{
      /**
       * Token jwt.
       */
      taskToken?: string;
      /**
       * Codigo del usuario.
       */
      userCode?: string;
      /**
       * Fecha en el formato.
       */
      date?: number;
      /**
       * Proceso.
       */
      process?: string;
    }>(),
    /**
     * Obtener los usuarios a los que se puede asignar una tareaExitoso.
     */
    'Get Task Users Success': props<{
      /**
       * Respuesta del store.
       */
      object: any;
    }>(),
    /**
     * Obtener los usuarios a los que se puede asignar una tarea Error.
     */
    'Get Task Users Error': props<{
      /**
       * Respuesta erronea del store.
       */
      err: any;
    }>(),

    /**
     * Reasignar tareas a un usuario.
     */
    'Put Reasing Task User': props<{
      /**
       * Codigo de usuario actual.
       */
      oldUserId: string;
      /**
       * Codigo de ususario nuevo.
       */
      newUserId: string;
      /**
       *  Proceso.
       */
      process: string;
      /**
       *  Fecha de la tarea.
       */
      date: number;
      /**
       * Token de la tarea.
       */
      taskToken: string;
    }>(),
    /**
     * Reasignar tareas a un usuario Exitoso.
     */
    'Put Reasing Task User Success': props<{
      /**
       * Respuesta del store.
       */
      object: any;
    }>(),
    /**
     * Reasignar tareas a un usuario Error.
     */
    'Put Reasing Task User Error': props<{
      /**
       * Respuesta erronea del store.
       */
      err: any;
    }>(),
  },
});
