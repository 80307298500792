import { Directive } from '@angular/core';
import {
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

/**
 * Direcitva de validacion de plantillas ubicacion geografica.
 */
@Directive({
  selector: '[wpBackOfficeCustomValidatorDirective]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ValidatorTemplateGeographicLocationDirective,
      multi: true,
    },
  ],
})
export class ValidatorTemplateGeographicLocationDirective implements Validator {
  /**
   *  Valida los formularios para determinar si la entrada es valida.
   * @param control - Control de entrada.
   * @returns Boleano.
   */
  public validate(control: AbstractControl): ValidationErrors | null {
    let isValid = true;
    if (control.value === '' || control.value === undefined) {
      isValid = false;
    } else {
      const isValidCountry = control.value.country !== '';
      const isValidState = control.value.state !== '';
      const isValidCity = control.value.city !== '';
      isValid = isValidCountry && isValidState && isValidCity;
    }

    return isValid
      ? null
      : {
          required: {
            valid: false,
          },
        };
  }
}
