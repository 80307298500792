import { createAction, props } from '@ngrx/store';
import { SuccessTask } from '@wp-back-office/core/workflow-sdk';
import { ResponseStart } from '../../../models';

export const TaskSuccess = createAction(
  '[TaskSuccess] Iniciar el proceso',
  props<{
    /**
     *
     */
    object: SuccessTask;
  }>()
);

export const TaskSuccessSuccess = createAction(
  '[TaskSuccess] Iniciar el proceso Exitoso',
  props<{
    /**
     *
     */
    object: ResponseStart;
  }>()
);

export const TaskSuccessError = createAction(
  '[TaskSuccess] Iniciar el proceso Error',
  props<{
    /**
     *
     */
    payload: any;
  }>()
);

export const TaskPayload = createAction(
  '[TaskSuccess] Armado del payload',
  props<{
    /**
     *
     */
    object: ResponseStart;
  }>()
);


