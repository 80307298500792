import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseAWS } from '../models/response-aws.model';
import {
  ResponseSegment,
  Segment,
  SegmentDetail,
} from '../models/segment.model';
import { SegmentationFormState } from 'libs/app/financial-plans/src/lib/store/reducers';
import { SegmentationMapper } from '../mappers/segmentation.mapper';

/**
 * Servicio de planes financieros.
 */
@Injectable()
export class SegmentationService {
  /**
   *
   */
  public mapper = new SegmentationMapper();
  /**
   * Crea una instancia de WorkflowService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}
  /**
   * Obtiene la lista de planes financieros.
   * @param typePriceCode - Codigo de tipo de precio.
   * @param description - Descripción.
   * @param page - Pagina.
   * @param pageSize - Tamaño de la pagina.
   * @returns - Observable.
   */
  public getSegmentationList(
    typePriceCode: string,
    description?: string,
    page?: number,
    pageSize?: number | string
  ): Observable<Segment[]> {
    let params = {};
    if (description !== undefined) {
      params = { ...params, description };
    }
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize: pageSize };
    }
    this.enviroment.endpoints.url;
    return this.http
      .get<ResponseAWS<Segment>>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/${typePriceCode}/segmentation`,
        {
          params,
        }
      )
      .pipe(
        map(response => {
          return response.items;
        })
      );
  }
  /**
   * Obtiene la lista de planes financieros.
   * @param page - Pagina.
   * @param pageSize - Tamaño de la pagina.
   * @param segment
   * @returns - Observable.
   */
  public createSegmentation(
    segment: SegmentationFormState
  ): Observable<SegmentDetail> {
    return this.http
      .post<ResponseSegment>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/segmentation`,
        this.mapper.mapTo(segment)
      )
      .pipe(map(response => response.body));
  }
  /**
   * Obtiene la lista de planes financieros.
   * @param page - Pagina.
   * @param pageSize - Tamaño de la pagina.
   * @param code
   * @returns - Observable.
   */
  public getSegmentation(code: string): Observable<Segment> {
    return this.http.get<Segment>(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/segmentation/${code}`
    );
  }
}
