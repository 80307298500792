import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, Observable } from 'rxjs';
import { Enviroment } from '../interfaces/enviroment.interface';
import { Catalogue, CatalogueSBS } from '../..';
import { CataloguePAS } from '../interfaces/catalogue-pas.interface';

/**
 * Servicio de tareas catalogos.
 */
@Injectable()
export class CatalogueTasksService {
  /**
   * Crea una instancia de CatalogueTasksService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}

  /**
   * Obtener catalogos por tareas.
   * @param entity - Entidad.
   * @param entityId - Identificador entidad.
   * @param childEntity - Entidad hija.
   * @returns Observable.
   */
  public getCatalogueTasks(
    entity: string,
    entityId?: string,
    childEntity?: string
  ): Observable<CatalogueSBS[]> {
    const url =
      entityId !== undefined
        ? `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/catalogue/${entity}/${entityId}/${childEntity}`
        : `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/catalogue/${entity}`;

    return this.http.get<CatalogueSBS[]>(url);
  }

  /**
   * Obtener catalogos de Products and Services.
   * @param entity - Entidad.
   * @returns Observable.
   */
  public getCataloguePAS(entity: string): Observable<Catalogue[]> {
    const url = `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/${entity}`;
    return this.http.get<CataloguePAS>(url).pipe(
      map(response =>
        response.items.map(item => ({
          code: String(item.code),
          description: item.description,
        }))
      )
    );
  }
}
