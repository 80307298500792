import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe extraer le nombre.
 */
@Pipe({
  name: 'extractRunName',
})
export class ExtractRunNamePipe implements PipeTransform {
  /**
   * Metodo transform para un valor del pipeline.
   * @param val - Valor.
   * @returns Nombre.
   */
  public transform(val: any): any {
    if (!val) return val;

    const partsExecution = (val || '').split(':'),
      name = partsExecution[partsExecution.length - 1];

    return name;
  }
}
