import { applicants } from './../../../../../../process/s-credit-decision-pj/src/lib/models/sarlaft-analisys/contents/task.models';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { DynamicSnackBarService } from '../../../dynamic-snackbar';
import { HeaderVar } from '../../models/dynamic-header-card.model';
import {
  Catalogue,
  CatalogueState,
  KeyValueCities,
  KeyValueDepartments,
  createSelectorInject,
} from '@wp-back-office/core/store';
import { Store } from '@ngrx/store';
import { combineLatest, filter, take } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { convertUnixDate } from '@wp-back-office/app/global-information';

/**
 * Caberceras dinamicas.
 */
@Component({
  selector: 'wp-back-office-dynamic-header-card',
  templateUrl: './dynamic-header-card.component.html',
  styleUrls: ['./dynamic-header-card.component.scss'],
})
export class DynamicHeaderCardComponent implements AfterViewInit {
  /**
   * Variables del header.
   */
  @Input()
  public headerVars: HeaderVar[];

  /**
   * Titulo del header.
   */
  @Input()
  public title: string;

  /**
   * Titulo del header.
   */
  @Input()
  public subtitle: string;

  /**
   * Informacion de la tarea.
   */
  @Input()
  public set taskPendingInfo(value: any | undefined) {
    if (value) {
      this.getHeaderVars(value);
    }
  }

  /**
   * Crea una instancia de la clase.
   * @param dynamicSnackBarService - Servicio de snackbars.
   * @param storeCatalogue - Redux store.
   * @param cdRef - Detector de cambios.
   * @param activatedRoute - Ruta activa.
   */
  constructor(
    public dynamicSnackBarService: DynamicSnackBarService,
    private storeCatalogue: Store<CatalogueState>,
    private cdRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute
  ) {
    this.title = '';
    this.subtitle = '';
    this.headerVars = [];
  }

  /**
   * Se ejecuta al renderizar el componente.
   */
  public ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  /**
   * Avisa al usuario que el texto se copio.
   * @param text - Texto a copiar.
   */
  public textCopied(text: string | undefined): void {
    this.dynamicSnackBarService.Open(`Copiado: ${text || ''}`, 'success');
  }

  /**
   * Obtiene las variables del header.
   * @param cardInfo - Informacion de la tarea.
   */
  public getHeaderVars(cardInfo: any): void {
    if (!cardInfo?.applicants) {
      return;
    }
    const dateStart = cardInfo?.dateCreated || cardInfo?.startDate;
    const startDate = convertUnixDate(dateStart);
    const sellerInfo = cardInfo?.applicants[0].sellerInfo?.roomCity
      ? cardInfo?.applicants[0].sellerInfo
      : {
          ...cardInfo?.applicants[0].sellerInfo,
          ...cardInfo?.applicants[0].sellerInfo?.seller.rooms[0],
        };
    const userCreator = {
      ...(cardInfo?.taskInformation?.userCreator
        ? cardInfo?.taskInformation?.userCreator
        : cardInfo?.userCreator),
      roomCountry: sellerInfo?.roomCountry,
      roomState: sellerInfo?.roomState,
      roomCity: sellerInfo?.roomCity,
      roomZoneDescription: sellerInfo?.roomZoneDescription,
    };
    this.storeCatalogue.dispatch(
      KeyValueDepartments({
        path: 'COUNTRIES',
        code: userCreator?.roomCountry,
        path2: 'STATES',
      })
    );
    this.storeCatalogue.dispatch(
      KeyValueCities({
        path: 'STATES',
        code: userCreator?.roomState,
        path1: 'CITIES',
      })
    );
    combineLatest([
      this.storeCatalogue
        .select(createSelectorInject('KeyValueDepartments', 'Catalogue'))
        .pipe(
          filter((data: any) => {
            if (data.loaded && !data.loading) {
              return true;
            }
            return false;
          }),
          take(1)
        ),
      this.storeCatalogue
        .select(createSelectorInject('KeyValueCities', 'Catalogue'))
        .pipe(
          filter((data: any) => {
            if (data.loaded && !data.loading) {
              return true;
            }
            return false;
          }),
          take(1)
        ),
    ]).subscribe(([state, city]) => {
      const stateDescription = state.catalogue.find(
        (item: Catalogue) => item.code === userCreator?.roomState
      ).description;
      const cityDescription = city.catalogue.find(
        (item: Catalogue) => item.code === userCreator?.roomCity
      ).description;
      const applicantsWithoutOtp =
        cardInfo?.taskInformation?.applicationData?.applicationFiling?.applicants?.filter(
          (applicant: any) =>
            applicant.verificationMethod !== undefined &&
            applicant.verificationMethod !== 'otp'
        );
      const applicantsOldOtp =
        cardInfo?.taskInformation?.applicationData?.applicationFiling?.applicants?.filter(
          (applicant: any) => applicant.verificationMethod === undefined
        );
      this.headerVars = [
        {
          code: cardInfo?.externalId,
          description: 'ID',
          copy: true,
        },
        {
          code: userCreator?.email,
          description: 'COMERCIAL',
          copy: true,
        },
        {
          code: cardInfo?.process || cardInfo?.nameProcess,
          description: 'PROCESO',
          copy: true,
        },
        {
          code: userCreator?.marketDescription,
          description: 'MERCADO',
          copy: true,
        },
        {
          code: userCreator?.officeDescription,
          description: 'CONCESIONARIO',
          copy: true,
        },
        {
          code: userCreator?.roomDescription,
          description: 'SALA',
          copy: true,
        },
        {
          code: userCreator?.roomZoneDescription,
          description: 'ZONA',
          copy: true,
        },
        {
          code: stateDescription,
          description: 'DEPARTAMENTO',
          copy: true,
        },
        {
          code: cityDescription,
          description: 'CIUDAD',
          copy: true,
        },
        {
          code: cardInfo?.taskInformation?.saleChannel || cardInfo?.saleChannel,
          description: 'CANAL',
          copy: true,
        },
        {
          code: startDate,
          description: 'FECHA INICIO',
          copy: true,
        },
      ];
      if (
        (applicantsWithoutOtp && applicantsWithoutOtp.length === 0) ||
        (applicantsOldOtp && applicantsOldOtp.length > 0)
      ) {
        this.headerVars = [
          ...this.headerVars,
          {
            code: 'CON OTP',
            description: 'AUTORIZACIÓN',
            copy: false,
          },
        ];
      } else {
        this.headerVars = [
          ...this.headerVars,
          {
            code: 'SIN OTP',
            description: 'AUTORIZACIÓN',
            copy: false,
          },
        ];
      }
      if (
        this.activatedRoute.snapshot.url.find(
          item => item.path === 'summary'
        ) === undefined
      ) {
        this.headerVars.splice(3, 0, {
          code: cardInfo?.taskName,
          description: 'TAREA',
          copy: true,
        });
      }
    });
  }
}
