import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicEditorComponent } from './components/dynamic-editor/dynamic-editor.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/**
 *{AGREGUE DOCUMENTACION}.
 */
@NgModule({
  declarations: [DynamicEditorComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatTooltipModule,
    AngularEditorModule
  ],
  exports: [DynamicEditorComponent],
})
export class DynamicEditorModule { }
