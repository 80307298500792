import { Injectable } from '@angular/core';
import { Catalogue } from '@wp-back-office/core/commons-backoffice';
import { ControlConfig } from '../models/form-config.model';

/**
 * Servicio de Address location.
 */
@Injectable({
  providedIn: 'root',
})
export class TemplateAddressLocationService {
  /**
   * Obtener la configuracion de los controles.
   * @returns ControlConfig[].
   */
  public getAddress(): ControlConfig[] {
    // control: ControlConfig

    const smallCols = {
      xxl: 1,
      xl: 3,
      lg: 3,
      md: 3,
      sm: 12,
      xs: 12,
    };

    const bigCols = {
      xxl: 12,
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12,
    };

    const controls: ControlConfig[] = [
      {
        type: 'Radio',
        key: 'type',
        label: '',
        validators: {
          required: true,
        },
        options: [
          {
            code: 'urbana',
            description: 'Urbana',
          },
          {
            code: 'rural',
            description: 'Rural',
          },
        ],
        col: {
          xxl: 12,
          xl: 12,
          lg: 12,
          md: 12,
          sm: 12,
          xs: 12,
        },
      },
      {
        keyOptions: {
          entity: 'ROUTE_TYPE',
        },
        type: 'DropDown',
        key: 'via',
        label: 'Vía',
        validators: {
          required: true,
        },
        col: {
          xxl: 3,
          xl: 12,
          lg: 12,
          md: 12,
          sm: 12,
          xs: 12,
        },
      },
      {
        type: 'Number',
        key: 'num',
        label: 'No.',
        placeholder: '11',
        validators: {
          required: true,
          maxLength: 5,
        },
        col: {
          ...smallCols,
        },
      },
      {
        type: 'Text',
        key: 'ref',
        label: 'Ref',
        placeholder: 'A',
        validators: {
          // required: true,
          textTransform: 'uppercase',
          maxLength: 8,
        },
        col: {
          ...smallCols,
        },
      },
      {
        keyOptions: {
          entity: 'DIRECTION_QUADRANT',
        },
        type: 'DropDown',
        key: 'sector',
        label: 'Sec',
        validators: {
          // required: true,
          dropDownUndefinedOption: true,
        },
        col: {
          ...smallCols,
        },
      },
      {
        label: '#',
        col: {
          ...smallCols,
        },
      },
      {
        type: 'Number',
        key: 'crossing',
        label: 'Cruce',
        placeholder: '23',
        validators: {
          required: true,
          maxLength: 5,
        },
        col: {
          ...smallCols,
        },
      },
      {
        type: 'Text',
        key: 'ref1',
        label: 'Ref',
        placeholder: 'A',
        validators: {
          // required: true,
          textTransform: 'uppercase',
          maxLength: 8,
        },
        col: {
          ...smallCols,
        },
      },
      {
        keyOptions: {
          entity: 'DIRECTION_QUADRANT',
        },
        type: 'DropDown',
        key: 'sector1',
        label: 'Sec',
        validators: {
          // required: true,
          dropDownUndefinedOption: true,
        },
        col: {
          ...smallCols,
        },
      },
      {
        label: '-',
        col: {
          ...smallCols,
        },
      },
      {
        type: 'Text',
        key: 'dist',
        label: 'Disc',
        placeholder: 'BIS',
        validators: {
          required: true,
          textTransform: 'uppercase',
          maxLength: 8,
        },
        col: {
          ...smallCols,
        },
      },
      {
        type: 'Text',
        key: 'complement',
        label: 'Complemento',
        placeholder: '23',
        validators: {
          // required: true,
          textTransform: 'uppercase',
          maxLength: 27,
        },
        col: {
          ...bigCols,
        },
      },
      {
        type: 'TextArea',
        key: 'nomenclature',
        label: 'Nomenclatura',
        placeholder: 'Calle 11 A Sur # 23 A BIS - 50.',
        validators: {
          required: true,
          readonly: true,
          maxLength: 100,
        },
        col: {
          ...bigCols,
        },
      },
    ];

    return controls;
  }
}
