import { createAction, props } from '@ngrx/store';
import { PendingTaskPost } from '../../../../models/post-data.models';
import { BasicInformation, DetailInformation, Product } from '../../../models';

export const StepOne = createAction(
  '[StepOne] Guardar informacion basica del cliente',
  props<{
    /**
     * Nombre del proceso.
     */
    basicInformation: BasicInformation;
  }>()
);

export const StepTwo = createAction(
  '[StepTwo] Guardar informacion del producto',
  props<{
    /**
     *
     */
    product: Product;
  }>()
);

export const StepThree = createAction(
  '[StepThree] Guardar informacion adicional del cliente',
  props<{
    /**
     *
     */
    detailInformation: DetailInformation;
  }>()
);

export const ParamsProcess = createAction(
  '[ParamsProcess] Guardar la informacion del proceso.',
  props<{
    /**
     * Parametros para envio del servicio.
     */
    params: PendingTaskPost;
  }>()
);

export const DocumentList = createAction(
  '[DocumentList] Guardar lista de documentos',
  props<{
    /**
     * Lista de documentos.
     */
    documentList?: string[];
  }>()
);
