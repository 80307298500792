import { Action, createReducer, on } from '@ngrx/store';
import { resetInitialState } from '../../../../root/store/actions';
import { BasicInformation, DetailInformation, Product } from '../../../models';
import {
  DocumentList,
  ParamsProcess,
  StepOne,
  StepThree,
  StepTwo,
} from '../../actions';

/**
 * Estado de la radicacion.
 */
export interface PayloadState {
  /**
   * Informacion basica.
   */
  basicInformation?: BasicInformation;
  /**
   * Producto.
   */
  product?: Product;
  /**
   * Detalle informacion basica.
   */
  detailInformation?: DetailInformation;
  /**
   * Lista de documentos.
   */
  documentList?: string[];
  /**
   * DocumentTypes.
   */
  initData?: State;
  /**
   * Data.
   */
  params?: any;
}
/**
 * Estado inicial.
 */
export interface State {
  /**
   * Estado de la radicacion.
   */
  data?: any;
  /**
   * Bandera de cargado.
   */
  loaded?: boolean;
  /**
   * Bandera de cargando.
   */
  loading?: boolean;
  /**
   * Errores.
   */
  error?: any;
}

export const payloadInitialState: PayloadState = {
  basicInformation: undefined,
  product: undefined,
  detailInformation: undefined,
  documentList: undefined,
  initData: undefined,
  params: undefined,
};

const featureReducer = createReducer(
  payloadInitialState,
  on(resetInitialState, () => ({ ...payloadInitialState })),
  on(StepOne, (state, { basicInformation }) => ({
    ...state,
    basicInformation: basicInformation,
  })),
  on(StepTwo, (state, { product }) => ({
    ...state,
    product: product,
  })),
  on(StepThree, (state, { detailInformation }) => ({
    ...state,
    detailInformation: detailInformation,
  })),
  on(DocumentList, (state, { documentList }) => ({
    ...state,
    documentList: documentList,
  })),
  on(ParamsProcess, (state, { params }) => ({
    ...state,
    params: params,
  }))
);

/**
 * Reductor iniciar proceso.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducerDocuments.
 */
export function PayloadReducer(
  state: PayloadState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
