import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DynamicHeaderTableComponent } from './components/dynamic-header-table/dynamic-header-table.component';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';

/**
 * Modulo de headers para tablas.
 */
@NgModule({
  declarations: [DynamicHeaderTableComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    FlexLayoutModule,
    MatTooltipModule,
  ],
  exports: [DynamicHeaderTableComponent],
})
export class DynamicHeaderTableModule { }
