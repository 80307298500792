import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';

import { Observable } from 'rxjs';
import { Preapproved } from '../models/preapproved.model';

/**
 * Servicio para lista de preaprobados.
 */
@Injectable()
export class PreApprovedListService {
  /**
   * Crea una instancia de WorkflowService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}
  
  /**
   * Obtiene la lista interna.
   * @param params - Tamaño de la pagina.
   * @returns Observable.
   */
  public getPreapprovedList(params: any): Observable<any> {
    let temp = {};
    if (params.pageSize && !params.typeFilter && !params.lastPageToken) {
      temp = {
        pageSize: params.pageSize,
      };
    } else if (params.lastPageToken && !params.typeFilter) {
      temp = {
        pageSize: params.pageSize,
        lastKey: params.lastPageToken,
      };
    } else if (params.typeFilter && !params.lastPageToken) {
      temp = {
        pageSize: params.pageSize,
        typeFilter: params.typeFilter,
        filter: params.filter,
      };
    } else if (params.typeFilter && params.lastPageToken) {
      temp = {
        pageSize: params.pageSize,
        lastKey: params.lastPageToken,
        typeFilter: params.typeFilter,
        filter: params.filter,
      };
    }

    return this.http.get(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/internal-list/pre-approve`,
      {
        params: temp,
      }
    );
  }

  /**
   * Elimina un elemento de la lista interna.
   * @param code - Código del elemento.
   * @returns Observable.
   */
  public deletePreApprovedList(code: string): Observable<any> {
    return this.http.delete<any>(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/internal-list/pre-approve/${code}`
    );
  }

  /**
   * Creacion de elemento de la lista interna.
   * @param element - Datos del elemento de la lista interna a crear.
   * @returns Observable.
   */
  public createPreApprovedList(element: Preapproved): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/internal-list/pre-approve`,
      { ...element, expirationDate: element.expirationDate.getTime() / 1000 }
    );
  }

  /**
   * Creacion de elemento de la lista interna.
   * @param element - Datos del elemento de la lista interna a crear.
   * @param code - Codigo.
   * @returns Observable.
   */
  public updatePreApprovedList(
    element: Preapproved,
    code: string
  ): Observable<any> {
    const { documentNumber, expirationDate } = element;
    return this.http.put(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/internal-list/pre-approve/${code}`,
      {
        ...element,
        documentNumber:
          typeof element.documentNumber === 'string'
            ? documentNumber
            : documentNumber.toString(),
        expirationDate: expirationDate.getTime() / 1000,
      }
    );
  }

  /**
   * Creacion de elemento de la lista de pre aperobados.
   * @param element - Datos del elemento de la lista pre aprobados a crear.
   * @returns Observable.
   */
  public uploadFilePreApprovedList(element: any): Observable<any> {
    const formData = new FormData();
    formData.append('preApprovedList', element[0]);

    return this.http.post(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/internal-list/pre-approve/upload-list`,
      formData
    );
  }
}
