import { createAction, props } from '@ngrx/store';
import { InputProcess } from '@wp-back-office/core/workflow-sdk';
import { ResponseStart } from '../../../models';

export const Process = createAction(
  '[Process] Inicializar proceso',
  props<{
    /**
     * Nombre del proceso.
     */
    name: string;
  }>()
);

export const StartProcess = createAction(
  '[StartProcess] Iniciar el proceso',
  props<{
    /**
     *
     */
    object: InputProcess;
    /**
     *
     */
    process: string;
  }>()
);

export const StartProcessSuccess = createAction(
  '[StartProcess] Iniciar el proceso exitoso',
  props<{
    /**
     *
     */
    object: ResponseStart;
  }>()
);

export const StartProcessError = createAction(
  '[StartProcess] Iniciar el proceso error',
  props<{
    /**
     *
     */
    payload: any;
  }>()
);

export const ChangesProcessById = createAction(
  '[ChangeProcess] Buscar la informacion del proceso por id',
  props<{
    /**
     * Nombre del proceso a buscar.
     */
    nameProcess: string;
  }>()
);

export const ChangesProcessByIdSuccess = createAction(
  '[ChangeProcess] Buscar la informacion del proceso por id exitoso',
  props<{
    /**
     * Respuesta.
     */
    object: any;
  }>()
);

export const ChangesProcessByIdError = createAction(
  '[ChangeProcess] Buscar la informacion del proceso por id error',
  props<{
    /**
     * Informacion json.
     */
    error: any;
  }>()
);
