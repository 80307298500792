import { createAction, props } from '@ngrx/store';
import { CatalogueSBS } from '@wp-back-office/core/commons-backoffice';
import { Catalogue } from '../../../models/contents/catalogue.models';
import { CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueSBSCities = createAction(
  '[KeyValueSBSCities] Cargar ciudades por id',
  props<{
    /**
     * Identificador.
     */
    path: CatalogueType;
  }>()
);

export const KeyValueSBSCitiesSuccess = createAction(
  '[KeyValueSBSCities] Crear ciudades Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: CatalogueSBS[];
  }>()
);

export const KeyValueSBSCitiesError = createAction(
  '[KeyValueSBSCities] Cargar ciudades Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
