import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import { ControlConfig } from '@wp-back-office/shared/dynamic-components';

import { DynamicFormService } from '../../../../services/dynamic-form.service';

/**
 * Controles para checkbox.
 */
@Component({
  selector: 'wp-back-office-form-control-check-box',
  templateUrl: './form-control-check-box.component.html',
  styleUrls: ['./form-control-check-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlCheckBoxComponent),
      multi: true,
    },
  ],
})
export class FormControlCheckBoxComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  /**
   * Configuracion del control.
   */
  @Input()
  public set control(value: ControlConfig | undefined) {
    if (value) {
      this.controlConfig = value;
      this._formControl = this.dynamicFormService.setValidators(value);
    }
  }
  /**
   * Form control del campo.
   */
  public _formControl: FormControl;

  /**
   * Configuracion del control variable.
   */
  public controlConfig!: ControlConfig;

  /**
   * Destructor sujeto.
   */
  private destroy$: Subject<boolean>;

  /**
   * Crea una instancia de la clase.
   * @param dynamicFormService - Servicio de formularios dinamicos.
   * @param cdRef - Detector de cambios.
   */
  constructor(
    public dynamicFormService: DynamicFormService,
    public cdRef: ChangeDetectorRef
  ) {
    this._formControl = new FormControl('');
    this.destroy$ = new Subject();
  }

  /**
   * Se ejecutar al iniciar el componente.
   */
  public ngOnInit() {
    let value: any = this.controlConfig?.value ? true : false;
    if (this.controlConfig?.checkBoxOptions?.onlyAcceptTrue) {
      value = this.controlConfig?.value ? true : null;
    }
    this._formControl.patchValue(value);
    this.onChange(value);
    if (this.controlConfig) {
      this._formControl = this.dynamicFormService.setValidators(
        this.controlConfig
      );
      this._formControl.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          this.valideDataValue(data);
        });
    }
  }

  /**
   * Valida el valor del checkbox.
   * @param data - Data.
   */
  public valideDataValue(data: any) {
    if (this.controlConfig?.checkBoxOptions?.onlyAcceptTrue) {
      this.onChange(data ? true : null);
      this.onTouchInput(data);
    } else {
      this.onChange(data ? true : false);
      this.onTouchInput(data);
    }
    this.cdRef.detectChanges();
  }

  /**
   * Se ejecuta el tocar el input.
   * @param data - Data.
   */
  public onTouchInput(data: any) {
    if (this._formControl.touched) {
      this.onTouch(!data ? true : false);
    }
  }

  /**
   * Se ejecuta al destruir el componente.
   */
  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * Verirfica si es cambiado.
   * @param obj - Callback.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  public onChange = (obj: any) => {};

  /**
   * Verirfica si es tocado.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  public onTouch: any = () => {};

  /**
   * Verirfica si es escrito.
   * @param obj - Callback.
   * @throws Error.
   */
  public writeValue(obj: any): void {
    this._formControl.patchValue(obj);
  }

  /**
   * Verirfica si es cambiado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Verirfica si es tocado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /**
   * Deshabilitar control.
   * @param isDisabled - Bandera.
   */
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this._formControl.disable();
    } else {
      this._formControl.enable();
    }
  }
}
