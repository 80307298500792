import { Action, createReducer, on } from '@ngrx/store';
import { AuditList } from 'libs/app/dashboard/src/lib/models/audit-list.model';
import { ErrorHttp } from '../../../../models/errors.models';
import {
  auditProcessesReset,
  auditProcesses,
  auditProcessesSuccess,
  auditProcessesError,
} from '../../actions';

/**
 *
 */
export interface AuditProcessesState {
  /**
   *
   */
  object?: AuditList;
  /**
   *
   */
  loaded: boolean;
  /**
   *
   */
  loading: boolean;
  /**
   *
   */
  isScrolling: boolean;
  /**
   *
   */
  error?: ErrorHttp;
}

export const getAuditProcesses: AuditProcessesState = {
  object: undefined,
  loaded: false,
  loading: false,
  isScrolling: false,
  error: undefined,
};

const featureReducer = createReducer(
  getAuditProcesses,
  on(auditProcessesReset, () => ({ ...getAuditProcesses })),
  on(auditProcesses, (state, { isScrolling }) => ({
    ...state,
    object: undefined,
    loading: true,
    loaded: false,
    isScrolling,
    error: undefined,
  })),
  on(auditProcessesSuccess, (state, { object }) => ({
    ...state,
    object,
    loading: false,
    loaded: true,
    error: undefined,
  })),
  on(auditProcessesError, (state, { error }) => ({
    ...state,
    loaded: true,
    loading: false,
    isScrolling: false,
    error,
  }))
);

/**
 * Reductor iniciar proceso.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function AuditProcessesReducer(
  state: AuditProcessesState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
