import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Catalogue } from '@wp-back-office/core/store';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';

export const modelsByTypePriceActions = createActionGroup({
  source: 'Models by Type Price',
  events: {
    Reset: emptyProps(),
    'Get List': props<{
      /**
       * Id del tipo de precio.
       */
      typePriceId: number;
      /**
       * Id del tipo de precio.
       */
      vehicles?: Catalogue[];
      /**
       * Pagina.
       */
      page?: number;
      /**
       * Codigo fasecolda.
       */
      code_fasecolda?: number | string;
      /**
       * Tamaño de la pagina.
       */
      pageSize?: number | string;
    }>(),
    Success: props<{
      /**
       * Lista de codigos.
       */
      object: Catalogue[];
    }>(),
    Error: props<{
      /**
       * Respuesta error Http.
       */
      error: ErrorHttp;
    }>(),
  },
});
