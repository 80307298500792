import { createAction, props } from '@ngrx/store';

export const RadicationProcess = createAction(
  '[RadicationProcess] Guardar informacion adicional del cliente'
);

export const RadicationProcessSuccess = createAction(
  '[RadicationProcess] Guardar informacion adicional del cliente exitoso',
  props<{
    /**
     *
     */
    response: any;
  }>()
);

export const RadicationProcessErrors = createAction(
  '[RadicationProcess] Guardar informacion adicional del cliente error',
  props<{
    /**
     *
     */
    error: any;
  }>()
);

export const DocumentTypes = createAction(
  '[DocumentTypes] Consultar tipos de documentos'
);

export const DocumentTypesCrudReset = createAction(
  '[DocumentTypes] Consultar tipos de documentos'
);


export const DocumentTypesSuccess = createAction(
  '[DocumentTypes] Consultar tipos de documentos exitoso',
  props<{
    /**
     *
     */
    response: any;
  }>()
);

export const DocumentTypesErrors = createAction(
  '[DocumentTypes] Consultar tipos de documentos error',
  props<{
    /**
     *
     */
    error: any;
  }>()
);

export const CreateDocumentTypes = createAction(
  '[CreateDocumentTypes] Consultar tipos de documentos',
  props<{
    /**
     *
     */
    formData: FormData;
  }>()
);

export const CreateDocumentTypesSuccess = createAction(
  '[CreateDocumentTypes] Consultar tipos de documentos exitoso',
  props<{
    /**
     *
     */
    response: any;
  }>()
);

export const CreateDocumentTypesErrors = createAction(
  '[CreateDocumentTypes] Consultar tipos de documentos error',
  props<{
    /**
     *
     */
    error: any;
  }>()
);

export const DeleteDocumentTypes = createAction(
  '[DeleteDocumentTypes] Consultar tipos de documentos',
  props<{
    /**
     *
     */
    documentName: string;
    /**
     *
     */
    taskToken: string;
  }>()
);

export const DeleteDocumentTypesSuccess = createAction(
  '[DeleteDocumentTypes] Consultar tipos de documentos exitoso',
  props<{
    /**
     *
     */
    response: any;
  }>()
);

export const DeleteDocumentTypesErrors = createAction(
  '[DeleteDocumentTypes] Consultar tipos de documentos error',
  props<{
    /**
     *
     */
    error: any;
  }>()
);
