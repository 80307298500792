import { Catalogue } from '@wp-back-office/core/store';
import { PaymentFrecuencyOutput } from '../models/payment-frecuency.model';

export class PaymentFrecuencyMapper {
  /**
   * Mapea desde el formato AWS a nuestro modelo.
   * @param param - Parametros desde el backend.
   * @returns - Objeto de nuestro modelo.
   */
  public mapFrom(param: PaymentFrecuencyOutput): Catalogue {
    return {
      code: param.code_payment_frecuency.toString(),
      description: param.description,
    };
  }
}
