import {
  Component,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { DynamicDialogMessageService } from '../../../dynamic-dialog-message/services/dynamic-dialog-message.service';

import { MatTabGroup } from '@angular/material/tabs';
import { FactoryTabDirective } from '../../directives/factory-tab.directive';
import {
  MatTabGroupConfig,
  MatTabConfig,
  ButtonConfig,
  typeButton,
} from '../../models/tabs-config.model';
import { AddTabComponent } from '../dialogs/add-tab/add-tab.component';
import { KeyValue } from '@angular/common';
import {
  DynamicTableComponent,
  TableButtonAction,
} from '../../../dynamic-form';

/**
 * Componente de tabuladores dinamicos.
 */
@Component({
  selector: 'wp-back-office-dynamic-tabs',
  templateUrl: './dynamic-tabs.component.html',
  styleUrls: ['./dynamic-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicTabsComponent
  implements OnChanges, AfterViewInit, OnDestroy
{
  /**
   * Configuracion de losde agregar tabs.
   */
  @Input()
  public matTabGroupConfig?: MatTabGroupConfig;
  /**
   * Evento de valor del tab.
   */
  @Output()
  public matTabValueJSON = new EventEmitter<string>();
  /**
   * Evento de valor del tab.
   */
  @Output()
  public matTableValueJSON = new EventEmitter<string>();
  /**
   * Evento de agregar tab.
   */
  @Output()
  public tabAdded = new EventEmitter<number>();
  /**
   * Evento de cambiar el tab.
   */
  @Output()
  public selectedIndexChange = new EventEmitter<number>();
  /**
   * Evento de valor del tab.
   */
  @Output()
  public matTableStepOneValueJSON = new EventEmitter<string>();
  /**
   * Fabrica.
   */
  @ViewChild(MatTabGroup)
  public matTabGroup?: MatTabGroup;
  /**
   * Fabrica de componentes.
   */
  @ViewChildren(FactoryTabDirective)
  public contentTabs?: QueryList<FactoryTabDirective>;
  /**
   * Arreglo de tabulaciones.
   */
  public arrayTabs?: any[];
  /**
   * Destructor sujeto.
   */
  private destroy$: Subject<boolean>;
  /**
   * Bandera para deshabilitar boton de agregar.
   */
  public disabledAdd: boolean;
  /**
   * Indice seleccionado.
   */
  public selectIndex: number;

  /**
   * Crea una instancia DynamicTabsComponent.
   * @param DynamicDialogMessageService - Servicio de DynamicDialogMessageServiceo.
   * @param cdRef - Deteccion de cambios.
   */
  public constructor(
    private DynamicDialogMessageService: DynamicDialogMessageService,
    private cdRef: ChangeDetectorRef
  ) {
    this.arrayTabs = [];
    this.destroy$ = new Subject();
    this.disabledAdd = false;
    this.selectIndex = 0;
  }

  /**
   * Detecta el cambio de las entradas y salidas.
   * @param changes - Cambios.
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['matTabGroupConfig']) {
      this.matTabGroupConfig = changes['matTabGroupConfig'].currentValue;
      this.arrayTabs = this.matTabGroupConfig?.matTabs?.filter(
        tab => (tab.createdInitial || false) === true
      );

      const index =
        this.matTabGroupConfig && this.matTabGroupConfig.selectIndex
          ? this.matTabGroupConfig.selectIndex
          : 0;

      setTimeout(() => {
        if (this.matTabGroup) {
          this.matTabGroup.selectedIndex = index;
        }
        this.cdRef.detectChanges();
      }, 1000);
    }
  }

  /**
   * A callback method that is invoked immediately after Angular has completed initialization of a component's view.
   */
  public ngAfterViewInit(): void {
    this.arrayTabs =
      this.matTabGroupConfig && this.matTabGroupConfig.matTabs
        ? this.matTabGroupConfig.matTabs?.filter(
            tab => (tab.createdInitial || false) === true
          )
        : [];

    const index =
      this.matTabGroupConfig && this.matTabGroupConfig.selectIndex
        ? this.matTabGroupConfig.selectIndex
        : 0;

    if (this.matTabGroup) {
      this.matTabGroup.selectedIndex = index;
    }
  }

  /**
   * A callback method that performs custom clean-up, invoked immediately before a directive, pipe, or service instance is destroyed.
   */
  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /**
   * Editar un tab.
   * @param event - Datos del row.
   */
  public onEdit(event: TableButtonAction) {
    const id = event.row.type.code;
    const indexTable = event.index;
    const data = event.row;
    const temp = this.matTabGroupConfig?.matTabs || [];
    const final = temp.find(
      item => (item.typeID?.code.toString() || '') === id.toString()
    );
    if (final) {
      const componentRef = this.pushTab(final);
      if (componentRef) {
        componentRef.instance.inputData = {
          key: indexTable,
          value: data,
        };
        componentRef.instance.cdRef.detectChanges();
      }
    }
  }

  /**
   * Adicionar un tab.
   * @param newTab - Titulo a adicionar.
   * @returns Component o undefined.
   */
  private pushTab(newTab: MatTabConfig | undefined) {
    if (newTab) {
      this.arrayTabs = [...(this.arrayTabs || []), newTab];
      this.selectTab((this.arrayTabs?.length || 0) - 1);
      this.tabAdded.emit(this.arrayTabs.length - 1);
      this.cdRef.detectChanges();
      return this.contentTabs?.last.componentRefFactory;
    }
    this.cdRef.detectChanges();
    return undefined;
  }

  /**
   * Metodo para eliminar tabs.
   * @param index - Index del tab a eliminar.
   */
  public removeTab(index: number) {
    this.DynamicDialogMessageService.open({
      type: 'question',
      header: { text: '¿Estás seguro de cerrar?' },
      config: {
        width: '400px',
        disableClose: false,
      },
    })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: boolean) => {
        if (result == true) {
          this.removeTabNoModal(index);
        }
      });
  }

  /**
   * Metodo para eliminar tabs.
   * @param index - Index del tab a eliminar.
   */
  public removeTabNoModal(index: number) {
    this.arrayTabs?.splice(index, 1);
    this.selectTab(0);
    this.cdRef.detectChanges();
  }

  /**
   * Seleccionar un tab.
   * @param index - Indice.
   */
  public selectTab(index: number) {
    this.matTabGroup ? (this.matTabGroup.selectedIndex = index) : undefined;
    this.cdRef.detectChanges();
  }

  /**
   * Indice actaul.
   * @param index - Numero de indice actual.
   */
  public onCurrentIndex(index: number) {
    this.selectIndex = index;
    this.selectedIndexChange.emit(index);
    const tableContent = this.contentTabs?.first.componentRefFactory;
    if ((this.arrayTabs?.length || 0) <= 1) {
      if (tableContent) tableContent.instance.disabled = false;
    } else {
      if (tableContent) tableContent.instance.disabled = true;
    }
    tableContent?.changeDetectorRef.detectChanges();
  }

  /**
   * Agrega un tab.
   */
  public onTabAddButtonClick() {
    const temp = this.matTabGroupConfig?.matTabs?.filter(
      tab => (tab.canBeCreated || false) === true
    );
    const final = temp?.map(item => {
      return item.typeID;
    });

    this.DynamicDialogMessageService.openFromComponent(AddTabComponent, {
      width: '90%',
      maxWidth: '420px',
      disableClose: false,
      data: {
        title: this.matTabGroupConfig?.modalAdd?.title || 'Agregar',
        catalogueList:
          this.matTabGroupConfig?.modalAdd?.catalogueList || undefined,
        options: final,
      },
    })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {
        /**
         * Validamos si en el resultado del modal tenemos el value formGroup que se crea al enviar el campo catalogueList
         * Lo validamos con el key tabKey que contiene la llave del campo de los tipos de tabs.
         */
        if (result && result[this.matTabGroupConfig?.modalAdd?.tabKey || '']) {
          const tabCreate = temp?.find(
            tab =>
              tab.typeID?.code?.toString() ===
              result[
                this.matTabGroupConfig?.modalAdd?.tabKey || ''
              ].code.toString()
          );
          if (tabCreate) {
            tabCreate.component =
              result[
                this.matTabGroupConfig?.modalAdd?.componentKey || ''
              ].component;
          }
          this.pushTab(tabCreate);
        } else if (result) {
          const tabCreate = temp?.find(
            tab => tab.typeID?.code?.toString() === result.code.toString()
          );
          this.pushTab(tabCreate);
        }
      });
  }

  /**
   * Evento captura de datos tab.
   * @param dataJSON - Valor del tab.
   */
  public onSubmitJSON(dataJSON: string) {
    this.matTabValueJSON.emit(dataJSON);
    this.disabledAdd = false;
    const dataJSONParse = JSON.parse(dataJSON);
    const tabConfig = this.matTabGroupConfig?.matTabs?.find(
      x => x?.typeID?.code.toString() === dataJSONParse.type.code.toString()
    );
    if (tabConfig) {
      this.removeTabNoModal(this.selectIndex);
      this.validMultiple(tabConfig);
    }
  }

  /**
   * Evento captura de datos tab.
   * @param dataJSON - Valor del tab.
   */
  public onSubmitEditJSON(dataJSON: KeyValue<number, string>) {
    this.disabledAdd = false;
    const dataJSONParse = JSON.parse(dataJSON.value);
    const tabConfig = this.matTabGroupConfig?.matTabs?.find(x =>
      x.typeID
        ? x.typeID.code.toString()
        : '00' === dataJSONParse.type.code.toString()
    );
    const tableContent = this.contentTabs?.first.componentRefFactory;
    const componentTable: DynamicTableComponent =
      tableContent?.instance.dynamicTableComponent;

    componentTable.onEdit(dataJSON.key, dataJSONParse);

    if (tabConfig) {
      this.removeTabNoModal(this.selectIndex);
    }
  }

  /**
   * Evento captura de datos table.
   * @param dataJSON - Valor del table.
   */
  public onSubmitTableJSON(dataJSON: string) {
    this.matTableValueJSON.emit(dataJSON);
  }

  /**
   * Evento captura de datos table.
   * @param dataJSON - Valor del table.
   */
  public onSubmitTableStepOneJSON(dataJSON: string) {
    this.matTableStepOneValueJSON.emit(dataJSON);
  }

  /**
   * Validacion de tab multiple.
   * @param tabTemp - MatTabCongig.
   */
  public validMultiple(tabTemp: MatTabConfig | undefined) {
    if (tabTemp) {
      if (!tabTemp.isMultiple) {
        tabTemp.canBeCreated = false;
        const index = this.matTabGroupConfig?.matTabs?.indexOf(tabTemp);
        this.matTabGroupConfig?.matTabs?.splice(index || 0, 1, tabTemp);
      }
    }
  }

  /**
   * Permite filtrar el boton deseado para el tab.
   * @param entrada - Entidad de la tabulacion.
   * @param typeMatTabButton - Tipo de boton dela tabulacion a filtrar.
   * @returns ButtonConfig | undefined.
   */
  public buttonFilter(
    entrada: ButtonConfig[] | undefined,
    typeMatTabButton: typeButton
  ): ButtonConfig | undefined {
    return entrada
      ? entrada.find(button => button.typeButton === typeMatTabButton)
      : undefined;
  }
}
