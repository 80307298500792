import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SessionState } from '../reducers';

/**
 * Selector al Feature FinancialPlans.
 */
export const accessControlSelector =
  createFeatureSelector<SessionState>('auth');

export const selectSendOtp = createSelector(accessControlSelector, state => {
  if (state.passwordReset.sendOtpCode?.loaded) {
    return state.passwordReset.sendOtpCode;
  }
  return {
    object: undefined,
    loaded: false,
    loading: true,
    error: undefined,
  };
});

export const selectVerifyOtp = createSelector(accessControlSelector, state => {
  if (state.passwordReset.verifyOtpCode?.loaded) {
    return state.passwordReset.verifyOtpCode;
  }
  return {
    object: undefined,
    loaded: false,
    loading: true,
    error: undefined,
  };
});

export const selectPasswordReset = createSelector(
  accessControlSelector,
  state => {
    if (state.passwordReset.passwordReset?.loaded) {
      return state.passwordReset.passwordReset;
    }
    return {
      object: undefined,
      loaded: false,
      loading: true,
      error: undefined,
    };
  }
);
