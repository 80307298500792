<div [formGroup]="geographicLocationForm" class="row" *ngIf="controls">
  <div *ngFor="let item of controls" class="p-relative" [class.input-readonly]="item?.validators?.readonly || false"
    class="{{valueControl?.classes || ''}}" [ngClass]="
      'col-xxl-' +
      (item.col?.xxl || '12') +
      (' col-xl-' + (item.col?.xl || '12')) +
      (' col-lg-' + (item.col?.lg || '12')) +
      (' col-md-' + (item.col?.md || '12')) +
      (' col-sm-' + (item.col?.sm || '12')) +
      (' col-xs-' + (item.col?.xs || '12'))
    ">
    <wp-back-office-form-control-autocomplete [formControlName]="item?.key?.trim() || ''" [value]="
        geographicLocationForm.value[item.key?.trim() || ''] || undefined
      " [uniqueIdForm]="uniqueIdForm" [control]="item"></wp-back-office-form-control-autocomplete>
  </div>
</div>