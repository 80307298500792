<div class="contianer-fluid">
  <h2 mat-DynamicDialogMessageService-title class="text-center">
    {{ title }}
  </h2>
  <mat-divider class="mb-4 text-center"></mat-divider>
  <mat-card-subtitle class="m-0" *ngIf="dialogData?.formSubtitle">{{
    dialogData?.formSubtitle
  }}</mat-card-subtitle>
  <mat-dialog-content class="{{ dialogData?.contentClasess || 'pt-5 pb-2' }}">
    <wp-back-office-dynamic-form
      #formID
      [controls]="controls || []"
      (codeVerification)="valideOtp()"
      (controlResendCode)="resendOtp()">
    </wp-back-office-dynamic-form>
  </mat-dialog-content>
  <mat-divider class="mt-4"></mat-divider>
  <mat-dialog-actions align="end">
    <button mat-flat-button color="primary" (click)="close()" id="dialogCancel">
      {{ buttonCancelText }}
    </button>
    <button
      *ngIf="!dialogData.closeOnOtpVerification"
      id="dialogNext"
      mat-flat-button
      color="accent"
      (click)="submit()"
      [disabled]="
        formComponent?.formGroupGeneric?.invalid || disableSendButton
      ">
      {{
        dialogData.buttonAddText
          ? dialogData.buttonAddText
          : dialogData?.row
          ? 'Modificar'
          : 'Agregar'
      }}
    </button>
  </mat-dialog-actions>
</div>
