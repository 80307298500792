import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Catalogue, Enviroment } from '@wp-back-office/core/commons-backoffice';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Banks,
  Beneficiaries,
  BranchOffices,
  Markets,
  OfficeGroups,
  TableBeneficiaries,
} from '../models/branch-offices.model';
import { ResponseAWS } from '../models/response-aws.model';
import { ALL } from '@wp-back-office/core/store';
import { Office, OfficeDetail, OfficeInput, OfficeOutput } from '../models/market.model';
/**
 * Servicio de planes financieros.
 */
@Injectable()
export class OfficesService {
  /**
   * Crea una instancia de WorkflowService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}
  /**
   * Obtiene la lista de planes financieros.
   * @param code - Codigo de concesionario.
   * @param page - Pagina.
   * @param pageSize - Tamaño de la pagina.
   * @returns - Observable.
   */
  public getOfficesList(
    code: string,
    page?: number,
    pageSize?: number
  ): Observable<Catalogue[]> {
    let params = {};
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    this.enviroment.endpoints.url;
    return this.http
      .get<ResponseAWS<Office>>(
        `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/office-group/${code}/offices`,
        {
          params: params,
        }
      )
      .pipe(
        map(response =>
          response.items.map(item => ({
            code: item.internal_code.toString(),
            code_office: item.code_office.toString(),
            description: item.description,
          }))
        )
      );
  }

  /**
   * Obtiene la lista de usuarios.
   * @param page - Columna a filtrar.
   * @param pageSize - Tamaño de la pagina.
   * @param description - Valor a filtrar.
   * @param market - Mercado.
   * @returns Observable.
   */
  public getOffice(
    page?: number,
    pageSize?: number,
    description?: string,
    market?: string
  ): Observable<ResponseAWS<Office>> {
    let params = {};
    page !== undefined ? (params = { ...params, page }) : null;
    pageSize !== undefined ? (params = { ...params, pageSize }) : null;
    description !== undefined ? (params = { ...params, description }) : null;
    market !== undefined ? (params = { ...params, market }) : null;
    return this.http.get<ResponseAWS<Office>>(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/office`,
      {
        params: params,
      }
    );
  }

  /**
   * Obtiene la lista de todos los concesionarios.
   * @param code - Código del concesionario.
   * @param page - Página.
   * @param pageSize - Tamaño de la página.
   * @returns - Observable.
   */
  public getOfficeBeneficiaries(
    code?: number,
    page?: number,
    pageSize?: number
  ): Observable<TableBeneficiaries[]> {
    let params = {};
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    this.enviroment.endpoints.url;
    return this.http
      .get<ResponseAWS<Beneficiaries>>(
        `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/office/${code}/beneficiaries`,
        {
          params: params,
        }
      )
      .pipe(
        map(response =>
          response.items.map(item => {
            item['code_service'] = `0${String(item.code_service)}`;
            return {
              name: `${item.first_name} ${item.first_surname}`,
              id: `${item.identification_type} ${item.identification_number}`,
              service: String(item.code_service),
              items: item,
            };
          })
        )
      );
  }

  /**
   * Obtiene la información de una oficina por el id.
   * @param code - Código de la oficina.
   * @returns Observable.
   */
  public getOfficeId(code: string): Observable<OfficeDetail> {
    return this.http.get<OfficeDetail>(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/office/${code}`
    );
  }

  /**
   * Creacion de concesionario.
   * @param office - Datos del concesionario a crear.
   * @returns Observable.
   */
  public createOffice(office: OfficeInput): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/office`,
      office
    );
  }

  /**
   * Actualización de concesionario.
   * @param code - Código de la oficina a editar.
   * @param office - Datos del concesionario a editar.
   * @returns Observable.
   */
  public updateOffice(code: string, office: OfficeInput): Observable<OfficeOutput> {
    return this.http.put<OfficeOutput>(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/office/${code}`,
      office
    );
  }

  /**
   * Obtiene lista de bancos.
   * @param description - Filtro de banco por descripción.
   * @param page - Página.
   * @param pageSize - Tamaño de la página.
   * @returns - Observable.
   */
  public getBanks(
    description?: string,
    page?: number,
    pageSize?: number | ALL
  ): Observable<Banks[]> {
    let params = {};
    if (description !== undefined) {
      params = { ...params, description };
    }
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    this.enviroment.endpoints.url;
    return this.http
      .get<ResponseAWS<Banks>>(
        `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/bank`,
        {
          params: params,
        }
      )
      .pipe(
        map(response =>
          response.items.map(item => ({
            code_bank: item.code_bank,
            description: item.description,
            created_date: item.created_date,
            updated_date: item.updated_date,
            object: item,
          }))
        )
      );
  }

  /**
   * Obtiene lista de grupos de oficina.
   * @param description - Filtro de grupo de oficina por descripción.
   * @param page - Página.
   * @param pageSize - Tamaño de la página.
   * @returns - Observable.
   */
  public getOfficeGroups(
    description?: string,
    page?: number,
    pageSize?: number
  ): Observable<OfficeGroups[]> {
    let params = {};
    if (description !== undefined) {
      params = { ...params, description };
    }
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    this.enviroment.endpoints.url;
    return this.http
      .get<ResponseAWS<OfficeGroups>>(
        `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/office-group`,
        {
          params: params,
        }
      )
      .pipe(
        map(response =>
          response.items.map(item => ({
            code_office_group: item.code_office_group,
            description: item.description,
            object: item,
          }))
        )
      );
  }

  /**
   * Obtiene lista de mercados.
   * @param description - Filtro del mercado por descripción.
   * @param page - Página.
   * @param pageSize - Tamaño de la página.
   * @returns - Observable.
   */
  public getMarkets(
    description?: string,
    page?: number,
    pageSize?: number | ALL
  ): Observable<Catalogue[]> {
    let params = {};
    if (description !== undefined) {
      params = { ...params, description };
    }
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    this.enviroment.endpoints.url;
    return this.http
      .get<ResponseAWS<Markets>>(
        `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/market`,
        {
          params: params,
        }
      )
      .pipe(
        map(response =>
          response.items.map(item => ({
            code: String(item.code_market),
            description: item.description,
            object: item,
          }))
        )
      );
  }

  /**
   * Deshabilitar de oficina.
   * @returns Observable.
   * @param id - Id del oficina.
   */
  public DisableOffice(id: string): Observable<any> {
    return this.http.patch(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/office/${id}/disable`,
      {}
    );
  }

  /**
   * Habilitar de oficina.
   * @returns Observable.
   * @param id - Id del oficina.
   */
  public EnableOffice(id: string): Observable<any> {
    return this.http.patch(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/office/${id}/enable`,
      {}
    );
  }
}
