import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueMaritalStatus = createAction(
  '[KeyValueMaritalStatus] Cargar estado civil',
  props<{
    /**
     * Ruta.
     */
    path: CatalogueType;
  }>()
);

export const KeyValueMaritalStatusSuccess = createAction(
  '[KeyValueMaritalStatus] Crear estado civil Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueMaritalStatusError = createAction(
  '[KeyValueMaritalStatus] Cargar estado civil Error',
   props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
