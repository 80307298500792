import { Action, createReducer, on } from '@ngrx/store';
import { themeActions } from '../../actions/contents/theme.actions';
import { ThemeRoot } from '@wp-back-office/app/dashboard';

/**
 * Estado de temas.
 */
export interface ThemeState {
  /**
   * Temas de cada mercado.
   */
  themes?: Record<string, ThemeRoot>;
  /**
   * Flag cargado.
   */
  loaded: boolean;
  /**
   * Flag cargando.
   */
  loading: boolean;
  /**
   * Error.
   */
  error?: any;
  /**
   * Tema seleccionado.
   */
  themeSelected?: ThemeRoot;
}

export const initialThemeState: ThemeState = {
  themes: undefined,
  loaded: false,
  loading: false,
  error: undefined,
  themeSelected: undefined,
};

const featureReducer = createReducer(
  initialThemeState,
  on(themeActions.obtenerTema, state => ({
    ...state,

    loaded: false,
    loading: true,
    error: undefined,
  })),
  on(themeActions.obtenerTemaExitoso, (state, { themes }) => ({
    ...state,
    themes,
    loaded: true,
    loading: false,
    error: undefined,
  })),
  on(themeActions.seleccionarTemaExitoso, (state, { themeSelected }) => ({
    ...state,
    themeSelected,
  }))
);

/**
 * Reductor autenticación.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function ThemeReducer(state: ThemeState | undefined, action: Action) {
  return featureReducer(state, action);
}
