import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueProductCategory = createAction(
  '[KeyValueProductCategory] Cargar productos por categoria',
  props<{
    /**
     * Ruta.
     */
    path: CatalogueType;
  }>()
);

export const KeyValueProductCategorySuccess = createAction(
  '[KeyValueProductCategory] Crear productos por categoria Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueProductCategoryError = createAction(
  '[KeyValueProductCategory] Cargar productos por categoria Error',
   props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
