import { createActionGroup, props } from '@ngrx/store';
import { Catalogue } from '../../../models/contents/catalogue.models';
import { CatalogueType } from '../../../models/contents/catalogue.models';

/**
 * Acciones para obtener catalogos.
 */
export const catalogueQueryActions = createActionGroup({
  source: 'catalogueQueryActions',
  events: {
    /**
     * Obtener  tipo de identificación.
     */
    'Get Type Of Identification': props<{
      /**
       * Ruta.
       */
      path: CatalogueType;
    }>(),
    'Get Type Of Identification Success': props<{
      /**
       * Respuesta exitosa.
       */
      keyValue: Catalogue[];
    }>(),
    'Get Type Of Identification Error': props<{
      /**
       * Error.
       */
      payload: any;
    }>(),

    /**
     * Obtener las actividades economicas.
     */
    'Get Economic Activities': props<{
      /**
       * Ruta.
       */
      path: CatalogueType;
    }>(),
    'Get Economic Activities Success': props<{
      /**
       * Respuesta exitosa.
       */
      keyValue: Catalogue[];
    }>(),
    'Get Economic Activities Error': props<{
      /**
       * Error.
       */
      payload: any;
    }>(),

    /**
     * Obtener Respuesta SI o NO.
     */
    'Get Response': props<{
      /**
       * Ruta.
       */
      path: CatalogueType;
    }>(),
    'Get Response Success': props<{
      /**
       * Respuesta exitosa.
       */
      keyValue: Catalogue[];
    }>(),
    'Get Response Error': props<{
      /**
       * Error.
       */
      payload: any;
    }>(),

    /**
     * Obtener Tipo de contrato.
     */
    'Get Contract Type': props<{
      /**
       * Ruta.
       */
      path: CatalogueType;
    }>(),
    'Get Contract Type Success': props<{
      /**
       * Respuesta exitosa.
       */
      keyValue: Catalogue[];
    }>(),
    'Get Contract Type Error': props<{
      /**
       * Error.
       */
      payload: any;
    }>(),

    /**
     * Obtener Tipo de regimen.
     */
    'Get Regime Type': props<{
      /**
       * Ruta.
       */
      path: CatalogueType;
    }>(),
    'Get Regime Type Success': props<{
      /**
       * Respuesta exitosa.
       */
      keyValue: Catalogue[];
    }>(),
    'Get Regime Type Error': props<{
      /**
       * Error.
       */
      payload: any;
    }>(),

    /**
     * Obtener Seguridad social.
     */
    'Get Social Security': props<{
      /**
       * Ruta.
       */
      path: CatalogueType;
    }>(),
    'Get Social Security Success': props<{
      /**
       * Respuesta exitosa.
       */
      keyValue: Catalogue[];
    }>(),
    'Get Social Security Error': props<{
      /**
       * Error.
       */
      payload: any;
    }>(),

    /**
     * Obtener Tipo de usuario.
     */
    'Get User Type': props<{
      /**
       * Ruta.
       */
      path: CatalogueType;
    }>(),
    'Get User Type Success': props<{
      /**
       * Respuesta exitosa.
       */
      keyValue: Catalogue[];
    }>(),
    'Get User Type Error': props<{
      /**
       * Error.
       */
      payload: any;
    }>(),

    /**
     * Obtener Estado.
     */
    'Get Status': props<{
      /**
       * Ruta.
       */
      path: CatalogueType;
    }>(),
    'Get Status Success': props<{
      /**
       * Respuesta exitosa.
       */
      keyValue: Catalogue[];
    }>(),
    'Get Status Error': props<{
      /**
       * Error.
       */
      payload: any;
    }>(),

    /**
     * Obtener Región.
     */
    'Get Region': props<{
      /**
       * Ruta.
       */
      path: CatalogueType;
    }>(),
    'Get Region Success': props<{
      /**
       * Respuesta exitosa.
       */
      keyValue: Catalogue[];
    }>(),
    'Get Region Error': props<{
      /**
       * Error.
       */
      payload: any;
    }>(),

    /**
     * Obtener Tipo de venta.
     */
    'Get Type Sale': props<{
      /**
       * Ruta.
       */
      path: CatalogueType;
    }>(),
    'Get Type Sale Success': props<{
      /**
       * Respuesta exitosa.
       */
      keyValue: Catalogue[];
    }>(),
    'Get Type Sale Error': props<{
      /**
       * Error.
       */
      payload: any;
    }>(),

    /**
     * Obtener Tipo de sociedad.
     */
    'Get Kind Society': props<{
      /**
       * Ruta.
       */
      path: CatalogueType;
    }>(),
    'Get Kind Society Success': props<{
      /**
       * Respuesta exitosa.
       */
      keyValue: Catalogue[];
    }>(),
    'Get Kind Society Error': props<{
      /**
       * Error.
       */
      payload: any;
    }>(),

    /**
     * Obtener Tipo de contribuidor.
     */
    'Get Type Contributor': props<{
      /**
       * Ruta.
       */
      path: CatalogueType;
    }>(),
    'Get Type Contributor Success': props<{
      /**
       * Respuesta exitosa.
       */
      keyValue: Catalogue[];
    }>(),
    'Get Type Contributor Error': props<{
      /**
       * Error.
       */
      payload: any;
    }>(),

    /**
     * Obtener Forma de pago.
     */
    'Get Form Payment': props<{
      /**
       * Ruta.
       */
      path: CatalogueType;
    }>(),
    'Get Form Payment Success': props<{
      /**
       * Respuesta exitosa.
       */
      keyValue: Catalogue[];
    }>(),
    'Get Form Payment Error': props<{
      /**
       * Error.
       */
      payload: any;
    }>(),

    /**
     * Obtener EPS.
     */
    'Get Eps': props<{
      /**
       * Ruta.
       */
      path: CatalogueType;
    }>(),
    'Get Eps Success': props<{
      /**
       * Respuesta exitosa.
       */
      keyValue: Catalogue[];
    }>(),
    'Get Eps Error': props<{
      /**
       * Error.
       */
      payload: any;
    }>(),

    /**
     * Obtener Fondos de pensión.
     */
    'Get Pension Funds': props<{
      /**
       * Ruta.
       */
      path: CatalogueType;
    }>(),
    'Get Pension Funds Success': props<{
      /**
       * Respuesta exitosa.
       */
      keyValue: Catalogue[];
    }>(),
    'Get Pension Funds Error': props<{
      /**
       * Error.
       */
      payload: any;
    }>(),
  },
});
