import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueTypePerson = createAction(
  '[KeyValueTypePerson] Cargar tipo de personas por id',
  props<{
    /**
     * Identificador.
     */
    id: CatalogueType;
  }>()
);

export const KeyValueTypePersonSuccess = createAction(
  '[KeyValueTypePerson] Crear tipo de personas Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueTypePersonError = createAction(
  '[KeyValueTypePerson] Cargar tipo de personas Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
