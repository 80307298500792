import { KeyValue } from '@angular/common';
import {
  AfterViewInit,
  ComponentRef,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { Catalogue } from '@wp-back-office/core/commons-backoffice';
import { ComponentType } from '@angular/cdk/portal';
import { Subject, takeUntil } from 'rxjs';
import { TableButtonAction } from '../../dynamic-form';

/**
 * Directiva que controla la creacion de componentes.
 */
@Directive({
  selector: '[wpBackOfficeFactoryTab]',
})
export class FactoryTabDirective implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Entrada de componente.
   */
  @Input()
  public component?: ComponentType<any>;
  /**
   * Identificador componente.
   */
  @Input()
  public typeID?: Catalogue;
  /**
   * Data del tab.
   */
  @Input()
  public tabData?: any;
  /**
   * Valor json del componente.
   */
  @Output()
  public submitValue = new EventEmitter<string>();
  /**
   * Valor json del componente.
   */
  @Output()
  public submitEditValue = new EventEmitter<KeyValue<number, string>>();
  /**
   * Valor json del componente.
   */
  @Output()
  public submitTableValue = new EventEmitter<string>();
  /**
   * Valor json del componente.
   */
  @Output()
  public editTable = new EventEmitter<TableButtonAction>();
  /**
   * Valor json del componente.
   */
  @Output()
  public submitStepOneValue = new EventEmitter<string>();
  /**
   * Componente creado.
   */
  public componentRefFactory!: ComponentRef<any>;
  /**
   * Destructor sujeto.
   */
  private destroy$ = new Subject();

  /**
   * Crea una instanacia de FactoryHostDirectiveTwo.
   * @param viewContainer - Vista contenedor.
   */
  constructor(private viewContainer: ViewContainerRef) {}

  /**
   *A callback method that is invoked immediately after the default change detector has checked the directive's data-bound properties for the first time.
   */
  public ngOnInit() {
    this.viewContainer.clear();
  }

  /**
   * A callback method that is invoked immediately after Angular has completed initialization of a component's view.
   */
  public ngAfterViewInit(): void {
    if (typeof this.component == 'function') {
      const componentRefTemp = this.createComponent(this.component);

      if (this.typeID) {
        componentRefTemp;
        componentRefTemp.instance.typeID = this.typeID;
        componentRefTemp.instance?.valueJSON
          ?.pipe(takeUntil(this.destroy$))
          .subscribe((resp: string) => {
            this.submitValue.emit(resp);
          });
        componentRefTemp.instance?.valueEditJSON
          ?.pipe(takeUntil(this.destroy$))
          .subscribe((resp: KeyValue<number, string>) => {
            this.submitEditValue.emit(resp);
          });
        componentRefTemp.instance?.valueStepOneJSON
          ?.pipe(takeUntil(this.destroy$))
          .subscribe((resp: string) => {
            this.submitStepOneValue.emit(resp);
          });
        // componentRefTemp.instance?.cdRef?.detectChanges();
      } else {
        componentRefTemp.instance?.editTable
          ?.pipe(takeUntil(this.destroy$))
          .subscribe((resp: TableButtonAction) => {
            this.editTable.emit(resp);
          });
        componentRefTemp.instance?.dataTableSubmit
          ?.pipe(takeUntil(this.destroy$))
          .subscribe((resp: string) => {
            this.submitTableValue.emit(resp);
          });
      }
    }
  }

  /**
   * A callback method that performs custom clean-up, invoked immediately before a directive, pipe, or service instance is destroyed.
   */
  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /**
   * Crear un componenten en el contenido.
   * @param componente - Componente de entrada.
   * @returns ComponentRef.
   */
  public createComponent(componente: Type<any>) {
    const componentRef = this.viewContainer.createComponent(componente);
    this.componentRefFactory = componentRef;
    return componentRef;
  }
}
