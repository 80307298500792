import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FinancialPlanMapper } from '../mappers/financial-plan.mapper';
import {
  FinancialPlan,
  FinancialPlanDetail,
  FinancialPlanItem,
  FinancialPlanOutput,
} from '../models/financial-plan.model';
import { ResponseAWS } from '../models/response-aws.model';
import { SuccessResponse } from '../models/service.model';
/**
 * Servicio de planes financieros.
 */
@Injectable()
export class FinancialPlansService {
  /**
   * Mapeador.
   */
  private mapper = new FinancialPlanMapper();
  /**
   * Crea una instancia de WorkflowService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}
  /**
   * Obtiene la lista de planes financieros.
   * @param page - Pagina.
   * @param pageSize - Tamaño de la pagina.
   * @param code_financial_plan - Codigo de plan financiero.
   * @param name - Nombre.
   * @param description - Descripción.
   * @returns - Observable.
   */
  public getFinancialPlansList(
    page?: number,
    pageSize?: number,
    code_financial_plan?: number,
    name?: string,
    description?: string
  ): Observable<FinancialPlanItem[]> {
    let params = {};
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    if (code_financial_plan !== undefined) {
      params = { ...params, code_financial_plan };
    }
    if (name !== undefined) {
      params = { ...params, name };
    }
    if (description !== undefined) {
      params = { ...params, description };
    }
    return this.http
      .get<ResponseAWS<FinancialPlanItem>>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/financial-plan`,
        {
          params: params,
        }
      )
      .pipe(map(response => response.items));
  }
  /**
   * Obtiene el detalle de plan financiero.
   * @param code - Codigo del plan financiero.
   * @returns - Observable.
   */
  public viewFinancialPlan(code: string): Observable<FinancialPlan> {
    return this.http
      .get<FinancialPlanDetail>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/financial-plan/${code}`
      )
      .pipe(map(response => this.mapper.mapFrom(response)));
  }
  /**
   * Crea el plan financiero.
   * @param financialPlan - Plan financiero.
   * @returns - Observable.
   */
  public createFinancialPlan(
    financialPlan: FinancialPlan
  ): Observable<SuccessResponse<FinancialPlanOutput>> {
    return this.http.post<SuccessResponse<FinancialPlanOutput>>(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/financial-plan`,
      this.mapper.mapTo(financialPlan)
    );
  }
  /**
   * Actualiza el plan financiero.
   * @param code - Codigo del plan financiero.
   * @param financialPlan - Plan financiero.
   * @returns - Observable.
   */
  public updateFinancialPlan(
    code: string,
    financialPlan: FinancialPlan
  ): Observable<SuccessResponse<undefined>> {
    return this.http.put<SuccessResponse<undefined>>(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/financial-plan/${code}`,
      this.mapper.mapTo(financialPlan)
    );
  }

  /**
   * Importar el plan financiero.
   * @param file - Archivo.
   * @returns - Observable.
   */
  public importFinancialPlan(file: any): Observable<any> {
    const headers = {
      'Content-Type': 'application/zip',
    };
    return this.http.put(
      `${this.enviroment.endpoints.urlPAS}/uploads/${this.enviroment.common.tenantId}/financial-plans/${file.name}`,
      file,
      { headers, reportProgress: true }
    );
  }

  /**
   * Obtiene el estado del plan financiero.
   * @returns - Observable.
   */
  public importFinancialPlanStatus(): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/financial-plan/logs-status/bulkload`,
      {
        reportProgress: true,
      }
    );
  }
}
