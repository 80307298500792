import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { CatalogueReducers } from './catalogue/store/catalogue.reducers';
import { EffectsCatalogueArray } from './catalogue/store/effects';
import { EffectsFeatureModule, EffectsModule } from '@ngrx/effects';

import {
  BackOfficeService,
  DashboardService,
  ProcessService,
} from '@wp-back-office/app/dashboard';
import {
  AccessControlService,
  AmortizationCreditsService,
  AuditProcessesService,
  CreditOriginationTemplateManagementService,
  DecisionTreesService,
  FeaturesService,
  FinancialPlansService,
  FinancialPlanTypesService,
  FinancingTypesService,
  LogsService,
  OfficeGroupsService,
  OfficesService,
  PaymentFrecuencyService,
  PendingTasksService,
  PriorityService,
  ProductsServicesService,
  ProductsVehiclesService,
  RBPOptionsService,
  RoomsService,
  SegmentationOptionsService,
  SegmentationService,
  TractOptionsService,
  WorkflowService,
} from '@wp-back-office/core/workflow-sdk';

import { AuditProcessReducers } from './audit-processes/store/audit-process.reducers';
import { EffectsAuditProcessesArray } from './audit-processes/store/effects';
import { EffectsDecisionRulesArray } from './decision-rules/store/effects';
import { DecisionRulesReducers } from './decision-rules/store/decision-rules.reducers';
import { AnalysisReducers } from './analysis/store/analysis.state.reducers';
import { EffectsAnalysisArray } from './analysis/store/effects';

/**
 * Modulo del estado.
 */
@NgModule({
  imports: [
    CommonModule,
    EffectsFeatureModule,
    StoreModule.forFeature('Catalogue', CatalogueReducers),
    EffectsModule.forFeature(EffectsCatalogueArray),
    // StoreModule.forFeature('Dashboard', DashboardReducers), //TODO ELIMINAR
    // EffectsModule.forFeature(EffectsDashboardArray), //TODO ELIMINAR
    // StoreModule.forFeature('Process', ProcessReducers), //TODO ELIMINAR
    // EffectsModule.forFeature(EffectsProcessArray), //TODO ELIMINAR
    StoreModule.forFeature('AuditProcess', AuditProcessReducers),
    EffectsModule.forFeature(EffectsAuditProcessesArray),
    StoreModule.forFeature('DecisionRules', DecisionRulesReducers),
    EffectsModule.forFeature(EffectsDecisionRulesArray),
    StoreModule.forFeature('Analysis', AnalysisReducers), //TODO ELIMINAR
    EffectsModule.forFeature(EffectsAnalysisArray), //TODO ELIMINAR
  ],
  providers: [
    BackOfficeService,
    DashboardService,
    WorkflowService,
    AccessControlService,
    ProductsVehiclesService,
    ProductsServicesService,
    DecisionTreesService,
    AuditProcessesService,
    CreditOriginationTemplateManagementService,
    FinancialPlansService,
    AmortizationCreditsService,
    FeaturesService,
    ProcessService,
    FinancingTypesService,
    SegmentationOptionsService,
    SegmentationService,
    RoomsService,
    OfficesService,
    OfficeGroupsService,
    RBPOptionsService,
    PriorityService,
    FinancialPlanTypesService,
    TractOptionsService,
    LogsService,
    PaymentFrecuencyService,
    PendingTasksService,
  ],
})
export class CoreStoreModule {}
