/* eslint-disable jsdoc/require-jsdoc */
export interface ScoreCard {
  tenantId: string;
  valor_advance_cc2: number;
  ltv_cc1: number;
  affectedOblications: boolean;
  affectedStatus: boolean;
}

export interface ScoreCardVo extends ScoreCard {
  creditvision_link_cc2: number;
  rev328_r2_cc3: number;
  co02exp006to_cc2: number;
  est_income_cifin: number;
  rev328: number; //Preguntar porque sin el da error
}

export interface ScoreCardPpvn extends ScoreCard {
  creditvision_link_cc3: number;
  plazo_cc2: number;
  co02exp009to_cc3: number;
  ul34s_cc3: number;
  co01exp002ro_cc4: number;
}

export interface ScoreCardPavn extends ScoreCard {
  creditvision_link_cc3: number;
  plazo_cc2: number;
  co02exp009to_cc3: number;
  g202a_r2_cc3: number;
  est_income_cifin: number;
  co01exp002ro_cc4: number;
}

export enum ScoreCardEnum {
  VO = 'vo',
  PPVN = 'ppvn',
  PAVN = 'pavn',
  NONE = 'none',
}
type ScoreCardInputMap = {
  [ScoreCardEnum.PAVN]: ScoreCardPavn;
  [ScoreCardEnum.PPVN]: ScoreCardPpvn;
  [ScoreCardEnum.VO]: ScoreCardVo;
};
export type ScoreCardInput<T extends ScoreCardEnum> =
  T extends keyof ScoreCardInputMap ? ScoreCardInputMap[T] : never;

export interface ScoreCardResponse<T, K extends string> {
  probabilityOfDefault: number;
  probabilityOfDefaultPercent: number;
  concepts: {
    [key in K]: number;
  };
  coeficient: number;
  judgment: string;
  finalJudgmentModifier: string;
  intercept: number;
  sumCoeficient: number;
  input: T;
}

export type ScoreCardVoEnum =
  | 'ltv_cc1'
  | 'co02exp006to_cc2'
  | 'rev328_r2_cc3'
  | 'valor_advance_cc2'
  | 'creditvision_link_cc2';

export type ScoreCardPpvnEnum =
  | 'valor_advance_cc2'
  | 'co01exp002ro_cc4'
  | 'ltv_cc1'
  | 'creditvision_link_cc3'
  | 'plazo_cc2'
  | 'co02exp009to_cc3'
  | 'ul34s_cc3';

export type ScoreCardPavnEnum =
  | 'co01exp002ro_cc4'
  | 'valor_advance_cc2'
  | 'ltv_cc1'
  | 'creditvision_link_cc3'
  | 'co02exp009to_cc3'
  | 'plazo_cc2'
  | 'g202a_r2_cc3';

export type ScoreCardResponseEnum<T> = T extends ScoreCardEnum.VO
  ? ScoreCardVoEnum
  : T extends ScoreCardEnum.PPVN
  ? ScoreCardPpvnEnum
  : T extends ScoreCardEnum.PAVN
  ? ScoreCardPavnEnum
  : never;
