import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { Subject, takeUntil } from 'rxjs';

import { ControlConfig } from '@wp-back-office/shared/dynamic-components';

import { DynamicFormService } from '../../../../services/dynamic-form.service';

/**
 * Control para formatear valores.
 */
@Component({
  selector: 'wp-back-office-form-control-currency',
  templateUrl: './form-control-currency.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlCurrencyComponent),
      multi: true,
    },
  ],
})
export class FormControlCurrencyComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  /**
   * Id unico del formulario.
   */
  @Input()
  public uniqueIdForm!: string;

  /**
   * Configuracion del control.
   */
  @Input()
  public set control(value: ControlConfig | undefined) {
    if (value) {
      this.options = {
        align: 'left',
        thousands: '.',
        precision: value.validators?.precision || 0,
        nullable: true,
        decimal: ',',
        prefix: '$',
        inputMode: CurrencyMaskInputMode.FINANCIAL,
      };
      this.controlConfig = value;
      this._formControl = this.dynamicFormService.setValidators(value);
    }
  }
  /**
   * Evento focus.
   */
  @Output()
  public controlFocus: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Evento focusOut.
   */
  @Output()
  public controlFocusOut: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Configuracion de currency mask.
   */
  public options: any;

  /**
   * Form control del campo.
   */
  public _formControl: FormControl;

  /**
   * Configuracion del control variable.
   */
  public controlConfig!: ControlConfig;

  /**
   * Destructor sujeto.
   */
  private destroy$: Subject<boolean>;

  /**
   * Crea una instancia de la clase.
   * @param dynamicFormService - Servicio de formularios dinamicos.
   * @param cdRef - Detector de cambios.
   */
  constructor(
    public dynamicFormService: DynamicFormService,
    public cdRef: ChangeDetectorRef
  ) {
    // this.options = {
    //   align: 'left',
    //   thousands: '.',
    //   precision: 0,
    //   nullable: true,
    //   decimal: '.',
    //   prefix: '$',
    //   inputMode: CurrencyMaskInputMode.FINANCIAL,
    // };
    this._formControl = new FormControl('');
    this.destroy$ = new Subject();
  }

  /**
   * Se ejecutar al iniciar el componente.
   */
  public ngOnInit() {
    if (this.controlConfig)
      this._formControl = this.dynamicFormService.setValidators(
        this.controlConfig
      );
    this._formControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.onChange(data);
        if (this._formControl.touched) {
          this.onTouch(data);
        }
      });
  }

  /**
   * Se ejecuta al destruir el componente.
   */
  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  /**
   * Ejecuta el evento focus del input.
   * @param event - Evento focus.
   */
  public onFocus(event: FocusEvent): void {
    this.controlFocus.emit(event);
  }

  /**
   * Ejecuta el evento focusOut del input.
   * @param event - Evento focusOut.
   */
  public onFocusOut(event: FocusEvent): void {
    this.controlFocusOut.emit(event);
  }

  /**
   * Verirfica si es cambiado.
   * @param obj - Callback.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  public onChange = (obj: any) => {};

  /**
   * Verirfica si es tocado.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  public onTouch: any = () => {};

  /**
   * Verirfica si es escrito.
   * @param obj - Callback.
   * @throws Error.
   */
  public writeValue(obj: any): void {
    this._formControl.patchValue(obj);
  }

  /**
   * Verirfica si es cambiado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /**
   * Verirfica si es tocado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /**
   * Deshabilitar control.
   * @param isDisabled - Bandera.
   */
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this._formControl.disable();
    } else {
      this._formControl.enable();
    }
  }
}
