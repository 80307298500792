import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceMapper } from '../mappers/services.mapper';
import { ResponseAWS } from '../models/response-aws.model';
import { Service, ServiceDetail } from '../models/service.model';
import * as moment from 'moment';
/**
 * Servicio de planes financieros.
 */
@Injectable()
export class ServiceLogsService {
  /**
   * Mapeador.
   */
  private mapper = new ServiceMapper();
  /**
   * Crea una instancia de WorkflowService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}
  /**
   * Obtiene la lista de planes financieros.
   * @param code - Codigo de concesionario.
   * @param page - Pagina.
   * @param pageSize - Tamaño de la pagina.
   * @returns - Observable.
   */
  public getServiceLogsList(
    code: number,
    userFilter?: string,
    pageSize?: number,
    paginationToken?: string
  ): Observable<ResponseAWS<ServiceLogModel>> {
    let params = {};
    if (userFilter !== undefined) {
      params = { ...params, userFilter };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    if (paginationToken !== undefined) {
      params = { ...params, paginationToken };
    }
    return this.http
      .get<ResponseAWS<ServiceInputLogModel>>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/service/logs/${code}`,
        // `${this.enviroment.endpoints.urlPAS}/2222222/financial-plan/Servicelogs/${code}`,
        {
          params: params,
        }
      )
      .pipe(
        map(response => ({
          ...response,
          items: response.items.map(
            item =>
              ({
                ...item,
                service: this.mapper.mapFrom(item.service),
                date: moment(item.date).format(),
              } as ServiceLogModel)
          ),
        }))
      );
  }
}
/**
 *{AGREGUE DOCUMENTACION}.
 */
export interface ServiceInputLogModel {
  /**
   * Servicio.
   */
  service: ServiceDetail;
  /**
   * Fecha.
   */
  date: Date;
  /**
   * ID del usuario.
   */
  user_id: string;
  /**
   * IP del cliente.
   */
  ip_client: string;
  /**
   * Nombre del usuario.
   */
  full_name_user: string;
  /**
   * Email del usuario.
   */
  user_email?: string;
}
/**
 *{AGREGUE DOCUMENTACION}.
 */
export interface ServiceLogModel
  extends Omit<ServiceInputLogModel, 'service' | 'date'> {
  /**
   * Servicio.
   */
  service: Service;
  /**
   * Servicio.
   */
  date: string;
}
