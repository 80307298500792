import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DecisionTree } from 'libs/app/dashboard/src/lib/models/decision-tree.model';
/**
 * Servicio de control de acceso.
 */
@Injectable()
export class DecisionTreesService {
  /**
   * Constructor.
   */
  constructor() {}

  // eslint-disable-next-line jsdoc/require-returns
  /**
   * Obtiene la lista de vehiculos.
   */
  public getDecisionTrees(): Observable<DecisionTree[]> {
    const array: DecisionTree[] = [
      {
        code: '01',
        description: 'DESCRIPCION01',
        name: 'NOMBRE01',
        version: 'v1.0.1',
      },
      {
        code: '02',
        description: 'DESCRIPCION02',
        name: 'NOMBRE02',
        version: 'v1.0.2',
      },
      {
        code: '03',
        description: 'DESCRIPCION03',
        name: 'NOMBRE03',
        version: 'v1.0.3',
      },
    ];
    return of(array);
  }
}
