import { Action, createReducer, on } from '@ngrx/store';
import { resetInitialState } from '../../../../root/store/actions';
import {
  CheckContactInfo,
  CheckContactInfoError,
  CheckContactInfoReset,
  CheckContactInfoSuccess,
} from '../../actions';

/**
 * Estado.
 */
export interface CheckContactInfoState {
  /**
   * Estado de la radicacion.
   */
  object?: any;
  /**
   * Bandera de cargado.
   */
  loaded?: boolean;
  /**
   * Bandera de cargando.
   */
  loading?: boolean;
  /**
   * Errores.
   */
  error?: any;
}

export const contactInfoCheckInitialState: CheckContactInfoState = {
  object: undefined,
  error: undefined,
  loaded: true,
  loading: false,
};

const featureReducer = createReducer(
  contactInfoCheckInitialState,
  on(resetInitialState, () => ({ ...contactInfoCheckInitialState })),
  on(CheckContactInfoReset, () => ({ ...contactInfoCheckInitialState })),
  on(CheckContactInfo, state => ({
    ...state,
    loading: true,
    loaded: false,
    error: undefined,
  })),
  on(CheckContactInfoSuccess, (state, { object }) => ({
    ...state,
    loading: false,
    loaded: true,
    object,
  })),
  on(CheckContactInfoError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      payload: payload,
      message: payload.message,
    },
  }))
);

/**
 * Reductor iniciar proceso.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function CheckContactInfoReducer(
  state: any | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
