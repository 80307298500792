import { UnaryFunction, Observable, pipe, filter, map } from 'rxjs';
/**
 * Filter if value is undefined.
 * @returns - Filter operator.
 */
export function filterNonNullable<T>(): UnaryFunction<
  Observable<T>,
  Observable<NonNullable<T>>
> {
  return pipe(
    filter(x => x !== undefined && x !== null),
    map(x => x as NonNullable<T>)
  );
}
