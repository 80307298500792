import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Modulo dashboard.
 */
@NgModule({
  imports: [CommonModule],
})
export class AppDashboardModule {}
