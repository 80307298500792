import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe mapear iterable.
 */
@Pipe({
  name: 'mapToIterable',
})
export class MapToIterablePipe implements PipeTransform {
  /**
   * Metodo transform para un valor del pipeline.
   * @param obj - Objeto.
   * @returns Datos.
   */
  public transform(obj: any): any {
    const data = [];

    for (const k in obj) {
      const element = obj[k];
      if (element === 'null') break;

      data.push({
        key: k,
        value: element,
      });
    }

    return data;
  }
}
