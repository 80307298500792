import {
  Action,
  ActionReducer,
  createReducer,
  MetaReducer,
  on,
} from '@ngrx/store';
import { Attributes, SignInUserSession } from '../../../models/session.model';
import { authActions } from '../../actions/contents/auth.actions';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';

/**
 * Reducer para obtener concesionarios.
 */
export interface StateData {
  /**
   * Respuesta del store.
   */
  object?: any;
  /**
   * Bandera de carga.
   */
  loaded: boolean;
  /**
   * Bandera de carga.
   */
  loading: boolean;
  /**
   * Respuesta erronea del store.
   */
  error?: ErrorHttp | any;
}

/**
 * Reinicio de contrasena.
 */
export interface PasswordReset {
  /**
   * Codigo otp.
   */
  sendOtpCode?: StateData;
  /**
   * Codigo otp.
   */
  verifyOtpCode?: StateData;
  /**
   * Codigo otp.
   */
  passwordReset?: StateData;
}

/**
 * Estado de autenticación.
 */
export interface SessionState {
  /**
   * Informacion de autenticacion.
   */
  signInUserSession?: SignInUserSession;
  /**
   * Atributos del usuario.
   */
  attributes?: Attributes;
  /**
   * Atributos del usuario.
   */
  passwordReset: PasswordReset;
}

export const initialSessionState: SessionState = {
  signInUserSession: undefined,
  attributes: undefined,
  passwordReset: {
    sendOtpCode: undefined,
    verifyOtpCode: undefined,
    passwordReset: undefined,
  },
};

const loadingObj = {
  object: undefined,
  loading: true,
  loaded: false,
  error: undefined,
};

const loadedObj = {
  loading: false,
  loaded: true,
};

const featureReducer = createReducer(
  initialSessionState,
  on(authActions.cerrarSesion, () => ({
    ...initialSessionState,
  })),
  on(authActions.inciarSesion, state => ({
    ...state,
  })),
  on(authActions.inciarSesionExitoso, (state, { response }) => ({
    ...state,
    signInUserSession: response.signInUserSession,
    attributes: response.attributes,
  })),
  on(authActions.sendEmailOtpCode, state => ({
    ...state,
    passwordReset: {
      sendOtpCode: loadingObj,
    },
  })),
  on(authActions.sendEmailOtpCodeSuccess, (state, { object }) => ({
    ...state,
    passwordReset: {
      sendOtpCode: {
        object,
        error: undefined,
        ...loadedObj,
      },
    },
  })),
  on(authActions.sendEmailOtpCodeError, (state, { error }) => ({
    ...state,
    passwordReset: {
      sendOtpCode: {
        object: undefined,
        error,
        ...loadedObj,
      },
    },
  })),
  on(authActions.verifyOtpCode, state => ({
    ...state,
    passwordReset: {
      verifyOtpCode: loadingObj,
    },
  })),
  on(authActions.verifyOtpCodeSuccess, (state, { object }) => ({
    ...state,
    passwordReset: {
      verifyOtpCode: {
        object,
        error: undefined,
        ...loadedObj,
      },
    },
  })),
  on(authActions.verifyOtpCodeError, (state, { error }) => ({
    ...state,
    passwordReset: {
      verifyOtpCode: {
        object: undefined,
        error,
        ...loadedObj,
      },
    },
  })),
  on(authActions.passwordReset, state => ({
    ...state,
    passwordReset: {
      passwordReset: loadingObj,
    },
  })),
  on(authActions.passwordResetSuccess, (state, { object }) => ({
    ...state,
    passwordReset: {
      passwordReset: {
        object,
        error: undefined,
        ...loadedObj,
      },
    },
  })),
  on(authActions.passwordResetError, (state, { error }) => ({
    ...state,
    passwordReset: {
      passwordReset: {
        object: undefined,
        error,
        ...loadedObj,
      },
    },
  }))
);

// eslint-disable-next-line require-jsdoc
export const logout = (reducer: ActionReducer<any>): ActionReducer<any> => {
  return (state, action) => {
    if (action.type === authActions.cerrarSesion.type) {
      return reducer(undefined, action);
    }
    return reducer(state, action);
  };
};

export const metaReducers: MetaReducer[] = [logout];

/**
 * Reductor autenticación.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function SessionReducer(
  state: SessionState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
