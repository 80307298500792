import { ComponentType } from '@angular/cdk/portal';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { DynamicDialogMessageComponent } from '../components/dynamic-dialog-message.component';
import {
  DialogConfig,
  DialogConfigUpdate,
  MessageCode,
} from '../models/dynamic-dialog.model';
import { ControlConfig, DialogAddDataComponent } from '../../dynamic-form';

/**
 * Opciones del loader.
 */
export interface LoaderOptions {
  /**
   * Codigo de mensaje.
   */
  messageCode?: MessageCode;
  /**
   * Tiempo de espera ms.
   */
  timeOut?: number;
}

/**
 * Configuracion del dialog del formulario.
 */
export interface FormDialogConfig {
  /**
   * Titulo.
   */
  title: string;
  /**
   * Subtitulo.
   */
  subtitle: string;
  /**
   * Configuracion del formulario.
   */
  controls: ControlConfig[];
  /**
   * Valor.
   */
  value?: object;
  /**
   * Confirmacion.
   */
  disableConfirmation?: boolean;
  /**
   * Clases.
   */
  contentClasess?: string;
  /**
   * Texto del boton.
   */
  buttonCancelText?: string;
  /**
   * Texto del boton.
   */
  buttonAddText?: string;
  /**
   * Cerrar al verificar el OTP.
   */
  closeOnOtpVerification?: boolean;
  /**
   * Activa el boton cerrar.
   */
  onlyClosed?: boolean;
  /**
   * Configuracion del dialog.
   */
  config?: MatDialogConfig;
  /**
   * Enviar data sin cerrar el dialog.
   */
  sendWithoutClose?: boolean;
}

/**
 * Servicio de Dialogs dinamicos.
 */
@Injectable()
// eslint-disable-next-line @nrwl/nx/workspace/doc-elements-angular
export class DynamicDialogMessageService {
  /**
   * Referencia del Dialog.
   */
  public dialogRef!: MatDialogRef<any>;

  /**
   * Referencia del componente.
   */
  public componentRef = this.componentFactoryResolver
    .resolveComponentFactory(DynamicDialogMessageComponent)
    .create(this.injector);

  /**
   * Diccionario Stack.
   */
  private dictionaryStack: Record<string, boolean>;

  /**
   * Crea una instancia de DynamicDialogMessageService.
   * @param matDialog - Dialogs de angular material.
   * @param componentFactoryResolver - ComponentFactoryResolver.
   * @param appRef - ApplicationRef.
   * @param injector - Injector.
   */
  constructor(
    public matDialog: MatDialog,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {
    this.dictionaryStack = {};
  }

  /**
   * Abre un Dialog con la cofiguración que envias.
   * @param Dialog - Configuracion del Dialog.
   * @param closeRef - Valida si se cierra la referencia del dialog.
   * @returns Dialog.
   */
  public open(
    Dialog: DialogConfig,
    closeRef?: boolean
  ): MatDialogRef<DynamicDialogMessageComponent, any> {
    if (closeRef) {
      this.dialogRef ? this.dialogRef.close() : null;
    }
    this.dialogRef = this.matDialog.open(DynamicDialogMessageComponent, {
      panelClass: 'Dialog-message',
      backdropClass: 'Dialog-message-backdrop',
      disableClose: true,
      ...Dialog.config,
    });
    this.dialogRef.componentInstance.dialogConfig = Dialog;
    return this.dialogRef;
  }

  /**
   * Abre un Dialog con la cofiguración que envias.
   * @param component - Componente del Dialog.
   * @param config - Configuracion del Dialog.
   * @param closeRef - Cierre.
   * @returns Dialog.
   */
  public openFromComponent<T = any>(
    component: ComponentType<any>,
    config?: MatDialogConfig,
    closeRef?: boolean
  ) {
    if (closeRef) {
      this.dialogRef ? this.dialogRef.close() : null;
    }
    this.dialogRef = this.matDialog.open(component, {
      ...config,
    });
    return this.dialogRef as MatDialogRef<any, T>;
  }

  /**
   * Abre un Dialog con la cofiguración que envias.
   * @param text - Texto.
   * @param options - Mensaje.
   */
  public openLoader(text?: string, options?: LoaderOptions): any {
    setTimeout(() => {
      this.appRef?.detachView(this.componentRef.hostView);
      this.componentRef?.destroy();

      this.componentRef = this.componentFactoryResolver
        .resolveComponentFactory(DynamicDialogMessageComponent)
        .create(this.injector);

      this.componentRef.instance.dialogConfig = {
        type: 'loading',
        header: {
          text: text || undefined,
          messageCode: options?.messageCode || undefined,
        },
      };
      this.appRef.attachView(this.componentRef.hostView);

      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;
      const dialogs = document.getElementsByTagName('mat-dialog-container');

      domElem.firstElementChild?.classList.add('appLoader');

      const matTabGroup = document.querySelectorAll(
        '.app-background > .ng-star-inserted > wp-back-office-dynamic-tabs-general mat-tab-group .mat-tab-body-wrapper'
      );

      domElem.classList.add('appLoaderContainer');

      if (dialogs.length === 0 && matTabGroup.length === 0) {
        domElem.style.position = 'fixed';
        domElem.style.top = '0';
        domElem.style.zIndex = '5000';
        domElem.style.background = '#ffffff';
        domElem.style.padding = '56px';
        domElem.style.width = '100vw';
        domElem.style.height = '100vh';
        domElem.style.display = 'flex';
        domElem.style.margin = 'auto';
        domElem.style.justifyContent = 'center';
        domElem.style.alignItems = 'center';
        domElem.style.animation = 'appear 0.5s cubic-bezier(0.5, 0.25, 0, 1)';
        document.body.appendChild(domElem);
      } else {
        domElem.style.position = 'absolute';
        domElem.style.background = 'white';
        domElem.style.top = '0';
        domElem.style.left = '0';
        domElem.style.right = '0';
        domElem.style.bottom = '0';
        domElem.style.margin = 'auto';
        domElem.style.display = 'flex';
        domElem.style.alignItems = 'center';
        domElem.style.zIndex = '100000';
        domElem.style.borderRadius = '20px';
        if (dialogs.length > 0) {
          for (let index = 0; index < dialogs.length; index++) {
            const _dialog = dialogs.item(index);
            _dialog?.appendChild(domElem);
            _dialog?.classList.add('p-relative');
          }
        } else if (matTabGroup.length > 0) {
          for (let index = 0; index < matTabGroup.length; index++) {
            const _tab = matTabGroup.item(index);
            _tab?.appendChild(domElem);
            _tab?.classList.add('p-relative');
          }
        }
      }
      return this.componentRef;
    }, options?.timeOut || 100);
  }

  /**
   * Actualiza campos especificos de la configuracion manteniendo la configuracion anterior.
   * @param Dialog - Configuracion del Dialog.
   */
  public update(Dialog: DialogConfigUpdate): void {
    this.dialogRef.componentInstance.dialogConfig = {
      ...this.dialogRef.componentInstance.dialogConfig,
      ...Dialog,
    };
  }

  /**
   * Reinicia la configuracion.
   * @param Dialog - Configuracion del Dialog.
   */
  public updateAll(Dialog: DialogConfigUpdate): void {
    this.dialogRef.componentInstance.dialogConfig = {
      ...Dialog,
    };
  }

  /**
   * Cerrar el Dialog.
   */
  public close(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  /**
   * Cerrar el Dialog.
   */
  public closeLoader(): void {
    setTimeout(() => {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
    }, 100);
  }

  /**
   * Cerrar el Dialog.
   */
  public closeAllLoaders(): void {
    setTimeout(() => {
      document
        .querySelectorAll('.appLoaderContainer')
        ?.forEach(el => el?.remove());
    }, 100);
  }
  /**
   * Abre el loader por stack.
   * @param stackKey - Clave del stack.
   * @param text - Mensaje de texto.
   * @param options - Opciones.
   * @throws - Error.
   */
  public openLoaderStack(
    stackKey: string,
    text?: string,
    options?: LoaderOptions
  ) {
    if (stackKey === '__isCreated') {
      this.open({
        type: 'error',
        header: {
          text: `Stack key ${stackKey} entered is reserved`,
        },
      });
      this.closeLoader();
      this.dictionaryStack = {};
      throw `Stack key ${stackKey} entered is reserved`;
    }
    if (this.dictionaryStack[stackKey] === undefined) {
      if (this.dictionaryStack['__isCreated'] === undefined) {
        this.dictionaryStack = {
          ...this.dictionaryStack,
          ['__isCreated']: true,
        };
        this.openLoader(text, {
          messageCode: options?.messageCode,
        });
      }
      this.dictionaryStack = {
        ...this.dictionaryStack,
        [stackKey]: true,
      };
    }
  }
  /**
   * Cierra loader por stack.
   * @param stackKey - Clave del stack.
   * @throws - Error.
   */
  public closeStack(stackKey: string) {
    if (stackKey === '__isCreated') {
      this.open({
        type: 'error',
        header: {
          text: `Stack key ${stackKey} entered is reserved`,
        },
      });
      this.closeLoader();
      this.dictionaryStack = {};
      throw `Stack key ${stackKey} entered is reserved`;
    }
    if (this.dictionaryStack[stackKey]) delete this.dictionaryStack[stackKey];
    if (
      Object.keys(this.dictionaryStack).length <= 1 &&
      this.dictionaryStack['__isCreated']
    ) {
      delete this.dictionaryStack['__isCreated'];
      this.closeLoader();
    }
  }

  /**
   * Abre un formulario.
   * @param config - Configuracion del control.
   * @returns Observable.
   */
  public openForm<T = any>(config: FormDialogConfig) {
    const dialogRef = this.matDialog.open(DialogAddDataComponent, {
      disableClose: true,
      width: '85%',
      maxWidth: '700px',
      ...(config?.config || {}),
      data: {
        formTitle: config.title,
        formSubtitle: config.subtitle,
        controls: config.controls,
        buttonCancelText: config.buttonCancelText,
        buttonAddText: config.buttonAddText,
        row: config.value,
        disableConfirmation: config.disableConfirmation,
        closeOnOtpVerification: config.closeOnOtpVerification,
        onlyClosed: config.onlyClosed,
        contentClasess: config.contentClasess,
        sendWithoutClose: config.sendWithoutClose,
      },
    });

    this.dialogRef = dialogRef;

    return dialogRef as MatDialogRef<any, T>;
  }
}

export const DynamicDialogMessageServiceTest: any = {
  /**
   * Referencia del Dialog.
   */
  dialogRef: {},

  /**
   * Referencia del componente.
   */
  componentRef: {},

  /**
   * Diccionario Stack.
   */
  dictionaryStack: {},

  /**
   * Abre un Dialog con la cofiguración que envias.
   * @param Dialog - Configuracion del Dialog.
   * @param closeRef - Valida si se cierra la referencia del dialog.
   * @returns Dialog.
   */
  open: (Dialog: DialogConfig, closeRef?: boolean) => {
    return;
  },

  /**
   * Abre un Dialog con la cofiguración que envias.
   * @param component - Componente del Dialog.
   * @param config - Configuracion del Dialog.
   * @param closeRef - Cierre.
   * @returns Dialog.
   */
  openFromComponent: (
    component: ComponentType<any>,
    config?: MatDialogConfig,
    closeRef?: boolean
  ) => {
    return;
  },

  /**
   * Abre un Dialog con la cofiguración que envias.
   * @param text - Texto.
   * @param options - Mensaje.
   */
  openLoader: (text?: string, options?: LoaderOptions) => {
    return;
  },

  /**
   * Actualiza campos especificos de la configuracion manteniendo la configuracion anterior.
   * @param Dialog - Configuracion del Dialog.
   */
  update: (Dialog: DialogConfigUpdate) => {
    return;
  },

  /**
   * Reinicia la configuracion.
   * @param Dialog - Configuracion del Dialog.
   */
  updateAll: (Dialog: DialogConfigUpdate) => {
    return;
  },

  /**
   * Cerrar el Dialog.
   */
  close: () => {
    return;
  },

  /**
   * Cerrar el Dialog.
   */
  closeLoader: () => {
    return;
  },

  /**
   * Cerrar el Dialog.
   */
  closeAllLoaders: () => {
    return;
  },
  /**
   * Abre el loader por stack.
   * @param stackKey - Clave del stack.
   * @param text - Mensaje de texto.
   * @param options - Opciones.
   * @throws - Error.
   */
  openLoaderStack: (
    stackKey: string,
    text?: string,
    options?: LoaderOptions
  ) => {
    return;
  },
  /**
   * Cierra loader por stack.
   * @param stackKey - Clave del stack.
   * @throws - Error.
   */
  closeStack: (stackKey: string) => {
    return;
  },

  /**
   * Abre un formulario.
   * @param config - Configuracion del control.
   * @returns Observable.
   */
  openForm: (config: FormDialogConfig) => {
    return;
  },
};
