import { Action, createReducer, on } from '@ngrx/store';
import { ErrorHttp } from '../../../../models/errors.models';
import { resetInitialState } from '../../../../root/store/actions';
import { TaskResult } from '../../../models/request.models';
import { cloneDeep } from 'lodash';

import {
  RadicationAnalysis,
  RadicationAnalysisSuccess,
  RadicationAnalysisError,
  RadicationAnalysisEdit,
  RadicationAnalysisEditError,
  RadicationAnalysisEditSuccess,
  ResetAnalysis,
  ResetAnalysisModifyProduct,
  RadicationAnalysisModifyProduct,
  RadicationAnalysisValueProduct,
  RadicationAnalysisValueInterveners,
  RadicationAnalysisModifyInterveners,
} from '../../actions';

/**
 * Estado de tareas exitosas.
 */
export interface RequestRisk {
  /**
   * Objecto.
   */
  request?: any;
  /**
   * Bandera de cargado.
   */
  loaded: boolean;
  /**
   * Bandera de cargando.
   */
  loading: boolean;
  /**
   * Errores.
   */
  error?: ErrorHttp;
}
/**
 *
 */
export interface SchemaModify {
  /**
   *
   */
  id?: string;
  /**
   * Id.
   */
  value?: string;
  /**
   * Valor.
   */
  taskResult?: TaskResult;
}

/**
 * Estado de tareas exitosas.
 */
export interface RequestAnalysisState {
  /**
   * Formularios para el proceso.
   */
  radication?: RequestRisk;
  /**
   * Formularios para editar en el proceso.
   */
  radicationEdit?: RequestRisk;
  /**
   * Modificaciones de los campos del formulari editado.
   */
  schemaModify?: {
    /**
     * Producto.
     */
    product?: SchemaModify;
    /**
     * Intervinientes.
     */
    interveners?: SchemaModify[];
  };
}

export const requestAnalysisStateInitialState: RequestAnalysisState = {
  radication: undefined,
  radicationEdit: undefined,
  schemaModify: undefined,
};

const featureReducer = createReducer(
  requestAnalysisStateInitialState,
  on(ResetAnalysisModifyProduct, () => ({ ...requestAnalysisStateInitialState })),
  on(ResetAnalysis, () => ({ ...requestAnalysisStateInitialState })),
  on(resetInitialState, () => ({ ...requestAnalysisStateInitialState })),
  on(RadicationAnalysisModifyProduct, (state, { modify }) => ({
    ...state,
    schemaModify: {
      ...state.schemaModify,
      product: {
        ...state.schemaModify?.product,
        taskResult: modify,
      },
    },
  })),
  on(RadicationAnalysisValueProduct, (state, { value }) => ({
    ...state,
    schemaModify: {
      ...state.schemaModify,
      product: {
        ...state.schemaModify?.product,
        value,
      },
    },
  })),
  on(RadicationAnalysisModifyInterveners, (state, { modify, id }) => {
    let newArray: SchemaModify[] = [];
    const clonArray = cloneDeep(state.schemaModify?.interveners);
    const item = clonArray?.find(x => x.id === id);
    if (item && clonArray) {
      clonArray.map(x => (x.id === id ? (x.taskResult = modify) : undefined));
      newArray = clonArray;
    } else {
      newArray = [
        ...(state.schemaModify?.interveners || []),
        {
          id,
          taskResult: modify,
        },
      ];
    }
    return {
      ...state,
      schemaModify: {
        ...state.schemaModify,
        interveners: newArray,
      },
    };
  }),
  on(RadicationAnalysisValueInterveners, (state, { value, id }) => {
    let newArray: SchemaModify[] = [];
    const clonArray = cloneDeep(state.schemaModify?.interveners);
    const item = clonArray?.find(x => x.id === id);
    if (item && clonArray) {
      clonArray.map(x => (x.id === id ? (x.value = value) : undefined));
      newArray = clonArray;
    } else {
      newArray = [
        ...(state.schemaModify?.interveners || []),
        {
          id,
          value,
        },
      ];
    }
    return {
      ...state,
      schemaModify: {
        ...state.schemaModify,
        interveners: newArray,
      },
    };
  }),
  on(RadicationAnalysis, state => ({
    ...state,
    radication: {
      loading: true,
      loaded: false,
    },
  })),
  on(RadicationAnalysisSuccess, (state, { object }) => ({
    ...state,
    radication: {
      request: object,
      loading: false,
      loaded: true,
    },
  })),
  on(RadicationAnalysisError, (state, { error }) => ({
    ...state,
    radication: {
      loading: false,
      loaded: true,
      error: {
        url: error.url,
        name: error.name,
        message: error.message,
      },
    },
  })),
  on(RadicationAnalysisEdit, state => ({
    ...state,
    radicationEdit: {
      loading: true,
      loaded: false,
    },
  })),
  on(RadicationAnalysisEditSuccess, (state, { object }) => ({
    ...state,
    radicationEdit: {
      request: object,
      loading: false,
      loaded: true,
    },
  })),
  on(RadicationAnalysisEditError, (state, { error }) => ({
    ...state,
    radicationEdit: {
      loading: false,
      loaded: true,
      error: {
        url: error.url,
        name: error.name,
        message: error.message,
      },
    },
  }))
);

/**
 * Reductor tareas exitosas.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function RequestAnalysisReducer(
  state: RequestAnalysisState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
