import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Catalogue, Enviroment } from '@wp-back-office/core/commons-backoffice';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseAWS } from '../models/response-aws.model';
/**
 * Servicio de planes financieros.
 */
@Injectable()
export class OfficeGroupsService {
  /**
   * Crea una instancia de WorkflowService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}
  /**
   * Obtiene la lista de planes financieros.
   * @param page - Pagina.
   * @param pageSize - Tamaño de la pagina.
   * @param codeMarket - Codigo de mercado.
   * @returns - Observable.
   */
  public getOfficeGroupsList(
    page?: number,
    pageSize?: number | string,
    codeMarket?: string
  ): Observable<Catalogue[]> {
    let params = {};
    if (codeMarket) {
      params = { ...params, code_market: codeMarket };
    }
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    this.enviroment.endpoints.url;
    return this.http
      .get<ResponseAWS<OfficeGroup>>(
        `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/office-group`,
        {
          params: params,
        }
      )
      .pipe(
        map(response =>
          response.items.map(item => ({
            code: item.code_office_group.toString(),
            description: item.description,
          }))
        )
      );
  }
}
/**
 *{AGREGUE DOCUMENTACION}.
 */
interface OfficeGroup {
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  code_office_group: number;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  description: string;
}
