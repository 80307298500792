import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueLevelOfSchooling = createAction(
  '[KeyValueLevelOfSchooling] Cargar nivel escolar',
  props<{
    /**
     * Ruta.
     */
    path: CatalogueType;
  }>()
);

export const KeyValueLevelOfSchoolingSuccess = createAction(
  '[KeyValueLevelOfSchooling] Crear nivel escolar Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueLevelOfSchoolingError = createAction(
  '[KeyValueLevelOfSchooling] Cargar nivel escolar Error',
   props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
