<ng-template [ngIf]="controlConfig && controlConfig.key && controlConfig.type">
  <mat-checkbox
    [checked]="controlConfig.value"
    [formControl]="_formControl">
    <a
      tabindex="-1"
      *ngIf="controlConfig.checkBoxOptions?.link"
      [href]="controlConfig.checkBoxOptions?.link"
      [target]="controlConfig.checkBoxOptions?.linkTarget || '_blank'">
      {{
        controlConfig.checkBoxOptions?.linkText ||
          controlConfig.checkBoxOptions?.link
      }}</a
    >
    {{ controlConfig.label || '' }}
    <mat-chip-option
      *ngFor="let chip of controlConfig.chipLabels || []"
      labelChip>
      {{ chip }}
    </mat-chip-option>
    <mat-error
      class="error"
      [class.opacity-0]="_formControl.valid"
      [innerHTML]="
        dynamicFormService.errorMessage(_formControl, controlConfig)
      ">
    </mat-error>

    <mat-hint *ngIf="controlConfig.hint">
      {{ controlConfig.hint }}
    </mat-hint>
  </mat-checkbox>
</ng-template>
