import { Injectable } from '@angular/core';
import { pki, util } from 'node-forge';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';

/**
 * Servicio de encriptación.
 */
@Injectable()
export class CryptoService {
  /**
   * Contructor de la clase.
   * @param enviroment - Clave publica de encriptacion.
   */
  constructor(private enviroment: Enviroment) {
    this.key = pki.publicKeyFromPem(this.enviroment.publicKey);
  }

  /**
   * Clave de encriptación.
   */
  protected key;
  /**
   * Encripta el objeto de entrada.
   * @param input - Objeto de entrada.
   * @returns - Respuesta encriptada.
   */
  public encrypt(input: any) {
    const dataEncrypt = this.key.encrypt(JSON.stringify(input));

    return util.encode64(dataEncrypt);
  }

  /**
   * Decifra un JTW sin validar la firma.
   * @param token - JWT a decifrar.
   * @returns - Informacion decifrada.
   */
  public decryptJTW(token: string): any {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          ?.split('')
          ?.map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(jsonPayload);
    } catch (error) {
      if (!token) {
        localStorage.setItem('exipredSesion', 'true');
        localStorage.setItem('invalidToken', 'true');
        return 'El token no es válido.';
      }
      return error;
    }
  }
}
