import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueTypeOfWelling = createAction(
  '[KeyValueTypeOfWelling] Cargar tipo de vivienda',
  props<{
    /**
     * Ruta.
     */
    path: CatalogueType;
  }>()
);

export const KeyValueTypeOfWellingSuccess = createAction(
  '[KeyValueTypeOfWelling] Crear tipo de vivienda Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueTypeOfWellingError = createAction(
  '[KeyValueTypeOfWelling] Cargar tipo de vivienda Error',
   props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
