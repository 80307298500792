/**
 * Separa texto capitalizado por simbolo dado.
 * @param inputString - Entrada.
 * @param symbol - Simbolo.
 * @param toUpperCase - Si es mayuscula.
 * @returns - String.
 */
export function separateBySymbol(
  inputString: string,
  symbol: string,
  toUpperCase: boolean
): string {
  let result = inputString.replace(/([a-z])([A-Z])/g, '$1' + symbol + '$2');
  if (toUpperCase) {
    result = result.toUpperCase();
  } else {
    result = result.toLowerCase();
  }
  return result;
}
