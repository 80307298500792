import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  DynamicFormComponent,
  DynamicDialogMessageService,
  ControlConfig,
  dialogMessageDefaultConfig,
} from '@wp-back-office/shared/dynamic-components';
import { first, Subject, takeUntil } from 'rxjs';

export interface SEND {
  row: any;
  itemCreated: boolean;
}

/**
 * Dialog add data.
 */
@Component({
  selector: 'wp-back-office-dialog-add-data',
  templateUrl: './dialog-add-data.component.html',
  styles: [
    `
      .mat-card-subtitle.m-0 {
        margin: 0 !important;
        position: relative;
        top: 20px;
        text-align: center;
        line-height: 25px;
      }
    `,
  ],
})
export class DialogAddDataComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  /**
   * Tipo de componente.
   */
  public title: string;
  /**
   * Deshabilitar boton enviar.
   */
  public disableSendButton: boolean = false;
  /**
   * Texto del boton close.
   */
  public buttonCancelText: string = 'Cancelar';
  /**
   * Formulario.
   */
  @ViewChild('formID', {
    static: false,
    read: DynamicFormComponent,
  })
  public formComponent?: DynamicFormComponent;
  /**
   * Valor de controles de operaciones exteriores.
   */
  public controls!: ControlConfig[];
  /**
   * Destructor sujeto.
   */
  private destroy$ = new Subject();
  /**
   * Evento de envio de datos.
   */
  @Output()
  public send: EventEmitter<SEND> = new EventEmitter<SEND>();

  /**
   * Crea una isntancia AssociatesRelativesListFormComponent.
   * @param dialogRef - Referencia del dialog actual.
   * @param dialogData - Data del dialog.
   * @param cdRef - Detector de cambios.
   * @param dynamicDialogMessageService - Servicio de dialogs dinamicos.
   */
  public constructor(
    public dialogRef: MatDialogRef<DialogAddDataComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private cdRef: ChangeDetectorRef,
    public dynamicDialogMessageService: DynamicDialogMessageService
  ) {
    this.title = '';
    this.controls = [];
    this.buttonCancelText = dialogData.buttonCancelText || 'Cancelar';
  }

  /**
   * A callback method that is invoked immediately first time.
   */
  public ngOnInit(): void {
    this.controls = this.dialogData.controls;
    this.title = this.dialogData.formTitle;
  }

  /**
   * Se ejecuta renderizar el componente.
   */
  public ngAfterViewInit(): void {
    this.cdRef.detectChanges();
    if (this.dialogData.row) {
      this.formComponent?.formGroupGeneric.patchValue(this.dialogData.row);
      this.cdRef.detectChanges();
    }
  }

  /**
   * A callback method that performs custom clean-up, invoked immediately before a directive, pipe, or service instance is destroyed.
   */
  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /**
   * Crear el documento.
   */
  public submit() {
    if (this.dialogData.disableConfirmation) {
      if (this.dialogData.sendWithoutClose) {
        this.send.emit({
          row: this.formComponent?.formGroupGeneric.value,
          itemCreated: true,
        });
      } else {
        this.dialogRef.close({
          row: this.formComponent?.formGroupGeneric.value,
          itemCreated: true,
        });
      }
    } else {
      this.dynamicDialogMessageService
        .open({
          ...dialogMessageDefaultConfig,
          type: 'question',
          header: {
            text: '¿Deseas agregar esta información?',
            textAlign: 'text-center',
          },
          actions: [
            {
              text: 'No',
              actionValue: false,
              principal: false,
            },
            {
              text: 'Si',
              actionValue: true,
              principal: true,
            },
          ],
        })
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe(res =>
          res
            ? this.dialogRef.close({
                row: this.formComponent?.formGroupGeneric.value,
                itemCreated: true,
              })
            : null
        );
    }
  }

  /**
   * Cerrar modal.
   */
  public close(): void {
    if(this.dialogData.onlyClosed === true) {
      this.dialogRef.close();
      return;
    }
    const hasValue = Object.values(
      this.formComponent?.formGroupGeneric?.value
    ).find(values => (values ? true : false));
    if (hasValue) {
      this.dynamicDialogMessageService
        .open({
          type: 'question',
          header: {
            text: '¿Quieres descartar los cambios?',
          },
        })
        .afterClosed()
        .pipe(first(), takeUntil(this.destroy$))
        .subscribe(result => {
          if (result) {
            this.dialogRef.close();
          }
        });
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * Validar OTP.
   */
  public valideOtp() {
    if (this.dialogData.closeOnOtpVerification) {
      this.submit();
    }
  }

  /**
   * Reenvia el codigo otp.
   */
  public resendOtp() {
    this.dialogRef.close({
      resendOtp: true,
    });
  }
}
