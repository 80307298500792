import { Action, createReducer, on } from '@ngrx/store';
import { Catalogue } from '@wp-back-office/core/commons-backoffice';
import { ErrorHttp } from '../../../../models/errors.models';
import {
  KeyValueCurrencyType,
  KeyValueCurrencyTypeError,
  KeyValueCurrencyTypeSuccess,
  KeyValueTypeMarket,
  KeyValueTypeMarketError,
  KeyValueTypeMarketSuccess,
  KeyValueTypePlan,
  KeyValueTypePlanError,
  KeyValueTypePlanSuccess,
} from '../../actions';

import { resetInitialState } from '../../../../root/store/actions/contents/reset-initial-state.actions';
import { KeyValueTypeService, KeyValueTypeServiceError, KeyValueTypeServiceSuccess } from '../../actions/contents/catalogue-type-service.actions';

/**
 * Estado llave valor.
 */

export interface KeyValueTypeMarketState {
  /**
   * Catalogo.
   */
  catalogue?: Catalogue[];
  /**
   * Bandera de cargado.
   */
  loaded: boolean;
  /**
   * Bandera de cargando.
   */
  loading: boolean;
  /**
   * Errores.
   */
  error?: ErrorHttp;
}

export const TypeMarketInitialState: KeyValueTypeMarketState = {
  catalogue: undefined,
  loaded: false,
  loading: false,
  error: undefined,
};

const featureReducer = createReducer(
    TypeMarketInitialState,
  on(resetInitialState, () => ({ ...TypeMarketInitialState })),
  on(KeyValueTypeMarket, state => ({ ...state, loading: true })),
  on(KeyValueTypeMarketSuccess, (state, { keyValue }) => ({
    ...state,
    loading: false,
    loaded: true,
    catalogue: [...keyValue],
  })),
  on(KeyValueTypeMarketError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

/**
 * Reductor de .
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function keyValueTypeMarketReducer(
  state: KeyValueTypeMarketState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
