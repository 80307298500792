import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ActionsHumanTaskComponent } from './components/actions-human-task/actions-human-task.component';

import HumanTaskSdkComponent from './human-task-sdk.component';
import { CardProcessComponent } from './components/card-process/card-process.component';

/**
 *{AGREGUE DOCUMENTACION}.
 */
@NgModule({
  declarations: [
    HumanTaskSdkComponent,
    CardProcessComponent,
    ActionsHumanTaskComponent,
  ],
  imports: [CommonModule, HttpClientModule, NgbModule],
  exports: [
    HumanTaskSdkComponent,
    CardProcessComponent,
    ActionsHumanTaskComponent,
  ],
})
export class HumanTaskSdkModule {}
