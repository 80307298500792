import { Action, createReducer, on } from '@ngrx/store';
import { queqeActions } from '../../actions';
import { Groups, User } from '../../models/queqe.models';

/**
 * Estado de autenticación.
 */
export interface QueqeState {
  /**
   * Usuario.
   */
  user?: User;
  /**
   * Grupos.
   */
  groups?: Groups;
  /**
   * Cuenta.
   */
  count?: number;
}

export const initialGroupsState: QueqeState = {};

const featureReducer = createReducer(
  initialGroupsState,
  on(queqeActions.obtenerColas, state => ({
    ...state,
  })),
  on(queqeActions.obtenerColasExitoso, (state, { response }) => ({
    ...state,
    ...response,
  }))
);

/**
 * Reductor autenticación.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function QueqeReducer(state: QueqeState | undefined, action: Action) {
  return featureReducer(state, action);
}
