import {
  Component,
  Input,
  OnDestroy,
  AfterViewInit,
  forwardRef,
  ChangeDetectorRef,
  ViewChild,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ControlConfig } from '../../../../models/form-config.model';
import { DynamicFormService } from '../../../../services/dynamic-form.service';
import { DynamicTableCrudComponent } from '../../dynamic-table-crud/dynamic-table-crud.component';

/**
 *
 */
@Component({
  selector: 'wp-back-office-form-control-table',
  templateUrl: './form-control-table.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlTableComponent),
      multi: true,
    },
  ],
})
export class FormControlTableComponent
  implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor
{
  /**
   * Componente de tabla crud.
   */
  @ViewChild('tableID', {
    static: false,
    read: DynamicTableCrudComponent,
  })
  public tableComponent?: DynamicTableCrudComponent;
  /**
   * Configuracion del control.
   */
  @Input()
  public set control(value: ControlConfig | undefined) {
    if (value) {
      this.controlConfig = value;
      this._formControl = this.dynamicFormService.setValidators(value);
    }
  }

  /**
   * Form control del campo.
   */
  public _formControl: FormControl;

  /**
   * Configuracion del control variable.
   */
  public controlConfig!: ControlConfig;

  /**
   * Valor del control.
   */
  public value!: any[];
  /**
   * Destructor observables.
   */
  private destroy$: Subject<boolean>;

  /**
   * Crea una instancia de la clase.
   * @param dynamicFormService - Servicio de formularios dinamicos.
   * @param cdRef - Detector de cambios.
   */
  constructor(
    public dynamicFormService: DynamicFormService,
    public cdRef: ChangeDetectorRef
  ) {
    this._formControl = new FormControl('');
    this.destroy$ = new Subject();
  }

  /**
   * Se ejecuta al iniciar el componente.
   */
  public ngOnInit() {
    if (this.controlConfig)
      this._formControl = this.dynamicFormService.setValidators(
        this.controlConfig
      );
  }
  /**
   * Se ejecutar al iniciar el componente.
   */
  public ngAfterViewInit() {
    this.tableComponent?.dataTableSubmit
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.onChange(data);
      });
  }

  /**
   * Se ejecuta al destruir el componente.
   */
  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * Verirfica si es cambiado.
   * @param obj - Callback.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  public onChange = (obj: any) => {};

  /**
   * Verirfica si es tocado.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  public onTouch: any = () => {};

  /**
   * Verirfica si es escrito.
   * @param obj - Callback.
   * @throws Error.
   */
  public writeValue(obj: any): void {
    this._formControl.patchValue(obj);
    if (typeof obj === 'object') {
      this.value = obj;
    } else if (typeof obj === 'string' && obj !== '') {
      this.value = JSON.parse(obj);
    }
  }

  /**
   * Verirfica si es cambiado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Verirfica si es tocado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /**
   * Verifica si el campo fue deshabilitado.
   * @param isDisabled - Bandera.
   */
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this._formControl.disable();
    } else {
      this._formControl.enable();
    }
  }
}
