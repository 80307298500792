import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  forwardRef,
} from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextTransform } from '../models/form-config.model';

/**
 * Directiva TextTransform.
 */
@Directive({
  selector: '[wpBackOfficeTextTransform]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextTransformInputDirective),
    },
  ],
})
export class TextTransformInputDirective extends DefaultValueAccessor {
  /**
   * Enabled.
   */
  @Input() public wpBackOfficeTextTransform!: TextTransform | undefined;
  /**
   * Enabled.
   */
  @Input() public wpBackOfficePresition!: number | undefined;

  /**
   * HostListener.
   * @param $event - Evento.
   */
  @HostListener('input', ['$event']) public input($event: InputEvent) {
    const target = $event.target as HTMLInputElement;
    const start = target.selectionStart;
    const TEXT_TRANSFORM_CASES: {
      /**
       * Casos de TextTranformType.
       */
      [key in TextTransform]: () => void;
    } = {
      uppercase: () => {
        target.value = target.value.toUpperCase();
      },
      lowercase: () => {
        target.value = target.value.toLowerCase();
      },
      text: () => {
        target?.setSelectionRange(start, start);
      },
      number: () => {
        if ($event.data === '0' && Number(target.value) === 0) {
          target.value = target.value.match(/^0+\d/)
            ? target.value.replace(/^0+/, '')
            : target.value;
          if (target.value === '') target.value = '0';
        } else if (target.value.match(/^0+\d/)) {
          target.value = target.value.replace(/^0+/, '');
        } else if (
          this.wpBackOfficePresition !== undefined &&
          target.value.split('.')[1] !== undefined
        ) {
          if (target.value.split('.')[1].length > this.wpBackOfficePresition) {
            target.value = target.value.slice(0, -1);
          }
        }
      },
      alphanumeric: () => {
        target.value = target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
      }
    };

    this.wpBackOfficeTextTransform
      ? TEXT_TRANSFORM_CASES[this.wpBackOfficeTextTransform]()
      : undefined;

    this.onChange(target.value);
  }

  /**
   * Crea una instancia de la clase.
   * @param renderer - Renderer2.
   * @param elementRef - Referencia al elemento.
   */
  constructor(public renderer: Renderer2, public elementRef: ElementRef) {
    super(renderer, elementRef, false);
  }
}
