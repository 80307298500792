/**
 * Convierte un arreglo a diccionario.
 * @param array - Arreglo a convertir.
 * @param key - Clave.
 * @returns Diccionario.
 */
export function arrayToDictionary<T>(
  array: T[],
  key: keyof T
): Record<string, T> {
  return array.reduce((dictionary, item) => {
    const keyValue = item[key];
    dictionary[keyValue as string] = item;
    return dictionary;
  }, {} as Record<string, T>);
}
