import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueCities = createAction(
  '[KeyValueCities] Cargar Catalogos por id',
  props<{
    /**
     * Payload.
     */
    path: CatalogueType;
    /**
     * Codigo.
     */
    code: string;
    /**
     * Payload.
     */
    path1: CatalogueType;
  }>()
);

export const KeyValueCitiesSuccess = createAction(
  '[KeyValueCities] Crear Catalogos Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueCitiesError = createAction(
  '[KeyValueCities] Cargar Catalogos Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
