import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const userProfileActions = createActionGroup({
  source: 'userProfileActions',
  events: {
    /**
     * Resetear estado inicial.
     */
    'Get profile picture reset': emptyProps(),
    /**
     * Obtener imagen de perfil.
     */
    'Get profile picture': props<{
      /**
       * Id execution.
       */
      idUser: string;
    }>(),
    /**
     * Obtener imagen de perfil exitoso.
     */
    'Get profile picture success': props<{
      /**
       * Respuesta del store.
       */
      response: any;
    }>(),
    /**
     * Obtener imagen de perfil error.
     */
    'Get profile picture error': props<{
      /**
       * Respuesta erronea del store.
       */
      error: any;
    }>(),
  },
});
