import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DynamicHeaderCardComponent } from './components/dynamic-header-card/dynamic-header-card.component';
import { MatCardModule } from '@angular/material/card';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { SharedDynamicComponentsModule } from '../shared-dynamic';

/**
 *{AGREGUE DOCUMENTACION}.
 */
@NgModule({
  declarations: [DynamicHeaderCardComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatCardModule,
    ClipboardModule,
    SharedDynamicComponentsModule,
  ],
  exports: [DynamicHeaderCardComponent],
})
export class DynamicHeaderCardModule {}
