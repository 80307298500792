import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Enviroment } from '../interfaces/enviroment.interface';

/**
 * Servicio de parametros en entidades.
 */
@Injectable()
export class ParametricEntitiesService {
  /**
   * Crea una instancia de ParametricEntitiesService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}

  /**
   * Obtener entidad.
   * @param entityId - Identificador entidad.
   * @param versionId - Identificador de version id.
   * @returns Observable.
   */
  public getEntity(entityId: string, versionId: string): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/parametricEntity/${entityId}/${versionId}`
    );
  }
}
