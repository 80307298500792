import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WorkflowService } from '@wp-back-office/core/workflow-sdk';
import { mergeMap, map, catchError, of } from 'rxjs';
import { ResponseStart } from '../../../models';
import { TaskInformation } from '../../../models/contents/task-form.models';

import * as actionsEffects from '../../actions';

/**
 * Efecto de tareas.
 */
@Injectable()
export class TaskSuccessEffects {
  /**
   * Crea una instancia de TaskSuccessEffects.
   * @param actions$ - Actions.
   * @param workflowService - Servicio de workflowservice.
   */
  constructor(
    private actions$: Actions,
    private workflowService: WorkflowService
  ) {}

  /**
   *
   */
  public TaskSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.TaskSuccess),
      mergeMap(params =>
        this.workflowService.successTask(params.object).pipe(
          map((object: ResponseStart) =>
            actionsEffects.TaskSuccessSuccess({ object })
          ),
          catchError(err =>
            of(actionsEffects.TaskSuccessError({ payload: err }))
          )
        )
      )
    )
  );

  /**
   *
   */
  public TaskForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.TaskSchema),
      mergeMap(params =>
        this.workflowService.getFormTask(params.paramsPost).pipe(
          map((object: TaskInformation) =>
            actionsEffects.TaskSchemaSuccess({
              taskPendingInfo: object,
            })
          ),
          catchError(err =>
            of(actionsEffects.TaskSchemaError({ payload: err }))
          )
        )
      )
    )
  );
}
