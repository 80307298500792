import {
  Component,
  forwardRef,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
  ViewChildren,
  QueryList,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  FormGroup,
  FormBuilder,
  Validators,
  ControlValueAccessor,
} from '@angular/forms';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import {
  CataloguePath,
  CountryState,
} from '@wp-back-office/app/global-information';
import { CatalogueTasksService } from '@wp-back-office/core/commons-backoffice';
import {
  Catalogue,
  CatalogueState,
  createSelectorInject,
} from '@wp-back-office/core/store';
import {
  ControlConfig,
  DynamicFormService,
} from '@wp-back-office/shared/dynamic-components';
import {
  Subject,
  takeUntil,
  Observable,
  switchMap,
  tap,
  catchError,
  of,
  filter,
  take,
  debounceTime,
} from 'rxjs';
import { DynamicSnackBarService } from '@wp-back-office/shared/dynamic-components';
import { TemplateGeographicLocationService } from '../../../services/template-geographic-location.service';
import { FormControlAutocompleteComponent } from '../../components/controls/form-control-autocomplete/form-control-autocomplete.component';
import { omit } from 'lodash';

/**
 * Plantilla de ubicacion geografica.
 */
@Component({
  selector: 'wp-back-office-template-geographic-location',
  templateUrl: './template-geographic-location.component.html',
  styleUrls: ['./template-geographic-location.component.scss'],
  providers: [
    ComponentStore,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TemplateGeographicLocationComponent),
      multi: true,
    },
  ],
})
export class TemplateGeographicLocationComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  /**
   * Id unico del formulario.
   */
  @Input()
  public uniqueIdForm!: string;
  /**
   * Evento de formulario valido.
   */
  @Input()
  public set control(value: ControlConfig | undefined) {
    if (value) {
      this.valueControl = value;
      setTimeout(() => {
        this.loadControl();
      }, 2000);
    }
  }

  /**
   * Query list de controles de seleccion.
   */
  @ViewChildren(FormControlAutocompleteComponent)
  formControlAutocompleteComponents!: QueryList<FormControlAutocompleteComponent>;

  /**
   * Valor del control.
   */
  public valueControl: ControlConfig | undefined;
  /**
   * Evento de formulario valido.
   */
  @Output()
  public submitValid: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Valor de controles direccion.
   */
  public controls!: ControlConfig[] | undefined;
  /**
   * Valor ingresado.
   */
  public patchvalue?: any;
  /**
   * Destructor sujeto.
   */
  private destroy$ = new Subject();

  /**
   * Evento de formulario valido.
   */
  @Input()
  public value!: any;

  /**
   * Valor del control.
   */
  // eslint-disable-next-line @nrwl/nx/workspace/doc-class-property
  @Input()
  public disabledControl: boolean;
  /**
   * Observable de departamentos.
   */
  public readonly departments$ = this.componentStore.select(
    state => state.departments
  );
  /**
   * Obtener los departamentos.
   */
  public readonly getDepartments = this.componentStore.effect(
    (path$: Observable<CataloguePath>) => {
      return path$.pipe(
        switchMap(params =>
          this.catalogueTasksService
            .getCatalogueTasks(
              params.path + '/' + params.code + '/' + params.path2
            )
            .pipe(
              tap({
                next: (departments: any) => this.updateDepartments(departments),
                error: e => this.dynamicSnackBarService.Open(e, 'error'),
              }),
              catchError(err => {
                return of({ error: err });
              })
            )
        )
      );
    }
  );
  /**
   * Actualizar los departmentos.
   */
  private readonly updateDepartments = this.componentStore.updater(
    (state, departments: any) => ({
      ...state,
      departments: departments,
    })
  );
  /**
   * Obtener las ciudades.
   */
  public readonly getCities = this.componentStore.effect(
    (path$: Observable<CataloguePath>) => {
      return path$.pipe(
        switchMap(params =>
          this.catalogueTasksService
            .getCatalogueTasks(
              params.path + '/' + params.code + '/' + params.path1
            )
            .pipe(
              tap({
                next: (cities: any) => this.updateCities(cities),
                error: e => this.dynamicSnackBarService.Open(e, 'error'),
              }),
              catchError(err => {
                return of({ error: err });
              })
            )
        )
      );
    }
  );
  /**
   * Actualizar las ciudades.
   */
  private readonly updateCities = this.componentStore.updater(
    (state, cities: Catalogue[]) => ({
      ...state,
      cities: cities,
    })
  );
  /**
   * Observable de ciudades.
   */
  public readonly cities$ = this.componentStore.select(state => state.cities);
  /**
   * Componente formulario dinamico.
   */
  public geographicLocationForm = new FormGroup<any>({});

  /**
   * Crea una instancia TemplateGeographicLocationComponent.
   * @param cdRef - Deteccion de cambios.
   * @param dynamicSnackBarService - Servicio de snackbar.
   * @param dynamicFormService - Servicio de formularios dinamicos.
   * @param storeCatalogue - Tienda catalogos.
   * @param componentStore - Tienda del componente.
   * @param catalogueTasksService - Servicio de catalogis.
   * @param templateGeographicLocationService - Servico para obtener los formularios.
   * @param formBuilder - Consctructor de formularios reactivos.
   */
  public constructor(
    private cdRef: ChangeDetectorRef,
    private dynamicSnackBarService: DynamicSnackBarService,
    public dynamicFormService: DynamicFormService,
    private readonly storeCatalogue: Store<CatalogueState>,
    private readonly componentStore: ComponentStore<CountryState>,
    private readonly catalogueTasksService: CatalogueTasksService,
    private readonly templateGeographicLocationService: TemplateGeographicLocationService,
    private formBuilder: FormBuilder
  ) {
    this.patchvalue = undefined;
    this.geographicLocationForm = this.formBuilder.group({
      country: [''],
      state: [''],
      city: [''],
    });
    this.disabledControl = false;
  }

  /**
   * A callback method that is invoked immediately first time.
   */
  public ngOnInit(): void {
    this.componentStore.setState({
      cities: [],
      countries: [],
      departments: [],
    });
  }

  private childControlsValidation(
    controls: QueryList<FormControlAutocompleteComponent>
  ) {
    controls?.forEach(control => {
      const key = control.controlConfig.key;

      if (key) {
        let _errors = {
          ...(this.geographicLocationForm.controls[key].errors || {}),
          notReady: true,
        };

        let errors = omit(_errors, 'notReady');

        if (
          control._formControl.valid &&
          control.controlConfig.options &&
          control.controlConfig.options?.length > 0
        ) {
          this.geographicLocationForm.controls[key].setErrors(
            Object.keys(errors).length === 0 ? null : errors
          );
        } else {
          this.geographicLocationForm.controls[key].setErrors(
            Object.keys(errors).length === 0
              ? null
              : { ...errors, notReady: true }
          );
        }
        
        this.cdRef.detectChanges();
      }
    });
  }

  /**
   * Carga la configuracion de los controles.
   */
  public loadControl() {
    if (this.valueControl?.geographicLocationOptions?.hideCountry) {
      this.geographicLocationForm = this.formBuilder.group({
        state: [''],
        city: [''],
      });
    }

    if (this.valueControl?.geographicLocationOptions?.hideStateAndCity) {
      this.geographicLocationForm = this.formBuilder.group({
        country: ['', Validators.required],
      });
    }

    this.controls =
      this.templateGeographicLocationService.getGeographicLocation(
        this.valueControl?.geographicLocationOptions,
        undefined,
        this.valueControl
      );

    if (this.valueControl?.geographicLocationOptions?.hideCountry) {
      this.onChangeForm('country', { code: 'CO', description: 'Colombia' });
    } else {
      this.geographicLocationForm.controls['country'].valueChanges
        .pipe(takeUntil(this.destroy$), debounceTime(100))
        .subscribe((value: any) => {
          this.onChangeForm('country', value);
        });
    }

    if (!this.valueControl?.geographicLocationOptions?.hideStateAndCity) {
      this.geographicLocationForm.controls['state'].valueChanges
        .pipe(takeUntil(this.destroy$), debounceTime(100))
        .subscribe((value: any) => {
          this.onChangeForm('state', value);
        });
    }
    this.geographicLocationForm.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(100))
      .subscribe(() => {
        this.childControlsValidation(this.formControlAutocompleteComponents)
        this.onChangeFormGeographic();
      });

    if (this.valueControl?.value) {
      this.geographicLocationForm.patchValue(this.valueControl?.value);
    }

    this.storeCatalogue
      .select(createSelectorInject('KeyValueCountries', 'Catalogue'))
      .pipe(
        filter((data: any) => {
          if (data.loaded && !data.loading) {
            return true;
          }
          return false;
        }),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(({ catalogue }) => {
        if (catalogue) {
          const newControls = this.controls?.map(oldControl => {
            const newControlDepartments: ControlConfig = {
              ...oldControl,
              options: catalogue,
            };

            return oldControl.key === 'country'
              ? newControlDepartments
              : oldControl;
          });

          this.controls = newControls;
          this.cdRef.detectChanges();
        }
      });

    if (this.value) {
      this.writeValue(this.value);
    }

    this.controls.forEach(control => {
      const validators = this.dynamicFormService.getValidators(control);
      this.geographicLocationForm
        .get(control.key?.trim() || '')
        ?.setValidators(validators);

      this.geographicLocationForm
        .get(control.key?.trim() || '')
        ?.updateValueAndValidity();
    });
  }

  /**
   * Envia el valor del formulario.
   */
  public onChangeFormGeographic() {
    if (this.geographicLocationForm.invalid) {
      this.onChange('');
    } else {
      this.onChange(this.geographicLocationForm.getRawValue());
    }
  }

  /**
   * A callback method that performs custom clean-up, invoked immediately before a directive, pipe, or service instance is destroyed.
   */
  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /**
   * Alternar deshabilitación del control.
   * @param nameControls - Nombre del control.
   * @param disabled - Estado para el control.
   */
  public toogleDisabled(nameControls: string, disabled: boolean): void {
    const control = this.geographicLocationForm.get(nameControls);
    if (control) {
      if (disabled) {
        control.disable();
      } else {
        control.enable();
      }
    }
  }

  /**
   * Configuracion de los controles dropdown carga de valores con el evento values changes.
   * @param keycontrol - Cambio.
   * @param value - Cambio.
   */
  public onChangeForm(keycontrol: string, value: Catalogue): void {
    switch (keycontrol) {
      case 'country':
        this.changeState(value);
        this.toogleDisabled('state', false);
        break;
      case 'state':
        this.changeCity(value);
        this.toogleDisabled('city', false);
        break;
      default:
        break;
    }
    this.onChangeFormGeographic();
  }

  /**
   * Reinicia las opciones.
   * @param onlyCity - Valida si solo se reinicia la ciudad.
   */
  public resetOptions(onlyCity?: boolean) {
    const controls = this.controls || [];
    const stateIndex = controls.findIndex(control => control.key === 'state');
    if (!onlyCity && stateIndex > -1) {
      controls[stateIndex] = {
        ...controls[stateIndex],
        options: [],
        validators: {
          ...controls[stateIndex].validators,
          loading: false,
          readonly: false,
        },
      };
    }

    const cityIndex = controls.findIndex(control => control.key === 'city');

    if (cityIndex > -1) {
      controls[cityIndex] = {
        ...controls[cityIndex],
        options: [],
        validators: {
          ...controls[cityIndex].validators,
          loading: false,
          readonly: false,
        },
      };
    }

    this.controls = controls;
    this.cdRef.detectChanges();
  }

  /**
   * Configuracion de los controles dropdown carga de valores departamentos.
   * @param country - Codigo de pais.
   */
  private changeState(country: any): void {
    this.updateDepartments(true);
    this.updateCities([]);
    this.resetOptions();
    this.geographicLocationForm?.controls['state']?.patchValue(undefined);
    this.geographicLocationForm?.controls['city']?.patchValue(undefined);

    if (country?.code) {
      this.getDepartments({
        path: 'COUNTRIES',
        code: country.code,
        path2: 'STATES',
      });
      const controls = this.controls || [];
      const stateIndex = controls.findIndex(control => control.key === 'state');
      if (stateIndex > -1) {
        controls[stateIndex].validators = {
          ...controls[stateIndex].validators,
          loading: true,
          readonly: true,
        };
        this.controls = controls;
        this.cdRef.detectChanges();

        this.departments$
          .pipe(
            debounceTime(500),
            filter(data => {
              if (typeof data === 'object' && data?.length > 0) {
                return true;
              } else if (data.length === 0) {
                this.dynamicSnackBarService.Open(
                  'El país seleccionado no tiene estados registrados.',
                  'warning'
                );
                controls[stateIndex] = {
                  ...controls[stateIndex],
                  options: [],
                  validators: {
                    ...controls[stateIndex].validators,
                    loading: false,
                    readonly: true,
                  },
                };

                const cityIndex = controls.findIndex(
                  control => control.key === 'city'
                );

                controls[cityIndex] = {
                  ...controls[cityIndex],
                  options: [],
                  validators: {
                    ...controls[cityIndex].validators,
                    loading: false,
                    readonly: true,
                  },
                };

                this.controls = controls;
                this.cdRef.detectChanges();
                return false;
              }
              return false;
            }),
            take(1),
            takeUntil(this.destroy$)
          )
          .subscribe((data: any[]) => {
            const newControls = controls.map(oldControl => {
              const newControlDepartments: ControlConfig = {
                ...oldControl,
                options: data,
                value:
                  this.patchvalue && this.patchvalue['state']
                    ? this.patchvalue['state']
                    : undefined,
                validators: {
                  ...controls[stateIndex].validators,
                  loading: false,
                  readonly: false,
                },
              };

              return oldControl.key === 'state'
                ? newControlDepartments
                : oldControl;
            });
            this.controls = newControls;
            this.cdRef.detectChanges();

            this.onChangeFormGeographic();
            this.cdRef.detectChanges();
            if (
              this.patchvalue &&
              this.patchvalue['state'] &&
              this.geographicLocationForm.controls['state']
            ) {
              this.onChangeFormGeographic();
              this.cdRef.detectChanges();
              if (!this.patchvalue['city']) {
                this.patchvalue = undefined;
              }
            }
            return false;
          });
      }
    }
  }

  /**
   * Configuracion de los controles dropdown carga de valores ciudades.
   * @param state - Codigo de departamento.
   */
  private changeCity(state: any) {
    this.geographicLocationForm.controls['city'].patchValue(undefined);
    this.updateCities([]);
    this.resetOptions(true);

    if (state?.code) {
      this.getCities({
        path: 'STATES',
        code: state.code,
        path1: 'CITIES',
      });
      const controls = this.controls || [];
      const cityIndex = controls.findIndex(control => control.key === 'city');
      if (cityIndex > -1) {
        controls[cityIndex].validators = {
          ...controls[cityIndex].validators,
          loading: true,
          readonly: true,
        };
        this.controls = controls;
        this.cdRef.detectChanges();
      }

      this.cities$
        .pipe(
          debounceTime(500),
          filter(data => {
            if (data.length > 0) {
              return true;
            }
            return false;
          }),
          take(1),
          takeUntil(this.destroy$)
        )
        .subscribe((data: any[]) => {
          const newControls = controls.map(oldControl => {
            const newControlCity: ControlConfig = {
              ...oldControl,
              options: data,
              value:
                this.patchvalue && this.patchvalue['city']
                  ? this.patchvalue['city']
                  : undefined,
              validators: {
                ...controls[cityIndex].validators,
                loading: false,
                readonly: false,
              },
            };
            return oldControl.key === 'city' ? newControlCity : oldControl;
          });
          this.controls = newControls;
          this.cdRef.detectChanges();
          this.onChangeFormGeographic();
          if (
            this.patchvalue &&
            this.patchvalue['state'] &&
            this.patchvalue['city'] &&
            this.geographicLocationForm.controls['city']
          ) {
            this.cdRef.detectChanges();
          }
          this.patchvalue = undefined;
          return false;
        });
    }
  }

  /**
   * Actualiza los dropdown con el id del objeto.
   * @param c1 - Valor inicial.
   * @param c2 - Valor final.
   * @returns Estado booleano.
   */
  public compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.code === c2.code : c1 === c2;
  }

  /**
   * Verirfica si es cambiado.
   * @param obj - Callback.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  public onChange = (obj: any) => {};

  /**
   * Verirfica si es tocado.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouch = () => {};

  /**
   * Verirfica si es escrito.
   * @param obj - Callback.
   * @throws Error.
   */
  public writeValue(obj: any): void {
    if (obj && obj !== '' && typeof obj == 'object') {
      this.patchvalue = obj;
      if (obj['country'] && this.geographicLocationForm.controls['country']) {
        this.geographicLocationForm.controls['country'].patchValue({
          ...obj['country'],
        });
      }
    } else {
      this.geographicLocationForm.reset();
    }
  }

  /**
   * Verirfica si es cambiado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /**
   * Verirfica si es tocado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /**
   * Estado.
   * @param isDisabled - Bandera.
   */
  public setDisabledState(isDisabled: boolean): void {
    this.disabledControl = isDisabled;
    if (this.disabledControl) {
      this.geographicLocationForm.disable();
    } else {
      this.geographicLocationForm.enable();
    }
  }
}
