import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';


export const KeyValueCurrencyType = createAction(
  '[KeyValueCurrencyType] Cargar tipos de divisa',
  props<{
    /**
     * Ruta.
     */
    path: CatalogueType;
  }>()
);

export const KeyValueCurrencyTypeSuccess = createAction(
  '[KeyValueCurrencyType] Crear tipos de divisa Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueCurrencyTypeError = createAction(
  '[KeyValueCurrencyType] Cargar tipos de divisa Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
