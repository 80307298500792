import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Enviroment } from '../interfaces/enviroment.interface';

/**
 * Servicio de documentos tareas.
 */
@Injectable()
export class DocumentsTasksService {
  /**
   * Crea una instancia de DocumentsTasksService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}

  /**
   * Obtiene las url temporales de la app.
   * @param keys - LLaves.
   * @returns Observable.
   */
  public getTemporyUrls(keys: string[]): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.urlDocuments}/documents/create/temporary-urls`,
      { keys }
    );
  }
}
