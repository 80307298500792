/**
 * Detecta que parametro ha cambiado.
 * @param prev - Valor anterior.
 * @param curr - Valor presente.
 * @returns - Parametro cambiado.
 */
export function detectChanges(prev: any, curr: any): OutputDetectChanges {
  const keysPrev = Object.keys(prev);
  const keysCurr = Object.keys(curr);
  if (keysPrev.length !== keysCurr.length) {
    return { changed: true, key: 'LENGTH_PARAMS' };
  }
  for (const key of keysCurr) {
    if (prev[key] !== curr[key]) {
      return { changed: true, key: key };
    }
  }
  return { changed: false };
}
/**
 * Interface de Salida de deteccion de cambios.
 */
export interface OutputDetectChanges {
  /**
   * Flag de cambio.
   */
  changed: boolean;
  /**
   * Llave que ha cambiado.
   */
  key?: string;
}
