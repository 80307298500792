import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ResponseAWS,
  ResponseAWSData,
  ResponseData,
} from '../models/response-aws.model';
import {
  Room,
  RoomDelete,
  RoomInput,
  RoomSellers,
  RoomsOfficeId,
  RoomsUserId,
  RoomsUserTable,
  RoomsZones,
  RoomTable,
  Zones,
} from '../models/rooms.model';
import { SellersTable } from '../models/sellers.model';
import { RoomsMapper } from '../mappers/rooms.mapper';
import * as marketModels from '../models/market.model';
import { RoomBulk } from '../models/market.model';
/**
 * Servicio de planes financieros.
 */
@Injectable()
export class RoomsService {
  /**
   * Mapeador.
   */
  private mapper = new RoomsMapper();
  /**
   * Crea una instancia de WorkflowService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}

  /**
   * Obtiene la lista de usuarios.
   * @param code - Codigo de la oficina.
   * @param description - Valor a filtrar.
   * @param page - Columna a filtrar.
   * @param pageSize - Tamaño de la pagina.
   * @returns Observable.
   */
  public getRooms(
    code: string,
    description?: string,
    page?: number,
    pageSize?: number
  ): Observable<ResponseAWS<marketModels.Room>> {
    let params: any = {};
    description ? (params = { ...params, description }) : null;
    page ? (params = { ...params, page }) : null;
    pageSize ? (params = { ...params, pageSize }) : null;
    return this.http.get<ResponseAWS<marketModels.Room>>(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/office/${code}/rooms`,
      {
        params: params,
      }
    );
  }

  /**
   * Obtiene la lista de planes financieros.
   * @param codeMarket - Codigo de mercado.
   * @param codeOffice - Codigo de oficina.
   * @param codeOfficeGroup - Codigo de grupo de oficinas.
   * @param city - Ciudad.
   * @param state - Departamento.
   * @param page - Pagina.
   * @param pageSize - Tamaño de la pagina.
   * @returns - Observable.
   */
  public getRoomsQuery(
    codeMarket?: string,
    codeOffice?: string,
    codeOfficeGroup?: string,
    city?: string,
    state?: string,
    page?: number,
    pageSize?: number
  ): Observable<ResponseAWSData<marketModels.RoomSegmentation>> {
    let params = {};
    codeMarket ? (params = { ...params, code_market: codeMarket }) : null;
    codeOffice ? (params = { ...params, code_office: codeOffice }) : null;
    codeOfficeGroup
      ? (params = { ...params, code_office_group: codeOfficeGroup })
      : null;
    city ? (params = { ...params, city }) : null;
    state ? (params = { ...params, state }) : null;
    page ? (params = { ...params, page }) : null;
    pageSize ? (params = { ...params, pageSize }) : null;
    return this.http
      .get<ResponseAWSData<marketModels.RoomSegmentation>>(
        `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/room/query`,
        {
          params: params,
        }
      )
      .pipe(
        map(response => ({
          ...response,
          data: {
            ...response.data,
            items: response.data.items.map(item => ({
              ...item,
              description_markets: item.markets
                .map(market => market.description_market)
                .join(', '),
            })),
          },
        }))
      );
  }
  /**
   * Obtiene la lista de planes financieros.
   * @param codes - Lista codigo de salas.
   * @returns - Observable.
   */
  public getDetailRoomsBulk(codes: string[]): Observable<ResponseAWSData<any>> {
    return this.http
      .post<ResponseData<(RoomBulk | null)[]>>(
        `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/rooms`,
        {
          roomsIds: codes,
        }
      )
      .pipe(
        map(response => {
          const rooms: any[] = [];
          response.data.forEach(data => {
            if (data) {
              rooms.push(data);
            }
          });

          return {
            ...response,
            data: {
              count: rooms.length,
              items: rooms.map(item => this.mapper.mapFrom(item)),
            },
          } as ResponseAWSData<Room>;
        })
      );
    // return of({
    //   data: {
    //     count: codes.length,
    //     items: codes.map(
    //       code =>
    //         ({
    //           city: '001',
    //           code_market: 123,
    //           code_office: 456,
    //           code_office_group: 789,
    //           code_room: Number(code),
    //           description_group: 'MOCK GROUP',
    //           description_market: 'MOCK MARKET',
    //           description_office: 'MOCK OFFICE',
    //           description_room: 'MOCK ROOM',
    //           state: '11',
    //         } as Room)
    //     ),
    //   },
    //   status: true,
    // } as ResponseAWSData<Room>);
  }

  /**
   * Acutaliza las salas de un usuario o asigna un vendedor a una sala.
   * @param code - Código del vendedor.
   * @param rooms - Código de la sala.
   * @returns Confirmación.
   */
  public putRoomSellers(code: string, rooms?: number[]): Observable<any> {
    return this.http.put(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/rba/user/${code}/rooms`,
      { rooms: rooms || [] }
    );
  }

  /**
   * Acutaliza las salas de un usuario o asigna un vendedor a una sala.
   * @param _codeUser - Id del usuario.
   * @param _codeRoom - Id de la sala.
   * @returns Confirmación.
   */
  public postRoomSellers(
    _codeUser: string,
    _codeRoom: string
  ): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/rba/user/room`,
      { codeUser: _codeUser, codeRoom: _codeRoom }
    );
  }

  /**
   * Obtiene la lista de planes financieros.
   * @param code - Codigo del usuario.
   * @param page - Pagina.
   * @param pageSize - Tamaño de la pagina.
   * @returns - Observable.
   */
  public getUserRooms(
    code: string,
    page?: number,
    pageSize?: number | string
  ): Observable<ResponseAWS<RoomsUserId>> {
    let params = {};
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    return this.http.get<ResponseAWS<RoomsUserId>>(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/rba/user/${code}/rooms`,
      {
        params: params,
      }
    );
  }

  /**
   * Obtiene las zonas de las salas.
   * @param description - Filtro de zona por descripción.
   * @param page - Página.
   * @param pageSize - Tamaño de la página.
   * @returns - Observable.
   */
  public getRoomsZones(
    description?: string,
    page?: number,
    pageSize?: number
  ): Observable<RoomsZones[]> {
    let params = {};
    if (description !== undefined) {
      params = { ...params, description };
    }
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    this.enviroment.endpoints.url;
    return this.http
      .get<ResponseAWS<Zones>>(
        `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/room/zone`,
        {
          params: params,
        }
      )
      .pipe(
        map(response =>
          response.items.map(item => ({
            code: item['code_room_zone'],
            description: item.description,
            object: item,
          }))
        )
      );
  }

  /**
   * Creacion de sala.
   * @param room - Datos de la sala a crear.
   * @returns Observable.
   */
  public createRoom(room: any): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/room`,
      room
    );
  }

  /**
   * Actualizar sala por Id.
   * @param code - Código de la sala.
   * @param room - Datos de la sala a crear.
   * @returns Observable.
   */
  public updateRoom(code: string, room: any): Observable<any> {
    return this.http.put(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/room/${code}`,
      room
    );
  }

  /**
   * Eliminar Sala.
   * @param code - Código de la sala.
   * @returns Observable.
   */
  public deleteRoom(code: number): Observable<any> {
    return this.http.delete<RoomDelete>(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/room/${code}`
    );
  }

  /**
   * Obtiene la lista de planes financieros.
   * @param room - Código de la sala.
   * @param page - Pagina.
   * @param pageSize - Tamaño de la pagina.
   * @returns - Observable.
   */
  public getRoomSellersList(
    room: string,
    page?: number,
    pageSize?: number
  ): Observable<RoomSellers[]> {
    let params = {};
    params = { ...params, room };
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    this.enviroment.endpoints.url;
    return this.http
      .get<ResponseAWS<SellersTable>>(
        `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/room/seller`,
        {
          params: params,
        }
      )
      .pipe(
        map(response =>
          response.items.map(item => ({
            item,
            name: String(item.email),
            identification: `${item.document_type} ${item.document_number}`,
            office: item.officeDescription,
            room: String(item.code_room),
          }))
        )
      );
  }
}
