<mat-card class="mb-4 sale-info dynamic-header-card border-none">
  <mat-card-header class="d-flex component-header" *ngIf="title">
    <mat-card-title> {{ title | spaceMayus }} </mat-card-title>
    <mat-card-subtitle>{{ subtitle | spaceMayus }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content
    class="component-content"
    *ngIf="(headerVars || []).length > 0">
    <div class="row">
      <div
        class="col-sm-12 col-lg-4 col-xxl-3 header-container"
        *ngFor="let headerVar of headerVars || []">
        <mat-card-subtitle class="card-subtitle">
          <div
            [matTooltip]="headerVar.code || ''"
            [matTooltipPosition]="'below'"
            class="ellipsis d-inline-flex">
            <b>{{ headerVar.description }}:&nbsp;&nbsp;</b>
            <i class="ellipsis" [ngStyle]="headerVar?.ngStyle || null">
              {{ headerVar.code }}
            </i>
          </div>
          <button
            mat-icon-button
            (click)="textCopied(headerVar.code)"
            *ngIf="headerVar.copy"
            [cdkCopyToClipboard]="headerVar.code || ''"
            [matTooltip]="'Copiar'">
            <mat-icon>content_copy</mat-icon>
          </button>
        </mat-card-subtitle>
      </div>
    </div>
  </mat-card-content>
</mat-card>
