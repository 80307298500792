import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Catalogue, Enviroment } from '@wp-back-office/core/commons-backoffice';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaymentFrecuencyMapper } from '../mappers/payment-frecuency.mapper';
import { PaymentFrecuencyOutput } from '../models/payment-frecuency.model';
import { ResponseAWS } from '../models/response-aws.model';
/**
 * Servicio de planes financieros.
 */
@Injectable()
export class PaymentFrecuencyService {
  /**
   * Mapeador.
   */
  private mapper = new PaymentFrecuencyMapper();
  /**
   * Crea una instancia de WorkflowService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}
  /**
   * Obtiene la lista de planes financieros.
   * @param code_amortization - Codigo de amortización.
   * @param description - Filtro descripción.
   * @param page - Pagina.
   * @param pageSize - Tamaño de la pagina.
   * @param paginationToken - Token para la paginacion.
   * @returns - Observable.
   */
  public getPaymentFrecuencyList(
    code_amortization: number,
    description?: string,
    page?: number,
    pageSize?: number,
    paginationToken?: number
  ): Observable<Catalogue[]> {
    let params = {};
    let paginationCounter = 0;
    if (description !== undefined) {
      params = { ...params, description };
    }
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    if (paginationToken !== undefined) {
      paginationCounter = paginationToken + 1;
      params = { ...params, page: paginationCounter };
    }
    return this.http
      .get<ResponseAWS<PaymentFrecuencyOutput>>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/payment-frecuency/amortization/${code_amortization}`,
        {
          params: params,
        }
      )
      .pipe(
        map(response => response.items.map(item => this.mapper.mapFrom(item)))
      );
  }
}
