import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import * as reducers from './reducers';

/**
 * Estadp general.
 */
export interface AppState {
  /**
   * Logueo.
   */
  Loggin: reducers.LogginState;
  /**
   * Bandera de autenticacion.
   */
  IsAuth: reducers.IsAuthState;
  /**
   * Pool user.
   */
  PoolUser: reducers.CurrentPoolUserState;
}

export const AppReducers: ActionReducerMap<AppState> = {
  Loggin: reducers.LogginReducer,
  IsAuth: reducers.isAuthReducer,
  PoolUser: reducers.CurrentPoolUserReducer,
};

/**
 * Metodo para crear un selector e inyectarlo.
 * @param stateName - Nombre del estado que se quiere tomar.
 * @param feature - Estado hijo.
 * @returns Selector con el nombre de estado recibido.
 */
export function createSelectorInject(stateName: string, feature?: string) {
  if (feature && feature !== 'AppState') {
    const featureSelector = createFeatureSelector<any>(feature);
    return createSelector(featureSelector, (state: any) => {
      return state[stateName];
    });
  }
  return createSelector(
    (state: any) => state[stateName],
    (state: any) => state
  );
}
