<ng-template [ngIf]="controlConfig && controlConfig.key && controlConfig.type">
  <mat-radio-group
    [class.mat-card]="controlConfig.radioConfig?.buttonLayout"
    class="full-width"
    [formControl]="_formControl"
    [value]="_formControl.value"
    ngClass="mb-4">
    <div class="row">
      <mat-label
        [attr.for]="controlConfig.key || ''"
        class="{{
          controlConfig.radioConfig?.buttonLayout
            ? 'mat-card-subtitle subtitle'
            : ''
        }}">
        {{ controlConfig.label || '' }}
      </mat-label>
    </div>
    <div
      [class.row]="!horizontal"
      [class.d-flex]="horizontal"
      [class.flex-flow-wrap]="horizontal"
      [class.my-2]="horizontal">
      <div
        [class.col-12]="!horizontal"
        class="radio-option"
        *ngFor="let opt of controlConfig.options">
        <mat-radio-button
          [disabled]="
            controlConfig.validators?.readonly ||
            controlConfig.validators?.disabled
          "
          [value]="opt">
          {{ opt.description }}
          <mat-chip-option *ngFor="let chip of opt.chipLabels" labelChip>
            {{ chip }}
          </mat-chip-option>
        </mat-radio-button>
      </div>
    </div>
  </mat-radio-group>
</ng-template>
