<div *ngIf="show">
  <div class="la-3x la-ball-fall loader">
    <div class="loader-div"></div>
    <div class="loader-div"></div>
    <div class="loader-div"></div>
  </div>
</div>

<div class="row text-center dialog-header">
  <h1 class="loader text-center">{{ text || '' }}</h1>
</div>
