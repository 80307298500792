import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

/**
 * Servicio de pruebas tareas pendientes.
 */
@Injectable()
export class PendingTasksMockService {
  /**
   * Crea una instancia de PendingTasksMockService.
   */
  constructor() {}

  /**
   * Obtiene la tarea su llave.
   * @returns Observable.
   */
  public getTaskKey(): Observable<any> {
    return of({
      process: 'tests',
      dateCreated: '2021-02-24T21:37:44.568Z',
      dateExpiration: '2021-03-16T21:37:44.000Z',
      payload: {
        name: 'Santiago',
        surnames: 'Sanche Lopez',
        salary: "$2'500.000",
        requested: "$10'000.000",
        direction: 'Calle 48 Sur #71-38',
        city: 'Medellin',
      },
      id: 'ae8b2810-64a8-46fc-8db9-3dc1b5888c9b',
    });
  }
}
