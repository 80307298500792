/**
 *
 * @param obj1
 * @param obj2
 * @param path
 * @returns
 */
export function deepObjectDifference(
  obj1: any,
  obj2: any,
  path: string[] = []
): Record<string, any> {
  const differences: Record<string, any> = {};
  for (const key in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, key)) {
      const newPath = [...path, key];
      if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
        differences[newPath.join('.')] = {
          oldValue: obj1[key],
          newValue: undefined,
        };
      } else if (
        typeof obj1[key] === 'object' &&
        typeof obj2[key] === 'object'
      ) {
        const nestedDifferences = deepObjectDifference(
          obj1[key],
          obj2[key],
          newPath
        );
        if (Object.keys(nestedDifferences).length > 0) {
          differences[newPath.join('.')] = nestedDifferences;
        }
      } else if (obj1[key] !== obj2[key]) {
        differences[newPath.join('.')] = {
          oldValue: obj1[key],
          newValue: obj2[key],
        };
      }
    }
  }
  for (const key in obj2) {
    if (
      Object.prototype.hasOwnProperty.call(obj2, key) &&
      !Object.prototype.hasOwnProperty.call(obj1, key)
    ) {
      const newPath = [...path, key];
      differences[newPath.join('.')] = {
        oldValue: undefined,
        newValue: obj2[key],
      };
    }
  }
  return differences;
}
