export const aclPermissions = {
  moduloAdministrativo: {
    plantillas: 'MODULO_ADMINISTRATIVO::PLANTILLAS',
    listaPreaprobados: 'MODULO_ADMINISTRATIVO::LISTA_PREAPROBADOS',
    reglasDecision: 'MODULO_ADMINISTRATIVO::REGLAS_DE_DECISION',
    roles: 'MODULO_ADMINISTRATIVO::ROLES',
    usuarios: 'MODULO_ADMINISTRATIVO::USUARIOS',
    reasignacion: 'MODULO_ADMINISTRATIVO::REASIGNACION',
    consecionariosSalas: 'MODULO_ADMINISTRATIVO::CONSECIONARIOS_Y_SALAS',
    listaRestrictiva: 'MODULO_ADMINISTRATIVO::LISTA_RESTRICTIVA',
    clausulasLegales: 'MODULO_ADMINISTRATIVO::CLAUSULAS_LEGALES',
    atdp: 'MODULO_ADMINISTRATIVO::ATDP',
    dashboardReports:'MODULO_ADMINISTRATIVO::DASHBOARD_REPORTS',
  },
  productosServicios: {
    tarificacion: 'PRODUCTOS_Y_SERVICIOS::TARIFICACION',
    servicios: 'PRODUCTOS_Y_SERVICIOS::SERVICIOS',
    vehiculos: 'PRODUCTOS_Y_SERVICIOS::VEHICULOS',
    planesFinancieros: 'PRODUCTOS_Y_SERVICIOS::PLANES_FINANCIEROS',
    prices: 'PRODUCTOS_Y_SERVICIOS::PRECIOS',
  },
  originacion: {
    personaJuridica: 'ORIGINACION::PERSONA_JURIDICA',
    personaNatural: 'ORIGINACION::PERSONA_NATURAL',
    auditoriaProcesos: 'ORIGINACION::AUDITORIA_DE_PROCESOS',
    tareasCompletadas: 'ORIGINACION::TAREAS_COMPLETADAS',
  },
  arbolDecision: {
    tarificacion: 'ARBOL_DECISION::TARIFICACION',
    originacion: 'ARBOL_DECISION::ORIGINACION',
    seguros: 'ARBOL_DECISION::SEGUROS',
  },
  reglasDecision: {
    segurosExtraprima: 'REGLAS_DE_DECISION::SEGUROS_EXTRAPRIMA',
  },
};
