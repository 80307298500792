import { Catalogue } from '@wp-back-office/core/commons-backoffice';
import { ResponseAWS } from 'libs/core/workflow-sdk/src/lib/models/response-aws.model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';

export const paymentFrecuencyActions = createActionGroup({
  source: 'Payment Frecuency',
  events: {
    Reset: emptyProps(),
    'Get List': props<{
      /**
       * Codigo de amortización.
       */
      code_amortization: number;
      /**
       * Filtro descripción..
       */
      description?: string;
      /**
       * Pagina.
       */
      page?: number;
      /**
       * Tamaño de la pagina.
       */
      pageSize?: number;
      /**
       * Token de la ultima pagina.
       */
      lastPageToken?: number;
    }>(),
    Success: props<{
      /**
       * Respuesta frecuencias de pago.
       */
      catalogue: Catalogue[];
    }>(),
    Error: props<{
      /**
       * Respuesta error Http.
       */
      error: ErrorHttp;
    }>(),
  },
});
