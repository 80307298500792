import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Modulo de autenticacion.
 */
@NgModule({
  imports: [CommonModule],
})
export class AppUserAuthenticationModule {}
