import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueCountries = createAction(
  '[KeyValueCountries] Cargar paises por id',
  props<{
    /**
     * Identificador.
     */
    id: CatalogueType;
  }>()
);

export const KeyValueCountriesSuccess = createAction(
  '[KeyValueCountries] Crear paises Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueCountriesError = createAction(
  '[KeyValueCountries] Cargar paises Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
