import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DynamicDialogMessageService } from './services/dynamic-dialog-message.service';
import { DynamicDialogMessageComponent } from './components/dynamic-dialog-message.component';
import { DynamicDialogSubmitComponent } from './components/dynamic-dialog-submit/dynamic-dialog-submit.component';
import { MatDividerModule } from '@angular/material/divider';
import { FactoryDialogComponentDirective } from './directive/factory-dialog-component.directive';
import { DynamicSpinnerComponent } from './components/dynamic-spinner/dynamic-spinner.component';

/**
 *Dialogs dinamicos.
 */
@NgModule({
  declarations: [
    DynamicDialogMessageComponent,
    DynamicDialogSubmitComponent,
    FactoryDialogComponentDirective,
    DynamicSpinnerComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    FlexLayoutModule,
  ],
  providers: [DynamicDialogMessageService],
  exports: [
    DynamicDialogMessageComponent,
    DynamicDialogSubmitComponent,
    FactoryDialogComponentDirective,
    DynamicSpinnerComponent,
  ],
})
export class DynamicDialogModule {}
