import { createAction, props } from '@ngrx/store';
import { DecisionTree } from 'libs/app/dashboard/src/lib/models/decision-tree.model';

export const decisionTreesListReset = createAction(
  '[DecisionTrees] Reset lista de arboles de decision'
);

export const decisionTreesReset = createAction(
  '[DecisionTrees] Reset servicios'
);

export const decisionTreesList = createAction(
  '[DecisionTrees] Obtener arboles de decision'
);

export const decisionTreesListSuccess = createAction(
  '[DecisionTrees] Obtener arboles de decision Exitoso',
  props<{
    /**
     *
     */
    object: DecisionTree[];
  }>()
);

export const decisionTreesListError = createAction(
  '[DecisionTrees] Obtener arboles de decision Error',
  props<{
    /**
     *
     */
    err: any;
  }>()
);
