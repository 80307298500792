<div *ngIf="(arrayTabs?.length || 0) <= 0; else TabComponente">
  <p>Loading</p>
</div>
<ng-template #TabComponente>
  <mat-tab-group
    class="card-table"
    (selectedIndexChange)="onCurrentIndex($event)">
    <mat-tab *ngFor="let tab of arrayTabs; let index = index" #tabs>
      <ng-template mat-tab-label>
        <div
          class="d-flex justify-content-center align-items-center align-self-baseline">
          <div>{{ tab.name }}</div>
          <ng-container *ngIf="buttonFilter(tab.buttons, 'DELETE') as button">
            <ng-template [ngIf]="button ? button.isEnable : false">
              <button
                mat-icon-button
                class="mb-1"
                [color]="'warn'"
                type="submit"
                [disabled]="disabledAdd"
                (click)="removeTab(index)">
                <mat-icon class="iconStart"> cancel </mat-icon>
              </button>
            </ng-template>
          </ng-container>
        </div>
      </ng-template>
      <div class="container-fluid px-4 py-5">
        <ng-container *ngIf="buttonFilter(tab.buttons, 'ADD') as button">
          <ng-template [ngIf]="button ? button.isEnable : false">
            <wp-back-office-dynamic-header-table
              [title]="button?.title || 'Nuevo'"
              [subtitle]="button?.subtitle || ''"
              [isCreate]="true"
              [titleButtonCreate]="tab.button?.name || 'Nuevo'"
              (create)="
                onTabAddButtonClick()
              "></wp-back-office-dynamic-header-table>
          </ng-template>
        </ng-container>
        <ng-container
          wpBackOfficeFactoryTab
          [component]="tab.component"
          [typeID]="tab.typeID"
          [tabData]="tab.tabData"
          (editTable)="onEdit($event)"
          (submitValue)="onSubmitJSON($event)"
          (submitTableValue)="onSubmitTableJSON($event)"
          (submitEditValue)="onSubmitEditJSON($event)"
          (submitStepOneValue)="onSubmitTableStepOneJSON($event)">
        </ng-container>
      </div>
    </mat-tab>
  </mat-tab-group>
</ng-template>
