import { Mapper } from '../utils/mapper';
import { Group } from '../models/group.model';

/**
 * Interfaz de Permiso.
 */
interface Permission {
  [key: string]: {
    /**
     * Categoria.
     */
    category: string;
    /**
     * Codigo del permiso.
     */
    permissionCode: string;
    /**
     * Codigo del grupo.
     */
    groupCode: string;
  };
}
/**
 * Interfaz del Proceso.
 */
interface Process {
  /**
   * Codigo del proceso.
   */
  code: string;
  /**
   * Descripción.
   */
  description: string;
}
/**
 * Interfaz del Grupo desde el Backend.
 */
export interface GroupAWS {
  /**
   * Codigo del grupo.
   */
  Code?: string;
  /**
   * Descripción.
   */
  description: string;
  /**
   * Permisos.
   */
  permissions?:
    | {
        /**
         * Interfaz de cada permiso.
         */
        [key: string]: Process[] | Permission;
      }
    | any;
  /**
   * Interfaz de cada permiso.
   */
  processes?: string[];
}
export class GroupMapper extends Mapper<GroupAWS, Group> {
  /**
   * Crea una instancia de GroupMapper.
   */
  constructor() {
    super();
  }
  /**
   * Mapea desde el formato AWS a nuestro modelo.
   * @param param - Parametros desde el backend.
   * @returns - Objeto de nuestro modelo.
   */
  public override mapFrom(param: GroupAWS): Group {
    return {
      name: param.Code as string,
      description: param.description,
      permissions: Object.values(param.permissions)
        .map((permission: any) => Object.values(permission))
        .flat()
        .map((permission: any) => ({
          code: permission.permissionCode as string,
          category: permission.category,
          description: permission.permissionCode,
        }))
        .concat(
          (param.permissions['PROCESS'] as Process[]).map((process: any) => ({
            code: process,
            category: 'PROCESS',
            description: process,
          }))
        ),
    };
  }
  /**
   * Mapea hacia el formato AWS.
   * @param param - Parametros desde nuestro modelo.
   * @returns - Objeto de nuestro formato AWS.
   */
  public override mapTo(param: Group): GroupAWS {
    const processes: string[] = [];
    const permissions: { [key: string]: any }[] = [];
    for (const permission of param.permissions) {
      if (permission.code) {
        if (permission.category === 'PROCESS') {
          processes.push(permission.code);
        } else {
          permissions.push({
            code: permission.code,
            category: permission.category,
          });
        }
      }
    }

    return {
      description: param.description,
      permissions: permissions.length > 0 ? permissions : undefined,
      processes: processes.length > 0 ? processes : undefined,
    };
  }
}
