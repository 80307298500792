export abstract class Mapper<I, O> {
  /**
   * Map from.
   */
  public mapFrom?(param: I): O;
  /**
   * Map To.
   */
  public mapTo?(param: O): I;
}
