import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Catalogue,
  CatalogueTasksService,
} from '@wp-back-office/core/commons-backoffice';
import { ControlConfig } from '../../../models/form-config.model';
import { DynamicFormService } from '../../../services/dynamic-form.service';
import { debounceTime, first, Subject, takeUntil } from 'rxjs';
import { CataloguesStore } from 'libs/core/commons-backoffice/src/lib/services/catalogues-store';
import { FormControlAutocompleteComponent } from '../../components/controls/form-control-autocomplete/form-control-autocomplete.component';
import { FormControlDropDownComponent } from '../../components/controls/form-control-drop-down/form-control-drop-down.component';
import { omit } from 'lodash';

/**
 * Template de dependencias.
 */
@Component({
  selector: 'wp-back-office-template-control-dependence',
  templateUrl: './template-control-dependence.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TemplateControlDependenceComponent),
      multi: true,
    },
  ],
})
export class TemplateControlDependenceComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  /**
   * Id unico del formulario.
   */
  @Input()
  public uniqueIdForm!: string;
  /**
   * Observable - Controles Base.
   */
  @Input()
  public set control(value: ControlConfig | undefined) {
    if (value?.dependencesControls) {
      this.controls = value.dependencesControls.map((control, index) => {
        return {
          keyOptions: {
            entity: control.entity,
            disableLocal: true,
            dependsOn: control.dependsOn || undefined,
          },
          type: control.type,
          key: control.key,
          label: control.label,
          col: control.col || value?.col || undefined,
          validators: {
            ...(control.validators || value?.validators),
            disabled: index === 0 ? false : true,
          },
          topLabel: control.topLabel || undefined,
          icon: control.icon || undefined,
          hint: control.hint || undefined,
          tooltip: control.tooltip || undefined,
          autocompleteConfig: {
            internalFilter: true,
          },
        };
      });
    }
  }

  /**
   * Valores iniciales.
   */
  @Input()
  public hideDisabled?: boolean;

  /**
   * Evento de formulario valido.
   */
  @Output()
  public submitValid: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Valor de controles direccion.
   */
  public controls!: ControlConfig[];
  /**
   * Destructor observables.
   */
  private destroy$: Subject<boolean>;
  /**
   * Componente formulario de dependencias.
   */
  public formGroupDependence = new FormGroup<any>({});

  /**
   * Evento de formulario valido.
   */
  @Input()
  public value!: any;

  /**
   * Query list de controles de seleccion.
   */
  @ViewChildren(FormControlAutocompleteComponent)
  formControlAutocompleteComponents!: QueryList<FormControlAutocompleteComponent>;

  /**
   * Query list de controles de seleccion.
   */
  @ViewChildren(FormControlDropDownComponent)
  formControlDropDownComponents!: QueryList<FormControlDropDownComponent>;

  /**
   * Crea una instancia de la clase.
   * @param dynamicFormService - Servicio de formularios dinamicos.
   * @param catalogueService - Servicio de catalogo.
   * @param cdRef - Detector de cambios.
   */
  constructor(
    public dynamicFormService: DynamicFormService,
    private cataloguesStore: CataloguesStore,
    public cdRef: ChangeDetectorRef
  ) {
    this.destroy$ = new Subject();
  }

  private childControlsValidation(
    controls: QueryList<
      FormControlAutocompleteComponent | FormControlDropDownComponent
    >
  ) {
    controls?.forEach(control => {
      const key = control.controlConfig.key;

      if (key) {
        let _errors = {
          ...(this.formGroupDependence.controls[key].errors || {}),
          notReady: true,
        };

        let errors = omit(_errors, 'notReady');

        if (
          control._formControl.valid &&
          control.controlConfig.options &&
          control.controlConfig.options?.length > 0
        ) {
          this.formGroupDependence.controls[key].setErrors(
            Object.keys(errors).length === 0 ? null : errors
          );
        } else {
          this.formGroupDependence.controls[key].setErrors(
            Object.keys(errors).length === 0
              ? null
              : { ...errors, notReady: true }
          );
        }

        this.cdRef.detectChanges();
      }
    });
  }

  /**
   * Se ejecuta al iniciar la clase.
   */
  public ngOnInit(): void {
    if (this.controls)
      this.controls.map(control => {
        if (control && control.key) {
          const formControl = this.dynamicFormService.setValidators(control);
          this.formGroupDependence.addControl(control.key.trim(), formControl);

          const validators = this.dynamicFormService.getValidators(control);
          this.formGroupDependence
            .get(control.key?.trim() || '')
            ?.setValidators(validators);

          this.formGroupDependence
            .get(control.key?.trim() || '')
            ?.updateValueAndValidity();
        }
      });

    this.formGroupDependence.updateValueAndValidity();
    this.formGroupDependence.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.onChangeFormDependence();
        this.onTouch();
        this.childControlsValidation(this.formControlAutocompleteComponents);
        this.childControlsValidation(this.formControlDropDownComponents);
      });

    if (this.value) {
      this.writeValue(this.value);
    }
  }

  /**
   * Se ejecuta al renderizar el componente.
   */
  public ngAfterViewInit(): void {
    if (this.controls) {
      this.controls?.forEach((control, index) => {
        if (index == 0) {
          this.getCatalogue(0, control.keyOptions?.entity || '');
        }
        this.formGroupDependence.controls[control.key || ''].valueChanges
          .pipe(takeUntil(this.destroy$), debounceTime(1000))
          .subscribe((value: any) => {
            const next = index + 1;
            if (this.controls && value && next !== this.controls.length) {
              this.getCatalogue(
                next,
                this.controls[next]?.keyOptions?.dependsOn || '',
                this.controls[next]?.keyOptions?.entity || '',
                value
              );
            }
          });
      });
    }
  }
  /**
   * Se destruye el componente.
   */
  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /**
   * Envia el valor del formulario.
   */
  public onChangeFormDependence() {
    if (this.formGroupDependence.valid) {
      this.onChange(this.formGroupDependence.getRawValue());
    } else {
      this.onChange('');
    }
  }
  /**
   * Valida si hay una sola opcion en la lista.
   * @param control - Control.
   * @param options - Opciones.
   * @param index - Index.
   */
  private valideOptions(control: string, options: any[], index: number) {
    if (
      this.controls[index]?.validators?.required &&
      options.length === 1 &&
      options[0].code
    ) {
      this.formGroupDependence.controls[control].patchValue(options[0]);
    }
  }

  /**
   * Obtiene los catalogos.
   * @param index - Index del control.
   * @param entity - Entidad.
   * @param childEntity - Entidad hija.
   * @param _value - Valor del control anterior (contiene el campo entityCode y el codigo).
   */
  public getCatalogue(
    index: number,
    entity: string,
    childEntity?: string,
    _value?: any
  ) {
    this.controls[index] = {
      ...this.controls[index],
      validators: {
        ...this.controls[index].validators,
        loading: true,
      },
    };

    this.cataloguesStore
      .getCatalogueService({
        entity: {
          entity,
          entityId: _value?.code,
          childEntity,
        },
      })
      .pipe(takeUntil(this.destroy$), first())
      .subscribe((ev: any[]) => {
        const formControl =
          this.formGroupDependence.controls[this.controls[index].key || ''];

        if (ev.length > 0) {
          formControl.enable();
          // formControl.patchValue(null);
        } else {
          formControl.disable();
          formControl.patchValue(null);
        }

        setTimeout(() => {
          this.controls[index] = {
            ...this.controls[index],
            options: [...ev],
            validators: {
              ...this.controls[index].validators,
              disabled: ev.length > 0 ? false : true,
              loading: false,
            },
          };
          const validators = this.dynamicFormService.getValidators(
            this.controls[index]
          );
          this.formGroupDependence
            .get(this.controls[index].key?.trim() || '')
            ?.setValidators(validators);

          this.formGroupDependence
            .get(this.controls[index].key?.trim() || '')
            ?.updateValueAndValidity();
          this.cdRef.detectChanges();
        }, 100);
        this.valideOptions(this.controls[index].key || '', [...ev], index);
      });
  }

  /**
   * Verirfica si es cambiado.
   * @param _obj - Callback.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  public onChange = (_obj: any) => {};

  /**
   * Verirfica si es tocado.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouch: any = () => {};

  /**
   * Verirfica si es escrito.
   * @param obj - Callback.
   * @throws Error.
   */
  public writeValue(obj: any): void {
    if (obj && typeof obj === 'object') {
      const arr = Object.keys(obj);
      arr?.forEach(key => {
        const index = this.controls.findIndex(control => control.key === key);
        if (
          index >= 0 &&
          obj[key] &&
          obj[key]?.code &&
          !obj[key]?.description
        ) {
          const value = this.getOptionFromCode(obj[key], index);
          if (value && value?.code && value?.description) {
            this.formGroupDependence.controls[key].patchValue(value);
          } else {
            this.formGroupDependence.controls[key].patchValue(obj[key]);
          }
        } else {
          this.formGroupDependence.controls[key].patchValue(obj[key]);
        }
      });
    } else {
      this.formGroupDependence.patchValue(obj);
    }
  }

  /**
   * Retorna un catalogo con su code.
   * @param obj - Catalogo.
   * @param index - Index.
   * @returns Catalogue | undefined.
   */
  public getOptionFromCode(
    obj: Catalogue,
    index: number
  ): Catalogue | undefined {
    if (obj && typeof obj === 'object' && obj?.code && !obj?.description) {
      return (this.controls[index].options || []).find(
        opt => opt.code === obj.code
      );
    } else {
      return obj;
    }
  }

  /**
   * Verirfica si es cambiado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /**
   * Verirfica si es tocado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /**
   * Deshabilitar control.
   * @param isDisabled - Bandera.
   */
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroupDependence.disable();
    } else {
      this.formGroupDependence.enable();
    }
  }
}
