import { TableConfig } from '../models/table-config.model';
/**
 * Configuración tabla con infiniteScroll.
 */
export const tableInfiniteScrollConfig: TableConfig = {
  columns: [],
  data: [],
  options: {
    classes: 'table-stripped' + ' ',
    stickyActions: true,
    stickyHeader: true,
    filter: {
      filterByColumnSelect: true,
    },
    actionNgStyle: {
      'width.px': '170',
    },
    sizes: {
      alwaysScrollVertical: true,
      ngStyle: {
        height: 'calc(100vh - 510px)',
        'min-height': '500px',
      },
    },
    noDataText: 'No hay datos.',
  },
};
/**
 * Configuración por defecto de tabla.
 */
export const tableDefaultConfig: TableConfig = {
  columns: [],
  data: [],
  options: {
    ...tableInfiniteScrollConfig.options,
    sizes: {
      alwaysScrollVertical: false,
      ngStyle: {
        height: 'calc(100vh - 510px)',
        'min-height': '500px',
      },
    },
  },
};


/**
 * Configuración por defecto de tabla.
 */
export const tableFitContentDefaultConfig: TableConfig = {
  columns: [],
  data: [],
  options: {
    ...tableInfiniteScrollConfig.options,
    sizes: {
      alwaysScrollVertical: false,
      ngStyle: {
        height: 'fit-content',
        'min-height': '150px',
      },
    },
  },
};