import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

/**
 * Accion para cargar los tipos de rechazo de documentos.
 */
export const KeyValueDocumentsReturnReasons = createAction(
  '[KeyValueDocumentsReturnReasons] Cargar accion para los tipos de rechazo de documentos',
  props<{
    /**
     * Ruta.
     */
    path: CatalogueType;
  }>()
);

/**
 * Accion que se ejecuta cuando la peticion fue ejecutada correctamente.
 */
export const KeyValueDocumentsReturnReasonsSuccess = createAction(
  '[KeyValueDocumentsReturnReasons] Crear accion para los tipos de rechazo de documentos Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

/**
 * Accion que se ejecuta en caso de un error en la peticion.
 */
export const KeyValueDocumentsReturnReasonsError = createAction(
  '[KeyValueDocumentsReturnReasons] Cargar accion para los tipos de rechazo de documentos Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
