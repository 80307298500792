import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Catalogue } from '@wp-back-office/core/commons-backoffice';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';
import { FinancingType } from 'libs/core/workflow-sdk/src/lib/models/financing-type.model';

export const financingTypesActions = createActionGroup({
  source: 'Financing Types',
  events: {
    Reset: emptyProps(),
    'Get List': props<{
      /**
       * Pagina.
       */
      page?: number;
      /**
       * Tamaño de la pagina.
       */
      pageSize?: number;
    }>(),
    Success: props<{
      /**
       * Tipòs de financiamiento.
       */
      object: Catalogue[];
    }>(),
    Error: props<{
      /**
       * Respuesta error Http.
       */
      error: ErrorHttp;
    }>(),
  },
});
