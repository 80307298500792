import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicSnackBarComponent } from './components/dynamic-snackbar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DynamicSnackBarService } from './services/dynamic-snackbar.service';

/**
 *{AGREGUE DOCUMENTACION}.
 */
@NgModule({
  declarations: [DynamicSnackBarComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatSnackBarModule],
  exports: [DynamicSnackBarComponent],
  providers: [DynamicSnackBarService],
})
export class DynamicSnackbarModule {}
