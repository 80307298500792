import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  Catalogue,
  CatalogueTasksService,
} from '@wp-back-office/core/commons-backoffice';
import { mergeMap, map, catchError, of } from 'rxjs';
import * as actionsEffects from '../../actions';

/**
 * Efecto de obtener catalogo documentos legalizacion.
 */
@Injectable()
export class KeyValueLegalizationDocumentEffects {
  /**
   * Crea una instancia de .
   * @param actions$ - Acciones.
   * @param catalogueTasksService - Servicio de catalogo.
   */
  constructor(
    private actions$: Actions,
    private catalogueTasksService: CatalogueTasksService
  ) {}

  /**
   * Obtener Catalogos.
   */
  public KeyValueCatalogues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        actionsEffects.catalogueLegalizationDocumentsActions
          .getLegalizationDocuments
      ),
      mergeMap(actions =>
        this.catalogueTasksService.getCatalogueTasks(actions.path).pipe(
          map((keyValue: Catalogue[]) =>
            actionsEffects.catalogueLegalizationDocumentsActions.getLegalizationDocumentsSuccess(
              { keyValue }
            )
          ),
          catchError(err =>
            of(
              actionsEffects.catalogueLegalizationDocumentsActions.getLegalizationDocumentsError(
                { payload: err }
              )
            )
          )
        )
      )
    )
  );
}
