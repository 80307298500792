import { createAction, props } from '@ngrx/store';
import { SuccessTask } from '@wp-back-office/core/workflow-sdk';
import {
  RequestRiskAnalysisEdit,
  TaskResult,
} from '../../../models/request.models';

export const ResetAnalysisModifyProduct = createAction(
  '[ResetAnalysisModifyProduct] Reiniciar el estado de modificaciones de producto'
);

export const ResetAnalysis = createAction(
  '[ResetAnalysis] Reiniciar el estado de analisis'
);

export const RadicationAnalysis = createAction(
  '[RadicationAnalysis] Radicar la solicitud de analisis',
  props<{
    /**
     * Respuesta.
     */
    procesData: SuccessTask;
  }>()
);

export const RadicationAnalysisSuccess = createAction(
  '[RadicationAnalysis] Radicar la solicitud de analisis exitoso',
  props<{
    /**
     * Respuesta.
     */
    object: any;
  }>()
);

export const RadicationAnalysisError = createAction(
  '[RadicationAnalysis] Radicar la solicitud de analisis error',
  props<{
    /**
     * Informacion json.
     */
    error: any;
  }>()
);

export const RadicationAnalysisEdit = createAction(
  '[RadicationAnalysisEdit] Radicar la edicion de la solicitud de analisis',
  props<{
    /**
     * Respuesta.
     */
    procesData: RequestRiskAnalysisEdit;
  }>()
);

export const RadicationAnalysisEditSuccess = createAction(
  '[RadicationAnalysisEdit] Radicar la edicion de la solicitud de analisis exitoso',
  props<{
    /**
     * Respuesta.
     */
    object: any;
  }>()
);

export const RadicationAnalysisEditError = createAction(
  '[RadicationAnalysisEdit] Radicar la edicion de la solicitud de analisis de error',
  props<{
    /**
     * Informacion json.
     */
    error: any;
  }>()
);

export const RadicationAnalysisModifyProduct = createAction(
  '[RadicationAnalysisModifyProduct] Agregar la modificacion de campos y valores del formulario producto',
  props<{
    /**
     * Respuesta.
     */
    modify: TaskResult;
  }>()
);

export const RadicationAnalysisModifyInterveners = createAction(
  '[RadicationAnalysisModifyInterveners] Agregar la modificacion de campos y valores del formulario intervinientes',
  props<{
    /**
     * Inidice a modificar.
     */
    id: string;
    /**
     * Respuesta.
     */
    modify: TaskResult;
  }>()
);

export const RadicationAnalysisValueProduct = createAction(
  '[RadicationAnalysisValueProduct] Valor de los formularios de productos modificados',
  props<{
    /**
     * Payload error.
     */
    value?: string;
  }>()
);

export const RadicationAnalysisValueInterveners = createAction(
  '[RadicationAnalysisValueInterveners] Valor de los formularios de intervinientes modificados',
  props<{
    /**
     * Inidice a modificar.
     */
    id: string;
    /**
     * Payload error.
     */
    value: string;
  }>()
);
