import { createAction, props } from '@ngrx/store';
import { Catalogue } from '../../../models/contents/catalogue.models';
import { CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueScoreBuro = createAction(
  '[KeyValueScoreBuro] Cargar score de buro por id',
  props<{
    /**
     * Identificador.
     */
    path: CatalogueType;
  }>()
);

export const KeyValueScoreBuroSuccess = createAction(
  '[KeyValueScoreBuro] Crear score de buro Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueScoreBuroError = createAction(
  '[KeyValueScoreBuro] Cargar score de buro Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
