import { Action, createReducer, on } from '@ngrx/store';
import { DecisionTree } from 'libs/app/dashboard/src/lib/models/decision-tree.model';
import { ErrorHttp } from '../../../../models/errors.models';
import { resetInitialState } from '../../../../root/store/actions';
import {
  decisionTreesList,
  decisionTreesListError,
  decisionTreesListSuccess,
} from '../../actions';

/**
 *
 */
export interface DecisionTreesState {
  /**
   *
   */
  object?: DecisionTree[];
  /**
   *
   */
  loaded: boolean;
  /**
   *
   */
  loading: boolean;
  /**
   *
   */
  error?: ErrorHttp;
}

export const decisionTreesInitialState: DecisionTreesState = {
  object: undefined,
  loaded: false,
  loading: false,
  error: undefined,
};

const featureReducer = createReducer(
  decisionTreesInitialState,
  on(resetInitialState, () => ({ ...decisionTreesInitialState })),
  on(decisionTreesList, state => ({
    ...state,
    loaded: false,
    loading: true,
    error: undefined,
  })),
  on(decisionTreesListSuccess, (state, { object }) => ({
    ...state,
    error: undefined,
    loaded: true,
    loading: false,
    object,
  })),
  on(decisionTreesListError, (state, { err }) => ({
    ...state,
    loaded: true,
    loading: false,
    error: {
      url: err.url,
      name: err.name,
      message: err.message,
    },
  }))
);

/**
 * Reductor iniciar proceso.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function DecisionTreesReducer(
  state: DecisionTreesState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
