import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WorkflowService } from '@wp-back-office/core/workflow-sdk';
import { mergeMap, map, catchError, of } from 'rxjs';
import { ResponseStart } from '../../../models/contents/task.models';

import * as actionsEffects from '../../actions';

/**
 *
 */
@Injectable()
export class CheckContactInfoEffects {
  /**
   * Crea una instancia CheckContactInfoEffects.
   * @param actions$ - Actions.
   * @param workflowService - Servicio work flow.
   */
  constructor(
    private actions$: Actions,
    private workflowService: WorkflowService
  ) {}

  /**
   * Efecto para la validacion de la información de contacto.
   */
  public CheckContactInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.CheckContactInfo),
      mergeMap(params =>
        this.workflowService.checkContactInfo(params.contactInfoCheck).pipe(
          map((object: ResponseStart) =>
            actionsEffects.CheckContactInfoSuccess({ object })
          ),
          catchError(err =>
            of(actionsEffects.CheckContactInfoError({ payload: err }))
          )
        )
      )
    )
  );
}
