import { ActionReducerMap } from '@ngrx/store';

import * as reducers from './reducers';

/**
 *
 */
export interface DecisionRulesState {
  /**
   * Obtener  Arboles de decision.
   */
  DecisionTrees?: reducers.DecisionTreesState;
}

export const DecisionRulesReducers: ActionReducerMap<DecisionRulesState> = {
  /**
   * Obtener Arboles de decision.
   */
  DecisionTrees: reducers.DecisionTreesReducer,
};
