import { Action, createReducer, on } from '@ngrx/store';
import {
  Catalogue,
  CatalogueSBS,
} from '@wp-back-office/core/commons-backoffice';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';
import { resetInitialState } from '../../../../root/store/actions/contents/reset-initial-state.actions';

import { catalogueQueryActions } from '../../actions';

/**
 * Reducer para obtener catalogos.
 */
export interface StateDataCatalogues {
  /**
   * Catalogo.
   */
  catalogue?: Catalogue[] | CatalogueSBS[];
  /**
   * Bandera de carga.
   */
  loaded: boolean;
  /**
   * Bandera de carga.
   */
  loading: boolean;
  /**
   * Respuesta erronea del store.
   */
  error?: ErrorHttp;
}

/**
 * Reducer para obtener catalogos.
 */
export interface GetCataloguesState {
  /**
   * Tipo de identificación.
   */
  typeOfIdentification?: StateDataCatalogues;
  /**
   * Actividad economica.
   */
  econimicActivities?: StateDataCatalogues;
  /**
   * Actividad economica.
   */
  response?: StateDataCatalogues;
  /**
   * Actividad economica.
   */
  contractType?: StateDataCatalogues;
  /**
   * Tipo de regimen.
   */
  regimeType?: StateDataCatalogues;
  /**
   * Seguridad social.
   */
  socialSecurity?: StateDataCatalogues;
  /**
   * Tipo de usuario.
   */
  userType?: StateDataCatalogues;
  /**
   * Estado.
   */
  status?: StateDataCatalogues;
  /**
   * Región.
   */
  region?: StateDataCatalogues;
  /**
   * Tipo de sociedad.
   */
  kindSociety?: StateDataCatalogues;
  /**
   * Tipo de contribuidor.
   */
  typeContributor?: StateDataCatalogues;
  /**
   * Forma de pago.
   */
  formPayment?: StateDataCatalogues;
  /**
   * Tipo de venta.
   */
  typeSale?: StateDataCatalogues;
  /**
   * Eps.
   */
  eps?: StateDataCatalogues;
  /**
   * Fondos de pensión.
   */
  pensionFunds?: StateDataCatalogues;
}

export const initialState: StateDataCatalogues = {
  catalogue: undefined,
  loaded: false,
  loading: false,
  error: undefined,
};
// Type of id
const featureGetTypeOfIdentificationsReducer = createReducer(
  initialState,
  on(resetInitialState, () => ({ ...initialState })),
  on(catalogueQueryActions.getTypeOfIdentification, state => ({
    ...state,
    loading: true,
    loaded: false,
    catalogue: undefined,
    error: undefined,
  })),
  on(
    catalogueQueryActions.getTypeOfIdentificationSuccess,
    (state, { keyValue }) => ({
      ...state,
      loading: false,
      loaded: true,
      catalogue: [...keyValue],
      error: undefined,
    })
  ),
  on(
    catalogueQueryActions.getTypeOfIdentificationError,
    (state, { payload }) => ({
      ...state,
      catalogue: undefined,
      loading: false,
      loaded: true,
      error: {
        url: payload.url,
        name: payload.name,
        message: payload.message,
      },
    })
  )
);
/**
 * Reductor de obtener varios.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureGetTypeOfIdentificationsReducer.
 */
export function keyValueGetTypeOfIdentificationsReducer(
  state: StateDataCatalogues | undefined,
  action: Action
) {
  return featureGetTypeOfIdentificationsReducer(state, action);
}

// Economic Activities
const featureGetEconomicActivitiesReducer = createReducer(
  initialState,
  on(resetInitialState, () => ({ ...initialState })),
  on(catalogueQueryActions.getEconomicActivities, state => ({
    ...state,
    loading: true,
    loaded: false,
    catalogue: undefined,
    error: undefined,
  })),
  on(
    catalogueQueryActions.getEconomicActivitiesSuccess,
    (state, { keyValue }) => ({
      ...state,
      loading: false,
      loaded: true,
      catalogue: [...keyValue],
      error: undefined,
    })
  ),
  on(
    catalogueQueryActions.getEconomicActivitiesError,
    (state, { payload }) => ({
      ...state,
      catalogue: undefined,
      loading: false,
      loaded: true,
      error: {
        url: payload.url,
        name: payload.name,
        message: payload.message,
      },
    })
  )
);
/**
 * Reductor de obtener varios.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureGetEconimicActivitiesReducer.
 */
export function keyValueGetEconomicActivitiesReducer(
  state: StateDataCatalogues | undefined,
  action: Action
) {
  return featureGetEconomicActivitiesReducer(state, action);
}

// Response
const featureGetResponseReducer = createReducer(
  initialState,
  on(resetInitialState, () => ({ ...initialState })),
  on(catalogueQueryActions.getResponse, state => ({
    ...state,
    loading: true,
    loaded: false,
    catalogue: undefined,
    error: undefined,
  })),
  on(catalogueQueryActions.getResponseSuccess, (state, { keyValue }) => ({
    ...state,
    loading: false,
    loaded: true,
    catalogue: [...keyValue],
    error: undefined,
  })),
  on(catalogueQueryActions.getResponseError, (state, { payload }) => ({
    ...state,
    catalogue: undefined,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);
/**
 * Reductor de obtener varios.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureGetTypeOfIdentificationsReducer.
 */
export function keyValueGetResponseReducer(
  state: StateDataCatalogues | undefined,
  action: Action
) {
  return featureGetResponseReducer(state, action);
}

// Tipo de contrato
const featureGetContractTypeReducer = createReducer(
  initialState,
  on(resetInitialState, () => ({ ...initialState })),
  on(catalogueQueryActions.getContractType, state => ({
    ...state,
    loading: true,
    loaded: false,
    catalogue: undefined,
    error: undefined,
  })),
  on(catalogueQueryActions.getContractTypeSuccess, (state, { keyValue }) => ({
    ...state,
    loading: false,
    loaded: true,
    catalogue: [...keyValue],
    error: undefined,
  })),
  on(catalogueQueryActions.getContractTypeError, (state, { payload }) => ({
    ...state,
    catalogue: undefined,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);
/**
 * Reductor de obtener varios.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureGetTypeOfIdentificationsReducer.
 */
export function keyValueContractTypeReducer(
  state: StateDataCatalogues | undefined,
  action: Action
) {
  return featureGetContractTypeReducer(state, action);
}

// Tipo de regimen
const featureGetRegimeTypeReducer = createReducer(
  initialState,
  on(resetInitialState, () => ({ ...initialState })),
  on(catalogueQueryActions.getRegimeType, state => ({
    ...state,
    loading: true,
    loaded: false,
    catalogue: undefined,
    error: undefined,
  })),
  on(catalogueQueryActions.getRegimeTypeSuccess, (state, { keyValue }) => ({
    ...state,
    loading: false,
    loaded: true,
    catalogue: [...keyValue],
    error: undefined,
  })),
  on(catalogueQueryActions.getRegimeTypeError, (state, { payload }) => ({
    ...state,
    catalogue: undefined,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);
/**
 * Reductor de obtener varios.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureGetTypeOfIdentificationsReducer.
 */
export function keyValueRegimeTypeReducer(
  state: StateDataCatalogues | undefined,
  action: Action
) {
  return featureGetRegimeTypeReducer(state, action);
}

// Seguridad social
const featureGetSocialSecurityReducer = createReducer(
  initialState,
  on(resetInitialState, () => ({ ...initialState })),
  on(catalogueQueryActions.getSocialSecurity, state => ({
    ...state,
    loading: true,
    loaded: false,
    catalogue: undefined,
    error: undefined,
  })),
  on(catalogueQueryActions.getSocialSecuritySuccess, (state, { keyValue }) => ({
    ...state,
    loading: false,
    loaded: true,
    catalogue: [...keyValue],
    error: undefined,
  })),
  on(catalogueQueryActions.getSocialSecurityError, (state, { payload }) => ({
    ...state,
    catalogue: undefined,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);
/**
 * Reductor de obtener varios.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureGetTypeOfIdentificationsReducer.
 */
export function keyValueSocialSecurityReducer(
  state: StateDataCatalogues | undefined,
  action: Action
) {
  return featureGetSocialSecurityReducer(state, action);
}

// Tipo de usuario.
const featureGetUserTypeReducer = createReducer(
  initialState,
  on(resetInitialState, () => ({ ...initialState })),
  on(catalogueQueryActions.getUserType, state => ({
    ...state,
    loading: true,
    loaded: false,
    catalogue: undefined,
    error: undefined,
  })),
  on(catalogueQueryActions.getUserTypeSuccess, (state, { keyValue }) => ({
    ...state,
    loading: false,
    loaded: true,
    catalogue: [...keyValue],
    error: undefined,
  })),
  on(catalogueQueryActions.getUserTypeError, (state, { payload }) => ({
    ...state,
    catalogue: undefined,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);
/**
 * Reductor de obtener varios.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureGetTypeOfIdentificationsReducer.
 */
export function keyValueUserTypeReducer(
  state: StateDataCatalogues | undefined,
  action: Action
) {
  return featureGetUserTypeReducer(state, action);
}

// Estado.
const featureGetStatusReducer = createReducer(
  initialState,
  on(resetInitialState, () => ({ ...initialState })),
  on(catalogueQueryActions.getStatus, state => ({
    ...state,
    loading: true,
    loaded: false,
    catalogue: undefined,
    error: undefined,
  })),
  on(catalogueQueryActions.getStatusSuccess, (state, { keyValue }) => ({
    ...state,
    loading: false,
    loaded: true,
    catalogue: [...keyValue],
    error: undefined,
  })),
  on(catalogueQueryActions.getStatusError, (state, { payload }) => ({
    ...state,
    catalogue: undefined,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);
/**
 * Reductor de obtener varios.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureGetTypeOfIdentificationsReducer.
 */
export function keyValueStatusReducer(
  state: StateDataCatalogues | undefined,
  action: Action
) {
  return featureGetStatusReducer(state, action);
}

// region
const featureGetRegionReducer = createReducer(
  initialState,
  on(resetInitialState, () => ({ ...initialState })),
  on(catalogueQueryActions.getRegion, state => ({
    ...state,
    loading: true,
    loaded: false,
    catalogue: undefined,
    error: undefined,
  })),
  on(catalogueQueryActions.getRegionSuccess, (state, { keyValue }) => ({
    ...state,
    loading: false,
    loaded: true,
    catalogue: [...keyValue],
    error: undefined,
  })),
  on(catalogueQueryActions.getRegionError, (state, { payload }) => ({
    ...state,
    catalogue: undefined,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);
/**
 * Reductor de obtener varios.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureGetTypeOfIdentificationsReducer.
 */
export function keyValueRegionReducer(
  state: StateDataCatalogues | undefined,
  action: Action
) {
  return featureGetRegionReducer(state, action);
}

// Tipo de sociedad.
const featureGetKindSocietyReducer = createReducer(
  initialState,
  on(resetInitialState, () => ({ ...initialState })),
  on(catalogueQueryActions.getKindSociety, state => ({
    ...state,
    loading: true,
    loaded: false,
    catalogue: undefined,
    error: undefined,
  })),
  on(catalogueQueryActions.getKindSocietySuccess, (state, { keyValue }) => ({
    ...state,
    loading: false,
    loaded: true,
    catalogue: [...keyValue],
    error: undefined,
  })),
  on(catalogueQueryActions.getKindSocietyError, (state, { payload }) => ({
    ...state,
    catalogue: undefined,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);
/**
 * Reductor de obtener varios.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureGetTypeOfIdentificationsReducer.
 */
export function keyValueKindSocietyReducer(
  state: StateDataCatalogues | undefined,
  action: Action
) {
  return featureGetKindSocietyReducer(state, action);
}

// Tipo de contribuidor
const featureGetTypeContributorReducer = createReducer(
  initialState,
  on(resetInitialState, () => ({ ...initialState })),
  on(catalogueQueryActions.getTypeContributor, state => ({
    ...state,
    loading: true,
    loaded: false,
    catalogue: undefined,
    error: undefined,
  })),
  on(
    catalogueQueryActions.getTypeContributorSuccess,
    (state, { keyValue }) => ({
      ...state,
      loading: false,
      loaded: true,
      catalogue: [...keyValue],
      error: undefined,
    })
  ),
  on(catalogueQueryActions.getTypeContributorError, (state, { payload }) => ({
    ...state,
    catalogue: undefined,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);
/**
 * Reductor de obtener varios.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureGetTypeOfIdentificationsReducer.
 */
export function keyValueTypeContributorReducer(
  state: StateDataCatalogues | undefined,
  action: Action
) {
  return featureGetTypeContributorReducer(state, action);
}

// Tipo de forma de pago.
const featureGetFormPaymentReducer = createReducer(
  initialState,
  on(resetInitialState, () => ({ ...initialState })),
  on(catalogueQueryActions.getFormPayment, state => ({
    ...state,
    loading: true,
    loaded: false,
    catalogue: undefined,
    error: undefined,
  })),
  on(catalogueQueryActions.getFormPaymentSuccess, (state, { keyValue }) => ({
    ...state,
    loading: false,
    loaded: true,
    catalogue: [...keyValue],
    error: undefined,
  })),
  on(catalogueQueryActions.getFormPaymentError, (state, { payload }) => ({
    ...state,
    catalogue: undefined,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);
/**
 * Reductor de obtener varios.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureGetTypeOfIdentificationsReducer.
 */
export function keyValueFormPaymentReducer(
  state: StateDataCatalogues | undefined,
  action: Action
) {
  return featureGetFormPaymentReducer(state, action);
}

// Tipo de venta.
const featureGetTypeSaleReducer = createReducer(
  initialState,
  on(resetInitialState, () => ({ ...initialState })),
  on(catalogueQueryActions.getTypeSale, state => ({
    ...state,
    loading: true,
    loaded: false,
    catalogue: undefined,
    error: undefined,
  })),
  on(catalogueQueryActions.getTypeSaleSuccess, (state, { keyValue }) => ({
    ...state,
    loading: false,
    loaded: true,
    catalogue: [...keyValue],
    error: undefined,
  })),
  on(catalogueQueryActions.getTypeSaleError, (state, { payload }) => ({
    ...state,
    catalogue: undefined,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);
/**
 * Reductor de obtener varios.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureGetTypeOfIdentificationsReducer.
 */
export function keyValueTypeSaleReducer(
  state: StateDataCatalogues | undefined,
  action: Action
) {
  return featureGetTypeSaleReducer(state, action);
}

// Eps.
const featureGetEpsReducer = createReducer(
  initialState,
  on(resetInitialState, () => ({ ...initialState })),
  on(catalogueQueryActions.getEps, state => ({
    ...state,
    loading: true,
    loaded: false,
    catalogue: undefined,
    error: undefined,
  })),
  on(catalogueQueryActions.getEpsSuccess, (state, { keyValue }) => ({
    ...state,
    loading: false,
    loaded: true,
    catalogue: [...keyValue],
    error: undefined,
  })),
  on(catalogueQueryActions.getEpsError, (state, { payload }) => ({
    ...state,
    catalogue: undefined,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);
/**
 * Reductor de obtener varios.
 * @param state - Estado.
 * @param action - Accion.
 * @returns featureGetEpsReducer.
 */
export function keyValueEpsReducer(
  state: StateDataCatalogues | undefined,
  action: Action
) {
  return featureGetEpsReducer(state, action);
}

// Tipo de venta.
const featureGetPensionFundsReducer = createReducer(
  initialState,
  on(resetInitialState, () => ({ ...initialState })),
  on(catalogueQueryActions.getPensionFunds, state => ({
    ...state,
    loading: true,
    loaded: false,
    catalogue: undefined,
    error: undefined,
  })),
  on(catalogueQueryActions.getPensionFundsSuccess, (state, { keyValue }) => ({
    ...state,
    loading: false,
    loaded: true,
    catalogue: [...keyValue],
    error: undefined,
  })),
  on(catalogueQueryActions.getPensionFundsError, (state, { payload }) => ({
    ...state,
    catalogue: undefined,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);
/**
 * Reductor de obtener varios.
 * @param state - Estado.
 * @param action - Accion.
 * @returns featureGetPensionFundsReducer.
 */
export function keyValuePensionFundsReducer(
  state: StateDataCatalogues | undefined,
  action: Action
) {
  return featureGetPensionFundsReducer(state, action);
}
