import { createAction, props } from '@ngrx/store';
import { TaskStart } from '@wp-back-office/core/workflow-sdk';
import { ResponseStart } from '../../../models';

export const StartTask = createAction(
  '[StartTask] Iniciar el proceso',
  props<{
    /**
     * Modelo de la tarea a iniciar.
     */
    startTask?: TaskStart;
    /**
     * Exitoso.
     */
    success?: boolean;
  }>()
);

export const StartTaskSuccess = createAction(
  '[StartTask] Iniciar el proceso Exitoso',
  props<{
    /**
     * Respuesta.
     */
    object: ResponseStart;
  }>()
);

export const StartTaskError = createAction(
  '[StartTask] Iniciar el proceso Error',
  props<{
    /**
     * Informacion json.
     */
    payload: any;
  }>()
);
