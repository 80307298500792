import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ImageCroppedEvent } from 'ngx-image-cropper';

import { ControlConfig } from '@wp-back-office/shared/dynamic-components';

import { DynamicFormService } from '../../../../services/dynamic-form.service';
import { DynamicDialogMessageService } from '../../../../../dynamic-dialog-message';

/**
 * Control para entrada de archivos.
 */
@Component({
  selector: 'wp-back-office-form-control-file',
  templateUrl: './form-control-file.component.html',
  styleUrls: ['./form-control-file.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlFileComponent),
      multi: true,
    },
  ],
})
export class FormControlFileComponent
  implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor
{
  /**
   * Configuracion del control.
   */
  @Input()
  public set control(value: ControlConfig | undefined) {
    if (value) {
      this.controlConfig = value;
      this._formControl = this.dynamicFormService.setValidators(value);
    }
  }

  /**
   * Form control del campo.
   */
  public _formControl: FormControl;

  /**
   * File Reader.
   */
  public fileReader: any = new FileReader();

  /**
   * Archivos seleccionados.
   */
  public files!: any[];

  /**
   * Configuracion del control variable.
   */
  public controlConfig!: ControlConfig;

  /**
   * Destructor observables.
   */
  private destroy$: Subject<boolean>;

  /**
   * Ocultta el cortador de imagen.
   */
  public hideCropper: boolean;

  /**
   * Imagen a cortar.
   */
  public imageChangedEvent: any = '';

  /**
   * Imagen cortada.
   */
  public croppedImage: any = '';

  /**
   * Bandera de carga.
   */
  public loading: boolean;

  /**
   * Crea una instancia de la clase.
   * @param dynamicFormService - Servicio de formularios dinamicos.
   * @param dynamicDialogMessageService - Servicio de dialogs.
   * @param cdRef - Detector de cambios.
   */
  constructor(
    public dynamicFormService: DynamicFormService,
    private dynamicDialogMessageService: DynamicDialogMessageService,
    public cdRef: ChangeDetectorRef
  ) {
    this._formControl = new FormControl('');
    this.destroy$ = new Subject();
    this.loading = true;
    this.hideCropper = true;
  }

  /**
   * Se ejecutar al iniciar el componente.
   */
  public ngOnInit() {
    if (this.controlConfig)
      this._formControl = this.dynamicFormService.setValidators(
        this.controlConfig
      );
    this._formControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.onChange(data);
        if (this._formControl.touched) {
          this.onTouch(data);
        }
      });
  }

  /**
   * Se ejecuta al renderizar el componente.
   */
  public ngAfterViewInit() {
    if (this.controlConfig.fileOptions?.defaultImage) {
      setTimeout(() => {
        this.setBackgroundImage(this.controlConfig.fileOptions?.defaultImage);
        this.loading = false;
      }, 1000);
    } else {
      this.loading = false;
    }
  }

  /**
   * Se ejecuta al destruir el componente.
   */
  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * Obtiene la previsualizacion d l imagen.
   */
  public getImgPreview() {
    if (!this.controlConfig?.fileOptions?.cropper) {
      const file = this.files[0];
      if (file) {
        /**
         * Leer un archivo.
         * @param file - Archivo.
         */
        const loadFile = (file: any) => {
          this.setBackgroundImage(file.target?.result);
          this.fileReader.removeEventListener('load', loadFile);
        };
        this.fileReader.readAsDataURL(file);
        this.fileReader.addEventListener('load', loadFile);
      } else {
        this.setBackgroundImage(null);
        const imgPreview = document.getElementById('img-preview');
        if (imgPreview?.classList.contains('has-image')) {
          imgPreview.classList.remove('has-image');
        }
      }
    }
  }

  /**
   * Aplica preview de la imagen.
   * @param backgroundImage - Imagen.
   */
  public setBackgroundImage(backgroundImage: any): void {
    const imgPreview = document.getElementById('img-preview');
    if (imgPreview) {
      imgPreview.style.display = 'block';
      imgPreview.style.backgroundImage = 'url(' + backgroundImage + ')';
      if (!imgPreview?.classList.contains('has-image')) {
        imgPreview.classList.add('has-image');
      }
    }
  }

  /**
   * Evento de cambio del archivo.
   * @param event - Evento.
   */
  public fileChangeEvent(event: any): void {
    if (event.target.files.length > 0) {
      this.files = event.target.files;
      let isValid = true;
      for (let index = 0; index < this.files.length; index++) {
        const file = this.files[index];
        const limit = 15; //Limite en MB
        if (file.size > limit * 1024 ** 2 - 1) {
          isValid = false;
          this.dynamicDialogMessageService.open({
            type: 'error',
            header: {
              text: `El tamaño máximo de archivo permitido es ${limit}MB.`,
            },
          });
          this.reset();
        }
      }
      if (isValid) {
        this.getImgPreview();
        let accept = true;
        const files = [...event.target.files];
        const arrayAccept = this.controlConfig?.validators?.accept?.split(',');
        files.forEach(element => {
          if (
            (this.controlConfig?.validators?.accept === 'image/*' &&
              element.type.includes('image/')) ||
            arrayAccept?.find((_accept: string) => _accept === element.type) ||
            arrayAccept?.find((_accept: string) =>
              element.name.toLowerCase().includes(_accept)
            )
          ) {
            accept = true;
          } else {
            accept = false;
          }
        });
        if (!accept) {
          this.dynamicDialogMessageService.open({
            type: 'error',
            header: {
              text: 'Este formato no está permitido.',
            },
          });
          this.reset();
        } else {
          if (!this.controlConfig?.fileOptions?.cropper) {
            const Files: File[] = [...event.target.files];
            this.emitDataChanged(Files);
          } else {
            this.loading = true;
            this.imageChangedEvent = event;
          }
        }
      }
    }
  }

  /**
   * Reinnicia el valor del formcontrol y el background.
   */
  public reset() {
    const imgPreview = document.getElementById('img-preview');
    this.setBackgroundImage(null);
    if (imgPreview?.classList.contains('has-image')) {
      imgPreview.classList.remove('has-image');
    }
    this.imageChangedEvent = null;
    this.emitDataChanged(null);
  }

  /**
   *  Emite los cambios en el valor del formControl.
   * @param data - Datos emitidos.
   */
  public emitDataChanged(data: any) {
    this._formControl.patchValue(data);
  }

  /**
   * Convierte un base 64 a File.
   * @param base64 - Base 64.
   * @returns File.
   */
  public base64ToFile(base64: any) {
    const arr = base64.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = window.atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], 'profile', { type: mime });
  }

  /**
   * Se ejecuta cuando la imagen fue cortada.
   * @param event - Resultado del recorte.
   */
  public async imageCropped(event: ImageCroppedEvent) {
    if (event.base64) {
      this.croppedImage = event.base64;
      this.setBackgroundImage(event.base64);
      const File = this.base64ToFile(event.base64);
      const Files: File[] = [File];
      this.emitDataChanged(Files);
    }
    this.loading = false;
  }

  /**
   * Se ejecuta al iniciar el corte de la imagen.
   */
  public startCropImage() {
    this.loading = true;
  }

  /**
   * Se ejecuta al cargar erroneamente la imagen.
   */
  public loadImageFailed() {
    this.loading = false;
    this.dynamicDialogMessageService.open({
      type: 'error',
      header: {
        text: 'Ha ocurrido un error al cargar el archivo.',
      },
    });
    this.reset();
  }

  /**
   * Verirfica si es cambiado.
   * @param obj - Callback.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  public onChange = (obj: any) => {};

  /**
   * Verirfica si es tocado.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  public onTouch: any = () => {};

  /**
   * Verirfica si es escrito.
   * @param obj - Callback.
   * @throws Error.
   */
  public writeValue(obj: any): void {
    const file = document.getElementById('choose-file') as HTMLInputElement;
    if (file) file.value = '';

    this._formControl.patchValue(obj);
  }

  /**
   * Verirfica si es cambiado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Verirfica si es tocado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /**
   * Verifica si el campo fue deshabilitado.
   * @param isDisabled - Bandera.
   */
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this._formControl.disable();
    } else {
      this._formControl.enable();
    }
  }

  /**
   * Evento para evitar qye el archivo se abra.
   * @param ev - Evento.
   */
  public dragOverHandler(ev: any) {
    ev.preventDefault();
  }

  /**
   * Evento drag and drop de los archivos.
   * @param ev - Evento.
   */
  public dropHandler(ev: any) {
    ev.preventDefault();
    // [...ev.dataTransfer.items] if (item.kind === 'file')
    this.fileChangeEvent({
      ...ev,
      target: {
        files: [...ev.dataTransfer.files],
      },
    });
  }
}
