import * as $ from 'jquery';
import { Injectable } from '@angular/core';

/**
 * Servicio de utlidad.
 */
@Injectable()
export class Utils {
  /**
   * No sobreescribir.
   */
  public notOverwrite() {
    $('.input__item')
      .keyup(function () {
        const value = $(this).val();
        if (value != '') {
          $(this).addClass('text__input');
        } else if (value == '') {
          $(this).removeClass('text__input');
        }
      })
      .keyup();
  }
}

/**
 * Convierte una fecha Unix.
 * @param unix_timestamp - Unix date.
 * @returns String.
 */
export const convertUnixDate = (unix_timestamp: number) => {
  let date;
  if (`${unix_timestamp}`.length > 10) {
    date = new Date(unix_timestamp);
  } else {
    date = new Date(unix_timestamp * 1000);
  }
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const resultDate = `${day}/${month}/${year} ${valideTime(hours)}:${valideTime(
    minutes
  )}:${valideTime(seconds)}`;
  return resultDate;
};

/**
 * Valida el tiempo.
 * @param _time - Tiempo.
 * @returns String.
 */
const valideTime = (_time: number) => {
  return _time.toString().length === 1 ? `0${_time}` : `${_time}`;
};

/**
 * Valida la existencia de un valor.
 * @param value - Valor.
 * @returns Boolean.
 */
export const hasValue = (value: any) => {
  return typeof value !== 'undefined' && value !== null;
};

/**
 * Elimina los elementos duplicados de un arreglo.
 * @param arr - Arreglo.
 * @returns Arreglo.
 */
export const removeDuplicated = (arr: any[]) => {
  const unique = arr.filter((val, i) => {
    return arr.indexOf(val) == i;
  });
  return unique;
};

/**
 * Valida si dos strings son iguales.
 * @param val - String.
 * @param val1 - String.
 * @param trim - Borrar espacios.
 * @returns Arreglo.
 */
export const isEqualString = (val: string, val1: string, trim?: boolean) => {
  if (trim) {
    return (
      (val || '').trim().toLowerCase() === (val1 || '').trim().toLowerCase()
    );
  } else {
    return (val || '').toLowerCase() === (val1 || '').toLowerCase();
  }
};

/**
 * Convierte un string en objeto.
 * @param value - Valor.
 * @returns Object.
 */
export const jParse = (value: any) => {
  return JSON.parse(value);
};

/**
 * Convierte un objeto en string.
 * @param value - Valor.
 * @returns String.
 */
export const jStringify = (value: any) => {
  return JSON.stringify(value);
};

/**
 * Convierte un objeto en string.
 * @param value - Valor.
 * @returns String.
 */
export const toNumber = (value: any) => {
  if (!isNaN(Number(value))) {
    return Number(value);
  } else {
    return 0;
  }
};
