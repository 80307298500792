import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ThemeRoot } from '@wp-back-office/app/dashboard';

export const themeActions = createActionGroup({
  source: 'Theme',
  events: {
    'obtener tema': emptyProps(),
    'obtener tema exitoso': props<{
      /**
       * Grupos.
       */
      themes: Record<string, ThemeRoot>;
    }>(),
    'obtener tema error': emptyProps(),
    'seleccionar tema': props<{
      /**
       * Grupos.
       */
      themeSelected: ThemeRoot;
      /**
       * Flag si es primera consulta.
       */
      isFirst?: boolean;
    }>(),
    'seleccionar tema exitoso': props<{
      /**
       * Grupos.
       */
      themeSelected: ThemeRoot;
    }>(),
  },
});
