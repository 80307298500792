import { createAction, props } from '@ngrx/store';
import {
  Catalogue,
  CataloguePASType,
} from '../../../models/contents/catalogue.models';

export const KeyValueAmortizationCredits = createAction(
  '[KeyValueAmortizationCredits] Cargar Catalogos',
  props<{
    /**
     * Ruta.
     */
    path: CataloguePASType;
  }>()
);

export const KeyValueAmortizationCreditsSuccess = createAction(
  '[KeyValueAmortizationCredits] Crear Catalogos Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueAmortizationCreditsError = createAction(
  '[KeyValueAmortizationCredits] Cargar Catalogos Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
