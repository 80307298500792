import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  Catalogue,
  CatalogueTasksService,
} from '@wp-back-office/core/commons-backoffice';
import { mergeMap, map, catchError, of } from 'rxjs';
import * as actionsEffects from '../../actions';

/**
 * Efecto de obtener catalogos tipo de identificación.
 */
@Injectable()
export class KeyValueIdentificationTypeEffects {
  /**
   * Crea una instancia de .
   * @param actions$ - Acciones.
   * @param catalogueTasksService - Servicio de catalogo.
   */
  constructor(
    private actions$: Actions,
    private catalogueTasksService: CatalogueTasksService
  ) {}

  /**
   * Obtener Catalogos.
   */
  public KeyValueCatalogues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.catalogueQueryActions.getTypeOfIdentification),
      mergeMap(actions =>
        this.catalogueTasksService.getCatalogueTasks(actions.path).pipe(
          map((keyValue: Catalogue[]) =>
            actionsEffects.catalogueQueryActions.getTypeOfIdentificationSuccess(
              { keyValue }
            )
          ),
          catchError(err =>
            of(
              actionsEffects.catalogueQueryActions.getTypeOfIdentificationError(
                { payload: err }
              )
            )
          )
        )
      )
    )
  );
}

/**
 * Efecto de obtener catalogos actividades economicas.
 */
@Injectable()
export class KeyValueEconomicActivitiesEffects {
  /**
   * Crea una instancia de catalogos.
   * @param actions$ - Acciones.
   * @param catalogueTasksService - Servicio de catalogo.
   */
  constructor(
    private actions$: Actions,
    private catalogueTasksService: CatalogueTasksService
  ) {}

  /**
   * Obtener Catalogos.
   */
  public KeyValueCatalogues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.catalogueQueryActions.getEconomicActivities),
      mergeMap(actions =>
        this.catalogueTasksService.getCatalogueTasks(actions.path).pipe(
          map((keyValue: Catalogue[]) =>
            actionsEffects.catalogueQueryActions.getEconomicActivitiesSuccess({
              keyValue,
            })
          ),
          catchError(err =>
            of(
              actionsEffects.catalogueQueryActions.getEconomicActivitiesError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}

/**
 * Efecto de obtener catalogos Respuesta SI o NO.
 */
@Injectable()
export class KeyValueResponseEffects {
  /**
   * Crea una instancia de catalogos.
   * @param actions$ - Acciones.
   * @param catalogueTasksService - Servicio de catalogo.
   */
  constructor(
    private actions$: Actions,
    private catalogueTasksService: CatalogueTasksService
  ) {}

  /**
   * Obtener Catalogos.
   */
  public KeyValueCatalogues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.catalogueQueryActions.getResponse),
      mergeMap(actions =>
        this.catalogueTasksService.getCatalogueTasks(actions.path).pipe(
          map((keyValue: Catalogue[]) =>
            actionsEffects.catalogueQueryActions.getResponseSuccess({
              keyValue,
            })
          ),
          catchError(err =>
            of(
              actionsEffects.catalogueQueryActions.getResponseError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}

/**
 * Efecto de obtener catalogos de tipo de contrato.
 */
@Injectable()
export class KeyValueContractTypeEffects {
  /**
   * Crea una instancia de catalogos.
   * @param actions$ - Acciones.
   * @param catalogueTasksService - Servicio de catalogo.
   */
  constructor(
    private actions$: Actions,
    private catalogueTasksService: CatalogueTasksService
  ) {}

  /**
   * Obtener Catalogos.
   */
  public KeyValueCatalogues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.catalogueQueryActions.getContractType),
      mergeMap(actions =>
        this.catalogueTasksService.getCatalogueTasks(actions.path).pipe(
          map((keyValue: Catalogue[]) =>
            actionsEffects.catalogueQueryActions.getContractTypeSuccess({
              keyValue,
            })
          ),
          catchError(err =>
            of(
              actionsEffects.catalogueQueryActions.getContractTypeError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}

/**
 * Efecto de obtener catalogos de tipo de regimen.
 */
@Injectable()
export class KeyValueRegimeTypeEffects {
  /**
   * Crea una instancia de catalogos.
   * @param actions$ - Acciones.
   * @param catalogueTasksService - Servicio de catalogo.
   */
  constructor(
    private actions$: Actions,
    private catalogueTasksService: CatalogueTasksService
  ) {}

  /**
   * Obtener Catalogos.
   */
  public KeyValueCatalogues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.catalogueQueryActions.getRegimeType),
      mergeMap(actions =>
        this.catalogueTasksService.getCatalogueTasks(actions.path).pipe(
          map((keyValue: Catalogue[]) =>
            actionsEffects.catalogueQueryActions.getRegimeTypeSuccess({
              keyValue,
            })
          ),
          catchError(err =>
            of(
              actionsEffects.catalogueQueryActions.getRegimeTypeError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}

/**
 * Efecto de obtener catalogos de seguridad social.
 */
@Injectable()
export class KeyValueSocialSecurityEffects {
  /**
   * Crea una instancia de catalogos.
   * @param actions$ - Acciones.
   * @param catalogueTasksService - Servicio de catalogo.
   */
  constructor(
    private actions$: Actions,
    private catalogueTasksService: CatalogueTasksService
  ) {}

  /**
   * Obtener Catalogos.
   */
  public KeyValueCatalogues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.catalogueQueryActions.getSocialSecurity),
      mergeMap(actions =>
        this.catalogueTasksService.getCatalogueTasks(actions.path).pipe(
          map((keyValue: Catalogue[]) =>
            actionsEffects.catalogueQueryActions.getSocialSecuritySuccess({
              keyValue,
            })
          ),
          catchError(err =>
            of(
              actionsEffects.catalogueQueryActions.getSocialSecurityError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}

/**
 * Efecto de obtener catalogos de tipo de usuarios.
 */
@Injectable()
export class KeyValueUserTypeEffects {
  /**
   * Crea una instancia de catalogos.
   * @param actions$ - Acciones.
   * @param catalogueTasksService - Servicio de catalogo.
   */
  constructor(
    private actions$: Actions,
    private catalogueTasksService: CatalogueTasksService
  ) {}

  /**
   * Obtener Catalogos.
   */
  public KeyValueCatalogues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.catalogueQueryActions.getUserType),
      mergeMap(actions =>
        this.catalogueTasksService.getCatalogueTasks(actions.path).pipe(
          map((keyValue: Catalogue[]) =>
            actionsEffects.catalogueQueryActions.getUserTypeSuccess({
              keyValue,
            })
          ),
          catchError(err =>
            of(
              actionsEffects.catalogueQueryActions.getUserTypeError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}

/**
 * Efecto de obtener catalogos de estado.
 */
@Injectable()
export class KeyValueStatusEffects {
  /**
   * Crea una instancia de catalogos.
   * @param actions$ - Acciones.
   * @param catalogueTasksService - Servicio de catalogo.
   */
  constructor(
    private actions$: Actions,
    private catalogueTasksService: CatalogueTasksService
  ) {}

  /**
   * Obtener Catalogos.
   */
  public KeyValueCatalogues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.catalogueQueryActions.getStatus),
      mergeMap(actions =>
        this.catalogueTasksService.getCatalogueTasks(actions.path).pipe(
          map((keyValue: Catalogue[]) =>
            actionsEffects.catalogueQueryActions.getStatusSuccess({
              keyValue,
            })
          ),
          catchError(err =>
            of(
              actionsEffects.catalogueQueryActions.getStatusError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}

/**
 * Efecto de obtener catalogos de región.
 */
@Injectable()
export class KeyValueRegionEffects {
  /**
   * Crea una instancia de catalogos.
   * @param actions$ - Acciones.
   * @param catalogueTasksService - Servicio de catalogo.
   */
  constructor(
    private actions$: Actions,
    private catalogueTasksService: CatalogueTasksService
  ) {}

  /**
   * Obtener Catalogos.
   */
  public KeyValueCatalogues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.catalogueQueryActions.getRegion),
      mergeMap(actions =>
        this.catalogueTasksService.getCatalogueTasks(actions.path).pipe(
          map((keyValue: Catalogue[]) =>
            actionsEffects.catalogueQueryActions.getRegionSuccess({
              keyValue,
            })
          ),
          catchError(err =>
            of(
              actionsEffects.catalogueQueryActions.getRegionError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}

/**
 * Efecto de obtener catalogos de tipo de venta.
 */
@Injectable()
export class KeyValueTypeSaleEffects {
  /**
   * Crea una instancia de catalogos.
   * @param actions$ - Acciones.
   * @param catalogueTasksService - Servicio de catalogo.
   */
  constructor(
    private actions$: Actions,
    private catalogueTasksService: CatalogueTasksService
  ) {}

  /**
   * Obtener Catalogos.
   */
  public KeyValueCatalogues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.catalogueQueryActions.getTypeSale),
      mergeMap(actions =>
        this.catalogueTasksService.getCatalogueTasks(actions.path).pipe(
          map((keyValue: Catalogue[]) =>
            actionsEffects.catalogueQueryActions.getTypeSaleSuccess({
              keyValue,
            })
          ),
          catchError(err =>
            of(
              actionsEffects.catalogueQueryActions.getTypeSaleError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}

/**
 * Efecto de obtener catalogos de tipo de sociedad.
 */
@Injectable()
export class KeyValueKindSocietyEffects {
  /**
   * Crea una instancia de catalogos.
   * @param actions$ - Acciones.
   * @param catalogueTasksService - Servicio de catalogo.
   */
  constructor(
    private actions$: Actions,
    private catalogueTasksService: CatalogueTasksService
  ) {}

  /**
   * Obtener Catalogos.
   */
  public KeyValueCatalogues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.catalogueQueryActions.getKindSociety),
      mergeMap(actions =>
        this.catalogueTasksService.getCatalogueTasks(actions.path).pipe(
          map((keyValue: Catalogue[]) =>
            actionsEffects.catalogueQueryActions.getKindSocietySuccess({
              keyValue,
            })
          ),
          catchError(err =>
            of(
              actionsEffects.catalogueQueryActions.getKindSocietyError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}

/**
 * Efecto de obtener catalogos de tipo de contribuyente.
 */
@Injectable()
export class KeyValueTypeContributorEffects {
  /**
   * Crea una instancia de catalogos.
   * @param actions$ - Acciones.
   * @param catalogueTasksService - Servicio de catalogo.
   */
  constructor(
    private actions$: Actions,
    private catalogueTasksService: CatalogueTasksService
  ) {}

  /**
   * Obtener Catalogos.
   */
  public KeyValueCatalogues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.catalogueQueryActions.getTypeContributor),
      mergeMap(actions =>
        this.catalogueTasksService.getCatalogueTasks(actions.path).pipe(
          map((keyValue: Catalogue[]) =>
            actionsEffects.catalogueQueryActions.getTypeContributorSuccess({
              keyValue,
            })
          ),
          catchError(err =>
            of(
              actionsEffects.catalogueQueryActions.getTypeContributorError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}

/**
 * Efecto de obtener catalogos de forma de pago.
 */
@Injectable()
export class KeyValueFormPaymentEffects {
  /**
   * Crea una instancia de catalogos.
   * @param actions$ - Acciones.
   * @param catalogueTasksService - Servicio de catalogo.
   */
  constructor(
    private actions$: Actions,
    private catalogueTasksService: CatalogueTasksService
  ) {}

  /**
   * Obtener Catalogos.
   */
  public KeyValueCatalogues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.catalogueQueryActions.getFormPayment),
      mergeMap(actions =>
        this.catalogueTasksService.getCatalogueTasks(actions.path).pipe(
          map((keyValue: Catalogue[]) =>
            actionsEffects.catalogueQueryActions.getFormPaymentSuccess({
              keyValue,
            })
          ),
          catchError(err =>
            of(
              actionsEffects.catalogueQueryActions.getFormPaymentError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}

/**
 * Efecto de obtener catalogos de fondos de pensión.
 */
@Injectable()
export class KeyValuePensionFundsEffects {
  /**
   * Crea una instancia de catalogos.
   * @param actions$ - Acciones.
   * @param catalogueTasksService - Servicio de catalogo.
   */
  constructor(
    private actions$: Actions,
    private catalogueTasksService: CatalogueTasksService
  ) {}

  /**
   * Obtener Catalogos.
   */
  public KeyValueCatalogues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.catalogueQueryActions.getPensionFunds),
      mergeMap(actions =>
        this.catalogueTasksService.getCatalogueTasks(actions.path).pipe(
          map((keyValue: Catalogue[]) =>
            actionsEffects.catalogueQueryActions.getPensionFundsSuccess({
              keyValue,
            })
          ),
          catchError(err =>
            of(
              actionsEffects.catalogueQueryActions.getPensionFundsError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}

/**
 * Efecto de obtener catalogos de Eps.
 */
@Injectable()
export class KeyValueEpsEffects {
  /**
   * Crea una instancia de catalogos.
   * @param actions$ - Acciones.
   * @param catalogueTasksService - Servicio de catalogo.
   */
  constructor(
    private actions$: Actions,
    private catalogueTasksService: CatalogueTasksService
  ) {}

  /**
   * Obtener Catalogos.
   */
  public KeyValueCatalogues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.catalogueQueryActions.getEps),
      mergeMap(actions =>
        this.catalogueTasksService.getCatalogueTasks(actions.path).pipe(
          map((keyValue: Catalogue[]) =>
            actionsEffects.catalogueQueryActions.getEpsSuccess({
              keyValue,
            })
          ),
          catchError(err =>
            of(
              actionsEffects.catalogueQueryActions.getEpsError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}
