import { Mapper } from '../utils/mapper';
import { Permission, PermissionList } from '../models/group.model';
/**
 * Interfaz del Proceso.
 */
interface Process {
  /**
   * Codigo del proceso.
   */
  code: string;
  /**
   * Descripción.
   */
  description: string;
}
/**
 * Interfaz del Grupo desde el Backend.
 */
export interface PermissionsAWS {
  /**
   * Nombre del permiso.
   */
  name: string;
  /**
   * Opciones del permiso.
   */
  options: {
    /**
     *
     */
    [key: string]: Process[] | Permission;
  }[];
}
export class PermissionMapper extends Mapper<PermissionsAWS, PermissionList[]> {
  /**
   * Crea una instancia de GroupMapper.
   */
  constructor() {
    super();
  }
  /**
   * Mapea desde el formato AWS a nuestro modelo.
   * @param param - Parametros desde el backend.
   * @returns - Objeto de nuestro modelo.
   */
  public override mapFrom(param: PermissionsAWS): PermissionList[] {
    return Object.entries(param).map(item => {
      if (Array.isArray(item[1])) {
        return {
          name: item[0],
          options: item[1].map(
            process =>
              ({
                ...process,
                category: item[0],
              } as Permission)
          ),
        };
      } else {
        return {
          name: item[0],
          options: Object.values(item[1]) as Permission[],
        };
      }
    });
  }
}
