/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChildren,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import {
  ControlConfig,
  TimeOut,
} from '@wp-back-office/shared/dynamic-components';

import { DynamicFormService } from '../../../../services/dynamic-form.service';
import * as moment from 'moment';
import 'moment-duration-format';

let interval: any = undefined;
/**
 * Componente para codigos de verficacion.
 */
@Component({
  selector: 'wp-back-office-form-control-code-verification',
  templateUrl: './form-control-code-verification.component.html',
  styleUrls: ['./form-control-code-verification.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlCodeVerificationComponent),
      multi: true,
    },
  ],
})
export class FormControlCodeVerificationComponent
  implements OnInit, OnDestroy, OnChanges, ControlValueAccessor
{
  /**
   * Configuracion del control.
   */
  @Input()
  public control?: ControlConfig;
  /**
   * Salida del boton submit.
   */
  @Output()
  public submitValue = new EventEmitter<boolean>(false);
  /**
   * Salida del boton submit.
   */
  @Output()
  public reSendCode = new EventEmitter<boolean>();
  /**
   * Valor JSON formulario.
   */
  @Output()
  public timeOutOTP = new EventEmitter<TimeOut>();
  /**
   * Listado de entradas formularios.
   */
  @ViewChildren('target')
  public inputs?: QueryList<ElementRef>;
  /**
   * Contador de Dígito restantes.
   */
  public digitsLeft: number;
  /**
   * Contador de Dígito restantes.
   */
  public timeLeft: number;
  /**
   * Form control del campo.
   */
  public codeVerification: FormGroup;
  /**
   * Destructor sujeto.
   */
  private destroy$: Subject<boolean>;

  /**
   * Contador.
   */
  public timerCount!: string;

  /**
   * Limite de reintentos.
   */
  public retryLimit!: number;

  /**
   * Crea una instancia de la clase.
   * @param renderer - Extend this base class to implement custom rendering.
   * @param dynamicFormService - Servicio de formularios dinamicos.
   * @param cdRef - Detector de cambios.
   */
  constructor(
    private renderer: Renderer2,
    public dynamicFormService: DynamicFormService,
    public cdRef: ChangeDetectorRef
  ) {
    this.digitsLeft = 0;
    this.timeLeft = 0;
    this.control = {};
    this.codeVerification = new FormGroup({});
    this.destroy$ = new Subject();
    this.retryLimit = 0;
  }

  /**
   * Se ejecutar al iniciar el componente.
   */
  public ngOnInit() {
    if (this.control) {
      const length =
        this.control.codeVerificationConfig &&
        this.control.codeVerificationConfig?.codeVerificationQuantity
          ? this.control.codeVerificationConfig?.codeVerificationQuantity
          : 0;
      for (let index = 0; index < length; index++) {
        const control = `text-${index}`;
        this.codeVerification.addControl(
          control,
          new FormControl('', [Validators.required, Validators.maxLength(1)])
        );
      }

      if (this.control.codeVerificationConfig?.countDown) {
        if (this.control.codeVerificationConfig?.retryLimit) {
          this.retryLimit = 1;
        }
        this.countDown(this.control.codeVerificationConfig.countDown);
      }
    }
  }

  /**
   * Detecta el cambio de las entradas y salidas.
   * @param changes - Cambios.
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['control'].currentValue) {
      this.digitsLeft =
        this.control &&
        this.control.codeVerificationConfig &&
        this.control.codeVerificationConfig?.codeVerificationQuantity
          ? this.control.codeVerificationConfig?.codeVerificationQuantity
          : 0;
    }
  }

  /**
   * Se ejecuta al destruir el componente.
   */
  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * Siguiente campo.
   * @param event - Evento del campo.
   * @param index - Indice.
   */
  public onNext(event: any, index: any) {
    if (event.code.includes('Digit') && this.control) {
      const cont: number = index + 1;
      const length =
        this.control.codeVerificationConfig &&
        this.control.codeVerificationConfig?.codeVerificationQuantity
          ? this.control.codeVerificationConfig?.codeVerificationQuantity
          : 0;
      if (cont < length && this.inputs) {
        if (this.digitsLeft <= length && this.digitsLeft > 0)
          this.digitsLeft = this.digitsLeft - 1;
        this.renderer
          .selectRootElement(this.inputs.get(cont)?.nativeElement)
          .focus();
      }
    }

    if (event.code === 'Backspace' && this.control) {
      const cont: number = index - 1;
      const length =
        this.control.codeVerificationConfig &&
        this.control.codeVerificationConfig?.codeVerificationQuantity
          ? this.control.codeVerificationConfig?.codeVerificationQuantity
          : 0;
      if (cont >= 0 && this.inputs) {
        if (this.digitsLeft < length) this.digitsLeft = this.digitsLeft + 1;
        this.renderer.setProperty(
          this.inputs.get(cont)?.nativeElement,
          'value',
          ''
        );
        this.renderer
          .selectRootElement(this.inputs.get(cont)?.nativeElement)
          .focus();
      }
    }
  }

  /**
   * Verifica que la entrada no sea una letra solo numerico.
   * @param event - Entrada de teclado evento.
   * @returns Booleano.
   */
  public numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  /**
   * Evento submit del formulario.
   */
  public onSubmit() {
    if (this.codeVerification.valid) {
      let value = '';
      for (const field in this.codeVerification.controls) {
        value = value + this.codeVerification.get(field)?.value;
      }
      this.onChange(value);
      this.submitValue.emit(true);
    }
  }

  /**
   * Evento submit del formulario.
   */
  public onReSend() {
    if (this.control?.codeVerificationConfig?.retryLimit) {
      this.retryLimit = this.retryLimit + 1;
      if (this.retryLimit > this.control?.codeVerificationConfig?.retryLimit) {
        this.reSendCode.emit(false);
      } else {
        this.reSendCode.emit(true);
        if (this.control.codeVerificationConfig?.countDown) {
          clearInterval(interval);
          this.countDown(this.control.codeVerificationConfig.countDown);
        }
      }
    } else {
      this.reSendCode.emit(true);
      if (this.control?.codeVerificationConfig?.countDown) {
        clearInterval(interval);
        this.countDown(this.control?.codeVerificationConfig.countDown);
      }
    }
  }

  /**
   * Evento countDown.
   * @param duration - Duracion en segundos.
   */
  public countDown(duration: number) {
    let timer = duration;
    interval = setInterval(() => {
      const timeDuration = moment.duration(timer, 'seconds');
      const formatted = timeDuration.format('H:mm:ss', {
        trim: 'left',
        forceLength: true,
        stopTrim: 'mm'
      });
      this.timerCount = formatted;

      if (--timer < 0) {
        timer = duration;
      }

      if (this.timerCount == '00:00') {
        this.timeOutOTP.emit({
          key: this.control?.key || '',
        });
        clearInterval(interval);
      }
      this.cdRef.detectChanges();
    }, 1000);
  }

  /**
   * Verirfica si es cambiado.
   * @param obj - Callback.
   */
  public onChange = (obj: any) => {};

  /**
   * Verirfica si es tocado.
   */
  public onTouch = () => {};

  /**
   * Verirfica si es escrito.
   * @param obj - Callback.
   * @throws Error.
   */
  public writeValue(obj: any): void {
    this.codeVerification.patchValue(obj);
  }
  /**
   * Verirfica si es cambiado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /**
   * Verirfica si es tocado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /**
   * Estado.
   * @param isDisabled - Bandera.
   */
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.codeVerification.disable();
    } else {
      this.codeVerification.enable();
    }
  }

  /**
   * Se ejecuta al pegar un texto.
   * @param event - ClipboardEvent.
   */
  public async onPaste(event: ClipboardEvent) {
    const pastedText = event?.clipboardData?.getData('text');
    if (pastedText && pastedText?.length === this.inputs?.length) {
      let value: any = {};
      const array = pastedText.split('');
      let isValid = true;
      array.every((num: any, index: number) => {
        if (!isNaN(Number(num))) {
          const obj = `{"text-${index}": "${num}"}`;
          value = {
            ...value,
            ...JSON.parse(obj),
          };
          return true;
        } else {
          setTimeout(() => {
            this.codeVerification.reset();
          }, 100);
          isValid = false;
          return false;
        }
      });

      if (isValid) {
        setTimeout(() => {
          this.codeVerification.patchValue(value);
          if (this.inputs) {
            this.renderer
              .selectRootElement(this.inputs.get(4)?.nativeElement)
              .focus();
          }
        }, 100);
      }
    }
  }
}
