import { LogModel } from '@wp-back-office/core/workflow-sdk';
import { ResponseAWS } from 'libs/core/workflow-sdk/src/lib/models/response-aws.model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';
import { FinancialPlan } from 'libs/core/workflow-sdk/src/lib/models/financial-plan.model';

export const logsFinancialPlansActions = createActionGroup({
  source: 'Financial Plan Logs',
  events: {
    Reset: emptyProps(),
    'Get List': props<{
      /**
       * Codigo plan financiero.
       */
      code: number;
      /**
       * Filtro de usuario.
       */
      userFilter?: string;
      /**
       * Tamaño de la pagina.
       */
      pageSize?: number;
      /**
       * Token de la ultima pagina.
       */
      lastPageToken?: string;
    }>(),
    Success: props<{
      /**
       * Logs de planes financieros.
       */
      object: LogModel<FinancialPlan>[];
    }>(),
    Error: props<{
      /**
       * Respuesta error Http.
       */
      error: ErrorHttp;
    }>(),
  },
});
