import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';
import { Catalogue } from '@wp-back-office/core/store';
import {
  Vehicle,
  VhTecnicalDataSheetResponse,
} from 'libs/app/admin-products/src/lib/models/vehicle.model';

import {
  concatMap,
  delay,
  filter,
  interval,
  map,
  Observable,
  of,
  Subject,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { GroupAWS, GroupMapper } from '../mappers/group.mapper';
import { PermissionMapper } from '../mappers/permission.mapper';
import { VehicleDetailMapper } from '../mappers/vehicle-detail.mapper';
import { PriceType } from '../models/price-type.model';
import { ResponseAWS } from '../models/response-aws.model';
import {
  VehicleDetail,
  VehicleDetailInput,
} from '../models/vehicle-detail.model';
/**
 * Servicio de control de acceso.
 */
@Injectable()
// eslint-disable-next-line @nrwl/nx/workspace/doc-elements-angular
export class ProductsVehiclesService {
  /**
   * Instancia del mapper de grupo.
   */
  public groupMapper = new GroupMapper();
  /**
   * Instancia del mapper de permisos.
   */
  public permissionMapper = new PermissionMapper();
  /**
   * Instancia del mapper de detalle vehiculos.
   */
  public vehicleDetailMapper = new VehicleDetailMapper();
  /**
   * Crea una instancia de WorkflowService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}

  /**
   * Obtiene la lista de vehiculos.
   * @param pageSize - Tamaño de la pagina.
   * @param paginationToken - Token para la paginacion.
   * @param typeFilter - Columna a filtrar.
   * @param page - Pagina para el paginador.
   * @param filter - Valor a filtrar.
   * @returns Observable.
   */
  public getVehicles(
    pageSize: number,
    page: number,
    filter?: string
  ): Observable<any> {
    const params: any = {
      pageSize: pageSize,
      page: page,
    };

    const filterAux: string = filter ? `?${filter}` : '';

    return this.http
      .get(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/vehicle${filterAux}`,
        {
          params: params,
        }
      )
      .pipe(delay(1000));
  }

  /**
   * Obtiene la lista de vehiculos.
   * @param code - Code del vehiculo.
   * @returns Observable.
   */
  public getVehicle(code: string): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/vehicle/${code}`
    );
  }

  /**
   * Crea un vehiculo nuevo.
   * @param data - Datos que recibe del formulario de modelo de vehiculo.
   * @returns Observable.
   */
  public createVehicle(data: Vehicle): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/vehicle`,
      data,
      {
        params: { isLaunchVehicle: data.launch_vehicle },
      }
    );
  }

  /**
   * Obtiene la lista de opciones de filtros para vehiculos.
   * @param description - Paginado.
   * @param pageSize - Paginado.
   * @param page - Paginado.
   * @returns Observable.
   */
  public getBrandsVehicle(
    description?: string,
    pageSize?: number | string,
    page?: number
  ): Observable<any> {
    let params = {};
    if (description) {
      params = { ...params, description };
    }
    if (pageSize) {
      params = { ...params, pageSize };
    }
    if (page) {
      params = { ...params, page };
    }
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/brand`,
      {
        params: params,
      }
    );
  }

  /**
   * Crea el parametro de vehículo.
   * @param data - Nombre del paramtro, del perametro reelacionado y la nueva descripción.
   * @returns Observable.
   */
  public createVehicleParam(data: any): Observable<any> {
    if (data && data.this_param_name) {
      switch (data.this_param_name) {
        case 'brand': {
          return this.http.post(
            `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/brand`,
            {
              description: data.this_param_description.trim(),
            }
          );
          break;
        }
        case 'line': {
          return this.http.post(
            `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/line`,
            {
              code_brand: data.related_param_code,
              description: data.this_param_description.trim(),
            }
          );
          break;
        }
        case 'family': {
          return this.http.post(
            `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/family`,
            {
              code_line: data.related_param_code,
              description: data.this_param_description.trim(),
            }
          );
          break;
        }
        case 'version': {
          return this.http.post(
            `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/version`,
            {
              code_family: data.related_param_code,
              description: data.this_param_description.trim(),
            }
          );
          break;
        }
      }
    }
    return of(null);
  }

  /**
   * Obtiene la lista de precios para vehiculos.
   * @returns Observable.
   */
  public getPrice(): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/price`
    );
  }

  /**
   * Obtiene la lista de referencias para vehiculos.
   * @returns Observable.
   */
  public getReference(): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/reference`
    );
  }

  /**
   * Obtiene la lista de tipos de precios.
   * @returns Observable.
   */
  public getTypePrice(
    pageSize?: number | string,
    page?: number,
    description?: string
  ): Observable<Catalogue[]> {
    let params = {};
    if (pageSize) {
      params = { ...params, pageSize: pageSize };
    }
    if (page) {
      params = { ...params, page: page };
    }
    if (description) {
      params = { ...params, description: description };
    }
    return this.http
      .get<ResponseAWS<PriceType>>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/type_price`,
        {
          params: params,
        }
      )
      .pipe(
        map(response =>
          response.items.map(item => ({
            code: item.code.toString(),
            description: item.description,
          }))
        )
      );
  }

  /**
   * Obtiene la lista de tipos de precios.
   * @returns Observable.
   */
  public getListTypePrices(pageSize?: number | string, group_price?: string) {
    let params = {};
    if (pageSize) {
      params = { ...params, pageSize: pageSize };
    }

    if (group_price) {
      params = { ...params, group_price: group_price };
    }
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/type_price`,
      {
        params: params,
      }
    );
  }

  /**
   * Obtiene la lista de tipos de referencia.
   * @returns Observable.
   */
  public deleteVehicleTypePrice(codePrice: any): Observable<any> {
    return this.http.delete(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/price/${codePrice}`
    );
  }

  /**
   * Obtiene la lista de tipos de referencia.
   * @returns Observable.
   */
  public getTypeReference(): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/type_reference`
    );
  }

  /**
   * Obtiene la lista de tipos de moneda.
   * @returns Observable.
   */
  public getTypeCurrency(): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/type_currency`
    );
  }

  /**
   * Obtiene la lista de tipos de servicio.
   * @returns Observable.
   */
  public getTypeService(): Observable<any> {
    return this.http.get<any>(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/vehicle-service`
    );
  }
  /**
   * Obtiene la lista de opciones de lineas para vehiculos.
   * @param pageSize - Paginado.
   * @returns Observable.
   */
  public getLinesVehicle(pageSize?: number | string): Observable<any> {
    let params = {};
    if (pageSize) {
      params = { ...params, pageSize: pageSize };
    }
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/line`,
      {
        params: params,
      }
    );
  }

  /**
   * Obtiene la lista de opciones de lineas por codigo de marca para vehiculos.
   * @param brandCode - Codigo de la marca de la linea.
   * @param description - Descripcion.
   * @param pageSize - Paginado.
   * @param page - Pagina.
   * @returns Observable.
   */
  public getLinesByBrandVehicle(
    brandCode: number,
    description?: string,
    pageSize?: number | string,
    page?: number
  ): Observable<any> {
    let params = {};
    if (description) {
      params = { ...params, description };
    }
    if (pageSize) {
      params = { ...params, pageSize };
    }
    if (page) {
      params = { ...params, page };
    }
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/line?code_brand=${brandCode}`,
      {
        params: params,
      }
    );
  }

  /**
   * Obtiene la lista de opciones de familias para vehiculos.
   * @param pageSize - Paginado.
   * @returns Observable.
   */
  public getFamilyVehicle(pageSize?: number | string): Observable<any> {
    let params = {};
    if (pageSize) {
      params = { ...params, pageSize: pageSize };
    }
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/family`,
      {
        params: params,
      }
    );
  }
  /**
   * Obtiene la lista de opciones de familias para vehiculos filtrado por linea.
   * @param lineCode - Codigo de la linea.
   * @param description - Descripcion.
   * @param pageSize - Paginado.
   * @param page - Pagina.
   * @returns Observable.
   */
  public getFamilyVehicleByLine(
    lineCode: number,
    description?: string,
    pageSize?: number | string,
    page?: number
  ): Observable<any> {
    let params = {};
    if (lineCode) {
      params = { ...params, code_line: lineCode };
    }
    if (description) {
      params = { ...params, description };
    }
    if (pageSize) {
      params = { ...params, pageSize };
    }
    if (page) {
      params = { ...params, page };
    }
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/family`,
      {
        params: params,
      }
    );
  }

  /**
   * Obtiene la lista de opciones de modelos para vehiculos.
   * @param pageSize - Paginado.
   * @returns Observable.
   */
  public getModelsVehicle(pageSize?: number | string): Observable<any> {
    let params = {};
    if (pageSize) {
      params = { ...params, pageSize: pageSize };
    }
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/model`,
      {
        params: params,
      }
    );
  }

  /**
   * Obtiene la lista de opciones de modelos para vehiculos.
   * @param pageSize - Paginado.
   * @param codeVersion - Codigo de la version.
   * @returns Observable.
   */
  public getModelsVehicleByVersion(
    codeVehicle: number,
    description?: string,
    pageSize?: number | string,
    page?: number
  ): Observable<any> {
    let params = {};
    if (description) {
      params = { ...params, description };
    }
    if (page) {
      params = { ...params, page };
    }
    if (pageSize) {
      params = { ...params, pageSize };
    }
    if (codeVehicle) {
      params = { ...params, code_vehicle: codeVehicle };
    }
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/model`,
      {
        params: params,
      }
    );
  }
  /**
   * Obtiene la lista  de referencias de vehiculo.
   * @returns Observable.
   */
  public getReferencesVehicle(): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/reference`
    );
  }

  /**
   * Obtiene la lista  de precios de vehiculo.
   * @returns Observable.
   */
  public getPricesVehicle(): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/price`
    );
  }
  /**
   * Obtiene la lista de opciones de versiones para vehiculos.
   * @param pageSize - Paginado.
   * @returns Observable.
   */
  public getVersionVehicle(pageSize?: number | string): Observable<any> {
    let params = {};
    if (pageSize) {
      params = { ...params, pageSize: pageSize };
    }
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/version`,
      {
        params: params,
      }
    );
  }

  /**
   * Obtiene la lista de opciones de versiones para vehiculos por codigo de familia.
   * @param familyCode
   * @param pageSize - Paginado.
   * @returns Observable.
   */
  public getVersionVehicleByFamily(
    familyCode: number,
    description?: string,
    pageSize?: number | string,
    page?: number
  ): Observable<any> {
    let params = {};
    if (description) {
      params = { ...params, description };
    }
    if (pageSize) {
      params = { ...params, pageSize };
    }
    if (page) {
      params = { ...params, page };
    }
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/version?code_family=${familyCode}`,
      {
        params: params,
      }
    );
  }

  /**
   * Obtiene los datos del modelo y elimina.
   * @param code - Código del modelo.
   * @returns Observable.
   */
  public deleteModelVehicle(code: string): Observable<any> {
    return this.http.delete<GroupAWS>(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/model/${code}`
    );
  }

  /**
   * Crea un modelo de vehiculo nuevo.
   * @param data - Datos que recibe del formulario de modelo de vehiculo.
   * @returns Observable.
   */
  public createModel(data: any): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/model`,
      data
    );
  }

  /**
   * Crea un tipo de precio por grupo de vehiculo.
   * @param data - Datos que recibe del formulario de tipo de precio por grupo de vehiculo.
   * @returns Observable.
   */
  public createTypePrice(data: any): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/type_price`,
      data
    );
  }

  /**
   * Actualiza los datos del servicio.
   * @param data - Data de actualización.
   * @param code - Código.
   * @returns Observable.
   */
  public updateModel(data: any, code: any): Observable<any> {
    return this.http.put<any>(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/model/${code}`,
      data
    );
  }

  /**
   * Obtiene la lista de opciones de filtros para vehiculos.
   * @param typePriceId - Paginado.
   * @param page - Paginado.
   * @param pageSize - Paginado.
   * @returns Observable.
   */
  public getBrandsByTypePriceVehicle(
    typePriceId: number,
    page?: number,
    pageSize?: number | string
  ): Observable<Catalogue[]> {
    let params = {};
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    return this.http
      .get<ResponseAWS<Catalogue>>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/${typePriceId}/brand`,
        {
          params: params,
        }
      )
      .pipe(
        map(response => {
          return response.items;
        })
      );
  }
  /**
   * Obtiene la lista de opciones de filtros para vehiculos.
   * @param typePriceId - Paginado.
   * @param brands - Paginado.
   * @param page - Paginado.
   * @param pageSize - Paginado.
   * @returns Observable.
   */
  public getLinesByTypePriceVehicle(
    typePriceId: number,
    brands?: Catalogue[],
    page?: number,
    pageSize?: number | string
  ): Observable<Catalogue[]> {
    let params = {};
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    if (brands !== undefined) {
      params = { ...params, 'brand_codes[]': brands.map(brand => brand.code) };
    }
    return this.http
      .get<ResponseAWS<Catalogue>>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/${typePriceId}/line`,
        {
          params: params,
        }
      )
      .pipe(
        map(response => {
          return response.items;
        })
      );
  }
  /**
   * Obtiene la lista de opciones de filtros para vehiculos.
   * @param typePriceId - Paginado.
   * @param lines - Paginado.
   * @param page - Paginado.
   * @param pageSize - Paginado.
   * @returns Observable.
   */
  public getFamiliesByTypePriceVehicle(
    typePriceId: number,
    lines?: Catalogue[],
    page?: number,
    pageSize?: number | string
  ): Observable<Catalogue[]> {
    let params = {};
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    if (lines !== undefined) {
      params = { ...params, 'line_codes[]': lines.map(line => line.code) };
    }
    return this.http
      .get<ResponseAWS<Catalogue>>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/${typePriceId}/family`,
        {
          params: params,
        }
      )
      .pipe(
        map(response => {
          return response.items;
        })
      );
  }
  /**
   * Obtiene la lista de opciones de filtros para vehiculos.
   * @param typePriceId - Paginado.
   * @param families - Paginado.
   * @param page - Paginado.
   * @param pageSize - Paginado.
   * @returns Observable.
   */
  public getVersionsByTypePriceVehicle(
    typePriceId: number,
    families?: Catalogue[],
    page?: number,
    pageSize?: number | string
  ): Observable<Catalogue[]> {
    let params = {};
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    if (families !== undefined) {
      params = {
        ...params,
        'family_codes[]': families.map(family => family.code),
      };
    }
    return this.http
      .get<ResponseAWS<Catalogue>>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/${typePriceId}/version`,
        {
          params: params,
        }
      )
      .pipe(
        map(response => {
          return response.items;
        })
      );
  }
  /**
   * Obtiene la lista de opciones de filtros para vehiculos.
   * @param typePriceId - Paginado.
   * @param codes - Paginado.
   * @param versions - Paginado.
   * @param page - Paginado.
   * @param pageSize - Paginado.
   * @returns Observable.
   */
  public getReferencesByTypePriceVehicle(
    typePriceId: number,
    codes?: string[],
    versions?: Catalogue[],
    code_price?: string,
    page?: number,
    pageSize?: number | string
  ): Observable<VehicleDetail[]> {
    let params = {};
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (code_price !== undefined) {
      params = { ...params, code_price };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    if (versions !== undefined) {
      params = {
        ...params,
        'version_codes[]': versions.map(version => version.code),
      };
    }
    if (codes !== undefined) {
      params = {
        ...params,
        'pr_code[]': codes,
      };
    }
    return this.http
      .get<ResponseAWS<VehicleDetailInput>>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/${typePriceId}/vehicle`,
        {
          params: params,
        }
      )
      .pipe(
        map(response => {
          return response.items.map(item =>
            this.vehicleDetailMapper.mapFrom(item)
          );
        })
      );
  }
  /**
   * Obtiene la lista de opciones de filtros para vehiculos.
   * @param typePriceId - Paginado.
   * @param references - Paginado.
   * @param page - Paginado.
   * @param pageSize - Paginado.
   * @returns Observable.
   */
  public getCodesByTypePriceVehicle(
    typePriceId: number,
    references?: Catalogue[],
    page?: number,
    pageSize?: number | string
  ): Observable<Catalogue[]> {
    let params = {};
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    if (references !== undefined) {
      params = {
        ...params,
        'reference_codes[]': references.map(reference => reference.code),
      };
    }
    return this.http
      .get<ResponseAWS<Catalogue>>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/${typePriceId}/version`,
        {
          params: params,
        }
      )
      .pipe(
        map(response => {
          return response.items;
        })
      );
  }
  /**
   * Obtiene la lista de opciones de filtros para vehiculos.
   * @param typePriceId - Paginado.
   * @param vehicles - Paginado.
   * @param page - Paginado.
   * @param pageSize - Paginado.
   * @returns Observable.
   */
  public getVehicleServicesByTypePriceVehicle(
    typePriceId: number,
    vehicles?: Catalogue[],
    page?: number,
    pageSize?: number | string
  ): Observable<Catalogue[]> {
    let params = {};
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    if (vehicles !== undefined) {
      params = {
        ...params,
        'vehicle_codes[]': vehicles.map(vehicle => vehicle.code),
      };
    }
    return this.http
      .get<ResponseAWS<Catalogue>>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/${typePriceId}/vehicle-service`,
        {
          params: params,
        }
      )
      .pipe(
        map(response => {
          return response.items;
        })
      );
  }
  /**
   * Obtiene la lista de opciones de filtros para vehiculos.
   * @param typePriceId - Paginado.
   * @param vehicles - Paginado.
   * @param page - Paginado.
   * @param pageSize - Paginado.
   * @returns Observable.
   */
  public getModelsByTypePriceVehicle(
    typePriceId: number,
    vehicles?: Catalogue[],
    page?: number,
    pageSize?: number | string,
    code_fasecolda?: number | string
  ): Observable<Catalogue[]> {
    let params = {};
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }

    if (code_fasecolda !== undefined) {
      params = { ...params, code_fasecolda };
    }
    if (vehicles !== undefined) {
      params = {
        ...params,
        'vehicle_codes[]': vehicles.map(vehicle => vehicle.code),
      };
    }
    return this.http
      .get<ResponseAWS<Catalogue>>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/${typePriceId}/model`,
        {
          params: params,
        }
      )
      .pipe(
        map((response: any) => {
          return response['models'].items;
        })
      );
  }

  ///----------------------------------

  /**
   * Obtiene la lista de datos tecnicos de vehiculo.
   * @param catalogueName - Nombre del catalogo en el endpoint.
   * @returns - Observable con la respuesta.
   */
  public getVehicleListTecnicalData(
    catalogueName: string
  ): Observable<VhTecnicalDataSheetResponse> {
    return this.http.get<VhTecnicalDataSheetResponse>(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/catalogue/${catalogueName}`
    );
  }

  /**
   * Envia un archivo excel, con el que se pueden crear vehiculos.
   * @param file - Archivo con el que se crean archivos.
   * @returns - Observable con la respuesta.
   */
  public postVehicleFileImport(file: any): Observable<any> {
    const headers = {
      'Content-Type': 'application/zip',
    };
    return this.http.put(
      `${this.enviroment.endpoints.urlPAS}/uploads/${this.enviroment.common.tenantId}/vehicles/fasecolda_vehiculos.zip`,
      file,
      { headers }
    );
  }

  /**
   * Envia un archivo excel, con el que se pueden crear vehiculos.
   * @returns - Observable con la respuesta.
   */
  public GetVehicleCreationFileUpdate(): Observable<any> {
    const date = new Date();
    const thisDateTimestamp = Math.floor(date.getTime() / 1000);

    return interval(8000).pipe(
      concatMap(() =>
        this.http.get(
          `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/vehicle/logs-status/bulkload`
        )
      ),
      filter((resp: any) => {
        const logStatus = resp.items[0].statusCode;
        const logTimestamp = resp.items[0].dateRegister;
        return (
          logTimestamp > thisDateTimestamp &&
          (logStatus === 'COMPLETE' || logStatus === 'ERROR')
        );
      }),
      take(1)
    );
  }

  /**
   * Obtiene todas las lineas que no se pueden enviar al endpoint.
   * @returns - Observable con la respuesta.
   */
  public getExcludedVehicleLines(): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/envs-multiclient`
    );
  }

  /**
   * Actualiza los datos del servicio.
   * @param data - Data de actualización.
   * @returns Observable http.client.
   */
  public updateVehicle(data: any): Observable<any> {
    return this.http.put<any>(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/vehicle/${data.code}`,
      data,
      {
        params: { isLaunchVehicle: data.launch_vehicle },
      }
    );
  }
  /**
   * Obtiene vehiculos por fasecolda.
   * @param code_fasecolda - Fasecolda.
   * @returns Observable http.client.
   */
  public getVehicleByFasecolda(
    code_fasecolda: string
  ): Observable<ResponseAWS<any>> {
    return this.http.get<any>(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/vehicle`,
      {
        params: { code_fasecolda },
      }
    );
  }

  /**
   * Obtiene modelos por lista de precios.
   * @param typePriceId - Codigo del tipo de precio.
   * @returns Observable http.client.
   */
  public getModelsByTypePrice(typePriceId: number): Observable<any> {
    return this.http.get<any>(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/${typePriceId}/model_fp`
    );
  }

  /**
   * Obtiene servicios por lista de precios.
   * @param typePriceId - Codigo del tipo de precio.
   * @returns Observable http.client.
   */
  public getServiciesByTypePrice(typePriceId: number): Observable<any> {
    return this.http.get<any>(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/${typePriceId}/vehicle-service_fp`
    );
  }

  /**
   * Obtiene familias por lista de precios.
   * @param typePriceId - Codigo del tipo de precio.
   * @returns Observable http.client.
   */
  public getFamiliesByTypePrice(typePriceId: number): Observable<any> {
    return this.http.get<any>(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/${typePriceId}/family_fp`
    );
  }

  /**
   * Obtiene versiones por lista de precios.
   * @param typePriceId - Codigo del tipo de precio.
   * @returns Observable http.client.
   */
  public getVersionsByTypePrice(typePriceId: number): Observable<any> {
    return this.http.get<any>(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/${typePriceId}/version_fp`
    );
  }
}
