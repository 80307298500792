import { ActionReducerMap } from '@ngrx/store';
import * as reducers from './reducers';

/**
 * Estado catalogo.
 */
export interface CatalogueState {
  /**
   * Llave tipo de documentos.
   */
  KeyValueTypeDocuments: reducers.KeyValueTypeDocumentsState;
  /**
   * Llave tipo de estado.
   */
  KeyValueTypeCustomers: reducers.KeyValueCustomerTypeState;
  /**
   * Llave tipo de paises.
   */
  KeyValueCountries: reducers.KeyValueCountriesState;
  /**
   * Llave nivel de escolaridad.
   */
  KeyValueValueLevelOfSchooling: reducers.KeyValueLevelOfSchoolingState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueTypeOfWelling: reducers.KeyValueTypeOfWellingState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueMaritalStatus: reducers.KeyValueMaritalStatusState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueIncomeFrequency: reducers.KeyValueIncomeFrequencyState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueCurrencyType: reducers.KeyValueCurrencyTypeState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueTypeOfPhone: reducers.KeyValueTypeOfPhoneState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueAssetType: reducers.KeyValueAssetTypeState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueTypeOfEgress: reducers.KeyValueTypeOfEgressState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueTypeJob: reducers.KeyValueTypeJobState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueTypeOfLiabilities: reducers.KeyValueTypeOfLiabilitiesState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueDepartments?: reducers.KeyValueDepartmentsState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueCities?: reducers.KeyValueCitiesState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueBank?: reducers.KeyValueBankState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueBankAccountType?: reducers.KeyValueBankAccountTypeState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueDocumentsApprovalStatus?: reducers.KeyValueDocumentsApprovalStatusState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueFamilyRelationships?: reducers.KeyValueFamilyRelationshipsState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueGender?: reducers.KeyValueGenderState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueLegalreviewApprovalStatus?: reducers.KeyValueLegalreviewApprovalStatusState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueLevel1ApprovalStatus?: reducers.KeyValueLevel1ApprovalStatusState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueReferenceReturnReasons?: reducers.KeyValueReferenceReturnReasonsState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueTypeAddress?: reducers.KeyValueTypeAddressState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueVerificationkycApprovalStatus?: reducers.KeyValueVerificationkycApprovalStatusState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueApplicantType?: reducers.KeyValueApplicantTypeState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueTypeOfReferences?: reducers.KeyValueTypeOfReferencesState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueProducts?: reducers.KeyValueProductsState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueTerms?: reducers.KeyValueTermsState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueProductCategory?: reducers.KeyValueProductCategoryState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueLoanApplicant?: reducers.KeyValueLoanApplicantState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueAmortizationCredits: reducers.KeyValueAmortizationCreditsState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueVehicleSegment: reducers.KeyValueVehicleSegmentState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueTypePerson: reducers.KeyValueTypePersonState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueTypePerson2: reducers.KeyValueTypePerson2State;
  /**
   *Llave estados de catálogo.
   */
  KeyValueCustomerClassification: reducers.KeyValueCustomerClassificationState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueFundingType: reducers.KeyValueFundingTypeState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueReferenceRate: reducers.KeyValueReferenceRateState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueIncomeType: reducers.KeyValueIncomeTypeState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueFirstExtraQuote: reducers.KeyValueFirstExtraQuoteState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueUnitAmount: reducers.KeyValueUnitAmountState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueUnitQuota: reducers.KeyValueUnitQuotaState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueTypeService: reducers.KeyValueTypeServiceState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueTypePlan: reducers.KeyValueTypePlanState;
  /**
   *Llave estados de catálogo.
   */
  KeyValueTypeMarket: reducers.KeyValueTypeMarketState;
  /**
   * Tipo de identificación.
   */
  KeyValueTypeOfIdentification: reducers.StateDataCatalogues;
  /**
   * Actividades econimicas.
   */
  KeyValueEconomicActivities: reducers.StateDataCatalogues;
  /**
   * Respuesta.
   */
  KeyValueResponse: reducers.StateDataCatalogues;
  /**
   * Tipo de contrato.
   */
  KeyValueContractType: reducers.StateDataCatalogues;
  /**
   * Tipo de regimen.
   */
  KeyValueRegimeType: reducers.StateDataCatalogues;
  /**
   * Seguridad social.
   */
  KeyValueSocialSecurity: reducers.StateDataCatalogues;
  /**
   * Tipo de usuario.
   */
  KeyValueUserType: reducers.StateDataCatalogues;
  /**
   * Estado.
   */
  KeyValueStatus: reducers.StateDataCatalogues;
  /**
   * Region.
   */
  KeyValueRegion: reducers.StateDataCatalogues;
  /**
   * Tipo de sociedad.
   */
  KeyValueKindSociety: reducers.StateDataCatalogues;
  /**
   * Tipo de contribuyente.
   */
  KeyValueTypeContributor: reducers.StateDataCatalogues;
  /**
   * Forma de pago.
   */
  KeyValueFormPayment: reducers.StateDataCatalogues;
  /**
   * Tipo de venta.
   */
  KeyValueTypeSale: reducers.StateDataCatalogues;
  /**
   * Documentos de legalizacion.
   */
  keyValueLegalizationDocuments: reducers.StateDataLegalizationDocCatalogues;
  /**
   * Tipo de venta.
   */
  KeyValueSBSCities: reducers.KeyValueSBSCitiesState;
  /**
   * Tipo de venta.
   */
  KeyValueSBSGender: reducers.KeyValueSBSGenderState;
  /**
   * Tipo de venta.
   */
  KeyValueCitiesCo: reducers.KeyValueCitiesCoState;
  /**
   * Tipo de venta.
   */
  KeyValueScoreBuro: reducers.KeyValueScoreBuroState;
  /**
   * Tipo de venta.
   */
  KeyValueArmoreLevel: reducers.KeyValueArmoreLevelState;
  /**
   * Eps.
   */
  KeyValueEps: reducers.KeyValueArmoreLevelState;
  /**
   * Fondo de pensión.
   */
  KeyValuePensionFunds: reducers.KeyValueArmoreLevelState;
  /**
   * Fondo de pensión.
   */
  KeyValueDefaultValuesInsurers: reducers.KeyValueDefaultValuesInsurersState;
  /**
   * Fondo de pensión.
   */
  KeyValueListInsurers: reducers.KeyValueListInsurersState;
  /**
   * Fondo de pensión.
   */
  general: reducers.getCatalogueGeneralState;
}

export const CatalogueReducers: ActionReducerMap<CatalogueState> = {
  KeyValueTypeDocuments: reducers.keyValueTypeDocumentsReducer,
  KeyValueTypeCustomers: reducers.keyValueCustomerTypeReducer,
  KeyValueCountries: reducers.keyValueCountriesReducer,
  KeyValueValueLevelOfSchooling: reducers.keyValueLevelOfSchoolingReducer,
  KeyValueTypeOfWelling: reducers.keyValueTypeOfWellingReducer,
  KeyValueMaritalStatus: reducers.keyValueMaritalStatusReducer,
  KeyValueIncomeFrequency: reducers.keyValueIncomeFrequencyReducer,
  KeyValueCurrencyType: reducers.keyValueCurrencyTypeReducer,
  KeyValueTypeOfPhone: reducers.keyValueTypeOfPhoneReducer,
  KeyValueAssetType: reducers.keyValueAssetTypeReducer,
  KeyValueTypeJob: reducers.keyValueTypeJobReducer,
  KeyValueTypeOfEgress: reducers.keyValueTypeOfEgressReducer,
  KeyValueTypeOfLiabilities: reducers.keyValueTypeOfLiabilitiesReducer,
  KeyValueDepartments: reducers.keyValueDepartmentsReducer,
  KeyValueCities: reducers.keyValueCitiesReducer,
  KeyValueBank: reducers.keyValueBankReducer,
  KeyValueBankAccountType: reducers.keyValueBankAccountTypeReducer,
  KeyValueDocumentsApprovalStatus:
    reducers.keyValueDocumentsApprovalStatusReducer,
  KeyValueFamilyRelationships: reducers.keyValueFamilyRelationshipsReducer,
  KeyValueGender: reducers.keyValueGenderReducer,
  KeyValueLegalreviewApprovalStatus:
    reducers.keyValueLegalreviewApprovalStatusReducer,
  KeyValueLevel1ApprovalStatus: reducers.keyValueLevel1ApprovalStatusReducer,
  KeyValueReferenceReturnReasons:
    reducers.keyValueReferenceReturnReasonsReducer,
  KeyValueTypeAddress: reducers.keyValueTypeAddressReducer,
  KeyValueVerificationkycApprovalStatus:
    reducers.keyValueVerificationkycApprovalStatusReducer,
  KeyValueApplicantType: reducers.keyValueApplicantTypeReducer,
  KeyValueTypeOfReferences: reducers.keyValueTypeOfReferencesReducer,
  KeyValueProducts: reducers.keyValueProductsReducer,
  KeyValueTerms: reducers.keyValueTermsReducer,
  KeyValueProductCategory: reducers.keyValueProductCategoryReducer,
  KeyValueLoanApplicant: reducers.keyValueLoanApplicantReducer,
  KeyValueAmortizationCredits: reducers.keyValueAmortizationCreditsReducer,
  KeyValueVehicleSegment: reducers.keyValueVehicleSegmentReducer,
  KeyValueTypePerson: reducers.keyValueTypePersonReducer,
  KeyValueTypePerson2: reducers.keyValueTypePerson2Reducer,
  KeyValueCustomerClassification:
    reducers.keyValueCustomerClassificationReducer,
  KeyValueFundingType: reducers.keyValueFundingTypeReducer,
  KeyValueReferenceRate: reducers.keyValueReferenceRateReducer,
  KeyValueIncomeType: reducers.KeyValueIncomeTypeReducer,
  KeyValueFirstExtraQuote: reducers.KeyValueFirstExtraQuoteReducer,
  KeyValueUnitAmount: reducers.keyValueUnitAmountReducer,
  KeyValueUnitQuota: reducers.keyValueUnitQuotaReducer,
  KeyValueTypeService: reducers.keyValueTypeServiceReducer,
  KeyValueTypePlan: reducers.keyValueTypePlanReducer,
  KeyValueTypeMarket: reducers.keyValueTypeMarketReducer,
  KeyValueTypeOfIdentification:
    reducers.keyValueGetTypeOfIdentificationsReducer,
  KeyValueEconomicActivities: reducers.keyValueGetEconomicActivitiesReducer,
  KeyValueResponse: reducers.keyValueGetResponseReducer,
  KeyValueContractType: reducers.keyValueContractTypeReducer,
  KeyValueRegimeType: reducers.keyValueRegimeTypeReducer,
  KeyValueSocialSecurity: reducers.keyValueSocialSecurityReducer,
  KeyValueUserType: reducers.keyValueUserTypeReducer,
  KeyValueStatus: reducers.keyValueStatusReducer,
  KeyValueRegion: reducers.keyValueRegionReducer,
  KeyValueKindSociety: reducers.keyValueKindSocietyReducer,
  KeyValueTypeContributor: reducers.keyValueTypeContributorReducer,
  KeyValueFormPayment: reducers.keyValueFormPaymentReducer,
  KeyValueTypeSale: reducers.keyValueTypeSaleReducer,
  keyValueLegalizationDocuments:
    reducers.keyValueGetLegalizationDocumentsReducer,
  KeyValueSBSCities: reducers.keyValueSBSCitiesReducer,
  KeyValueSBSGender: reducers.keyValueSBSGenderReducer,
  KeyValueCitiesCo: reducers.keyValueCitiesCoReducer,
  KeyValueScoreBuro: reducers.keyValueScoreBuroReducer,
  KeyValueArmoreLevel: reducers.keyValueArmoreLevelReducer,
  KeyValuePensionFunds: reducers.keyValuePensionFundsReducer,
  KeyValueEps: reducers.keyValueEpsReducer,
  KeyValueDefaultValuesInsurers: reducers.keyValueDefaultValuesInsurersReducer,
  KeyValueListInsurers: reducers.keyValueListInsurersReducer,
  general: reducers.getCatalogueGeneralStateReducer,
};
