import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  Catalogue,
  CatalogueTasksService,
} from '@wp-back-office/core/commons-backoffice';
import { mergeMap, map, catchError, of } from 'rxjs';
import { CatalogueType } from '../../../models';
import * as actionsEffects from '../../actions';
import { initApp } from '../../actions';

/**
 * Servicio de catalogue.
 */
@Injectable()
export class KeyValueIncomeFrequencyEffects {
  /**
   * Crea una instancia de .
   * @param actions$ - Acciones.
   * @param catalogueTasksService - Servicio de catalogo.
   */
  constructor(
    private actions$: Actions,
    private catalogueTasksService: CatalogueTasksService
  ) {}

  /**
   * Obtener catalogos.
   */
  public keyValueIncomeFrequency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initApp, actionsEffects.KeyValueIncomeFrequency),
      mergeMap(actions => {
        const path: CatalogueType =
          actions.type === initApp.type ? 'INCOMEFREQUENCY' : actions.path;
        return this.catalogueTasksService.getCatalogueTasks(path).pipe(
          map((keyValue: Catalogue[]) =>
            actionsEffects.KeyValueIncomeFrequencySuccess({ keyValue })
          ),
          catchError(err =>
            of(actionsEffects.KeyValueIncomeFrequencyError({ payload: err }))
          )
        );
      })
    )
  );
}
