import { createAction, props } from '@ngrx/store';

export const IsAuth = createAction('[isAuth] Obtener Autenticación');

export const IsAuthSuccess = createAction(
  '[isAuth] Obtener Autenticación Exitoso',
  props<{
    /**
     *
     */
    isAuthenticated: boolean;
  }>()
);

export const IsAuthError = createAction(
  '[isAuth] Obtener Autenticación Error',
  props<{
    /**
     *
     */ payload: any;
  }>()
);
