import { Action, createReducer, on } from '@ngrx/store';
import { FormatedTask } from '@wp-back-office/app/global-information';
import { resetInitialState } from '@wp-back-office/core/store';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';
import { taskPendingActions } from '../../actions/contents/task-pending.actions';

/**
 * Interfaz TaskPendingState.
 */
export interface TaskPendingState {
  /**
   * Variable taskPending.
   */
  taskPending: FormatedTask[];
  /**
   * Variable firstKey.
   */
  firstKey: string;
  /**
   * Variable lastkey.
   */
  lastkey: string;
  /**
   * Variable loaded.
   */
  loaded: boolean;
  /**
   * Variable de estado.
   */
  loading: boolean;
  /**
   * Variable de estado.
   */
  error?: ErrorHttp;
}

export const taskPendingInitialState: TaskPendingState = {
  taskPending: [],
  firstKey: '',
  lastkey: '',
  loaded: false,
  loading: false,
  error: undefined,
};

const featureReducer = createReducer(
  taskPendingInitialState,
  on(resetInitialState, () => ({ ...taskPendingInitialState })),
  on(taskPendingActions.taskPendingReset, () => ({
    ...taskPendingInitialState,
  })),
  on(taskPendingActions.taskPending, state => ({
    ...state,
    loading: true,
    taskPending: [],
  })),
  on(
    taskPendingActions.taskPendingSuccess,
    (state, { taskPending, lastKey }) => ({
      ...state,
      taskPending: [...taskPending],
      lastKey: lastKey || '',
      loading: false,
      loaded: true,
      error: undefined,
    })
  ),
  on(taskPendingActions.taskPendingError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
  on(taskPendingActions.taskPendingNext, state => ({
    ...state,
    loading: true,
  })),
  on(
    taskPendingActions.taskPendingNextSuccess,
    (state, { taskPending, nextKey }) => ({
      ...state,
      taskPending: [...taskPending],
      lastkey: nextKey,
      loading: false,
      loaded: true,
      error: undefined,
    })
  ),
  on(taskPendingActions.taskPendingNextError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
  on(taskPendingActions.taskPendingDelete, state => ({
    ...state,
    loading: true,
  })),
  on(taskPendingActions.taskPendingDeleteSuccess, state => ({
    ...state,
    loading: false,
    loaded: true,
    error: undefined,
  })),
  on(taskPendingActions.taskPendingDeleteError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
  on(taskPendingActions.taskPendingById, state => ({
    ...state,
    loading: true,
  })),
  on(taskPendingActions.taskPendingByIdSuccess, state => ({
    ...state,
    loading: false,
    loaded: true,
    error: undefined,
  })),
  on(taskPendingActions.taskPendingByIdError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
  on(taskPendingActions.taskPendingUpdate, state => ({
    ...state,
    loading: true,
  })),
  on(taskPendingActions.taskPendingUpdateSuccess, state => ({
    ...state,
    loading: false,
    loaded: true,
    error: undefined,
  })),
  on(taskPendingActions.taskPendingUpdateError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

/**
 * Reductor de logueo.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function TaskPendingReducer(
  state: TaskPendingState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
