/**
 * Modelo de enviroments.
 */
export abstract class Enviroment {
  /**
   * Bandera produccion.
   */
  abstract readonly production: boolean;
  /**
   * Valor Recaptcha.
   */
  abstract readonly valueRecaptcha: string;
  /**
   * Bandera del body certicamara.
   */
  abstract readonly bodyCerticamara: {
    /**
     * Process Type Enum.
     */
    readonly ProcessTypeEnum: string;
  };
  /**
   * Datos cuenta AWS.
   */
  abstract readonly aws: {
    /**
     * Cuenta AWS.
     */
    awsAccount: string;
    /**
     * Region.
     */
    region: string;
  };
  /**
   * Permisos ACL.
   */
  abstract readonly aclPermissions: any;
  /**
   * Bamdera de common.
   */
  abstract readonly common: {
    /**
     * Tenant.
     */
    readonly tenantId: string;
  };
  /**
   * Salida de puntos.
   */
  abstract readonly endpoints: {
    /**
     * Url.
     */
    readonly url: string;
    /**
     * Url Products And Services.
     */
    readonly urlPAS: string;
    /**
     * Url workFlow.
     */
    readonly workFlow: string;
    /**
     * Url Buckets.
     */
    readonly urlBuckets: string;
    /**
     * Url de administracion documentos.
     */
    readonly urlDocuments: string;
    /**
     * Url de administracion dashboard.
     */
    readonly urlDashboard: string;
    /**
     * Url transunion.
     */
    readonly urlIntegrations: string;
    /**
     * Url APL.
     */
    readonly urlApl: string;
    /**
     * Url Arus.
     */
    readonly urlArus: string;
    /**
     * Url Certicamara.
     */
    readonly urlCerticamara: string;
    /**
     * Url rule validation pj.
     */
    readonly urlRuleValidationPj: string;
    /**
     * Url de autenticacion.
     */
    readonly urlAuth: string;
    /**
     * Url Backoffice Rules.
     */
    readonly backofficeRules: string;
  };
  /**
   * Url de autenticacion.
   */
  abstract readonly SESSION_KEY: {
    /**
     * Key IdToken.
     */
    readonly idToken: string;
    /**
     * Key AccessToken.
     */
    readonly accessToken: string;
    /**
     * Key Refresh Token.
     */
    readonly refreshToken: string;
  };

  abstract readonly publicKey: string;
}
