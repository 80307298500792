import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueTypeOfPhone = createAction(
  '[KeyValueTypeOfPhone] Cargar Catalogos',
  props<{
    /**
     * Ruta.
     */
    path: CatalogueType;
  }>()
);

export const KeyValueTypeOfPhoneSuccess = createAction(
  '[KeyValueTypeOfPhone] Crear Catalogos Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueTypeOfPhoneError = createAction(
  '[KeyValueTypeOfPhone] Cargar Catalogos Error',
   props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
