import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueProducts = createAction(
  '[KeyValueProducts] Cargar Catalogos por id',
  props<{
    /**
     * Ruta.
     */
    path: CatalogueType;
    /**
     * Codigo.
     */
    code: string;
    /**
     * Ruta 2.
     */
    path2: CatalogueType;
  }>()
);

export const KeyValueProductsSuccess = createAction(
  '[KeyValueProducts] Crear Catalogos Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueProductsError = createAction(
  '[KeyValueProducts] Cargar Catalogos Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
