import { Component, Input } from '@angular/core';

/**
 *
 */
@Component({
  selector: 'wp-back-office-dynamic-spinner',
  templateUrl: './dynamic-spinner.component.html',
  styleUrls: ['./dynamic-spinner.component.scss'],
})
export class DynamicSpinnerComponent {
  /**
   * Mostrar spinner.
   */
  @Input()
  public show: boolean;

  /**
   * Mostrar spinner.
   */
  @Input()
  public text!: string;

  /**
   * Crea una instancia de la clase.
   */
  constructor() {
    this.show = true;
  }
}
