<ng-template [ngIf]="controlConfig && controlConfig.key && controlConfig.type">
  <div class="chip-select">
    <mat-button-toggle-group
      [id]="controlConfig.key"
      [formControl]="_formControl"
      [multiple]="controlConfig.validators?.multiple"
      class="chip-select"
      [value]="_formControl.value">
      <mat-label class="label-toggle-group">
        {{ controlConfig.label || '' }}
      </mat-label>
      <div class="category" *ngFor="let cat of controlConfig.categories">
        <mat-label>
          {{ cat.name }}
        </mat-label>
        <div class="grid">
          <mat-button-toggle
            *ngFor="let opt of cat.options"
            [value]="opt"
            [matTooltip]="opt.description || ''"
            [checked]="
              dynamicFormService.existIn(opt.code, _formControl.value || [])
            ">
            <div class="lines-2">
              {{ opt.description }}
            </div>
            <mat-chip class="toggle-chip" [disableRipple]="true">
              {{
                dynamicFormService.existIn(opt.code, _formControl.value || [])
                  ? 'HABILITADO'
                  : 'DESHABILITADO'
              }}
            </mat-chip>
          </mat-button-toggle>
        </div>
      </div>
    </mat-button-toggle-group>
  </div>
</ng-template>
