<div class="h-100 app-appear">
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="large"
    color="#808B96"
    type="ball-fall"
    [fullScreen]="true">
  </ngx-spinner>
  <router-outlet class="router-app"></router-outlet>
</div>

<span id="apptimer" class="mat-card"> </span>
