<ng-template [ngIf]="control && control.key && control.type">
  <div class="container-fluid">
    <div class="d-flex justify-content-center">
      <div class="container">
        <div class="text-center my-4">
          <h2 class="fw-bold">
            {{
              control && control.label
                ? control.label
                : 'Le hemos enviado un mensaje de texto'
            }}
          </h2>
          <div class="text-muted">
            {{
              control && control.codeVerificationConfig?.text
                ? control.codeVerificationConfig?.text
                : 'Escribe el código del participante que estás gestionando para poder continuar con la solicitud de crédito.'
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="container-input-otp">
        <form
          [formGroup]="codeVerification"
          class="d-flex justify-content-center">
          <ng-container
            *ngFor="
              let input of [].constructor(
                control &&
                  control.validators &&
                  control.codeVerificationConfig?.codeVerificationQuantity
                  ? control.codeVerificationConfig?.codeVerificationQuantity
                  : 0
              );
              let index = index
            ">
            <input
              #target
              class="mx-2 col-2 digit-otp"
              type="text"
              [formControlName]="'text-' + index.toString()"
              [maxLength]="1"
              (keypress)="numberOnly($event)"
              (keyup)="onNext($event, index)"
              (paste)="onPaste($event)"
              autocomplete="off" />
          </ng-container>
        </form>

        <div
          class="d-flex justify-content-center mt-4 text-muted"
          [ngClass.sm]="'d-block'"
          [ngClass.xs]="'d-block'">
          <mat-label
            class="d-block"
            *ngIf="control.codeVerificationConfig?.countDown">
            <div>
              <b>{{ timerCount }}</b> Tiempo restante
            </div>
          </mat-label>
          <a
            matTooltip="Reenviar código"
            (click)="onReSend()"
            class="mx-2"
            *ngIf="retryLimit !== control?.codeVerificationConfig?.retryLimit">
            <mat-label
              >Reenviar código
              {{
                control.codeVerificationConfig?.retryLimit
                  ? '(Intentos ' +
                    retryLimit +
                    ' de ' +
                    control.codeVerificationConfig?.retryLimit +
                    ')'
                  : ''
              }}</mat-label
            >
          </a>
        </div>
        <div
          class="d-flex justify-content-center mt-4"
          [hidden]="
            control.codeVerificationConfig?.countDown && timerCount === '00:00'
          ">
          <button
            mat-flat-button
            matTooltip="Envio código OTP"
            [disabled]="
              codeVerification.invalid ||
              control.validators?.readonly ||
              control.validators?.disabled
            "
            (click)="onSubmit()"
            class="mx-1"
            color="accent">
            <div *ngIf="codeVerification.invalid">
              {{ digitsLeft }} dígitos restantes
            </div>
            <div *ngIf="codeVerification.valid">Verificar código</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
