import {
  VehicleDetail,
  VehicleDetailInput,
} from '../models/vehicle-detail.model';

export class VehicleDetailMapper {
  /**
   * Mapea desde el formato AWS a nuestro modelo.
   * @param param - Parametros desde el backend.
   * @returns - Objeto de nuestro modelo.
   */
  public mapFrom(param: VehicleDetailInput): VehicleDetail {
    return {
      code: param.code_price,
      vehicleCode: param.code,
      codeTypePrice: param.type_price,
      brand: param.brand_description,
      line: param.line_description,
      model: param.model_description,
      subvention: param.subvention,
      price: param.value,
      version: param.version_description,
      reference: param.reference,
      fasecolda: param.code_fasecolda,
      family: param.family_description,
      vehicleService: param.vehicle_services.map(
        service => service.type_service
      ),
    };
  }
}
