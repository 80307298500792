import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';

import { Observable } from 'rxjs';

/**
 * Servicio de listas internas.
 */
@Injectable()
export class RestrictiveListsService {
  /**
   * Crea una instancia de WorkflowService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}

  /**
   * Obtiene la lista interna.
   * @param params - Tamaño de la pagina.
   * @returns Observable.
   */
  public restrictiveList(params: any): Observable<any> {
    let temp = {};
    if (params.pageSize && !params.typeFilter && !params.lastPageToken) {
      temp = {
        pageSize: params.pageSize,
      };
    } else if (params.lastPageToken && !params.typeFilter) {
      temp = {
        pageSize: params.pageSize,
        lastKey: params.lastPageToken,
      };
    } else if (params.typeFilter && !params.lastPageToken) {
      temp = {
        pageSize: params.pageSize,
        typeFilter: params.typeFilter,
        filter: params.filter,
      };
    } else if (params.typeFilter && params.lastPageToken) {
      temp = {
        pageSize: params.pageSize,
        lastKey: params.lastPageToken,
        typeFilter: params.typeFilter,
        filter: params.filter,
      };
    }

    return this.http.get(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/internal-list/rejection`,
      {
        params: temp,
      }
    );
  }

  /**
   * Elimina un elemento de la lista interna.
   * @param code - Código del elemento.
   * @returns Observable.
   */
  public deleteRestrictiveList(code: string): Observable<any> {
    return this.http.delete<any>(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/internal-list/rejection/${code}`
    );
  }

  /**
   * Creacion de elemento de la lista interna.
   * @param element - Datos del elemento de la lista interna a crear.
   * @returns Observable.
   */
  public createRestrictiveList(element: any): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/internal-list/rejection`,
      element
    );
  }

  /**
   * Creacion de elemento de la lista interna.
   * @param element - Datos del elemento de la lista interna a crear.
   * @returns Observable.
   */
  public uploadFileRestrictiveList(element: any): Observable<any> {
    const formData = new FormData();
    formData.append('restrictiveList', element[0]);

    return this.http.post(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/internal-list/rejection/upload-list`,
      formData
    );
  }

  /**
   * Creacion de elemento de la lista interna.
   * @param element - Datos del elemento de la lista interna a crear.
   * @param code - Identificador del elemento.
   * @returns Observable.
   */
  public updateRestrictiveList(element: any, code: string): Observable<any> {
    return this.http.put(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/internal-list/rejection/${code}`,
      element
    );
  }
}
