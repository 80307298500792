<ng-template [ngIf]="controlConfig && controlConfig.key && controlConfig.type">
  <mat-form-field
    [appearance]="controlConfig.appearance || 'outline'"
    class="{{ controlConfig.classes || '' }}">
    <mat-label [attr.for]="controlConfig.key">
      {{ controlConfig.label || '' }}
    </mat-label>
    <input
      matInput
      currencyMask
      [options]="options"
      [aria-describedby]="controlConfig.label || ''"
      [placeholder]="controlConfig.label || ''"
      [formControl]="_formControl"
      [id]="uniqueIdForm + controlConfig.key"
      (focus)="onFocus($event)"
      (focusout)="onFocusOut($event)"
      [type]="'text'"
      [readonly]="
        controlConfig.validators?.readonly
          ? controlConfig.validators?.readonly
          : false
      " />
    <mat-error
      [innerHTML]="
        dynamicFormService.errorMessage(_formControl, controlConfig)
      ">
    </mat-error>
    <mat-icon matSuffix *ngIf="controlConfig?.icon">
      {{ controlConfig.icon || '' }}
    </mat-icon>
  </mat-form-field>
</ng-template>
