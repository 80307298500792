import { Action, createReducer, on } from '@ngrx/store';
import { resetInitialState } from '@wp-back-office/core/store';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';
import { userProfileActions } from '../../actions';

/**
 * Reducer para obtener imagen de perfil.
 */
export interface StateDataUserProfile {
  /**
   * Imagen de perfil.
   */
  profilePicture?: any;
  /**
   * Bandera de carga.
   */
  loaded: boolean;
  /**
   * Bandera de carga.
   */
  loading: boolean;
  /**
   * Respuesta erronea del store.
   */
  error?: ErrorHttp;
}

export const initialStateUserProfile: StateDataUserProfile = {
  profilePicture: undefined,
  loaded: false,
  loading: false,
  error: undefined,
};

const featureGetUserProfileReducer = createReducer(
  initialStateUserProfile,
  on(resetInitialState, () => ({ ...initialStateUserProfile })),
  on(userProfileActions.getProfilePictureReset, () => ({
    ...initialStateUserProfile,
  })),
  on(userProfileActions.getProfilePicture, state => ({
    ...state,
    loading: true,
    loaded: false,
    error: undefined,
  })),
  on(userProfileActions.getProfilePictureSuccess, (state, { response }) => ({
    ...state,
    loading: false,
    loaded: true,
    profilePicture: response,
  })),
  on(userProfileActions.getProfilePictureError, (state, { error }) => ({
    ...state,
    profilePicture: undefined,
    loading: false,
    loaded: true,
    error: error,
  }))
);

/**
 * Reducer para obtener imagen de perfil.
 * @param state - Estado.
 * @param action - Acción.
 * @returns Reducer.
 */
export function getUserProfileReducer(
  state: StateDataUserProfile | undefined,
  action: Action
) {
  return featureGetUserProfileReducer(state, action);
}
