import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SessionRefresh } from 'libs/app/user-authentication/src/lib/models/refresh-session.model';
import { SessionState } from '../../reducers/contents/session.reducers';

export const sessionActions = createActionGroup({
  source: 'Session',
  events: {
    'obtener sesion exitoso': props<{
      /**
       * Grupos.
       */
      response: any;
    }>(),
    'obtener sesion error': emptyProps(),
    'actualizar sesion': props<SessionRefresh>(),
    'actualizar sesion exitoso': props<{
      /**
       * Sesion activa.
       */
      response: SessionState;
    }>(),
    'cargar sesion': emptyProps(),
  },
});
