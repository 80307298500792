<div class="row background-primary h-100-vh login-base">
  <div
    class="col-12 col-md-4 col-lg-3 background-logo-login row logo-container">
    <div class="logo-app-login m-auto mt-5">
      <img src="/assets/logo/LogoLogin.svg" class="desktop w-100" alt="" />
      <img src="/assets/logo/LogoMenu.svg" class="mobile" alt="" />
    </div>
    <img class="person-image" src="/assets/img/user-login.png" alt="" />
  </div>
  <div class="col-12 col-md-8 col-lg-9">
    <div class="row h-100">
      <div class="card-container m-auto">
        <mat-card class="card-login m-2 border-none">
          <mat-card-header>
            <div>
              <mat-icon class="lock-icon"> lock </mat-icon>
            </div>
            <div>
              <mat-card-title>Inicia sesión</mat-card-title>
            </div>
          </mat-card-header>
          <mat-card-content>
            <div class="body">
              <mat-card-subtitle class="primary-text"
                >Ingresa tu usuario y contraseña</mat-card-subtitle
              >
              <div class="form">
                <form [formGroup]="signInForm">
                  <mat-form-field appearance="outline" class="mb-2">
                    <div class="icon" matPrefix>
                      <img src="{{ CUSTOMER_CONSTANTS['iconUser'] }}" alt="" />
                    </div>
                    <mat-label>{{ LOGIN.user }}</mat-label>
                    <input
                      matInput
                      autocomplete="off"
                      (click)="NOT_OVERWIRTE()"
                      id="username"
                      type="text"
                      class="form-control input__item"
                      formControlName="username"
                      aria-describedby="usernameHelp"
                      [maxlength]="LOGIN.usernameMinLength"
                      [minlength]="7"
                      required />
                    <div *ngIf="!signInForm.value.username" matSuffix>
                      <span class="suffix">
                        {{ LOGIN.messageErrorUser }}
                      </span>
                    </div>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <div class="icon" matPrefix>
                      <img
                        src="{{ CUSTOMER_CONSTANTS['iconPassword'] }}"
                        alt="" />
                    </div>
                    <mat-label>{{ LOGIN.password }}</mat-label>
                    <input
                      matInput
                      autocomplete="off"
                      (click)="NOT_OVERWIRTE()"
                      type="password"
                      class="form-control input__item"
                      id="password"
                      formControlName="password"
                      [maxlength]="LOGIN.passwordMaxLength"
                      [minlength]="8"
                      required />
                    <div matSuffix>
                      <span *ngIf="!signInForm.value.password" class="suffix">
                        {{ LOGIN.messageErrorPassword }}
                      </span>
                      <div
                        *ngIf="signInForm.value.password"
                        class="d-flex justify-content-end mr-1">
                        <div class="show__password" (click)="showPassword()">
                          <mat-icon>
                            {{ showEye ? 'visibility_off' : 'visibility' }}
                          </mat-icon>
                        </div>
                      </div>
                    </div>
                  </mat-form-field>

                  <div class="form-submit">
                    <div class="submit">
                      <button
                        mat-flat-button
                        type="submit"
                        class="px-5 py-1"
                        color="accent"
                        (click)="signIn()"
                        [disabled]="signInForm.invalid">
                        {{ LOGIN.button }}
                      </button>
                    </div>
                    <div class="olvidate-password link">
                      <a
                        mat-button
                        id="forgotPassword"
                        (click)="forgotPassword()"
                        >{{ LOGIN.forgetPassword }}</a
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <wp-back-office-footer
              class="app-card-footer mobie-login d-md-none"></wp-back-office-footer>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-12 col-lg-12 foot">
    <img class="icon__footer" [src]="footerLogo" alt="" />
  </div>
</div>

<wp-back-office-footer
  class="app-card-footer login d-none d-sm-none d-md-block"></wp-back-office-footer>
