import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AmortizationCredit,
  AmortizationCreditResponse,
} from '../models/amortization-credit.model';
/**
 * Servicio de planes financieros.
 */
@Injectable()
export class AmortizationCreditsService {
  /**
   * Crea una instancia de WorkflowService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}
  /**
   * Obtiene la lista de planes financieros.
   * @param page - Pagina.
   * @param pageSize - Tamaño de la pagina.
   * @returns - Observable.
   */
  public getAmortizationCreditsList(
    page?: number,
    pageSize?: number | string
  ): Observable<AmortizationCredit[]> {
    let params = {};
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    return this.http
      .get<AmortizationCreditResponse>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/amortization-credit`,
        {
          params: params,
        }
      )
      .pipe(
        map(response =>
          response.items.map(item => ({
            ...item,
            enable_client_rate: item.enable_client_rate === 0 ? false : true,
            enable_subvention: item.enable_subvention === 0 ? false : true,
          }))
        )
      );
  }

  /**
   * Obtiene la lista de planes financieros.
   * @param page - Pagina.
   * @param pageSize - Tamaño de la pagina.
   * @returns - Observable.
   */
  public legalAmortizationCredits(
    code_amortization: string,
    legal_clauses: string
  ): Observable<any> {
    return this.http.put<any>(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/amortization-credit/${code_amortization}`,
      {
        legal_clauses: legal_clauses,
      }
    );
  }

  /**
   * Obtiene la lista de planes financieros.
   * @param page - Pagina.
   * @param pageSize - Tamaño de la pagina.
   * @returns - Observable.
   */
  public getLegalAmortizationCredits(
    code_amortization: string
  ): Observable<any> {
    return this.http.get<any>(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/amortization-credit/${code_amortization}`
    );
  }

  /**
   * Obtiene las variable multicliente.
   * @returns - Observable.
   */
  public getEnvsMulticlient(): Observable<any> {
    return this.http.get<any>(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/envs-multiclient`
    );
  }
}
