import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { VehicleDetail } from 'libs/core/workflow-sdk/src/lib/models/vehicle-detail.model';
import { OptionValue } from '../../reducers';
import { Room } from 'libs/core/workflow-sdk/src/lib/models/rooms.model';
import { RoomSegmentation } from 'libs/core/workflow-sdk/src/lib/models/market.model';

export const segmentationFormActions = createActionGroup({
  source: 'Segmentation Form',
  events: {
    Reset: emptyProps(),
    'Add Detail': props<{
      /**
       * Id del tipo de precio.
       */
      name: string;
      /**
       * Descripcion.
       */
      description: string;
      /**
       * Tipo de segmentacion.
       */
      segment_type: string;
    }>(),
    'Add Vehicles': props<{
      /**
       * Id del tipo de precio.
       */
      vehicles: VehicleDetail[];
    }>(),
    'Add Rooms': props<{
      /**
       * Id del tipo de precio.
       */
      rooms: RoomSegmentation[];
    }>(),
    'Add Options': props<{
      /**
       * Id del tipo de precio.
       */
      options: OptionValue[];
    }>(),
    'Remove Vehicle': props<{
      /**
       * Id del tipo de precio.
       */
      code: number;
    }>(),
    'Update Vehicles': props<{
      /**
       * Vehiculos.
       */
      vehicles: VehicleDetail[];
    }>(),
  },
});
