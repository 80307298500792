import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';

/**
 * Componente Angular para un editor dinámico con AngularEditorConfig.
 * Este componente se encarga de configurar la edición de texto enriquecido.
 * Utiliza la librería AngularEditor de Kolkov.
 */
@Component({
  selector: 'wp-back-office-dynamic-editor',
  templateUrl: './dynamic-editor.component.html',
})
export class DynamicEditorComponent implements OnInit {
  /**
   * La configuración del editor, instancia de AngularEditorConfig.
   */
  public editorConfig!: AngularEditorConfig;

  /**
   * Contenido HTML del editor de texto.
   */
  public htmlContent!: string;

  /**
   * Constructor del componente.
   */
  constructor() {}

  /**
   * Implementa el método ngOnInit de la interfaz OnInit.
   */
  public ngOnInit(): void {
    this.editorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        { class: 'arial', name: 'Arial' },
        { class: 'times-new-roman', name: 'Times New Roman' },
        { class: 'calibri', name: 'Calibri' },
        { class: 'comic-sans-ms', name: 'Comic Sans MS' },
      ],
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText',
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
      uploadUrl: 'v1/image',
      uploadWithCredentials: false,
      sanitize: true,
      toolbarPosition: 'top',
      toolbarHiddenButtons: [['bold', 'italic'], ['fontSize']],
    };
  }
}
