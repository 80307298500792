import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe ejemplo 1.
 */
@Pipe({
  name: 'pipeExample1',
})
export class PipeExample1Pipe implements PipeTransform {
  /**
   * Metodo transform para un valor del pipeline.
   * @param value - Valor.
   * @returns Cadena.
   */
  public transform(value: string): string {
    let newStr: any = '';
    for (let i = value.length - 1; i >= 0; i--) {
      newStr += value.charAt(i);
    }
    return newStr;
  }
}
