import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WorkflowService } from '@wp-back-office/core/workflow-sdk';
import { mergeMap, map, catchError, of } from 'rxjs';
import { ResponseStart } from '../../../models/contents/task.models';

import * as actionsEffects from '../../actions';

/**
 *
 */
@Injectable()
export class StartProcessEffects {
  /**
   * Crea una instancia StartProcessEffects.
   * @param actions$ - Actions.
   * @param workflowService - Servicio work flow.
   */
  constructor(
    private actions$: Actions,
    private workflowService: WorkflowService
  ) {}

  /**
   *
   */
  public StartProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.StartProcess),
      mergeMap(params =>
        this.workflowService.startProcess(params.object, params.process).pipe(
          map((object: ResponseStart) =>
            actionsEffects.StartProcessSuccess({ object })
          ),
          catchError(err =>
            of(actionsEffects.StartProcessError({ payload: err }))
          )
        )
      )
    )
  );

  /**
   *
   */
  public ChangesProcessById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.ChangesProcessById),
      mergeMap(params =>
        this.workflowService
          .changesProcessById(
            params.nameProcess,
            'AGREGAR EL NOMBRE DE LA TAREA'
          )
          .pipe(
            map((object: ResponseStart) =>
              actionsEffects.ChangesProcessByIdSuccess({ object })
            ),
            catchError(error =>
              of(actionsEffects.ChangesProcessByIdError({ error }))
            )
          )
      )
    )
  );
}
