import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueFirstExtraQuote = createAction(
  '[KeyValueFirstExtraQuote] Cargar Catalogos',
  props<{
    /**
     * Payload.
     */
    path: CatalogueType;
  }>()
);

export const KeyValueFirstExtraQuoteSuccess = createAction(
  '[KeyValueFirstExtraQuote] Crear Catalogos Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueFirstExtraQuoteError = createAction(
  '[KeyValueFirstExtraQuote] Cargar Catalogos Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
