<ng-template [ngIf]="controlConfig && controlConfig.key && controlConfig.type">
  <mat-form-field
    [appearance]="controlConfig.appearance || 'outline'"
    class="{{ controlConfig.classes || '' }}"
    (click)="picker.open()">
    <mat-label>
      {{ controlConfig.label || '' }}
    </mat-label>
    <input
      matInput
      [matDatepicker]="picker"
      [formControl]="_formControl"
      [id]="uniqueIdForm + controlConfig.key"
      [min]="minDate"
      [max]="maxDate"
      (click)="picker.open()"
      (keyup.enter)="picker.open()"
      readonly />
    <mat-datepicker-toggle
      matSuffix
      [for]="picker"
      tabindex="-1"></mat-datepicker-toggle>
    <mat-datepicker [touchUi]="innerWidth <= 767" #picker></mat-datepicker>
    <mat-error
      [innerHTML]="
        dynamicFormService.errorMessage(_formControl, controlConfig)
      ">
    </mat-error>
  </mat-form-field>
</ng-template>
