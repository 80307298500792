import { Action, createReducer, on } from '@ngrx/store';
import { AuditDetail } from 'libs/app/dashboard/src/lib/models/audit-detail.model';
import { ErrorHttp } from '../../../../models/errors.models';
import {
  auditDetailReset,
  auditDetail,
  auditDetailSuccess,
  auditDetailError,
} from '../../actions';

/**
 * Estado de auditar detalle.
 */
export interface AuditDetailState {
  /**
   * Objeto.
   */
  object?: AuditDetail;
  /**
   * Cargó.
   */
  loaded: boolean;
  /**
   * Cargando.
   */
  loading: boolean;
  /**
   * Error.
   */
  error?: ErrorHttp;
}

export const getAuditDetail: AuditDetailState = {
  object: undefined,
  loaded: false,
  loading: false,
  error: undefined,
};

const featureReducer = createReducer(
  getAuditDetail,
  on(auditDetailReset, () => ({ ...getAuditDetail })),
  on(auditDetail, state => ({
    ...state,
    object: undefined,
    loading: true,
    loaded: false,
    error: undefined,
  })),
  on(auditDetailSuccess, (state, { object }) => ({
    ...state,
    object,
    loading: false,
    loaded: true,
    error: undefined,
  })),
  on(auditDetailError, (state, { error }) => ({
    ...state,
    loaded: true,
    loading: false,
    error,
  }))
);

/**
 * Reductor iniciar proceso.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function AuditDetailReducer(
  state: AuditDetailState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
