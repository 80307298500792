import { createAction, props } from '@ngrx/store';
import { AuditList } from 'libs/app/dashboard/src/lib/models/audit-list.model';
import { ErrorHttp } from '../../../../models/errors.models';

export const auditProcessesReset = createAction(
  '[auditProcesses] Obtener Procesos a auditar Reset'
);
export const auditProcesses = createAction(
  '[auditProcesses] Obtener Procesos a auditar',
  props<{
    /**
     * Es modo de scrolling.
     */
    isScrolling: boolean;
    /**
     * Tipo de filtro.
     */
    filterType: 'documentNumber' | 'executionId' | 'externalId';
    /**
     * Valor del filtro.
     */
    filter: string;
    /**
     * Tamaño de la pagina.
     */
    pageSize?: number;
    /**
     * Token de la ultima pagina.
     */
    lastPageToken?: string;
  }>()
);
export const auditProcessesSuccess = createAction(
  '[auditProcesses] Obtener Procesos a auditar Exitoso',
  props<{
    /**
     * Objeto que retorna en caso exitoso.
     */
    object: AuditList;
  }>()
);
export const auditProcessesError = createAction(
  '[auditProcesses] Obtener Procesos a auditar Error',
  props<{
    /**
     * Objeto que retorna en caso de error.
     */
    error: ErrorHttp;
  }>()
);
