import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Subject } from 'rxjs';
import { FactoryTabGeneralDirective } from '../../directives/factory-tab-general.directive';
import {
  MatTabConfig,
  MatTabGroupConfig,
} from '../../models/tabs-config.model';

/**
 * Componente de tabuladores dinamicos general.
 */
@Component({
  selector: 'wp-back-office-dynamic-tabs-general',
  templateUrl: './dynamic-tabs-general.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicTabsGeneralComponent
  implements OnChanges, OnInit, AfterViewInit, OnDestroy
{
  /**
   * Configuracion de losde agregar tabs.
   */
  @Input()
  public matTabGroupConfig?: MatTabGroupConfig;
  /**
   * Evento de valor del tab.
   */
  @Output()
  public matTabValueJSON = new EventEmitter<string>();
  /**
   * Evento de valor del tab.
   */
  @Output()
  public matTableValueJSON = new EventEmitter<string>();
  /**
   * Fabrica.
   */
  @ViewChild(MatTabGroup)
  public matTabGroup?: MatTabGroup;
  /**
   * Fabrica de componentes.
   */
  @ViewChildren(FactoryTabGeneralDirective)
  public contentTabs?: QueryList<FactoryTabGeneralDirective>;
  /**
   * Arreglo de tabulaciones.
   */
  public arrayTabs?: MatTabConfig[];
  /**
   * Destructor sujeto.
   */
  private destroy$: Subject<boolean>;

  /**
   * Crea una instancia DynamicTabsComponent.
   * @param cdRef - Deteccion de cambios.
   */
  public constructor(private cdRef: ChangeDetectorRef) {
    this.arrayTabs = [];
    this.destroy$ = new Subject();
  }

  /**
   * Detecta el cambio de las entradas y salidas.
   * @param changes - Cambios.
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['matTabGroupConfig']) {
      this.matTabGroupConfig = changes['matTabGroupConfig'].currentValue;
      this.arrayTabs = this.matTabGroupConfig?.matTabs?.filter(
        tab => (tab.createdInitial || false) === true
      );

      this.arrayTabs?.forEach(tab => {
        if (tab.component?.prototype) {
          tab.component.prototype.typeID = tab.typeID;
        }
      });

      const index =
        this.matTabGroupConfig && this.matTabGroupConfig.selectIndex
          ? this.matTabGroupConfig.selectIndex
          : 0;

      setTimeout(() => {
        if (this.matTabGroup) {
          this.matTabGroup.selectedIndex = index;
          this.cdRef.detectChanges();
        }
      }, 1000);
    }
  }

  /**
   * A callback method that is invoked immediately after the default change detector has checked the directive's data-bound properties for the first time.
   */
  public ngOnInit(): void {
    this.arrayTabs =
      this.matTabGroupConfig && this.matTabGroupConfig.matTabs
        ? this.matTabGroupConfig.matTabs?.filter(
            tab => (tab.createdInitial || false) === true
          )
        : [];
  }

  /**
   * A callback method that is invoked immediately after Angular has completed initialization of a component's view.
   */
  public ngAfterViewInit(): void {
    const index =
      this.matTabGroupConfig && this.matTabGroupConfig.selectIndex
        ? this.matTabGroupConfig.selectIndex
        : 0;

    setTimeout(() => {
      if (this.matTabGroup) {
        this.matTabGroup.selectedIndex = index;
        this.cdRef.detectChanges();
      }
    }, 1000);
  }

  /**
   * A callback method that performs custom clean-up, invoked immediately before a directive, pipe, or service instance is destroyed.
   */
  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /**
   * Seleccionar un tab.
   * @param index - Indice.
   */
  public selectTab(index: number) {
    this.matTabGroup ? (this.matTabGroup.selectedIndex = index) : undefined;
    this.cdRef.detectChanges();
  }
}
