import { Action, createReducer, on } from '@ngrx/store';
import { resetInitialState } from '@wp-back-office/core/store';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';

import { tasksActionsDashBoard } from '../../actions/contents/tasks.actions';

/**
 * Reducer para Tareas.
 */
export interface StateDataTasks {
  /**
   * Tareas.
   */
  taskPending?: any;
  /**
   * Ultima llave de consulta.
   */
  lastKey?: string;
  /**
   * Bandera de carga.
   */
  loaded: boolean;
  /**
   * Bandera de carga.
   */
  loading: boolean;
  /**
   * Respuesta erronea del store.
   */
  error?: ErrorHttp;
}

/**
 * Reducer para obtener tareas de un usuario.
 */
export interface TasksState {
  /**
   * Respuesta del store.
   */
  userTasks?: StateDataTasks;
  /**
   * Respuesta del store.
   */
  users?: StateDataTasks;
  /**
   * Respuesta del store.
   */
  putTask?: StateDataTasks;
}

export const initialStateTasks: StateDataTasks = {
  taskPending: undefined,
  lastKey: undefined,
  loaded: false,
  loading: false,
  error: undefined,
};

const featureGetUserTasksReducer = createReducer(
  initialStateTasks,
  on(resetInitialState, () => ({ ...initialStateTasks })),
  on(tasksActionsDashBoard.getUserTasksReset, () => ({ ...initialStateTasks })),
  on(tasksActionsDashBoard.getUserTasks, state => ({
    ...state,
    loading: true,
    loaded: false,
    error: undefined,
  })),
  on(tasksActionsDashBoard.getUserTasksSuccess, (state, { object }) => ({
    ...state,
    loading: false,
    loaded: true,
    taskPending: [...(state.taskPending || []), ...(object.items || [])],
    lastKey: object.lastKey,
    error: undefined,
  })),
  on(tasksActionsDashBoard.getUserTasksError, (state, { err }) => ({
    ...state,
    taskPending: undefined,
    lastKey: undefined,
    loading: false,
    loaded: true,
    error: err,
  }))
);

/**
 * Reductor de obtener concesionarios.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureTasksReducer.
 */
export function getUserTasksReducer(
  state: StateDataTasks | undefined,
  action: Action
) {
  return featureGetUserTasksReducer(state, action);
}

const featureGetTaskUsersReducer = createReducer(
  initialStateTasks,
  on(resetInitialState, () => ({ ...initialStateTasks })),
  on(tasksActionsDashBoard.getTaskUsers, state => ({
    ...state,
    loading: true,
    loaded: false,
    object: undefined,
    error: undefined,
  })),
  on(tasksActionsDashBoard.getTaskUsersSuccess, (state, { object }) => ({
    ...state,
    loading: false,
    loaded: true,
    object,
    error: undefined,
  })),
  on(tasksActionsDashBoard.getTaskUsersError, (state, { err }) => ({
    ...state,
    object: undefined,
    loading: false,
    loaded: true,
    error: err,
  }))
);

/**
 * Reductor de obtener concesionarios.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureTasksReducer.
 */
export function getTaskUsersReducer(
  state: StateDataTasks | undefined,
  action: Action
) {
  return featureGetTaskUsersReducer(state, action);
}

const featurePutReasingTaskUserReducer = createReducer(
  initialStateTasks,
  on(resetInitialState, () => ({ ...initialStateTasks })),
  on(tasksActionsDashBoard.putReasingTaskUser, state => ({
    ...state,
    loading: true,
    loaded: false,
    object: undefined,
    error: undefined,
  })),
  on(tasksActionsDashBoard.putReasingTaskUserSuccess, (state, { object }) => ({
    ...state,
    loading: false,
    loaded: true,
    object,
    error: undefined,
  })),
  on(tasksActionsDashBoard.putReasingTaskUserError, (state, { err }) => ({
    ...state,
    object: undefined,
    loading: false,
    loaded: true,
    error: err,
  }))
);

/**
 * Reductor de obtener concesionarios.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureTasksReducer.
 */
export function putReasingTaskUserReducer(
  state: StateDataTasks | undefined,
  action: Action
) {
  return featurePutReasingTaskUserReducer(state, action);
}
