import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

/**
 * Accion para cargar los tipos de cuenta de banco.
 */
export const KeyValueBanks = createAction(
  '[KeyValueBanks] Cargar Bancos',
  props<{
    /**
     * Ruta.
     */
    path: CatalogueType;
  }>()
);

/**
 * Accion que se ejecuta cuando la peticion fue ejecutada correctamente.
 */
export const KeyValueBanksSuccess = createAction(
  '[KeyValueBanks] Crear Bancos Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

/**
 * Accion que se ejecuta en caso de un error en la peticion.
 */
export const KeyValueBanksError = createAction(
  '[KeyValueBanks] Cargar Bancos Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
