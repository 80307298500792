import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WorkflowService } from '@wp-back-office/core/workflow-sdk';
import { DynamicSnackBarService } from '@wp-back-office/shared/dynamic-components';
import { catchError, map, mergeMap, of } from 'rxjs';
import { auditSummaryActions } from '../../actions';

/**
 *
 */
@Injectable()
export class AuditSummaryffects {
  /**
   * Crea una instancia GetAuditDetailEffects.
   * @param actions$ - Actions.
   * @param dynamicSnackBarService - Servicio de snackbar.
   * @param workflowService - Servicio.
   */
  constructor(
    private actions$: Actions,
    private workflowService: WorkflowService,
    private dynamicSnackBarService: DynamicSnackBarService
  ) {}

  /**
   * Efecto para obtener detalle del proceso a auditar.
   */
  public AuditDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(auditSummaryActions.getDetailProcessAudit),
      mergeMap(params =>
        this.workflowService
          .getProcessHistory(params.nameProcess, params.executionId)
          .pipe(
            map(object =>
              auditSummaryActions.getDetailProcessAuditSuccess(object)
            ),
            catchError(error => {
              this.dynamicSnackBarService.Open(error.message, 'error');
              return of(
                auditSummaryActions.getDetailProcessAuditError({ error })
              );
            })
          )
      )
    )
  );

  /**
   * Efectos de grupos de procesos.
   */
  public payload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(auditSummaryActions.getProcessData),
      mergeMap(params =>
        this.workflowService.getPendingTask(params.processData).pipe(
          map(response => {
            return auditSummaryActions.getProcessDataSuccess({
              response,
            });
          }),
          catchError(error => {
            this.dynamicSnackBarService.Open(error.message, 'error');
            return of(auditSummaryActions.getProcessDataError());
          })
        )
      )
    );
  });

  /**
   * Efectos de grupos de procesos.
   */
  public getExecutionTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(auditSummaryActions.getAuditProcessExecution),
      mergeMap(params =>
        this.workflowService.getExecutionTask(params.executionId).pipe(
          map(response => {
            return auditSummaryActions.getAuditProcessExecutionSuccess({
              response,
            });
          }),
          catchError(error => {
            this.dynamicSnackBarService.Open(error.message, 'error');
            return of(auditSummaryActions.getAuditProcessExecutionError(error));
          })
        )
      )
    );
  });
}
