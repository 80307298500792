import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueTerms = createAction(
  '[KeyValueTerms] Cargar Catalogos por id',
    props<{
    /**
     * Ruta.
     */
    path: CatalogueType;
    /**
     * Codigo.
     */
    code: string;
    /**
     * Ruta 2.
     */
    path2: CatalogueType;
  }>()
);

export const KeyValueTermsSuccess = createAction(
  '[KeyValueTerms] Crear Catalogos Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueTermsError = createAction(
  '[KeyValueTerms] Cargar Catalogos Error',
   props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
