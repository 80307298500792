import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

/**
 * Accion para cargar los tipos de cuenta de banco.
 */
export const KeyValueTypeBankAccounts = createAction(
  '[KeyValueTypeBankAccount] Cargar Tipos de banco',
  props<{
    /**
     * Ruta.
     */
    path: CatalogueType;
  }>()
);

/**
 * Accion que se ejecuta cuando la peticion fue ejecutada correctamente.
 */
export const KeyValueTypeBankAccountsSuccess = createAction(
  '[KeyValueTypeBankAccount] Crear Tipos de banco Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

/**
 * Accion que se ejecuta en caso de un error en la peticion.
 */
export const KeyValueTypeBankAccountsError = createAction(
  '[KeyValueTypeBankAccount] Cargar Tipos de banco Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
