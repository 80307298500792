import { ActionReducerMap } from '@ngrx/store';

import * as reducers from './reducers';

/**
 * Estado de listas internas.
 */
export interface RootState {
  /**
   * Obtener listas restrictivas.
   */
  auth?: reducers.SessionState;
}

export const rootReducers: ActionReducerMap<RootState> = {
  /**
   * Obtener listas restrictivas.
   */
  auth: reducers.SessionReducer,
};
