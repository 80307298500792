import { Injectable } from '@angular/core';
import { Catalogue } from '@wp-back-office/core/commons-backoffice';
import { of } from 'rxjs';
import {
  ControlConfig,
  GeographicLocationOptions,
} from '../models/form-config.model';

/**
 *  Servicio para plantilllas ubicacion geografica.
 */
@Injectable()
export class TemplateGeographicLocationService {
  /**
   * Formulario de geografia.
   * @returns Obseravble.
   */
  public getGeographicLocation(
    options?: GeographicLocationOptions,
    catalogueCountry?: Catalogue[],
    _controlConfig?: ControlConfig
  ) {
    const controlCountry: ControlConfig = {
      type: 'AutoComplete',
      autocompleteConfig: {
        internalFilter: true,
      },
      key: 'country',
      label: options?.bornDate
        ? 'País de nacimiento'
        : options?.labelCountry || 'País',
      options: catalogueCountry || [{ code: '', description: 'seleccione...' }],
      validators: {
        required: true,
        readonly: options?.readOnlyCountry,
      },
      col: {
        xxl: options?.hideStateAndCity ? 12 : 4,
        xl: options?.hideStateAndCity ? 12 : 4,
        lg: options?.hideStateAndCity ? 12 : 4,
        md: 12,
        sm: 12,
        xs: 12,
      },
      geographicLocationOptions: options,
    };

    const controlState: ControlConfig = {
      type: 'AutoComplete',
      autocompleteConfig: {
        internalFilter: true,
      },
      key: 'state',
      label: options?.bornDate
        ? 'Departamento de nacimiento'
        : options?.labelState || 'Departamento',
      options: [{ code: '', description: 'seleccione...' }],
      validators: {
        required: true,
        readonly: options?.readOnlyState,
      },
      col: {
        xxl: options?.hideCountry ? 6 : 4,
        xl: options?.hideCountry ? 6 : 4,
        lg: options?.hideCountry ? 6 : 4,
        md: 12,
        sm: 12,
        xs: 12,
      },
      geographicLocationOptions: options,
    };

    const controlCity: ControlConfig = {
      type: 'AutoComplete',
      autocompleteConfig: {
        internalFilter: true,
      },
      key: 'city',
      label: options?.bornDate
        ? 'Ciudad de nacimiento'
        : options?.labelCity || 'Ciudad',
      options: [{ code: '', description: 'seleccione...' }],
      validators: {
        required: true,
        readonly: options?.readOnlyCity,
      },
      col: {
        xxl: options?.hideCountry ? 6 : 4,
        xl: options?.hideCountry ? 6 : 4,
        lg: options?.hideCountry ? 6 : 4,
        md: 12,
        sm: 12,
        xs: 12,
      },
      geographicLocationOptions: options,
    };

    let form: ControlConfig[] = [controlCountry, controlState, controlCity];

    if (options?.hideCountry == true) {
      form = [controlState, controlCity];
    }

    if (options?.hideStateAndCity == true) {
      form = [controlCountry];
    }
    return form?.map(control => ({
      ...control,
      appearance: _controlConfig?.appearance || undefined,
      classes: _controlConfig?.classes || undefined,
    }));
  }
}
