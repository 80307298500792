import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';

/**
 * Error.
 */
export interface ErrorFile {
  /**
   * Estado.
   */
  status: number;
  /**
   * Mensaje.
   */
  message: string;
}
/**
 * Respuesta del servicio.
 */
export interface DownloadResponse {
  /**
   * Error.
   */
  error?: ErrorFile;
  /**
   * Archivo.
   */
  file?: Blob;
}

/**
 *  Payload para la descarga de archivos.
 */
export interface FilePayload {
  /**
   * Url del archivo.
   */
  url: string;
  /**
   * Nombre del archivo.
   */
  name: string;
  /**
   * DontSave.
   */
  dontSave?: boolean;
}
/**
 * Servicio de descarga de archivos.
 */
@Injectable()
export class DynamicFileDownloaderService {
  /**
   * Descargar archivos con una url.
   * @param filePayload - Payload del archivo.
   * @returns Observable<DownloadResponse>.
   */
  public download(filePayload: FilePayload): Observable<DownloadResponse> {
    return fromFetch(filePayload.url).pipe(
      switchMap(async data => {
        if (data.ok) {
          const result = await data.blob();
          if (!filePayload?.dontSave) {
            const objectUrl = URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = objectUrl;
            link.download = filePayload.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(objectUrl);
          }
          return { file: result };
        } else {
          return {
            error: {
              status: data.status,
              message: `${data.statusText}`,
            },
          };
        }
      })
    );
  }
}
