<ng-template [ngIf]="controlConfig && controlConfig.key && controlConfig.type">
  <mat-form-field
    [appearance]="controlConfig.appearance || 'outline'"
    class="{{ controlConfig.classes || '' }}"
    [class.readonly]="this.controlConfig.validators?.readonly ? true : false">
    <mat-label [attr.for]="controlConfig.key || ''">
      {{ controlConfig.label || '' }}
    </mat-label>
    <mat-select
      #select="matSelect"
      overlayPanelClass="alv"
      matNativeControl
      [id]="uniqueIdForm + controlConfig.key"
      aria-readonly="true"
      (opened)="focus()"
      [formControl]="_formControl"
      [compareWith]="compareFn"
      (selectionChange)="onSelect($event.value, controlConfig.key)"
      (openedChange)="onOpened($event)"
      [multiple]="controlConfig.validators?.multiple || false"
      msInfiniteScroll
      (infiniteScroll)="
        onInfiniteScroll(controlConfig.options, controlConfig.key)
      ">
      <mat-option
        disabled
        search
        class="hide-checkbox"
        *ngIf="
          controlConfig?.type === 'DropDownFilter' ||
          controlConfig.validators?.multiple
        ">
        <mat-form-field
          appearance="fill"
          table
          cell
          *ngIf="controlConfig?.type === 'DropDownFilter'">
          <input
            onclick="this.select()"
            (click)="$event.stopPropagation()"
            (keydown)="$event.stopPropagation()"
            (keyup)="
              this.filteredOptions = this.filter(
                autoCompleteControl.value || ''
              )
            "
            matInput
            autocomplete="off"
            [id]="'inputAC' + controlConfig.key || ''"
            aria-readonly="true"
            [formControl]="autoCompleteControl"
            type="text"
            placeholder="Buscar" />
        </mat-form-field>

        <mat-checkbox
          *ngIf="controlConfig.validators?.multiple || false"
          class="m-0"
          [checked]="selectAllenabled"
          disabled
          (click)="selectAll()"
          [color]="'accent'">
          {{ !selectAllenabled ? 'Seleccionar todo' : 'Eliminar selección' }}
        </mat-checkbox>
      </mat-option>

      <mat-option
        class="loading hide-checkbox"
        *ngIf="controlConfig.loading"
        [disabled]="this.controlConfig.validators?.readonly ? true : false">
        <div class="input-spinner">
          <mat-spinner color="primary" [diameter]="25"> </mat-spinner>
        </div>
      </mat-option>

      <mat-option
        class="opacity-08 hide-checkbox"
        *ngIf="(controlConfig?.options?.length || 0) === 0"
        [matTooltip]="'No hay opciones disponibles en este campo.'"
        [value]="undefined"
        [disabled]="true">
        No hay opciones disponibles en este campo.
      </mat-option>

      <mat-option
        class="opacity-08 hide-checkbox"
        *ngIf="controlConfig?.validators?.dropDownUndefinedOption"
        [matTooltip]="'Seleccione una opción.'"
        [value]="undefined">
        Seleccione una opción.
      </mat-option>

      <ng-container *ngIf="controlConfig?.type !== 'DropDownFilter'">
        <ng-container
          *ngIf="
            controlConfig.validators?.multiple ||
            (!controlConfig.validators?.multiple &&
              (controlConfig.options || []).length <= 6)
          ">
          <mat-option
            *ngFor="let opt of controlConfig.options || []"
            [value]="opt"
            [disabled]="this.controlConfig.validators?.readonly ? true : false"
            >{{ opt?.description }}
          </mat-option>
        </ng-container>

        <ng-container
          *ngIf="
            !controlConfig.validators?.multiple &&
            (controlConfig.options || []).length > 6
          ">
          <cdk-virtual-scroll-viewport
            style="height: 247px"
            minBufferPx="247"
            maxBufferPx="500"
            itemSize="39"
            class="example-viewport"
            (scroll)="
              onScroll($event, controlConfig.options, controlConfig.key)
            ">
            <mat-option
              *cdkVirtualFor="let opt of controlConfig.options || []"
              [value]="opt"
              [disabled]="
                _formControl.disabled ||
                this.controlConfig.validators?.readonly
                  ? true
                  : false
              ">
              {{ opt?.description }}
            </mat-option>
          </cdk-virtual-scroll-viewport>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="controlConfig?.type === 'DropDownFilter'">
        <ng-container *ngIf="controlConfig.validators?.multiple">
          <mat-option
            *ngFor="let opt of filteredOptions || []"
            [value]="opt"
            [disabled]="
              _formControl.disabled || this.controlConfig.validators?.readonly
                ? true
                : false
            ">
            {{ opt?.description }}
          </mat-option>
        </ng-container>

        <ng-container *ngIf="!controlConfig.validators?.multiple">
          <cdk-virtual-scroll-viewport
            style="height: 247px"
            minBufferPx="247"
            maxBufferPx="500"
            itemSize="39"
            class="example-viewport"
            (scroll)="
              onScroll($event, controlConfig.options, controlConfig.key)
            ">
            <mat-option
              *cdkVirtualFor="let opt of filteredOptions || []"
              [value]="opt"
              [disabled]="
                _formControl.disabled ||
                this.controlConfig.validators?.readonly
                  ? true
                  : false
              ">
              {{ opt?.description }}
            </mat-option>
          </cdk-virtual-scroll-viewport>
        </ng-container>
      </ng-container>
    </mat-select>
    <mat-error
      [innerHTML]="
        dynamicFormService.errorMessage(_formControl, controlConfig)
      ">
    </mat-error>
    <mat-icon matSuffix *ngIf="controlConfig?.icon">
      {{ controlConfig.icon || '' }}
    </mat-icon>
  </mat-form-field>
  <div class="input-spinner" *ngIf="controlConfig.loading">
    <mat-spinner color="primary" [diameter]="25"> </mat-spinner>
  </div>
  <mat-chip-list
    class="select-multiple-list"
    *ngIf="
      controlConfig.validators?.multiple &&
      (_formControl.value || []).length > 0
    ">
    <mat-chip
      *ngFor="let chip of _formControl.value || []"
      color="accent"
      selected>
      {{ chip?.description || '' }}
    </mat-chip>
  </mat-chip-list>
</ng-template>
