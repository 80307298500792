import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';
import { environment } from 'apps/back-office/src/environments/environment';
import { errorHandler } from '@wp-back-office/app/global-information';

/**
 * Modelo de consulta parametros servicio usuarios.
 */
export interface UsersReasingTask {
  /**
   * Codigo de usuario.
   */
  userCode: string;
  /**
   *  Proceso.
   */
  process: string;
  /**
   *  Fecha de la tarea.
   */
  date: number;
  /**
   * Token de la tarea.
   */
  taskToken: string;
}

/**
 *{AGREGUE DOCUMENTACION}.
 */
export interface Document {
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  type: string;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  name: string;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  key: string;
}

/**
 *{AGREGUE DOCUMENTACION}.
 */
export interface PendingKey {
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  idExecution: string;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  date: string;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  token: string;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  process: string;
}

/**
 * Modelo de put reasignar tarea a un nuevo usuaio.
 */
export interface ReasingTaskNewUser {
  /**
   * Codigo de usuario actual.
   */
  oldUserId: string;
  /**
   * Codigo de ususario nuevo.
   */
  newUserId: string;
  /**
   *  Proceso.
   */
  process: string;
  /**
   *  Fecha de la tarea.
   */
  date: number;
  /**
   * Token de la tarea.
   */
  taskToken: string;
}

/**
 * Servicio de tareas pendientes..
 */
@Injectable()
export class PendingTasksService implements OnDestroy {
  /**
   * Constante url.
   */
  private URL = environment.endpoints.url;
  /**
   * Constate tenant.
   */
  private TENANT_ID = environment.common.tenantId;
  /**
   * Destructor sujeto.
   */
  private destroy$ = new Subject();
  /**
   * Crea una instancia PendingTasksService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}

  /**
   * A callback method that performs custom clean-up, invoked immediately before a directive, pipe, or service instance is destroyed.
   */
  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /**
   * Busqueda de tareas pendientes.
   * @param options - Opciones.
   * @param options.size - Tamaño.
   * @param options.target - Objetivo.
   * @param options.process - Proceso.
   * @returns Observable.
   */
  public searchUserProcess(options: {
    /**
     * Tamaño.
     */
    size: number;
    /**
     * Objetivo.
     */
    target: string;
    /**
     * Token para la paginacion.
     */
    lastKey?: string;
    /**
     * Valor a filtrar.
     */
    filter?: string;
    /**
     * Proceso.
     */
    process: string;
    /**
     * Id del proceso.
     */
    id?: any;
  }): Observable<any> {
    const url =
      options.process && options.process !== ''
        ? `${this.URL}/${
            this.TENANT_ID
          }/tasks/pending/process/${options.process.toUpperCase()}`
        : `${this.URL}/${
            this.TENANT_ID
          }/tasks/pending/user/${options.target.toLowerCase()}`;

    return this.http
      .get(url, {
        params: {
          page_size: options.size,
          last_key: options.lastKey || '',
          filter: options.filter || '',
          process: options.id || '',
        },
      })
      .pipe(
        catchError(errorHandler),
        tap(res => {
        })
      );
  }

  /**
   * Obtener llave tarea.
   * @param defaultGroup - Grupo por defecto.
   * @param key - Llave.
   * @returns Observable.
   */
  public getTaskKey(defaultGroup: string, key: string): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/tasks/pending/key`,
      { key, defaultGroup }
    );
  }

  /**
   * Obtener tareas pendientes.
   * @param task - Tarea.
   * @returns Observable.
   */
  public getPendingTaskKey(task: PendingKey): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/tasks/pending/key`,
      task
    );
  }

  /**
   * Obtener los usuarios para reasignarle una tarea.
   * @param params - Parametros del servicio.
   * @returns Observable.
   */
  public getUsersReasingTask(params: UsersReasingTask): Observable<any> {
    return this.http
      .post(
        `${this.URL}/${this.TENANT_ID}/task-reassignment/task-available-users`,
        {
          ...params,
        }
      )
      .pipe(catchError(errorHandler));
  }

  /**
   * Reasignar una tarea a un nuevo usuario.
   * @param params - Parametros del servicio.
   * @returns Observable.
   */
  public reasingTaskNewUser(params: ReasingTaskNewUser): Observable<any> {
    return this.http.put(
      `${this.URL}/${this.TENANT_ID}/task-reassignment/user`,
      {
        ...params,
      }
    );
  }
}
