import { createAction, props } from '@ngrx/store';
import { PendingTaskPost } from '../../../../models/post-data.models';
import { TaskInformation } from '../../../models/contents/task-form.models';

export const TaskSchema = createAction(
  '[TaskSchema] Formularios para tareas',
  props<{
    /**
     * Parametros para envio del servicio.
     */
    paramsPost: PendingTaskPost;
  }>()
);

export const TaskSchemaSuccess = createAction(
  '[TaskSchema] Formularios para tareas Exitoso',
  props<{
    /**
     * Informacion de la tarea.
     */
    taskPendingInfo: TaskInformation;
  }>()
);

export const TaskSchemaError = createAction(
  '[TaskSchema] Formularios para tareas Error',
  props<{
    /**
     * Payload error.
     */
    payload: any;
  }>()
);

export const ResetTaskSchema = createAction('[TaskSchema] Reiniciar el estado');