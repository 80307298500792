import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuditProcessesService } from '@wp-back-office/core/workflow-sdk';
import { mergeMap, map, catchError, of } from 'rxjs';
import * as actionsEffects from '../../actions';

/**
 *
 */
@Injectable()
export class AuditProcessesEffects {
  /**
   * Crea una instancia GetAuditProcessesEffects.
   * @param actions$ - Actions.
   * @param auditProcessesService - Servicio.
   */
  constructor(
    private actions$: Actions,
    private auditProcessesService: AuditProcessesService
  ) {}

  /**
   * Efecto para obtener lista interna.
   */
  public AuditProcessesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.auditProcesses),
      mergeMap(params =>
        this.auditProcessesService
          .getAuditProcesses(
            params.filterType,
            params.filter,
            params.pageSize,
            params.lastPageToken
          )
          .pipe(
            map(object => actionsEffects.auditProcessesSuccess({ object })),
            catchError(error => {
              return of(actionsEffects.auditProcessesError({ error }));
            })
          )
      )
    )
  );
}
