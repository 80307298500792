import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe encargado de transformar la letra mayusucula en espacios.
 */
@Pipe({
  name: 'spaceMayus',
})
export class SpaceMayusPipe implements PipeTransform {
  /**
   * Cambia una cadena.
   * @param value -valor.
   * @returns String.
   */
  public transform(value: string): string {
    const first = value.replace(/([A-Z])/g, ' $1').trim();
    return first;
  }
}
