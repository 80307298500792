import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  CatalogueSBS,
  CatalogueTasksService,
} from '@wp-back-office/core/commons-backoffice';
import { mergeMap, map, catchError, of } from 'rxjs';
import * as actionsEffects from '../../actions';
/**
 * Servicio de catalogue.
 */
@Injectable()
export class KeyValueSBSGenderEffects {
  /**
   * Crea una instancia de .
   * @param actions$ - Acciones.
   * @param catalogueTasksService - Servicio de catalogo.
   */
  constructor(
    private actions$: Actions,
    private catalogueTasksService: CatalogueTasksService
  ) {}

  /**
   * Obtener catalogo.
   */
  public keyValueSBSGender$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.KeyValueSBSGender),
      mergeMap(actions =>
        this.catalogueTasksService.getCatalogueTasks(actions.path).pipe(
          map((keyValue: CatalogueSBS[]) =>
            actionsEffects.KeyValueSBSGenderSuccess({ keyValue })
          ),
          catchError(err =>
            of(actionsEffects.KeyValueSBSGenderError({ payload: err }))
          )
        )
      )
    )
  );
}
