import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ControlConfig } from '../../../../models/form-config.model';
import { DynamicFormService } from '../../../../services/dynamic-form.service';
import { Catalogue } from '@wp-back-office/core/store';
import { MatRadioGroup } from '@angular/material/radio';

/**
 * Componente para los controles de radio.
 */
@Component({
  selector: 'wp-back-office-form-control-radio-button',
  templateUrl: './form-control-radio-button.component.html',
  styleUrls: ['./form-control-radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlRadioButtonComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormControlRadioButtonComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  /**
   * Configuracion del control.
   */
  @Input()
  public set control(value: ControlConfig | undefined) {
    if (value) {
      this.controlConfig = value;
      this._formControl = this.dynamicFormService.setValidators(value);
    }
  }

  /**
   * ViewChild del radio group.
   */
  @ViewChild(MatRadioGroup)
  myradio!: MatRadioGroup;

  /**
   * Horizontal.
   */
  @Input()
  public horizontal!: boolean;

  /**
   * Valor del control.
   */
  @Input()
  public value!: any;

  /**
   * Form control del campo.
   */
  public _formControl: FormControl;

  /**
   * Configuracion del control variable.
   */
  public controlConfig!: ControlConfig;

  /**
   * Destructor observables.
   */
  private destroy$: Subject<boolean>;

  /**
   * Crea una instancia de la clase.
   * @param dynamicFormService - Servicio de formularios dinamicos.
   * @param cdRef - Detector de cambios.
   */
  constructor(
    private dynamicFormService: DynamicFormService,
    public cdRef: ChangeDetectorRef
  ) {
    this._formControl = new FormControl('');
    this.destroy$ = new Subject();
  }

  /**
   * Se ejecutar al iniciar el componente.
   */
  public ngOnInit() {
    if (this.controlConfig) {
      this._formControl = this.dynamicFormService.setValidators(
        this.controlConfig
      );
      if (this.controlConfig.radioConfig?.buttonLayout == true)
        this.horizontal = true;
    }
    this._formControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.onChange(data);
        if (this._formControl.touched) {
          this.onTouch(data);
        }
        this.cdRef.detectChanges();
      });

    if (this.value) {
      this.writeValue(this.value);
    }
  }

  /**
   * Se ejecuta al destruir el componente.
   */
  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * Verirfica si es cambiado.
   * @param obj - Callback.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  public onChange = (obj: any) => {};

  /**
   * Verirfica si es tocado.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  public onTouch: any = () => {};

  /**
   * Verirfica si es escrito.
   * @param obj - Callback.
   * @throws Error.
   */
  public writeValue(obj: any): void {
    if (obj && obj?.code && !obj?.description) {
      const value = this.getOptionFromCode(obj);
      if (value && value?.code && value?.description) {
        this._formControl.patchValue(value);
      } else {
        this._formControl.patchValue(obj);
      }
    } else {
      this._formControl.patchValue(obj);
    }
    setTimeout(() => {
      this.cdRef.detectChanges();
      this.valideOptions();
    }, 100);
  }

  /**
   * Verirfica si es cambiado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Verirfica si es tocado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /**
   * Verifica si el campo fue deshabilitado.
   * @param isDisabled - Bandera.
   */
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this._formControl.disable();
    } else {
      this._formControl.enable();
    }
  }

  /**
   * Valida si hay una sola opcion en la lista.
   */
  public valideOptions() {
    if (
      this.controlConfig?.validators?.required &&
      this.controlConfig?.options?.length === 1 &&
      this.controlConfig?.options[0]?.code &&
      !this.controlConfig?.uniqueOptionAutoFillDeactivated
    ) {
      this._formControl.patchValue(this.controlConfig?.options[0]);
    }
    const controlValue = this._formControl.value;
    if (
      controlValue &&
      typeof controlValue === 'object' &&
      controlValue?.code &&
      !controlValue?.description
    ) {
      const value = this.getOptionFromCode(controlValue);
      if (value && value?.code && value?.description) {
        this._formControl.patchValue(value);
      }
    }
    this.cdRef.detectChanges();

    setTimeout(() => {
      const radio = this.myradio._radios.find(
        radio => radio.value?.code === this._formControl.value?.code
      );
      if (radio) radio.checked = true;
    }, 100);
  }

  /**
   * Retorna un catalogo con su code.
   * @param obj - Catalogo.
   * @returns Catalogue | undefined.
   */
  public getOptionFromCode(obj: Catalogue): Catalogue | undefined {
    if (obj && typeof obj === 'object' && obj?.code && !obj?.description) {
      return (this.controlConfig.options || []).find(
        opt => opt.code === obj.code
      );
    } else {
      return obj;
    }
  }
}
