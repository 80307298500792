import { Action, createReducer, on } from '@ngrx/store';
import { ErrorHttp } from '../../../../models/errors.models';
import {
  KeyValueUnitAmount,
  KeyValueUnitAmountSuccess,
  KeyValueUnitAmountError,
} from '../../actions';

import { resetInitialState } from '../../../../root/store/actions/contents/reset-initial-state.actions';
import { Catalogue } from '../../../models';

/**
 * Estado llave valor.
 */
export interface KeyValueUnitAmountState {
  /**
   * Catalogo.
   */
  catalogue?: Catalogue[];
  /**
   * Bandera de cargado.
   */
  loaded: boolean;
  /**
   * Bandera de cargando.
   */
  loading: boolean;
  /**
   * Errores.
   */
  error?: ErrorHttp;
}

export const unitAmountInitialState: KeyValueUnitAmountState = {
  catalogue: undefined,
  loaded: false,
  loading: false,
  error: undefined,
};

const featureReducer = createReducer(
  unitAmountInitialState,
  on(resetInitialState, () => ({ ...unitAmountInitialState })),
  on(KeyValueUnitAmount, state => ({ ...state, loading: true })),
  on(KeyValueUnitAmountSuccess, (state, { keyValue }) => ({
    ...state,
    loading: false,
    loaded: true,
    catalogue: [...keyValue],
  })),
  on(KeyValueUnitAmountError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

/**
 * Reductor de .
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function keyValueUnitAmountReducer(
  state: KeyValueUnitAmountState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
