import { createAction, props } from '@ngrx/store';
import { PendingTaskPost } from '../../../../models/post-data.models';

export const PayloadAnalysis = createAction(
  '[PayloadAnalysis] Informacion para analisis',
  props<{
    /**
     * Parametros para envio del servicio.
     */
    processData: PendingTaskPost;
  }>()
);

export const PayloadAnalysisSuccess = createAction(
  '[PayloadAnalysis] Informacion para analisis exitoso',
  props<{
    /**
     * Formularios para el proceso.
     */
    response: any;
  }>()
);

export const PayloadAnalysisError = createAction(
  '[PayloadRisk] Informacion para analisis error',
  props<{
    /**
     * Payload error.
     */
    error: any;
  }>()
);

export const TaskAnalysisResult = createAction(
  '[TaskAnalysisResult] Informacion para analisis token y proceso',
  props<{
    /**
     * Parametros para envio del servicio.
     */
    processData: PendingTaskPost;
  }>()
);
