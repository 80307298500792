import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as reducers from './reducers';
/**
 * Estado de listas internas.
 */
export interface AuditProcessState {
  /**
   * Obtener listas de procesos a auditar.
   */
  AuditProcesses?: reducers.AuditProcessesState;
  /**
   * Obtener detalle del proceso a auditar.
   */
  AuditDetail?: reducers.AuditDetailStateNew;
  /**
   * Obtener carga útil.
   */
  payload?: reducers.PayloadAuditState;
  /**
   * Obtener carga útil.
   */
  AuditProcessExecution?: reducers.AuditProcessExecutionState;
}

export const AuditProcessReducers: ActionReducerMap<AuditProcessState> = {
  /**
   * Obtener listas de procesos a auditar.
   */
  AuditProcesses: reducers.AuditProcessesReducer,
  /**
   * Obtener detalle del proceso a auditar.
   */
  AuditDetail: reducers.AuditDetailReducerNew,
  /**
   * Obtener historia del proceso a auditar.
   */
  payload: reducers.PayloadAuditReducer,
  /**
   * Obtener carga útil.
   */
  AuditProcessExecution: reducers.auditProcessExecutionReducer,
};

/**
 * Selector de token de acesso.
 * @param state - Estado.
 * @returns Token.
 */
export const featureAuditProcess =
  createFeatureSelector<AuditProcessState>('AuditProcess');
