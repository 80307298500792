import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { Catalogue } from '@wp-back-office/core/commons-backoffice';
import { combineLatest, map, Observable } from 'rxjs';
import { ControlConfig } from '../..';

/**
 * Valida que la opcion seleccionada tenga el formato correcto.
 * @param control
 * @returns
 */
export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;
  if (control.errors && control.errors['required']) {
    if (typeof selection !== 'object') {
      return { incorrect: true };
    }
    return null;
  } else {
    if (
      typeof selection !== 'object' &&
      typeof selection !== 'undefined' &&
      selection !== '' &&
      selection !== null
    ) {
      return { incorrect: true };
    }
    return null;
  }
}

/**
 *
 * @param control
 * @returns
 */
export function isArray(control: AbstractControl) {
  const value: any = control.value;
  if (typeof value === 'object' && Array.isArray(value)) {
    return null;
  }
  return { notArray: true };
}

/**
 * Servicio del formulario dinamico.
 */
@Injectable()
export class DynamicFormService {
  /**
   * Expresion regular para los campos de tipo email.
   */
  private emailRegularExpression =
    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;

  /**
   * Modifica el valor del campo.
   * @param control$ - Observable de control.
   * @param inputKey - Key del input.
   * @param catalogue - Catalogo.
   * @param loading - Observable de carga.
   * @returns - Observable de control.
   */
  public modifyFormObservable(
    control$: Observable<ControlConfig[]>,
    inputKey: string,
    catalogue: Catalogue[],
    loading?: boolean
  ): Observable<ControlConfig[]> {
    return combineLatest([control$]).pipe(
      map(([form]) => {
        form.map((data, index) => {
          if (data.key == 'permissions' && inputKey === 'permissions') {
            form[index] = {
              ...data,
              categories: (catalogue as any) || data.categories,
              validators: {
                ...data.validators,
                loading: loading,
              },
            };
          } else if (data.key == inputKey) {
            form[index] = {
              ...data,
              options: catalogue || data.options,
              validators: {
                ...data.validators,
                loading: loading,
              },
            };
          }
        });
        return form;
      })
    );
  }

  /**
   * Modifica el valor del campo.
   * @param control - Controles.
   * @param inputKey - Key del input.
   * @param catalogue - Catalogo.
   * @param loading -  Carga.
   * @returns - Controles.
   */
  public modifyForm(
    control: ControlConfig[],
    inputKey: string,
    catalogue: Catalogue[],
    loading?: boolean
  ): ControlConfig[] {
    control.map((data, index) => {
      if (data.key == 'permissions' && inputKey === 'permissions') {
        control[index] = {
          ...data,
          categories: (catalogue as any) || data.categories,
          validators: {
            ...data.validators,
            loading: loading,
          },
        };
      } else if (data.key == inputKey) {
        control[index] = {
          ...data,
          options: catalogue || data.options,
          validators: {
            ...data.validators,
            loading: loading,
          },
        };
      }
    });
    return control;
  }

  /**
   * Retorna un mensaje de error al campo.
   * @param formControl - Formulario.
   * @param item - Item.
   * @returns String.
   */
  public errorMessage(formControl: FormControl, item: any): string {
    if (item?.validators?.customErrorMessage) {
      return item?.validators?.customErrorMessage;
    } else if (
      formControl.errors?.['required'] &&
      (formControl.dirty || formControl.touched)
    ) {
      return `<strong>*</strong> ${item.label} es <strong>requerido</strong>.`;
    } else if (
      formControl.errors?.['pattern'] &&
      (formControl.dirty || formControl.touched)
    ) {
      return `El formato de ${item.label} es <strong>incorrecto</strong>.`;
    } else {
      return '';
    }
  }

  /**
   * Retorna un mensaje de error al campo.
   * @param formControl - Formulario.
   * @param item - Item.
   * @returns String.
   */
  public errorMessageAbstractControl(
    formControl: AbstractControl,
    item: any
  ): string {
    if (item.customErrorMessage) {
      return item.customErrorMessage;
    } else if (formControl.hasError('notArray')) {
      return `Escribe y presiona enter para agregar a la lista.`;
    } else if (formControl.hasError('required')) {
      return `<strong>*</strong> ${item.label} es <strong>requerido</strong>.`;
    } else if (formControl.hasError('pattern')) {
      return `El formato de ${item.label} es <strong>incorrecto</strong>.`;
    } else if (formControl.hasError('incorrect')) {
      return `Selecciona una opción de la lista.`;
    } else {
      return '';
    }
  }

  /**
   * Existe datos en el formulario.
   * @param code - Codigo.
   * @param array - Array.
   * @returns - Boolean.
   */
  public existIn(code: any, array: any[]): boolean {
    return array?.filter(item => item.code === code).length > 0 ? true : false;
  }

  /**
   * Asignar validadores al control dependiendo de su configuracion.
   * @param item - Control a validar.
   * @returns FormControl.
   */
  public setValidators(item: ControlConfig) {
    const validators = this.getValidators(item);

    let value: any = item?.value || '';
    if (item.type === 'CheckBox') {
      value = item?.value ? true : false;
      if (item?.checkBoxOptions?.onlyAcceptTrue) {
        value = item?.value ? true : null;
      }
    }

    const cotrolValidators = new FormControl(
      {
        value: value,
        disabled:
          item.validators?.disabled || item.validators?.readonly || false,
      },
      Validators.compose(validators)
    );

    return cotrolValidators;
  }

  /**
   * Obtiene los validators del formulario.
   * @param item - Item del formulario.
   * @returns Validators[].
   */
  public getValidators(item: ControlConfig): any[] {
    let validators: any[] = [];
    if (!item.validators?.disabled) {
      const pattern =
        item.type && item.type == 'Email'
          ? this.emailRegularExpression
          : item.validators?.pattern;

      if (item.validators?.pattern || item.type == 'Email') {
        validators = [...validators, Validators.pattern(pattern || '')];
      }

      if (
        item.type == 'AutoComplete' ||
        item.type == 'DropDown' ||
        item.type == 'DropDownFilter'
      ) {
        validators.push(RequireMatch);
      }

      if (item.validators?.chipList) {
        validators.push(isArray);
      }

      if (item.validators) {
        for (const [key, value] of Object.entries(item.validators)) {
          switch (key) {
            case 'min':
              validators.push(Validators.min(value));
              break;
            case 'max':
              validators.push(Validators.max(value));
              break;
            case 'required':
              if (value) {
                validators.push(Validators.required);
              }
              break;
            case 'requiredTrue':
              if (value) {
                validators.push(Validators.requiredTrue);
              }
              break;
            case 'email':
              if (value) {
                validators.push(Validators.email);
              }
              break;
            case 'minLength':
              validators.push(Validators.minLength(value));
              break;
            case 'maxLength':
              validators.push(Validators.maxLength(value));
              break;
            case 'pattern':
              validators.push(Validators.pattern(value));
              break;
            case 'nullValidator':
              if (value) {
                validators.push(Validators.nullValidator);
              }
              break;
            default:
              break;
          }
        }
      }
    }
    return validators;
  }

  /**
   * Convierte un base 64 a File.
   * @param base64 - Base 64.
   * @returns File.
   */
  public base64ToFile(base64: any) {
    try {
      const arr = base64.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = window.atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], 'profile', { type: mime });
    } catch (error) {
      return undefined;
    }
  }
}
