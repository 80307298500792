import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SignInService } from '@wp-back-office/app/user-authentication';
import {
  DynamicDialogMessageService,
  DynamicSnackBarService,
} from '@wp-back-office/shared/dynamic-components';
import { CognitoUser } from 'libs/app/user-authentication/src/lib/models/cognito.models';
import { mergeMap, catchError, tap, EMPTY, map, delay, of } from 'rxjs';
import { authActions } from '../../actions/contents/auth.actions';
import { StorageService } from '@wp-back-office/core/workflow-sdk';
import { isEqualString } from '@wp-back-office/app/global-information';

@Injectable()
export class AuthEffects {
  /**
   * Correo del usuario.
   */
  public userEmail!: string;
  /**
   * Iniciar sesion.
   */
  public loggin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.inciarSesion),
      mergeMap(({ signInInformation }) => {
        this.dialog.openLoader();
        return this.signInService.signInAmplify(signInInformation).pipe(
          map(({ idToken, accessToken, refreshToken }: any) => {
            const userSession: CognitoUser = {
              signInUserSession: {
                idToken,
                accessToken,
                refreshToken,
              },
            };

            this.signInService.updateSessionStorage(userSession);
            return authActions.inciarSesionExitoso({
              response: userSession,
            });
          }),

          catchError((error: any) => {
            if (error.message.includes('not exist')) {
              error.message = 'El usuario no existe.';
            }
            if (error.message.includes('Incorrect')) {
              error.message = 'Nombre de usuario o contraseña incorrectos.';
            }
            this.dialog.closeAllLoaders();
            this.dynamicSnackBarService.Open(error.message, 'error');
            this.router.navigate(['login']);
            return EMPTY;
          })
        );
      })
    )
  );

  /**
   * Cerrar sesion.
   */
  public logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.cerrarSesion),
        tap(() => localStorage.setItem('closeSession', 'true')),
        delay(1000),
        tap(async () => {
          if (await this.storage.getItem('userEmail')) {
            this.userEmail = (await this.storage.getItem('userEmail')) || '';
          }
          localStorage.clear();
          this.dialog.openLoader();
          this.signInService.deleteSession();
          this.router.navigate(['/login']);
          localStorage.setItem('closeSession', 'true');
          if (this.userEmail)
            this.signInService.removeSession(this.userEmail).subscribe();
        }),
        delay(3000),
        tap(() => {
          window.location.reload();
        })
      ),
    {
      dispatch: false,
    }
  );

  /**
   * Sesion invalida.
   */
  public invalidSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.sesionExpirada),
        tap(({ invalid }) => {
          localStorage.setItem('closeSession', 'true');
          localStorage.setItem('exipredSesion', 'true');
          if (invalid) localStorage.setItem('invalidToken', 'true');
        }),
        delay(1000),
        tap(async ({ invalid }) => {
          this.dialog.openLoader();
          const userEmail = await this.storage.getItem('userEmail');
          this.signInService.removeSession(userEmail || '').subscribe();
          this.signInService.deleteSession();
          this.router.navigate(['/login']);
          localStorage.clear();
          localStorage.setItem('closeSession', 'true');
          localStorage.setItem('exipredSesion', 'true');
          if (invalid) localStorage.setItem('invalidToken', 'true');
        }),
        delay(2000),
        tap(() => {
          window.location.reload();
        })
      ),
    {
      dispatch: false,
    }
  );

  /**
   * Iniciar sesion.
   */
  public sendEmailOtpCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.sendEmailOtpCode),
      mergeMap(({ email }) => {
        this.dialog.openLoader();
        return this.signInService.sendEmailOtpCode(email).pipe(
          map((object: any) => {
            this.dialog.closeAllLoaders();
            this.dynamicSnackBarService.Open(
              'El código OTP ha sido enviado correctamente.',
              'success'
            );
            return authActions.sendEmailOtpCodeSuccess({
              object,
            });
          }),
          catchError((error: any) => {
            if (error?.message?.description) {
              error.message = 'El correo electrónico enviado no es válido.';
            }
            this.dialog.closeAllLoaders();
            this.dynamicSnackBarService.Open(error.message, 'error');
            return of(authActions.sendEmailOtpCodeError({ error }));
          })
        );
      })
    )
  );

  /**
   * Iniciar sesion.
   */
  public verifyOtpCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.verifyOtpCode),
      mergeMap(({ email, otp }) => {
        this.dialog.openLoader();
        return this.signInService.verifyOtpCode(email, otp).pipe(
          map((object: any) => {
            this.dialog.closeAllLoaders();
            this.dynamicSnackBarService.Open('Código OTP válido.', 'success');
            return authActions.verifyOtpCodeSuccess({
              object,
            });
          }),
          catchError((error: any) => {
            if (error?.message?.description) {
              error.message = 'El correo electrónico enviado no es válido.';
              if (
                error?.message?.validations.length > 0 &&
                isEqualString(error?.message?.validations[0]?.param, 'otp')
              ) {
                error.message = 'El código OTP enviado no es válido.';
              }
            }
            this.dialog.closeAllLoaders();
            this.dynamicSnackBarService.Open(error.message, 'error');
            return of(authActions.verifyOtpCodeError({ error }));
          })
        );
      })
    )
  );

  /**
   * Iniciar sesion.
   */
  public passwordReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.passwordReset),
      mergeMap(({ email, password, token }) => {
        this.dialog.openLoader();
        return this.signInService.passwordReset(email, password, token).pipe(
          map((object: any) => {
            this.dialog.closeAllLoaders();
            this.dynamicSnackBarService.Open(
              'El código OTP ha sido enviado correctamente.',
              'success'
            );
            return authActions.passwordResetSuccess({
              object,
            });
          }),
          catchError((error: any) => {
            if (error?.message?.description) {
              if (
                error?.message?.validations.length > 0 &&
                isEqualString(error?.message?.validations[0]?.param, 'password')
              ) {
                error.message = 'El código OTP enviado no es válido.';
              } else {
                error.message = 'El nombre de usuario enviado no es válido.';
              }
            }

            if (
              isEqualString(error?.message, 'the token is undefined') ||
              isEqualString(error?.message, 'Invalid token')
            ) {
              error.message = 'El token no es válido.';
            }

            if (isEqualString(error?.message, 'oken expired')) {
              error.message = 'El token ha expirado.';
            }

            this.dialog.closeAllLoaders();
            this.dynamicSnackBarService.Open(error.message, 'error');
            return of(authActions.passwordResetError({ error }));
          })
        );
      })
    )
  );

  /**
   * Crea una instancia de SessionEffects.
   * @param router - Rutas.
   * @param actions$ - Actions.
   * @param signInService - Servicio de login.
   * @param dialog - Dialogs dinamicos.
   * @param dynamicSnackBarService - SnackBar.
   * @param storage - Servicio de storage.
   */
  constructor(
    private router: Router,
    private actions$: Actions,
    private signInService: SignInService,
    private dialog: DynamicDialogMessageService,
    private dynamicSnackBarService: DynamicSnackBarService,
    public storage: StorageService
  ) {}
}
