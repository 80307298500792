import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SignInService } from '@wp-back-office/app/user-authentication';
import { environment } from '../../../../../../apps/back-office/src/environments/environment';
import { Subject } from 'rxjs';

const LONGITUD_SAL = 16;
const LONGITUD_VECTOR_INICIALIZACION = LONGITUD_SAL;
/**
 *
 * @param buffer
 * @returns
 */
const bufferABase64 = (buffer: any) =>
  window.btoa(String.fromCharCode(...new Uint8Array(buffer)));

/**
 *
 * @param buffer
 * @returns
 */
const base64ABuffer = (buffer: any) =>
  Uint8Array.from(atob(buffer), c => c.charCodeAt(0));

/**
 * Servicio que intercepta las peticiones HTTP.
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  /**
   * Instancia del servicio de Interseptor.
   *
   * @param serviceSignIn - Servicio de autenticacion.
   */
  constructor() {}

  /**
   *
   * @param password
   * @param salt
   * @param iterations
   * @param length
   * @param hash
   * @param algorithm
   * @returns
   */
  public async createKey(
    password: string,
    salt: any,
    iterations: number,
    length: number,
    hash: string,
    algorithm: string = 'AES-CBC'
  ) {
    const encoder = new TextEncoder();
    const importKey = await window?.crypto?.subtle.importKey(
      'raw',
      encoder.encode(password),
      { name: 'PBKDF2' },
      false,
      ['deriveKey']
    );
    return await window?.crypto?.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt: encoder.encode(salt),
        iterations: iterations,
        hash,
      },
      importKey,
      { name: algorithm, length: length },
      false,
      ['encrypt', 'decrypt']
    );
  }

  /**
   *
   * @param contrasena
   * @param textoPlano
   */
  public async encrypt(contrasena: string, textoPlano: string) {
    const encoder = new TextEncoder();
    const sal = window.crypto.getRandomValues(new Uint8Array(16));

    const vectorInicializacion = window.crypto.getRandomValues(
      new Uint8Array(16)
    );
    const bufferTextoPlano = encoder.encode(textoPlano);
    const clave = await this.createKey(contrasena, sal, 100000, 256, 'SHA-256');

    const encrypted = await window.crypto.subtle.encrypt(
      { name: 'AES-CBC', iv: vectorInicializacion },
      clave,
      bufferTextoPlano
    );

    const value = bufferABase64([
      ...sal,
      ...vectorInicializacion,
      ...new Uint8Array(encrypted),
    ]);

    return value;
  }

  /**
   *
   * @param password
   * @param encriptadoEnBase64
   * @returns
   */
  public async decrypt(password: string, encriptadoEnBase64: string) {
    const decoder = new TextDecoder();
    const datosEncriptados = base64ABuffer(encriptadoEnBase64);
    const sal = datosEncriptados.slice(0, LONGITUD_SAL);
    const vectorInicializacion = datosEncriptados.slice(
      0 + LONGITUD_SAL,
      LONGITUD_SAL + LONGITUD_VECTOR_INICIALIZACION
    );
    const clave = await this.createKey(password, sal, 100000, 256, 'SHA-256');
    const datosDesencriptadosComoBuffer = await window.crypto.subtle.decrypt(
      { name: 'AES-CBC', iv: vectorInicializacion },
      clave,
      datosEncriptados.slice(LONGITUD_SAL + LONGITUD_VECTOR_INICIALIZACION)
    );
    return decoder.decode(datosDesencriptadosComoBuffer);
  }

  /**
   *
   * @param name
   * @param item
   */
  public async setItem(name: string, item: string) {
    const encrypted = await this.encrypt(this.getUser(), `${item}`);
    localStorage.setItem(name, encrypted);
  }

  /**
   *
   * @param name
   * @param item
   */
  public async getItem(name: string): Promise<any> {
    if (!localStorage.getItem(name)) {
      return null;
    }

    const encrypted = await this.decrypt(
      this.getUser(),
      localStorage.getItem(name) || ''
    );
    return encrypted;
  }

  /**
   *
   * @param name
   * @param item
   */
  public getItemObs(name: string) {
    const subject = new Subject<string>();
    if (!localStorage.getItem(name)) {
      return null;
    }

    this.decrypt(this.getUser(), localStorage.getItem(name) || '')
      .then(value => subject.next(value))
      .catch(err => subject.next(err))
      .finally(() => {
        subject.complete();
        subject.unsubscribe();
      });

    return subject;
  }

  /**
   *
   * @param name
   */
  public removeItem(name: string) {
    localStorage.removeItem(name);
  }

  /**
   *
   */
  public clear() {
    localStorage.clear();
  }

  /**
   *
   * @returns
   */
  private getUser() {
    const name = 'UPLS=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  /**
   *
   * @param user
   */
  public setUser(user: string) {
    const cookie = document.cookie;
    if (!cookie.includes('UPLS=')) {
      document.cookie = `UPLS=${user}`;
    }
  }
}
