/**
 * Normaliza las cadenas de texto.
 * @param input - Entrada.
 * @returns - Cadena de texto normalizada.
 */
export function normalizeString(input: string): string {
  return input
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .replace(/[^a-z0-9]/g, '');
}
/**
 * Compara dos cadenas de texto.
 * @param a - Cadena A.
 * @param b - Cadena B.
 * @returns - Boolean.
 */
export function compareStrings(a: string, b: string): boolean {
  return normalizeString(a) === normalizeString(b);
}
