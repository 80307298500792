import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueTypeMarket = createAction(
  '[KeyValueTypeMarket] Cargar tipos de mercado',
  props<{
    /**
     * Ruta.
     */
    path: CatalogueType;
  }>()
);

export const KeyValueTypeMarketSuccess = createAction(
  '[KeyValueTypeMarket] Cargar tipo de mercado exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueTypeMarketError = createAction(
  '[KeyValueTypeMarket] Cargar tipos de mercado Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
