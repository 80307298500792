import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { jStringify, jParse } from '@wp-back-office/app/global-information';
import {
  Catalogue,
  CatalogueTasksService,
} from '@wp-back-office/core/commons-backoffice';
import {
  CatalogueType,
  createSelectorInject,
  getCatalogueGeneralSuccess,
} from '@wp-back-office/core/store';
import { StorageService } from '@wp-back-office/core/workflow-sdk';
import { ControlConfig } from '@wp-back-office/shared/dynamic-components';
import { from, map, take, tap } from 'rxjs';

/**
 * Servicio de tareas catalogos.
 */
@Injectable()
export class CataloguesStore {
  /**
   * Crea una instancia de la clase.
   * @param catalogueService - Servicio de catalogos.
   * @param store - NgRx Store.
   */
  constructor(
    private catalogueService: CatalogueTasksService,
    private store: Store
  ) {}

  /**
   * Guarda el catalogo en el store.
   * @param entity - Entidad del catalogo.
   * @param _catalogue - Catalogo
   */
  private saveCatalogue(entity: string, _catalogue: Catalogue[]) {
    this.store.dispatch(
      getCatalogueGeneralSuccess({
        path: entity,
        keyValue: [..._catalogue],
      })
    );
  }

  /**
   * Valida si un catalogo existe en el store antes de consultarlo.
   * @param entity - Entidad del catalogo.
   * @returns Boolean.
   */
  private existCatalogue(entity: string) {
    let _catalogue: any[] = [];
    this.store
      .select(createSelectorInject('general', 'Catalogue'))
      .pipe(
        map(val => {
          const existEntity = Object.keys(val?.catalogues || {}).find(
            key => key === entity
          );

          if (existEntity) {
            _catalogue = val?.catalogues[existEntity];
          }
          return _catalogue;
        }),
        take(1)
      )
      .subscribe();

    return _catalogue?.length > 0;
  }

  /**
   * Obtiene el catalogo guardado en el store.
   * @param entity - Entidad del catalogo.
   * @returns Observable.
   */
  private getCatalogue(entity: string) {
    let _catalogue: any[];
    // SE HACE LA CONSULTA DEL CATALOGO
    return this.store.select(createSelectorInject('general', 'Catalogue')).pipe(
      map(val => {
        const existEntity = Object.keys(val?.catalogues || {}).find(
          key => key === entity
        );

        if (existEntity) {
          _catalogue = val?.catalogues[existEntity];
        }
        return _catalogue;
      }),
      take(1)
    );
  }

  /**
   * Consulta y guarda los catalogos.
   * @param consult - Opciones de la consulta.
   * @returns Observable.
   */
  public getCatalogueService(consult: {
    control?: {
      controlConfig: ControlConfig;
      entityId?: any;
      childEntity?: string;
    };
    entity?: {
      entity: string;
      entityId?: any;
      childEntity?: string;
    };
  }) {
    let entity: string = '';
    let entityId: string | undefined, childEntity: string | undefined;

    if (consult.control) {
      // VALIDA SI EL LLAMADO SE HACE DESDE LOS CONTROLES DINAMICOS
      const contol = consult.control;
      entity = contol.controlConfig?.keyOptions?.entity || '';
      entityId = contol.entityId;
      childEntity = contol.childEntity;
    } else if (consult.entity) {
      // VALIDA SI EL LLAMADO SE HACE DESDE CUALQUIER OTRO LUGAR DE LA APLICACION
      const _entity = consult.entity;
      entity = _entity.entity || '';
      entityId = _entity.entityId;
      childEntity = _entity.childEntity;
    }

    // VALIDA SI EL CATALOGO CONSULTADO TIENE UN CHILD ENTITY Y SI EXISTE EN EL STORE.
    const validation =
      !entityId && !entity.includes('/') && this.existCatalogue(entity);

    const obsv = validation
      ? // SI EL CATALOGO NO TIENE CHILD ENTITY Y ADEMAS EXISTE EN EL STORE SE TRAE DESDE ALLI.
        this.getCatalogue(entity || '')
      : // SI EL CATALOGO NO TIENE CHILD ENTITY, PERO NO EXISTE EN EL STORE SE CONSULTA DIRECTAMENTE DESDE EL ENDPOINT DE CATALOGOS Y LUEGO SE GUARDA.
        // SI EL CATALOGO TIENE CHILD ENTITY, NO SE DEBE GUARDAR EN EL STORE, POR ENDE SE CONSULTA DIRECTAMENTE DESDE EL ENDPOINT DE CATALOGOS.
        this.catalogueService.getCatalogueTasks(
          entity,
          entityId || undefined,
          childEntity || undefined
        );

    // SE OBTIENE LA RESPUESTA DE LA CONSULTA
    return obsv.pipe(
      tap((ev: any[]) => {
        if (!entityId && !entity.includes('/')) {
          // SI EL CATALOGO NO TIENE CHILD ENTITY SE GUARDA EN EL STORE ANTES DE RETORNARLO AL COMPONENTE QUE HIZO EL LLAMADO.
          this.saveCatalogue(entity, [...ev]);
        }
        // SE RETORNA EL CATALOGO AL COMPONENTE QUE HIZO EL LLAMADO
        return ev;
      })
    );
  }
}
