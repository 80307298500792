import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AuditHistory } from 'libs/app/dashboard/src/lib/models/audit-summary.model';
import { ErrorHttp } from '../../../../models/errors.models';
import { TaskExecution } from 'libs/core/workflow-sdk/src/lib/models/task-execution.model';

export const auditSummaryActions = createActionGroup({
  source: 'auditSummaryActions',
  events: {
    'get detail process audit reset': emptyProps(),
    'get detail process audit': props<{
      /**
       * Nombre del proceso.
       */
      nameProcess: string;
      /**
       * Id de la ejecución.
       */
      executionId: string;
    }>(),
    'get detail process audit success': props<{
      /**
       * Objeto que retorna en caso exitoso.
       */
      response: any;
    }>(),
    'get detail process audit error': props<{
      /**
       * Objeto que retorna en caso de error.
       */
      error: ErrorHttp;
    }>(),
    'get process data': props<{
      /**
       * Informacion del proceso.
       */
      processData: any;
    }>(),
    'get process data success': props<{
      /**
       * Informacion de la tarea.
       */
      response: any;
    }>(),
    'get process data error': emptyProps(),

    'get audit history reset': emptyProps(),
    'get audit history': props<{
      /**
       * Id de la ejecución.
       */
      executionId: string;
    }>(),
    'get audit history success': props<{
      /**
       * Objeto que retorna en caso exitoso.
       */
      object: AuditHistory;
    }>(),
    'get audit history error': props<{
      /**
       * Objeto que retorna en caso de error.
       */
      error: ErrorHttp;
    }>(),
    'get audit process execution reset': emptyProps(),
    'get audit process execution': props<{
      /**
       * Id de la ejecución.
       */
      executionId: string;
    }>(),
    'get audit process execution success': props<{
      /**
       * Objeto que retorna en caso exitoso.
       */
      response: TaskExecution;
    }>(),
    'get audit process execution error': props<{
      /**
       * Objeto que retorna en caso de error.
       */
      error: ErrorHttp;
    }>(),
  },
});
