import { Injectable } from '@angular/core';
import { ThemePalette } from '../models/palette.model';
/**
 *INTERFAZ DE COLORES RGB.
 */
export interface RGB {
  /**
   *BLUE.
   */
  b: number;
  /**
   *GREEN.
   */
  g: number;
  /**
   *RED.
   */
  r: number;
}

/**
 * Servicio de temas.
 */
@Injectable()
export class PaletteGeneratorService {
  /**
   * Variables de temas.
   */
  public themeCssVariables: string[] = [
    'primary',
    'secundary',
    'accent',
    'warn',
    '--primary-contrast-900',
    '--secundary-contrast-900',
    '--accent-contrast-900',
    '--warn-contrast-900',
  ];

  /**
   * Constructor.
   */
  constructor() {}

  /**
   * Convertir valor (r)(g)(b) a hex.
   * @param c - Color.
   * @returns String.
   */
  private hex(c: any) {
    const s = '0123456789abcdef';
    let i = parseInt(c);
    if (i == 0 || isNaN(c)) return '00';
    i = Math.round(Math.min(Math.max(0, i), 255));
    return s.charAt((i - (i % 16)) / 16) + s.charAt(i % 16);
  }

  /**
   * Convierte rgb a hex.
   * @param rgb - Array.
   * @returns String.
   */
  private convertToHex(rgb: any[]) {
    return this.hex(rgb[0]) + this.hex(rgb[1]) + this.hex(rgb[2]);
  }

  /**
   * Elimina el # del hex.
   * @param c - Color.
   * @returns Any.
   */
  private trim(c: any) {
    return c.charAt(0) == '#' ? c.substring(1, 7) : c;
  }

  /**
   * Convierte el color hex a rgb.
   * @param hex - Color hexadecimal.
   * @returns Array.
   */
  private convertToRGB(hex: string) {
    const color = [];
    color[0] = parseInt(this.trim(hex).substring(0, 2), 16);
    color[1] = parseInt(this.trim(hex).substring(2, 4), 16);
    color[2] = parseInt(this.trim(hex).substring(4, 6), 16);
    return color;
  }

  /**
   * Generar array de colores.
   * @param colorStart - String.
   * @param prefix - String.
   * @param colorCount - Number.
   * @returns Array.
   */
  public generatePalette(
    colorStart: string,
    prefix: string,
    colorCount: number
  ) {
    const start = this.convertToRGB(colorStart);

    const colorEnd = this.colorLuminance(colorStart, 9);

    const end = this.convertToRGB(colorEnd);

    const len = colorCount;

    const palette = [];

    let alpha = 0.0;

    for (let i = 0; i < len; i++) {
      const c = [];
      alpha += 1.0 / len;

      c[0] = start[0] * alpha + (1 - alpha) * end[0];
      c[1] = start[1] * alpha + (1 - alpha) * end[1];
      c[2] = start[2] * alpha + (1 - alpha) * end[2];

      const color = this.convertToHex(c);
      const contrast = this.contrast(color);
      palette.push({
        color: '#' + color,
        contrast: contrast,
      });
    }

    const palette_opacity = this.generateOpacityPalette(colorStart);

    this.setCssVars(palette, prefix, false);
    this.setCssVars(palette, prefix, true);
    this.setCssVars(palette_opacity, prefix, false, true);
    return palette;
  }

  /**
   * Envia la paleta de colores a nuestra hoja de estilos.
   * @param _palette - Array.
   * @param prefix - String.
   * @param contrast - Boolean.
   * @param opacity - Boolean.
   */
  public setCssVars(
    _palette: any[],
    prefix: string,
    contrast: boolean,
    opacity?: boolean
  ): void {
    const root: any = document.querySelector(':root');
    let darkness = 0;
    _palette.forEach((_palette, index) => {
      if (index == 0) {
        darkness = 50;
      } else if (index == 1) {
        darkness = 100;
      } else {
        darkness += 100;
      }
      if (!opacity) {
        root?.style.setProperty(
          `--${prefix}-${contrast ? 'contrast-' + darkness : darkness}`,
          contrast ? _palette.contrast : _palette.color
        );
      } else {
        root?.style.setProperty(
          `--${prefix}-opacity-${darkness}`,
          contrast ? _palette.contrast : _palette.color
        );
      }
    });
  }

  /**
   * Valida si el color blanco o negro contrasta con el color que se envia.
   * @param colorHex - String.
   * @param threshold - Number.
   * @returns String.
   */
  public contrast(
    colorHex: string | undefined,
    threshold: number = 128
  ): string {
    if (colorHex === undefined) {
      return '#000';
    }
    const rgb: RGB | undefined = this.hexToRgb(colorHex);
    if (rgb === undefined) {
      return '#000';
    }
    return this.rgbToYIQ(rgb) >= threshold ? '#000' : '#fff';
  }

  /**
   * Valida el contraste del color con el modelo YIQ.
   * @param rgb - Color en formato RGB.
   * @returns Number.
   */
  public rgbToYIQ(rgb: RGB): number {
    return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
  }

  /**
   * Convirete un color rgb a hex.
   * @param hex - Color hexadecimal.
   * @returns Any.
   */
  public hexToRgb(hex: string): RGB | undefined {
    if (!hex || hex === undefined || hex === '') {
      return undefined;
    }
    const result: RegExpExecArray | null =
      /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : undefined;
  }

  /**
   * Genera una paleta de colores con opacidad.
   * @param color - String.
   * @returns Array.
   */
  public generateOpacityPalette(color: string) {
    const colorRgb = this.convertToRGB(color);
    const palette = [];
    let max = 10;
    for (let i = 0; i < 10; i++) {
      const res =
        `rgba(${colorRgb[0]}, ${colorRgb[1]}, ${colorRgb[2]}, ` +
        `${i == 0 ? '' : '0.'}` +
        `${i == 9 ? '04' : i == 8 ? '09' : max--})`;
      const contrast = this.contrast(res);
      palette.push({
        color: res,
        contrast: contrast,
      });
    }
    return palette;
  }

  /**
   * Retorna el mismo color enviado con mas luminosidad.
   * @param hex - Color hexadecimal.
   * @param lum - Luminosidad.
   * @returns String.
   */
  public colorLuminance(hex: string, lum: number) {
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;
    let color = '#',
      c,
      i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      color += ('00' + c).substr(c.length);
    }
    return color;
  }

  /**
   * Aplicar tema.
   * @param themeInput - ThemePalette | undefined.
   */
  public applyTheme(themeInput: ThemePalette | undefined) {
    const themeDefault: ThemePalette = {
      descritpion: 'DEFAULT',
      themeColors: {
        primary: '#000000',
        accent: '#F45000',
        secundary: '#05C3DD',
        warn: '#c62828',
        primaryContrast: false,
        accentContrast: '#fff',
        secundaryContrast: false,
        warnContrast: false,
      },
    };
    const root: HTMLHtmlElement | null = document.querySelector(':root');
    const palette = themeInput ? themeInput : themeDefault;
    this.generatePalette(
      palette.themeColors.primary,
      this.themeCssVariables[0],
      10
    );
    this.generatePalette(
      palette.themeColors.secundary,
      this.themeCssVariables[1],
      10
    );
    this.generatePalette(
      palette.themeColors.accent,
      this.themeCssVariables[2],
      10
    );
    this.generatePalette(
      palette.themeColors.warn,
      this.themeCssVariables[3],
      10
    );
    if (root) {
      if (palette.themeColors.primaryContrast) {
        root.style.setProperty(
          this.themeCssVariables[4],
          palette.themeColors.primaryContrast.toString()
        );
      }
      if (palette.themeColors.secundaryContrast) {
        root.style.setProperty(
          this.themeCssVariables[5],
          palette.themeColors.secundaryContrast.toString()
        );
      }
      if (palette.themeColors.accentContrast) {
        root.style.setProperty(
          this.themeCssVariables[6],
          palette.themeColors.accentContrast.toString()
        );
      }
      if (palette.themeColors.warnContrast) {
        root.style.setProperty(
          this.themeCssVariables[7],
          palette.themeColors.warnContrast.toString()
        );
      }
    }
  }
}
