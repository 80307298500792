import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

/**
 * Accion para cargar los tipos de aprobacion de documentos.
 */
export const KeyValueDocumentsApprovalStatus = createAction(
  '[KeyValueDocumentsApprovalStatus] Cargar accion para los tipos de aprobacion de documentos',
  props<{
    /**
     * Ruta.
     */
    path: CatalogueType;
  }>()
);

/**
 * Accion que se ejecuta cuando la peticion fue ejecutada correctamente.
 */
export const KeyValueDocumentsApprovalStatusSuccess = createAction(
  '[KeyValueDocumentsApprovalStatus] Crear accion para los tipos de aprobacion de documentos Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

/**
 * Accion que se ejecuta en caso de un error en la peticion.
 */
export const KeyValueDocumentsApprovalStatusError = createAction(
  '[KeyValueDocumentsApprovalStatus] Cargar accion para los tipos de aprobacion de documentos Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
