import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';
import { delay, map, Observable } from 'rxjs';
import { ServiceMapper } from '../mappers/services.mapper';
/**
 * Servicio de control de acceso.
 */
@Injectable()
export class ProductsRaterService {
  /**
   * Mapeador.
   */
  private mapper = new ServiceMapper();
  /**
   * Crea una instancia de WorkflowService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}
  /**
   * Obtiene la lista de arboles de decision.
   * @param pageSize - Tamaño de la pagina.
   * @param paginationToken - Token para la paginacion.
   * @returns - Observable.
   */
  public getDecisionTree(
    pageSize?: number | string,
    paginationToken?: number,
    category?: string
  ): Observable<any> {
    let params = {};
    let paginationCounter = 0;
    if (pageSize) {
      params = { ...params, pageSize: pageSize };
    }
    if (paginationToken !== undefined) {
      paginationCounter = paginationToken + 1;
      params = { ...params, page: paginationCounter };
    }
    this.enviroment.endpoints.urlPAS;
    return this.http
      .get<any>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/decision-trees/${category}`,
        {
          params: params,
        }
      )
      .pipe(delay(1000));
  }

  /**
   * Obtiene la lista de versiones del arbol de decision.
   * @param code - Codigo del arbol de decision.
   * @param nameVersion - Nombre version del arbol de decision.
   * @param numerationVersion - Numero de version del arbol de decision.
   * @param pageSize - Tamaño de la pagina.
   * @param paginationToken - Token para la paginacion.
   * @returns - Observable.
   */
  public getAllDecisionTreeVersion(
    code: string,
    category: string,
    nameVersion?: string,
    numerationVersion?: string,
    pageSize?: number | string,
    paginationToken?: number
  ): Observable<any> {
    let params = {};
    let paginationCounter = 0;

    if (nameVersion) {
      params = { ...params, version_name: nameVersion };
    }
    if (numerationVersion) {
      params = { ...params, version_numeration: numerationVersion };
    }
    if (pageSize) {
      params = { ...params, pageSize: pageSize };
    }
    if (paginationToken !== undefined) {
      paginationCounter = paginationToken + 1;
      params = { ...params, page: paginationCounter };
    }
    this.enviroment.endpoints.urlPAS;
    return this.http
      .get<any>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/all-versions-by-decision-tree/${category}/${code}`,
        {
          params: params,
        }
      )
      .pipe(delay(1000));
  }

  /**
   * Obtiene la informacion del arbol de decision.
   * @param code - Codigo del arbol.
   * @param category - Categoria.
   * @returns Observable.
   */
  public getDecisionTreeVersion(
    code: string,
    category: string
  ): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/details-currently-active-version/${category}/${code}?pageSize=ALL`
    );
  }

  /**
   * Obtiene la informacion del arbol de decision.
   * @param code - Codigo del arbol.
   * @returns Observable.
   */
  public getDecisionTreeVersionDetail(
    code: string,
    pageSize?: string | number,
    page?: number
  ): Observable<any> {
    let params = {};
    if (pageSize) {
      params = { ...params, pageSize: pageSize };
    }

    if (page) {
      params = { ...params, page: page };
    }
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/details-version-decision-tree/${code}`,
      {
        params: params,
      }
    );
  }

  /**
   * Obtiene la informacion del tipo de precio.
   * @param code - code del tipo de precio.
   * @returns Observable.
   */
  public getTypePriceDetails(
    code: string,
    code_fasecolda?: string,
    code_model?: string,
    pageSize?: number | string,
    page?: number
  ): Observable<any> {
    let params = {};
    if (code_fasecolda) {
      params = { ...params, code_fasecolda };
    }
    if (code_model) {
      params = { ...params, code_model };
    }
    if (pageSize) {
      params = { ...params, pageSize };
    }
    if (page) {
      params = { ...params, page };
    }
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/${code}/vehicle`,
      {
        params: params,
      }
    );
  }

  /**
   * Obtiene la informacion del tipo de precio.
   * @param code - code del tipo de precio.
   * @returns Observable.
   */
  public getTypeVhPriceDetails(
    code: string,
    pageSize?: number | string,
    code_version?: string,
    code_model?: string,
    code_price?: string
  ): Observable<any> {
    let params = {};
    if (pageSize) {
      params = { ...params, pageSize: pageSize };
    }

    if (code_version) {
      params = { ...params, code_version: code_version };
    }

    if (code_model) {
      params = { ...params, code_model: code_model };
    }

    if (code_price) {
      params = { ...params, code_price: code_price };
    }
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/${code}/vehicle`,
      {
        params: params,
      }
    );
  }

  /**
   * Obtiene la informacion del tipo de precio.
   * @param code - code del tipo de precio.
   * @returns Observable.
   */
  public getTypePrice(code: string): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/type_price/${code}`
    );
  }

  /**
   * Obtiene la informacion del tipo de precio.
   * @param code - Code del tipo de precio.
   * @param data - Detalles del tipo de precio.
   * @returns Observable.
   */
  public updateTypePrice(code: string, data: any): Observable<any> {
    return this.http.put(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/type_price/${code}`,
      data
    );
  }

  /**
   * Elimina un vehiculo del tipo de precio.
   * @param code - Code del tipo de precio.
   * @returns Observable.
   */
  public deleteTypePriceVehicle(code: string): Observable<any> {
    return this.http.delete(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/price/${code}`
    );
  }

  /**
   * Obtiene la informacion de la siguiente version arbol de decision.
   * @param code - code del arbol.
   * @returns Observable.
   */
  public getNextDecisionTreeVersion(
    code: string,
    category: string
  ): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/version-number-for-new-rule/${category}/${code}`
    );
  }

  /**
   * Obtiene la informacion de las variables de la version arbol de decision.
   * @param code - Code del arbol.
   * @param category - Categoria.
   * @returns Observable.
   */
  public getVariablesDecisionTreeVersion(
    code: string,
    category: string
  ): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/list-variables-decision-tree/${category}/${code}`
    );
  }

  /**
   * Obtiene la informacion de las variables de la version arbol de decision.
   * @param code - Code del arbol.
   * @returns Observable.
   */
  public getChangeDecisionTreeVersion(code: string): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/version-change-history/TARIFICATION/${code}`
    );
  }

  /**
   * Obtiene la informacion de las formulas de la version arbol de decision.
   * @param nameVariable - Nombre de la variable de la operacion.
   * @param nameFunction - Nombre de la funcion de la operacion.
   * @param pageSize - Nombre de la funcion de la operacion.
   * @returns Observable.
   */
  public getOperationDecisionTreeVersion(
    nameCategory?: string,
    nameFunction?: string,
    pageSize?: number | string
  ): Observable<any> {
    let params = {};
    params = { ...params, pageSize: pageSize };
    if (nameCategory) {
      params = { ...params, nameCategory: nameCategory };
    }
    if (nameFunction) {
      params = { ...params, nameFunction: nameFunction };
    }
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/operations-availables-hyperformula`,
      {
        params: params,
      }
    );
  }

  /**
   * Obtiene la informacion de las formulas de la version arbol de decision.
   * @param userCode - Nombre de usuario.
   * @param category - Categoria .
   * @param pageSize - Tamaño de paginado.
   * @param code - Codigo del arbol.
   * @returns Observable.
   */
  public getLogDecisionTreeVersion(
    code?: string,
    category?: string,
    userCode?: string,
    pageSize?: number | string
  ): Observable<any> {
    let params = {};

    params = { ...params, pageSize: pageSize };
    if (userCode) {
      params = { ...params, userEmail: userCode };
    }
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/version-change-history/${category}/${code}`,
      {
        params: params,
      }
    );
  }

  /**
   * Crea un modelo de vehiculo nuevo.
   * @param functionHyperFormula - Datos que recibe del formulario de modelo de vehiculo.
   * @returns Observable.
   */
  public validate(functionHyperFormula: any): Observable<any> {
    let params = {};
    params = { ...params, functionHyperFormula: functionHyperFormula };
    return this.http.post(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/validate-function-hyperFormula`,
      {
        functionHyperFormula: functionHyperFormula,
      }
    );
  }

  /**
   * Crea un modelo de arbol nuevo.
   * @param code - Codigo del arbol.
   * @param data - Datos del objeto del arbol a duplicar.
   * @returns Observable.
   */
  public duplicateTree(
    code: string,
    category: string,
    data: any,
    code_version?: any
  ): Observable<any> {
    let params = {};

    if (code_version) {
      params = { ...params, code_version: code_version };
    }

    return this.http.post(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/create-new-blank-version/${category}/${code}?typeOfCreation=existing_version`,
      data,
      {
        params: params,
      }
    );
  }

  /**
   * Crea un modelo de arbol nuevo.
   * @param code - Codigo del arbol.
   * @param data - Datos del objeto del arbol a duplicar.
   * @returns Observable.
   */
  public blankTree(
    code: string,
    category: string,
    data: any,
    pathDestXlsx?: any
  ): Observable<any> {
    const file = data;
    if (pathDestXlsx) {
      const formData = new FormData();
      formData.append(
        'file',
        new Blob([data], { type: 'application/json' }),
        'data.json'
      );
      formData.append('pathDestXlsx', pathDestXlsx);

      return this.http.post(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/create-new-blank-version/${category}/${code}?typeOfCreation=blank_version`,
        formData
      );
    } else {
      return this.http.post(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/create-new-blank-version/${category}/${code}?typeOfCreation=blank_version`,
        file
      );
    }
  }

  /**
   * Crea un modelo de arbol nuevo.
   * @param code - Codigo del arbol.
   * @param data - Datos del objeto del arbol a simular.
   * @returns Observable.
   */
  public simulateTree(
    code: string,
    category: string,
    data: any,
    code_version?: string
  ): Observable<any> {
    if (code_version) {
      let params = {};

      if (code_version) {
        params = { ...params, code_version: code_version };
      }
      return this.http.post(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/simulate-decision-tree-version/${category}/${code}`,
        data,
        {
          params: params,
        }
      );
    } else {
      return this.http.post(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/simulate-decision-tree-version/${category}/${code}`,
        data
      );
    }
  }

  /**
   * Crea un modelo de arbol nuevo.
   * @param code - Codigo del arbol.
   * @param file - Datos del objeto del arbol a importar.
   * @returns Observable.
   */
  public excelTree(
    code: string,
    category: string,
    file: any,
    listVariables: any
  ): Observable<any> {
    const headers = {
      tenantId: this.enviroment.common.tenantId,
      category: category,
      code: code,
      listVariables: listVariables,
    };
    return this.http.post(
      `${this.enviroment.endpoints.urlPAS}/files/tarification-xlsx`,
      file,
      {
        headers: headers,
      }
    );
  }

  /**
   * Crea un modelo de arbol nuevo.
   * @param file - Datos del objeto del arbol a importar.
   * @returns Observable.
   */
  public excelTypePrice(file: any): Observable<any> {
    const params = {
      tenantId: '1111111',
      module: 'type-prices-list',
      'file-name': 'tipos_de_precios.zip',
    };
    const headers = {
      'Content-Type': 'application/zip',
    };
    return this.http.put(
      `${this.enviroment.endpoints.urlPAS}/uploads/${this.enviroment.common.tenantId}/type-prices-list/${file.name}`,
      file,
      { headers }
    );
  }

  /**
   * Crea un modelo de arbol nuevo.
   * @param code - Codigo del arbol.
   * @param data - Datos del objeto del arbol a duplicar.
   * @returns Observable.
   */
  public GetTtypePriceFileUpdate(): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/type_price/logs-status/bulkload`
    );
  }

  /**
   * Actualiza versiones de arbol.
   * @returns Observable.
   * @param category - Categoria.
   */
  public treeUpdateRefresh(category: string): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/refresh-effective-dates-in-version-decision-trees/${category}`
    );
  }

  /**
   * Obtiene la informacion de las formulas de la version arbol de decision.
   * @param userCode - Nombre de usuario.
   * @param category - Categoria .
   * @param pageSize - Tamaño de paginado.
   * @param code - Codigo del arbol.
   * @returns Observable.
   */
  public getVehicleVersion(
    code_version?: string,
    pageSize?: number | string
  ): Observable<any> {
    let params = {};

    if (code_version) {
      params = { ...params, code_version: code_version };
    }
    if (pageSize) {
      params = { ...params, pageSize: pageSize };
    }
    return this.http.get(
      `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/vehicle`,
      {
        params: params,
      }
    );
  }
}
