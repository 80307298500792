import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';
export const KeyValueDepartments = createAction(
  '[KeyValueDepartments] Cargar Catalogos por id',
  props<{
    /**
     * Ruta.
     */
    path: CatalogueType;
    /**
     * Ruta 2.
     */
    path2: CatalogueType;
    /**
     * Codigo.
     */
    code: string;
  }>()
);

export const KeyValueDepartmentsSuccess = createAction(
  '[KeyValueDepartments] Crear Catalogos Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueDepartmentsError = createAction(
  '[KeyValueDepartments] Cargar Catalogos Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
