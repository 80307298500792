<h2 mat-DynamicDialogMessageService-title>{{ data.title || 'Agregar tab' }}</h2>
<mat-divider></mat-divider>
<mat-dialog-content>
  <mat-form-field
    appearance="outline"
    class="full-width"
    *ngIf="data.options && !data.catalogueList">
    <mat-label> Seleccione </mat-label>
    <mat-select matNativeControl [formControl]="selectControl">
      <mat-option
        *ngFor="let opt of options"
        [value]="opt"
        [matTooltip]="opt?.formDescription || ''">
        {{ opt.description }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <wp-back-office-dynamic-form
    *ngIf="data.catalogueList"
    #formID
    [controls]="form">
  </wp-back-office-dynamic-form>
</mat-dialog-content>

<mat-dialog-actions class="justify-content-end gap-2 flex-nowrap" *ngIf="data.catalogueList">
  <wp-back-office-dynamic-button
    [buttonSelector]="'mat-flat-button'"
    mat-dialog-close=""
    [text]="'Cancelar'"
    [color]="'primary'">
  </wp-back-office-dynamic-button>
  <wp-back-office-dynamic-button
    [buttonSelector]="'mat-flat-button'"
    [disabled]="formComponent?.formGroupGeneric?.invalid || false"
    (ButtonClick)="onChangeForm(formComponent?.formGroupGeneric)"
    [text]="'Aceptar'"
    [color]="'accent'">
  </wp-back-office-dynamic-button>
</mat-dialog-actions>

<mat-dialog-actions class="justify-content-end gap-2 flex-nowrap" *ngIf="!data.catalogueList">
  <wp-back-office-dynamic-button
    [buttonSelector]="'mat-flat-button'"
    mat-dialog-close=""
    [text]="'Cancelar'"
    [color]="'primary'">
  </wp-back-office-dynamic-button>
  <wp-back-office-dynamic-button
    [buttonSelector]="'mat-flat-button'"
    [disabled]="selectControl.invalid || false"
    (ButtonClick)="onChangeForm(selectControl)"
    [text]="'Aceptar'"
    [color]="'accent'">
  </wp-back-office-dynamic-button>
</mat-dialog-actions>
