import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueCustomerClassification = createAction(
  '[KeyValueCustomerClassification] Cargar clasificacion del cliente por id',
  props<{
    /**
     * Identificador.
     */
    path: CatalogueType;
  }>()
);

export const KeyValueCustomerClassificationSuccess = createAction(
  '[KeyValueCustomerClassification] Crear clasificacion del cliente Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueCustomerClassificationError = createAction(
  '[KeyValueCustomerClassification] Cargar clasificacion del cliente Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
