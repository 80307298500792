export const constantsLogin = {
    applicationLogo : '../../../../assets/logo/logoSophos.svg',
    applicationLogoMobile : '../../../../assets/logo/Logo_Bank4us_Blanco_Azul.png',
    backgroundLogin : '../../../../assets/img/backgroundLogin.png',
    backgroundLogin1 : '../../../../assets/img/rectanguloLogin.svg',
    iconUser : '../../../../assets/icons/icon-user.svg',
    iconPassword : '../../../../assets/icons/icon-password.svg',
    iconShowEyes:'../../../../assets/icons/icon-eyeshow.svg',
    iconHideEyes:'../../../../assets/icons/icon-eyeshide.svg',
    textCopyright : '2020 Sophos Solutions S.A.S All Rights Reserved'
}

export const constantsGeneral = {
    gitLoading : '../../../../../assets/gif/loading.gif',
    titleApplication: 'BackOffice',
}