import { Component, Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

/**
 * Componente base de los snack bars dinamicos.
 */
@Component({
  selector: 'wp-back-office-snackbar',
  templateUrl: './snackbar-template.html',
})
export class DynamicSnackBarComponent {
  /**
   * Crra una instancia de DynamicSnackBarComponent.
   * @param SnackBarRef - Referencia del snacknar actual.
   * @param data - Configuración del SnackBar.
   */
  constructor(
    public SnackBarRef: MatSnackBarRef<DynamicSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  /**
   * Cerrar el SnackBar con el botón.
   */
  public close() {
    this.SnackBarRef.dismissWithAction();
  }
}
