import { createAction, props } from '@ngrx/store';
import { AuditDetail } from 'libs/app/dashboard/src/lib/models/audit-detail.model';
import { ErrorHttp } from '../../../../models/errors.models';

export const auditDetailReset = createAction(
  '[auditDetail] Obtener detalle procesos a auditar Reset'
);

export const auditDetail = createAction(
  '[auditDetail] Obtener detalle procesos a auditar',
  props<{
    /**
     * Nombre del proceso.
     */
    nameProcess: string;
    /**
     * Id de la ejecución.
     */
    executionId: string;
  }>()
);

export const auditDetailSuccess = createAction(
  '[auditDetail] Obtener detalle procesos a auditar Exitoso',
  props<{
    /**
     * Objeto que retorna en caso exitoso.
     */
    object: AuditDetail;
  }>()
);

export const auditDetailError = createAction(
  '[auditDetail] Obtener detalle procesos a auditar Error',
  props<{
    /**
     * Objeto que retorna en caso de error.
     */
    error: ErrorHttp;
  }>()
);
