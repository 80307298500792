import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueUnitAmount = createAction(
  '[KeyValueUnitAmount] Cargar unidad monto por id',
  props<{
    /**
     * Identificador.
     */
    id: CatalogueType;
  }>()
);

export const KeyValueUnitAmountSuccess = createAction(
  '[KeyValueUnitAmount] Crear unidad monto Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueUnitAmountError = createAction(
  '[KeyValueUnitAmount] Cargar unidad monto Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
