import { createAction, props } from '@ngrx/store';
import { ContactInfoCheck } from '../../../models';

export const CheckContactInfo = createAction(
  '[CheckContactInfo] Guardar informacion basica del cliente',
  props<{
    /**
     * Informacion de contacto a validar.
     */
    contactInfoCheck: ContactInfoCheck;
  }>()
);

export const CheckContactInfoReset = createAction(
  '[CheckContactInfo] Guardar informacion basica del cliente',
  props<{
    /**
     * Reiniciar estado de informacion de contacto a validar.
     */
    contactInfoCheck: ContactInfoCheck;
  }>()
);

export const CheckContactInfoSuccess = createAction(
  '[CheckContactInfo] Iniciar el proceso exitoso',
  props<{
    /**
     * Respuesta correcta.
     */
    object: any;
  }>()
);

export const CheckContactInfoError = createAction(
  '[CheckContactInfo] Iniciar el proceso error',
  props<{
    /**
     * Error en la respuesta.
     */
    payload: any;
  }>()
);
