import { Directive, ElementRef, HostListener } from '@angular/core';

/**
 * Directiva solo numeros.
 */
@Directive({
  selector: '[wpBackOfficeSoloNumerosDecimales]',
})
export class OnlyNumbersDecimalsDirective {
  /**
   * Crea una instancia de la clase.
   * @param el - Elemento del DOM.
   */
  constructor(private el: ElementRef) {}

  /**
   * Host listener.
   * @param event - Evento.
   */
  @HostListener('input', ['$event'])
  public onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    const decimals = value.replace(/[^\d.]/g, '');
    // Remover caracteres no numéricos y no decimales
    const sanitizedValue = decimals && typeof Number(decimals) === 'number' ? decimals : '0';
    input.value = sanitizedValue;
  }
}
