import { throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

const mapErrors: any = {
  0: 'Ocurrio un error, asegure su conexión a internet.',
  400: 'En este momento no podemos atender tu solicitud, por favor intenta más tarde.',
  401: 'El usuario no esta autorizado o el token expiró.',
  500: 'En este momento no podemos procesar tu solicitud, inténtalo de nuevo más tarde.',
};

/**
 * Constantes de errores captura.
 * @param err - Error.
 * @returns Returns an observable that will error with the specified error immediately upon subscription.
 */
export const errorHandler = (err: HttpErrorResponse) => {
  let error: string = mapErrors[err.status];

  if (err.status === 404 || err.status === 422) error = err.error.message;

  return throwError({
    status: err.status,
    error: {
      internalCode: err.error.internal_code || 500,
      message: error,
    },
  });
};
