import { SegmentationFormState } from 'libs/app/financial-plans/src/lib/store/reducers';
import {
  OptionValueOutput,
  SegmentationOptionOutput,
  SegmentationOutput,
} from '../models/segmentation-output.model';

export class SegmentationMapper {
  /**
   * Mapea desde el formato AWS a nuestro modelo.
   * @param param - Parametros desde el backend.
   * @returns - Objeto de nuestro modelo.
   */
  public mapTo(param: SegmentationFormState): SegmentationOutput {
    return {
      name: param.name || '',
      description: param.description || '',
      options: [
        {
          code_type_options_segmentation: 33,
          values: [
            { value: param.segment_type || '' }
          ]
        },
        {
          code_type_options_segmentation: 28,
          values: Object.values(param.vehicle.entities).map(entry => {
            if (entry)
              return {
                value: entry.code.toString(),
              } as OptionValueOutput;
            return {} as OptionValueOutput;
          }),
        },
        {
          code_type_options_segmentation: 9,
          values: Object.values(param.room.entities).map(entry => {
            if (entry)
              return {
                value: entry.code_room.toString(),
              } as OptionValueOutput;
            return {} as OptionValueOutput;
          }),
        },
        ...Object.values(param.options.entities).map(entry => {
          if (entry)
            return {
              code_type_options_segmentation: entry.code,
              values: Array.isArray(entry.value)
                ? entry.value.map(item => ({
                  value: item.code,
                }))
                : [
                  {
                    value:
                      typeof entry.value === 'object'
                        ? entry.value.code.toString()
                        : entry.value.toString(),
                  },
                ],
            } as SegmentationOptionOutput;
          return {} as SegmentationOptionOutput;
        }),
      ],
    };
  }
}
