import { Injectable } from '@angular/core';
import { SignInInformation } from '../models/signin-information.model';

import { CryptoService } from './crypto.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CognitoUser } from '../models/cognito.models';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';
import { SessionRefresh } from '../models/refresh-session.model';
import { DialogConfig } from '@wp-back-office/shared/dynamic-components';
import { StorageService } from '@wp-back-office/core/workflow-sdk';

/**
 * Servicio de autenticación.
 */
@Injectable()
export class SignInService {
  /**
   * Dialog de cerrar sesion.
   */
  public dialogCloseSessionConfig: DialogConfig = {
    type: 'question',
    header: {
      text: '¿Sigues ahí?',
    },
    body: {
      html: `<span style="min-width: 250px" id="countDown">La sesión expirará en<br/> <h2 class="m-0">30</h2></span>`,
      textAlign: 'text-center',
      script: `
  function countdown(milliseconds) {
    let seconds = milliseconds / 1000;
    const interval = setInterval(function () {
      const element = document.getElementById('countDown');
      seconds -= 1;
      if (element && seconds >= 0) {
        element.innerHTML = 'La sesión expirará en<br/> <h2 class="m-0">' + seconds.toFixed(0) + '</h2>';
      } else {
        clearInterval(interval);
      }
      if (seconds < 0) {
        clearInterval(interval);
      }
    }, 1000);
  };
  countdown(30000);`,
    },
    actions: [
      {
        actionValue: true,
        text: 'si',
        principal: true,
      },
    ],
  };
  /**
   * Crea una instancia de SignInService.
   * @param http - Servicio HTTP.
   * @param cryptoService - Servicio para encriptar data.
   * @param enviroment - Variables de entorno.
   * @param storage - Storage.
   */
  public constructor(
    private http: HttpClient,
    private cryptoService: CryptoService,
    private enviroment: Enviroment,
    private storage: StorageService
  ) {}
  /**
   * Enviar la autenticacion a aws.
   *
   * @param signInInformation - Modelo de la informacion basica para la autenticación.
   * @returns Promesa de la autenticación.
   */
  public signInAmplify(signInInformation: SignInInformation): Observable<any> {
    const {
      tokenCaptcha: captcha,
      username,
      password,
      email,
    } = signInInformation;

    const message = this.cryptoService.encrypt({ username, password, email });
    const urlReq = `${this.enviroment.endpoints.urlAuth}/${this.enviroment.common.tenantId}/originauth/authuser`;

    const bodyReq = {
      typeAuth: 'auth',
      captcha,
      tokenUser: message,
    };

    return this.http.post(urlReq, bodyReq);
  }

  // eslint-disable-next-line jsdoc/require-param
  /**
   * Funcion para actualizar las credenciales de sesion en el Local Storage.
   */
  public updateSessionStorage({ signInUserSession, attributes }: CognitoUser) {
    const { idToken, accessToken, refreshToken } = signInUserSession;

    localStorage.setItem(this.enviroment.SESSION_KEY.idToken, idToken);
    localStorage.setItem(this.enviroment.SESSION_KEY.accessToken, accessToken);
    localStorage.setItem(
      this.enviroment.SESSION_KEY.refreshToken,
      refreshToken
    );

    if (attributes)
      this.storage.setItem('attributes', JSON.stringify(attributes));
  }

  /**
   * Elimina la informacion de sesion del Local Storage.
   */
  public deleteSession(): void {
    localStorage.removeItem(this.enviroment.SESSION_KEY.idToken);
    localStorage.removeItem(this.enviroment.SESSION_KEY.accessToken);
    localStorage.removeItem(this.enviroment.SESSION_KEY.refreshToken);
  }

  /**
   * Carga la informacion de la sesion actual.
   * @returns Retorna una promesa de la sesion actual.
   */
  public loadSession(): any {
    const idToken = localStorage.getItem(this.enviroment.SESSION_KEY.idToken);
    const accessToken = localStorage.getItem(
      this.enviroment.SESSION_KEY.accessToken
    );
    const refreshToken = localStorage.getItem(
      this.enviroment.SESSION_KEY.refreshToken
    );

    return { idToken, accessToken, refreshToken };
  }

  // eslint-disable-next-line jsdoc/require-param
  /**
   * Obtiene la informacion de la sesion actual.
   * @returns Retorna una promesa de la sesion actual.
   */
  public refreshSession({
    tokenRefresh,
    username,
  }: SessionRefresh): Observable<any> {
    const message = this.cryptoService.encrypt({
      username,
    });
    const urlReq = `${this.enviroment.endpoints.urlAuth}/${this.enviroment.common.tenantId}/originauth/authuser`;

    const bodyReq = {
      typeAuth: 'authrefresh',
      tokenUser: message,
      refreshToken: tokenRefresh,
    };

    return this.http.post(urlReq, bodyReq);
  }

  /**
   * Eliminar la sesion.
   * @param email - Correo.
   * @returns Observable de la respuesta.
   */
  public removeSession(email: string): Observable<any> {
    const urlReq = `${this.enviroment.endpoints.urlAuth}/${this.enviroment.common.tenantId}/originauth/delete-session`;
    const bodyReq = {
      email,
    };
    return this.http.post(urlReq, bodyReq);
  }

  /**
   * Obtiene la fecha y hora actuales.
   * @returns Observable de la respuesta.
   */
  public getCurrentTime(): Observable<any> {
    const urlReq = `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/getCurrentTime`;
    return this.http.get(urlReq);
  }

  /**
   * Restaurar la sesion.
   * @param email - Correo.
   * @param accessToken - Token de acceso.
   * @param idToken - IdToken.
   * @param refreshToken - RefreshToken.
   * @returns Observable de la respuesta.
   */
  public resumeSession(
    email: string,
    accessToken: string,
    idToken: string,
    refreshToken: string
  ): Observable<any> {
    this.deleteSession();
    const urlReq = `${this.enviroment.endpoints.urlAuth}/${this.enviroment.common.tenantId}/originauth/activate-session`;
    const bodyReq = {
      email,
      tokens: {
        accessToken,
        idToken,
        refreshToken,
      },
    };
    return this.http.post(urlReq, bodyReq);
  }

  /**
   * Enviar OTP.
   * @param email - Correo.
   * @returns Observable de la respuesta.
   */
  public sendEmailOtpCode(email: string): Observable<any> {
    const urlReq = `${this.enviroment.endpoints.urlAuth}/${this.enviroment.common.tenantId}/originauth/send-code`;
    const bodyReq = {
      email,
    };
    return this.http.post(urlReq, bodyReq);
  }

  /**
   * Verificar OTP.
   * @param email - Correo.
   * @param otp - Token.
   * @returns Observable de la respuesta.
   */
  public verifyOtpCode(email: string, otp: string): Observable<any> {
    const urlReq = `${this.enviroment.endpoints.urlAuth}/${this.enviroment.common.tenantId}/originauth/verify-code`;
    const bodyReq = {
      email,
      otp,
    };
    return this.http.post(urlReq, bodyReq);
  }

  /**
   * Reiniciar contrasena.
   * @param email - Correo.
   * @param password - Contrasena.
   * @param token - Token.
   * @returns Observable de la respuesta.
   */
  public passwordReset(
    email: string,
    password: string,
    token: string
  ): Observable<any> {
    const urlReq = `${this.enviroment.endpoints.urlAuth}/${this.enviroment.common.tenantId}/originauth/reset-password`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    });
    const bodyReq = {
      email,
      password,
    };
    return this.http.post(urlReq, bodyReq, { headers });
  }
}
