import { Action, createReducer, on } from '@ngrx/store';
import { ErrorHttp } from '../../../../models/errors.models';
import { Form } from '../../../../process/models';
import { resetInitialState } from '../../../../root/store/actions';

import {
  SchemaAnalysis,
  SchemaAnalysisSuccess,
  SchemaAnalysisError,
  SchemaAnalysisEditSuccess,
  SchemaAnalysisEditError,
  ResetAnalysis,
  ResetAnalysisModifyProduct,
} from '../../actions';
import { SchemaValueModify } from '../../../models/schemas.model';

/**
 * Estado de tareas exitosas.
 */
export interface SchemaForm {
  /**
   * Objecto.
   */
  forms?: Form[];
  /**
   * Valor de la modififcacion.
   */
  value?: SchemaValueModify[];
  /**
   * Bandera de cargado.
   */
  loaded: boolean;
  /**
   * Bandera de cargando.
   */
  loading: boolean;
  /**
   * Errores.
   */
  error?: ErrorHttp;
}

/**
 * Estado de tareas exitosas.
 */
export interface SchemaAnalysisState {
  /**
   * Formularios para el proceso.
   */
  formsToProcess?: SchemaForm;
  /**
   * Formularios para editar en el proceso.
   */
  formsToEdit?: SchemaForm;
}

export const schemaAnalysisInitialState: SchemaAnalysisState = {
  formsToProcess: undefined,
  formsToEdit: undefined,
};

const featureReducer = createReducer(
  schemaAnalysisInitialState,
  on(ResetAnalysisModifyProduct, state => ({
    ...state,
    formsToEdit: {
      ...state.formsToEdit,
      value: undefined,
      loading: false,
      loaded: true,
    },
  })),
  on(ResetAnalysis, () => ({ ...schemaAnalysisInitialState })),
  on(resetInitialState, () => ({ ...schemaAnalysisInitialState })),
  on(SchemaAnalysis, state => ({
    ...state,
    formsToProcess: {
      loading: true,
      loaded: false,
    },
    formsToEdit: {
      loading: true,
      loaded: false,
    },
  })),
  on(SchemaAnalysisSuccess, (state, { formsProcess }) => ({
    ...state,
    formsToProcess: {
      forms: formsProcess,
      loading: false,
      loaded: true,
    },
  })),
  on(SchemaAnalysisError, (state, { payload }) => ({
    ...state,
    formsToProcess: {
      loading: false,
      loaded: true,
      error: {
        url: payload.url,
        name: payload.name,
        message: payload.message,
      },
    },
  })),
  on(SchemaAnalysisEditSuccess, (state, { formsEdit }) => ({
    ...state,
    formsToEdit: {
      forms: formsEdit,
      loading: false,
      loaded: true,
    },
  })),
  on(SchemaAnalysisEditError, (state, { payload }) => ({
    ...state,
    formsToEdit: {
      loading: false,
      loaded: true,
      error: {
        url: payload.url,
        name: payload.name,
        message: payload.message,
      },
    },
  }))
);

/**
 * Reductor tareas exitosas.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function SchemaAnalysisReducer(
  state: SchemaAnalysisState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
