import { Action, createReducer, on } from '@ngrx/store';
import { Catalogue } from '@wp-back-office/core/commons-backoffice';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';
import { resetInitialState } from '../../../../root/store/actions/contents/reset-initial-state.actions';

import { catalogueLegalizationDocumentsActions } from '../../actions';

/**
 * Reducer para obtener catalogos.
 */
export interface StateDataLegalizationDocCatalogues {
  /**
   * Catalogo.
   */
  catalogue?: Catalogue[];
  /**
   * Bandera de carga.
   */
  loaded: boolean;
  /**
   * Bandera de carga.
   */
  loading: boolean;
  /**
   * Respuesta erronea del store.
   */
  error?: ErrorHttp;
}

export const initialLegalizationDocState: StateDataLegalizationDocCatalogues = {
  catalogue: undefined,
  loaded: false,
  loading: false,
  error: undefined,
};

const featureGetLegalizationDocumentsReducer = createReducer(
  initialLegalizationDocState,
  on(resetInitialState, () => ({ ...initialLegalizationDocState })),
  on(catalogueLegalizationDocumentsActions.getLegalizationDocuments, state => ({
    ...state,
    loading: true,
    loaded: false,
    catalogue: undefined,
    error: undefined,
  })),
  on(
    catalogueLegalizationDocumentsActions.getLegalizationDocumentsSuccess,
    (state, { keyValue }) => ({
      ...state,
      loading: false,
      loaded: true,
      catalogue: [...keyValue],
      error: undefined,
    })
  ),
  on(
    catalogueLegalizationDocumentsActions.getLegalizationDocumentsError,
    (state, { payload }) => ({
      ...state,
      catalogue: undefined,
      loading: false,
      loaded: true,
      error: {
        url: payload.url,
        name: payload.name,
        message: payload.message,
      },
    })
  )
);
/**
 * Reductor de obtener varios.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureGetTypeOfIdentificationsReducer.
 */
export function keyValueGetLegalizationDocumentsReducer(
  state: StateDataLegalizationDocCatalogues | undefined,
  action: Action
) {
  return featureGetLegalizationDocumentsReducer(state, action);
}
