import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';
import { Observable, delay } from 'rxjs';

/**
 * Servicio de listas internas.
 */
@Injectable()
export class InternalListsService {

  /**
 * Crea una instancia de WorkflowService.
 * @param http - Servicio cliente.
 * @param enviroment - Variables de entorno.
 */
  constructor(private http: HttpClient, private enviroment: Enviroment) { }
  /**
   * Obtiene la lista interna.
   * @param pageSize - Tamaño de la pagina.
   * @param lastKey - Token para la paginacion.
   * @param typeFilter - Columna a filtrar.
   * @param filter - Valor a filtrar.
   * @returns Observable.
   */
  public InternalListsRejection(
    pageSize: number,
    lastKey?: string,
    typeFilter?: string,
    filter?: string
  ): Observable<any> {
    let params: any = {
      pageSize: pageSize,
    };
    if (lastKey && !typeFilter) {
      params = {
        pageSize: pageSize,
        lastKey: lastKey,
      };
    } else if (typeFilter && !lastKey) {
      params = {
        pageSize: pageSize,
        typeFilter: typeFilter,
        filter: filter,
      };
    } else if (typeFilter && lastKey) {
      params = {
        pageSize: pageSize,
        lastKey: lastKey,
        typeFilter: typeFilter,
        filter: filter,
      };
    }
    return this.http
      .get(
        `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/internal-list/rejection`,
        {
          params: params,
        }
      )
      .pipe(delay(1000));
  }

  /**
 * Elimina un elemento de la lista interna.
 * @param code - Código del elemento.
 * @returns Observable.
 */
  public deleteInternalListsRejection(code: string): Observable<any> {
    return this.http.delete<any>(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/internal-list/rejection/${code}`
    );
  }

  /**
 * Creacion de elemento de la lista interna.
 * @param element - Datos del elemento de la lista interna a crear.
 * @returns Observable.
 */
  public createInternalListsRejection(element: any): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/internal-list/rejection`,
      element
    );
  }

  /**
* Creacion de elemento de la lista interna.
* @param element - Datos del elemento de la lista interna a crear.
* @returns Observable.
*/
  public updateInternalListsRejection(element: any, code: string): Observable<any> {
    return this.http.put(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/internal-list/rejection/${code}`,
      element
    );
  }
}
