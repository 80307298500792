import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarDismiss,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { DynamicSnackBarComponent } from '../components/dynamic-snackbar.component';
import { SnackBarType } from '../models/snackbar-config.models';
import { Observable } from 'rxjs';

/**
 * Servicio de los SnackBars dinamicos.
 */
@Injectable()
export class DynamicSnackBarService {
  /**
   * Crea una instancia de SnackBar.
   * @param _SnackBar - SnackBar de angular material.
   */
  constructor(public _SnackBar: MatSnackBar) {}

  /**
   * Abrir un SnackBar.
   * @param message - Mensaje del SnackBar ('Hola soy un SnackBar').
   * @param type - Tipo de SnackBar ('success' | 'error' | 'info' | 'warning').
   * @param duration - Duracion en milisegundos (3000) = 3 segundos, si deseas que el usuario lo cierre el valor debe ser (0) indeterminado.
   * @param buttonText - Texto del botón 'CERRAR' por defecto.
   * @param icon - Icono material design ('home'), puedes encontrar más en https://fonts.google.com/icons?selected=Material+Icons.
   * @param verticalPosition - Posicion vertical ('top' | 'bottom').
   * @param horizontalPosition - Posicion horizontal ('start' | 'center' | 'end' | 'left' | 'right').
   * @returns - Observable<{ dismissedByAction: boolean }>.
   */
  public Open(
    message: string,
    type: SnackBarType,
    duration: number = 30000,
    buttonText?: string,
    icon?: string | null,
    verticalPosition?: MatSnackBarVerticalPosition,
    horizontalPosition?: MatSnackBarHorizontalPosition
  ): Observable<MatSnackBarDismiss> | undefined {
    if (!message) return;
    if (!icon) {
      switch (type) {
        case 'success':
          icon = 'check_circle';
          break;
        case 'error':
          icon = 'cancel';
          break;
        case 'info':
          icon = 'info';
          break;
        case 'warning':
          icon = 'warning';
          break;
        default:
          icon = 'info';
          break;
      }
    }

    const SnackBarRef = this._SnackBar.openFromComponent(
      DynamicSnackBarComponent,
      {
        duration: duration,
        data: {
          message: message,
          buttonText: buttonText ? buttonText : 'cerrar',
          icon: icon,
          type: type,
        },
        verticalPosition: verticalPosition ? verticalPosition : 'bottom',
        horizontalPosition: horizontalPosition ? horizontalPosition : 'center',
        panelClass: 'snackbar-panel',
      }
    );
    return SnackBarRef.afterDismissed();
  }
}
