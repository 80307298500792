import { createAction, props } from '@ngrx/store';

export const PoolUser = createAction('[PoolUser] Iniciar sesión');

export const PoolUserSuccess = createAction(
  '[PoolUser] Obtener CognitoUser Exitoso',
  props<{
    /**
     *
     */ token: string;
  }>()
);

export const PoolUserError = createAction(
  '[PoolUser] Obtener CognitoUser Error',
  props<{
    /**
     *
     */ payload: any;
  }>()
);

export const refreshToken = createAction('[refreshToken] Refrescar el token');

export const refreshTokenSuccess = createAction(
  '[refreshToken] Refrescar el token Exitoso',
  props<{
    /**
     *
     */ token: string;
  }>()
);

export const refreshTokenError = createAction(
  '[refreshToken] Refrescar el token Error',
  props<{
    /**
     *
     */ payload: any;
  }>()
);
