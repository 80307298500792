import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SignInInformation } from '@wp-back-office/app/global-information';
import { CognitoUser } from 'libs/app/user-authentication/src/lib/models/cognito.models';

export const authActions = createActionGroup({
  source: 'Auth',
  events: {
    'inciar sesion': props<{
      /**
       * Informacion para iniciar sesion.
       */ signInInformation: SignInInformation;
    }>(),
    'inciar sesion exitoso': props<{
      /**
       * Informacion para iniciar sesion.
       */ response: CognitoUser;
    }>(),
    'cerrar sesion': emptyProps(),
    'sesion expirada': props<{
      /**
       * Valida si el token no es valido.
       */ invalid: boolean;
    }>(),
    'send email otp code': props<{
      /**
       * Valida si el token no es valido.
       */ email: string;
    }>(),
    'send email otp code success': props<{
      /**
       * Valida si el token no es valido.
       */ object: any;
    }>(),
    'send email otp code error': props<{
      /**
       * Valida si el token no es valido.
       */ error: any;
    }>(),
    'verify otp code': props<{
      /**
       * Valida si el token no es valido.
       */ email: string;
      /**
       * Otp.
       */ otp: string;
    }>(),
    'verify otp code success': props<{
      /**
       * Valida si el token no es valido.
       */ object: any;
    }>(),
    'verify otp code error': props<{
      /**
       * Valida si el token no es valido.
       */ error: any;
    }>(),
    'password reset': props<{
      /**
       * Valida si el token no es valido.
       */ email: string;
      /**
       * Contrasena.
       */ password: string;
      /**
       * Token.
       */ token: string;
    }>(),
    'password reset success': props<{
      /**
       * Valida si el token no es valido.
       */ object: any;
    }>(),
    'password reset error': props<{
      /**
       * Valida si el token no es valido.
       */ error: any;
    }>(),
  },
});
