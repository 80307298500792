import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Catalogue } from '@wp-back-office/core/store';
import * as _ from 'lodash';
import { clone, isEqual } from 'lodash';
import {
  Observable,
  Subject,
  Subscription,
  debounceTime,
  takeUntil,
} from 'rxjs';
import {
  ButtonFormConfig,
  ControlConfig,
  justify,
  TimeOut,
  ValidatorsConfig,
} from '../../models/form-config.model';
import { DynamicFormService } from '../../services/dynamic-form.service';
import { DynamicSnackBarService } from '../../../dynamic-snackbar';

/**
 * Respuesta de un evento select.
 */
export interface SelectResponse {
  /**
   * Key del select.
   */
  keycontrol: string;
  /**
   * Valor del select.
   */
  value: Catalogue;
  /**
   * Valor del select.
   */
  options?: Catalogue[] | undefined;
}

/**
 * Respuesta de un evento edit.
 */
export interface EditControl {
  /**
   * Control a editar.
   */
  control: ControlConfig;
  /**
   * Index del control a editar.
   */
  index: number;
}

/**
 * Configuracion.
 */
export interface AddOptionsSettings {
  /**
   * Ultimas opciones.
   */
  last?: boolean;
  /**
   * Mantener opciones anteriores.
   */
  add?: boolean;
}

/**
 * Componente de formularios dinamicos.
 */
@Component({
  selector: 'wp-back-office-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Identificador unico del formulario.
   */
  public uniqueIdForm: string;

  /**
   * Prefijo unico de cada control.
   */
  public uniqueIdControlPrefix: string;
  /**
   *  Formulario.
   */
  @ViewChild('form')
  public set temForm(formGroup: FormGroupDirective) {
    if (this.patchValue && formGroup && formGroup.form)
      formGroup.form.patchValue({
        ...this.patchValue,
      });
  }

  /**
   * FormGroup.
   */
  @ViewChild('form', { static: false })
  public formGroup!: FormGroupDirective;

  /**
   * Formulario creado.
   */
  public initialized: boolean;
  /**
   * Observable - Controles Base.
   */
  @Input()
  public set controls(value: ControlConfig[] | undefined) {
    const controls = value || [];
    const prevControls = this.controlsInput?.map(control => control.key);
    const currControls = controls.map(control => control.key) || [];
    this.controlsInput =
      controls.map(item =>
        clone({
          ...item,
          disableInfiniteScroll: item.disableInfiniteScroll
            ? item.disableInfiniteScroll
            : false,
        })
      ) || [];
    if (!this.initialized || !isEqual(prevControls, currControls)) {
      this.buildForm(this.controlsInput || []);
    } else {
      this.updateForm(this.controlsInput || []);
    }
    this.initialized = true;
    this.cdRef.detectChanges();
  }

  /**
   * Observable - Controles Base.
   */
  public controlsInput: ControlConfig[];
  /**
   * Observable - Controles Base.
   */
  @Input()
  public buttonSubmit?: ButtonFormConfig;
  /**
   * Delay valuechange.
   */
  @Input()
  public debounceTimeDelay?: number;
  /**
   * Valores iniciales.
   */
  @Input()
  public patchValue?: any;
  /**
   * Valores iniciales.
   */
  @Input()
  public idForm?: string;
  /**
   * Valores iniciales.
   */
  @Input()
  public keyForm?: string;
  /**
   * Valores iniciales.
   */
  @Input()
  public formGenerator!: boolean;
  /**
   * Valores iniciales.
   *
   */
  @Input()
  public autoComplete!: boolean;
  /**
   * Valores iniciales.
   */
  @Input()
  public hideDisabled?: boolean;
  /**
   * Valores iniciales.
   */
  @Input()
  public justify?: justify;
  /**
   * Valores iniciales.
   */
  @Input()
  public loading?: boolean;
  /**
   * Valores iniciales.
   */
  @Input()
  public getCatalogue?: boolean = true;

  /**
   * Evento de cambio de valores.
   */
  @Output()
  public changeValue: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  /**
   * Evento de cambio de valores.
   */
  @Output()
  public action: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Evento de codigo de verificacion.
   */
  @Output()
  public codeVerification: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * Evento de formulario valido.
   */
  @Output()
  public submitValid: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  /**
   * Evento de formulario valido.
   */
  @Output()
  public submitForm: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Evento de formulario valido.
   */
  @Output()
  public selectInput: EventEmitter<SelectResponse> =
    new EventEmitter<SelectResponse>();
  /**
   * Evento de formulario valido.
   */
  @Output()
  public infiniteScroll: EventEmitter<SelectResponse> =
    new EventEmitter<SelectResponse>();
  /**
   * Evento de formulario valido.
   */
  @Output()
  public edit: EventEmitter<EditControl> = new EventEmitter<EditControl>();
  /**
   * Evento de formulario valido.
   */
  @Output()
  public editNewChanges: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Evento de formulario valido.
   */
  @Output()
  public isLoading: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  /**
   * Evento focus.
   */
  @Output()
  public controlFocus: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Evento focusOut.
   */
  @Output()
  public controlFocusOut: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Evento resendCode.
   */
  @Output()
  public controlResendCode: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Evento resendCode.
   */
  @Output()
  public formRendered: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Evento timeOutOTP.
   */
  @Output()
  public timeOutOTP = new EventEmitter<TimeOut>();
  /**
   * Entrada formulario generico.
   */
  public formGroupGeneric: FormGroup;
  /**
   * Json del formulario dinamico.
   */
  public payLoad;
  /**
   * Destructor sujeto.
   */
  public formDestroy$: Subject<boolean>;
  /**
   * Destructor sujeto.
   */
  public componentDestroy$: Subject<boolean>;
  /**
   * Destructor sujeto.
   */
  public editDestroy$: Subject<boolean>;

  /**
   * Crea una instancia de DynamicFormComponent.
   * @param dynamicFormService - Servicio para mensajes de error.
   * @param dynamicSnackBarService - Servicio de snackbars.
   * @param renderer - Renderer2.
   * @param cdRef - Detector de cambios.
   */
  constructor(
    public dynamicFormService: DynamicFormService,
    public dynamicSnackBarService: DynamicSnackBarService,
    private renderer: Renderer2,
    public cdRef: ChangeDetectorRef
  ) {
    this.controlsInput = [];
    this.autoComplete = false;
    this.formDestroy$ = new Subject();
    this.editDestroy$ = new Subject();
    this.componentDestroy$ = new Subject();
    this.payLoad = '';
    this.formGroupGeneric = new FormGroup<any>({});
    this.initialized = false;
    this.uniqueIdForm = `Form_${this.generateFormId()}`;
    this.uniqueIdControlPrefix = `${this.uniqueIdForm}_Control_`;
  }

  /**
   * Genera un id unico para el formulario.
   * @returns String.
   */
  public generateFormId() {
    const caracteres =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let id = '';
    for (let i = 0; i < 10; i++) {
      const indice = Math.floor(Math.random() * caracteres.length);
      id += caracteres.charAt(indice);
    }
    return id;
  }

  /**
   * Se ejecuta cuando se inicia el componente.
   */
  public ngOnInit(): void {
    this.autoComplete = false;
  }

  /**
   * Se ejecuta al renderizar el componente.
   */
  public ngAfterViewInit() {
    if (this.patchValue) {
      this.formGroupGeneric.patchValue(this.patchValue);
      this.patchValue = {
        ...this.formGroupGeneric.value,
        ...this.patchValue,
      };
    }
    setTimeout(() => {
      this.formRendered.emit();
    }, 100);
  }

  /**
   * A callback method that performs custom clean-up, invoked immediately before a directive, pipe, or service instance is destroyed.
   */
  public ngOnDestroy(): void {
    this.formDestroy$.next(false);
    this.formDestroy$.complete();
    this.editDestroy$.next(false);
    this.editDestroy$.complete();
    this.componentDestroy$.next(false);
    this.componentDestroy$.complete();
  }

  /**
   * Suscribirse a los cambios en el formGroup.
   */
  public onFormChange(): void {
    this.formDestroy$.next(true);
    this.disableAndEnableControls(); // VALIDA LOS CAMPOS QUE SE DEBEN MOSTRAR U OCULTAR AL INICIO.
    this.formRendered.emit();

    this.formGroupSubscription();

    this.controlsInput?.forEach(control => {
      this.formGroupGeneric.controls[control.key || '']?.valueChanges
        .pipe(takeUntil(this.formDestroy$))
        .subscribe(() => {
          this.disableAndEnableControls(control.key || ''); // VALIDA LOS CAMPOS QUE SE DEBEN MOSTRAR U OCULTAR DURANTE LA EJECUCION.
        });
    });
  }
  /**
   * Subscripcion del FormGroupGeneric.
   */
  private formGroupSubscription(): void {
    this.formGroupGeneric.valueChanges
      .pipe(
        takeUntil(this.formDestroy$),
        debounceTime(
          typeof this.debounceTimeDelay === 'number'
            ? this.debounceTimeDelay
            : 1000
        )
      )
      .subscribe(valueForm => this.formValueChange(valueForm));
  }

  private formValueChange(valueForm: any) {
    const inputValue = this.patchValue;
    let data = {};
    for (const ctrla in inputValue) {
      for (const ctrlb in valueForm) {
        if (ctrlb && _.isEqual(ctrla, ctrlb)) {
          if (!_.isEqual(inputValue[ctrla], valueForm[ctrlb])) {
            const dataString = JSON.stringify(valueForm[ctrlb]);
            const resp = JSON.parse(
              `{ "${this.keyForm}.${ctrlb.toString()}" : ${dataString} }`
            );
            data = { ...data, ...resp };
          }
        }
      }
    }
    this.changeValue.emit(this.formGroupGeneric);
    this.editNewChanges.emit(
      !data || JSON.stringify(data) === '{}' ? undefined : data
    );
    if (this.formGroupGeneric.valid) {
      this.submitValid.emit(this.formGroupGeneric);
    }
  }

  /**
   * Habilita y deshabilita los controles dependiendo del ngIf.
   * @param key - Clave del input.
   */
  private disableAndEnableControls(key?: string) {
    this.controlsInput?.forEach(control => {
      if (
        (!key && control.validators?.ngIf?.keyValue) || //SI EL PARAMETRO KEY (NO) EXISTE VALIDARA TODOS LOS CAMPOS, ESTO SUCEDE AL INICIO.
        (key &&
          control.validators?.ngIf?.keyValue !== control.key && // EL PARAMETRO keyValue DEL NGIF (NO) DEBE SER IGUAL AL key DEL MISMO CONTROL.
          control.validators?.ngIf?.keyValue === key) // SI EL PARAMETRO KEY (EXISTE) VALIDARA SOLO EL CAMPO QUE CAMBIO SU VALOR Y TIENE EL NGIF, ESTO SUCEDE DURANTE LA EJECUCION.
      ) {
        if (this.controlNgIf(control)) {
          this.formGroupGeneric.controls[control.key || ''].enable();
        } else {
          this.formGroupGeneric.controls[control.key || ''].disable();
        }
      }
    });
  }

  /**
   * Valida si un boton se debe mostrar.
   * @param control - Boton a validar.
   * @returns Boolean.
   */
  private controlNgIf(control: ControlConfig | undefined): boolean {
    if (
      Object.prototype.hasOwnProperty.call(
        control?.validators?.ngIf || {},
        'equalsTo'
      )
    ) {
      return _.isEqual(
        this.formGroupGeneric.controls[
          control?.validators?.ngIf?.keyValue || ''
        ].value,
        control?.validators?.ngIf?.equalsTo
      );
    } else if (
      Object.prototype.hasOwnProperty.call(
        control?.validators?.ngIf || {},
        'differentFrom'
      )
    ) {
      return !_.isEqual(
        this.formGroupGeneric.controls[
          control?.validators?.ngIf?.keyValue || ''
        ].value,
        control?.validators?.ngIf?.differentFrom
      );
    } else if (
      Object.prototype.hasOwnProperty.call(
        control?.validators?.ngIf || {},
        'keyValue'
      )
    ) {
      return this.formGroupGeneric.controls[
        control?.validators?.ngIf?.keyValue || ''
      ].value;
    } else {
      return true;
    }
  }

  /**
   * Modifica el valor de una llave del formulario.
   * @param key - Llave a modificar.
   * @param value - Valor a ingresar.
   */
  public changeFormValue(key: string, value: any) {
    this.formGroupGeneric.controls[key]?.patchValue(value);
    if (value) {
      this.formGroupGeneric.controls[key]?.markAsDirty();
      this.formGroupGeneric.controls[key]?.markAsTouched();
    }
  }

  /**
   * Emitir accion del boton del input.
   * @param value - Valor a emitir.
   */
  public sendAction(value: any) {
    this.action.emit(value);
  }
  /**
   * Alternar deshabilitación de todos los controles.
   * @param nameControls - Nombre del control.
   * @param disabled - Estado para el control.
   */
  public toogleDisabled(disabled: boolean): void;
  /**
   * Alternar deshabilitación del control.
   * @param nameControls - Nombre del control.
   * @param disabled - Estado para el control.
   */
  public toogleDisabled(nameControls: string, disabled: boolean): void;

  /**
   * Alternar deshabilitación del control.
   * @param param1 - Nombre del control.
   * @param param2 - Estado para el control.
   */
  public toogleDisabled(param1: string | boolean, param2?: boolean): void {
    if (typeof param1 === 'string') {
      if (param2) {
        this.formGroupGeneric.controls[param1].disable();
      } else {
        this.formGroupGeneric.controls[param1].enable();
      }
      const control = this.controlsInput?.find(
        control => control.key?.trim() || '' === param1.trim() || ''
      );
      if (control) {
        const validators = this.dynamicFormService.getValidators(control);
        this.formGroupGeneric
          .get(param1.trim() || '')
          ?.setValidators(validators);

        this.formGroupGeneric
          .get(param1.trim() || '')
          ?.updateValueAndValidity();
      }
    } else if (typeof param1 === 'boolean') {
      if (param1) {
        this.formGroupGeneric.disable();
      } else {
        this.formGroupGeneric.enable();
      }
      this.controlsInput?.forEach(control => {
        if (control) {
          const validators = this.dynamicFormService.getValidators(control);
          this.formGroupGeneric
            .get(control.key || '')
            ?.setValidators(validators);

          this.formGroupGeneric
            .get(control.key || '')
            ?.updateValueAndValidity();
        }
      });
    }
  }

  /**
   * Actualiza la configuración de un control.
   * @param key - Llave del control.
   * @param config - Configuración.
   * @returns - ControlConfig[].
   */
  public updateControlConfig(
    key: string,
    config: ControlConfig
  ): ControlConfig[] {
    if (this.controlsInput) {
      this.controlsInput = this.controlsInput.map(control => {
        if (control.key === key) return Object.create(config) as ControlConfig;
        return Object.create(control) as ControlConfig;
      });
      this.updateForm(this.controlsInput);
      this.cdRef.detectChanges();
      return this.controlsInput;
    }
    return [];
  }

  /**
   * Crea el formulario inicial del componente.
   * @param controls - Controles del formulario.
   */
  private buildForm(controls: ControlConfig[]) {
    if (controls)
      try {
        this.formDestroy$.next(true);
        this.formGroupGeneric = new FormGroup<any>({});
        controls.map(control => {
          if (control && control.key) {
            const formControl = this.dynamicFormService.setValidators(control);
            if (control.validators?.disabled) {
              formControl?.disable();
            } else if (formControl?.disabled) {
              formControl?.enable();
            }
            this.formGroupGeneric.addControl(control.key.trim(), formControl);

            if (control.validators?.match) {
              this.formGroupGeneric.addValidators(
                this.matchValue(control.key.trim(), control.validators?.match)
              );
              this.formGroupGeneric.updateValueAndValidity();
            }
          }
        });
        this.onFormChange();
      } catch (error) {
        console.error(error);
      }
  }

  /**
   * Actualiza los validators del formulario del componente.
   * @param controls - Controles del formulario.
   */
  private updateForm(controls: ControlConfig[]) {
    const oldKeys = Object.keys(this.formGroupGeneric.value);
    if (controls)
      try {
        this.formDestroy$.next(true);
        oldKeys.forEach(key => {
          if (!controls.find(control => control.key?.trim() === key?.trim())) {
            this.formGroupGeneric.removeControl(key);
          }
        });
        controls.forEach(control => {
          const validators = this.dynamicFormService.getValidators(control);
          this.formGroupGeneric
            .get(control.key?.trim() || '')
            ?.setValidators(validators);

          this.formGroupGeneric
            .get(control.key?.trim() || '')
            ?.updateValueAndValidity();

          if (control.validators?.disabled) {
            this.formGroupGeneric.get(control.key?.trim() || '')?.disable();
          } else if (
            this.formGroupGeneric.get(control.key?.trim() || '')?.disabled
          ) {
            this.formGroupGeneric.get(control.key?.trim() || '')?.enable();
          }

          if (control.key && control.validators?.match) {
            this.formGroupGeneric.addValidators(
              this.matchValue(control.key?.trim(), control.validators?.match)
            );
          }
        });
        this.cdRef.detectChanges();
        this.formGroupGeneric.updateValueAndValidity();
        this.onFormChange();
      } catch (error) {
        console.error(error);
      }
  }

  /**
   * Valida si dos campos coinciden.
   * @param firstControl - Primer control a validar.
   * @param secondControl - Segundo control a validar.
   * @returns ValidatorFn.
   */
  public matchValue(firstControl: string, secondControl: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const first = control?.get(firstControl);
      const second = control?.get(secondControl);

      const firstLabel =
        this.controlsInput?.find(control => control.key === firstControl)
          ?.label || '';
      const secondLabel =
        this.controlsInput?.find(control => control.key === secondControl)
          ?.label || '';
      if ((first?.value || '') != (second?.value || '')) {
        first?.setErrors({
          ...(first?.errors || {}),
          noMatch: true,
        });
        second?.setErrors({
          ...(second?.errors || {}),
          noMatch: true,
        });
        return {
          noMatch: {
            message: `Los campos <b>${firstLabel}</b> y <b>${secondLabel}</b> deben <b>coincidir</b>.`,
          },
        };
      }

      const firstErrors = first?.errors || false;
      const secondErrors = second?.errors || false;

      if (firstErrors && firstErrors['noMatch']) {
        delete firstErrors['noMatch'];
        const isEmpty = this.isObjectEmpty(firstErrors);
        first?.setErrors(isEmpty ? null : firstErrors);
      }

      if (secondErrors && secondErrors['noMatch']) {
        delete secondErrors['noMatch'];
        const isEmpty = this.isObjectEmpty(secondErrors);
        second?.setErrors(isEmpty ? null : secondErrors);
      }

      return null;
    };
  }

  /**
   * Valida si un objeto esta vacio.
   * @param objectName - Objeto a validar.
   * @returns Boolean.
   */
  public isObjectEmpty(objectName: any) {
    return (
      Object.keys(objectName).length === 0 && objectName.constructor === Object
    );
  }

  /**
   * Emite un evento al seleccionar un valor en un select en el output selectInput.
   * @param data - Valor seleccionado.
   * @param controlKey - Nombre del contol.
   */
  public onSelect(data: any, controlKey: string | undefined) {
    this.selectInput.emit({
      keycontrol: controlKey || '',
      value: data,
    });
  }

  /**
   * Emite un evento al hacer scroll hacia abajo en un select en el output infiniteScroll.
   * @param options - Opciones actuales.
   * @param controlKey - Nombre del control.
   * @param control - Nombre del control.
   */
  public onInfiniteScroll(
    options: any,
    controlKey: string | undefined,
    control: ControlConfig
  ): void {
    if (!control?.disableInfiniteScroll) {
      this.infiniteScroll.emit({
        keycontrol: controlKey || '',
        value: {
          code: '',
          description: '',
        },
        options: options,
      });
    }
  }

  /**
   * Evento para emitir los datosen formato json del formulario.
   */
  public onSubmit() {
    const jsonDataForm = JSON.stringify(this.formGroupGeneric.value);
    this.submitForm.emit(jsonDataForm);
  }

  /**
   * Evento de codigo de verificacion bandera.
   * @param status - Bandera de submit.
   */
  public onCodeVerification(status: boolean) {
    this.codeVerification.emit(status);
  }

  /**
   * Metodo para eliminar el campo seleccionado.
   * @param index - Index de la fila seleccionada.
   */
  public deleteFormItem(index: number): void {
    this.controlsInput?.splice(index, 1);
  }

  /**
   * Reinicia el formulario.
   */
  public reset(): void {
    this.formGroupGeneric.reset();
    const controls = this.controlsInput?.map(control => {
      return {
        ...control,
        value: null,
      };
    });
    this.controlsInput = controls;
  }

  /**
   * Modifica el valor del formulario.
   * @param value - Valor nuevo del formulario.
   * @param emitEvent - Bandera de evento emit.
   */
  public setValue(value: any, emitEvent?: boolean) {
    this.emitEventFn(() => {
      Object.keys(value).forEach(element => {
        this.changeFormValue(element, value[element]);
      });
    }, emitEvent);
  }
  /**
   * Configura la emision de evento.
   * @param fn - Funcion de entrada.
   * @param emitEvent - Bandera de evento emit.
   */
  private emitEventFn(fn: () => void, emitEvent?: boolean) {
    if (emitEvent !== undefined && emitEvent === false) {
      this.formDestroy$.next(false);
      this.formDestroy$.complete();
    }
    fn();
    if (emitEvent !== undefined && emitEvent === false) {
      this.formGroupSubscription();
    }
  }

  /**
   * Ejecuta el evento focus del input.
   * @param event - Evento focus del input.
   * @param control - Control.
   */
  public onFocus(event: FocusEvent, control: ControlConfig): void {
    this.controlFocus.emit({
      event: event,
      key: control.key?.trim() || '',
      value: this.formGroupGeneric.value[control.key?.trim() || ''],
      validators: control.validators || {},
    });
  }

  /**
   * Ejecuta el evento focusOut del input.
   * @param event - Evento.
   * @param control - Configuracion del control.
   */
  public onFocusOut(event: FocusEvent, control: ControlConfig): void {
    this.controlFocusOut.emit({
      event: event,
      key: control.key?.trim() || '',
      value: this.formGroupGeneric.value[control.key?.trim() || ''],
      validators: control.validators || {},
    });
  }

  /**
   * Ejecuta el evento resendCode del input codeVerification.
   * @param control - Configuracion del control.
   */
  public onReSendCode(control: ControlConfig): void {
    this.controlResendCode.emit({
      key: control.key?.trim() || '',
      value: this.formGroupGeneric.value[control.key?.trim() || ''],
      validators: control.validators || {},
    });
  }

  /**
   * Actualiza las opciones del control con el index.
   * @param options - Options.
   * @param index - Index.
   * @param config - Configuracion.
   */
  public updateOptionsByIndex(
    options: any[],
    index: number,
    config?: AddOptionsSettings
  ) {
    const finalOptions = config?.add
      ? [...(this.controlsInput[index].options || []), ...(options || [])]
      : [...(options || [])];

    if (this.controlsInput) {
      this.controlsInput[index].disableInfiniteScroll = config?.last
        ? config.last
        : undefined;

      if (this.controlsInput[index]?.autocompleteConfig?.internalFilter) {
        this.controlsInput[index] = {
          ...this.controlsInput[index],
          keyOptions: undefined,
          options: finalOptions,
          value:
            this.formGroupGeneric.value[this.controlsInput[index].key || ''],
        };
      }

      this.controlsInput[index].options = finalOptions;
      this.controlsInput[index].keyOptions = undefined;
      this.controlsInput[index].loading = false;

      setTimeout(() => {
        if (
          this.controlsInput[index]?.validators?.required &&
          options.length === 1 &&
          options[0].code &&
          !this.controlsInput[index]?.uniqueOptionAutoFillDeactivated
        ) {
          this.formGroupGeneric.controls[
            this.controlsInput[index].key || ''
          ].patchValue(
            this.controlsInput[index].validators?.multiple
              ? options
              : options[0]
          );
        }
      }, 100);
      this.cdRef.detectChanges();
    }
  }

  /**
   * Desactiva o activa el infiniteScroll de un control.
   * @param value - Valor.
   * @param index - Indice del control.
   */
  public disabledInfiniteScroll(value: boolean, index: number): void {
    if (this.controlsInput) {
      this.controlsInput[index].disableInfiniteScroll = value;
    }
  }

  /**
   * Actualiza los validadores del control con el index.
   * @param validators - Options.
   * @param index - Index.
   * @param replace - Reemplazar el valor del validador.
   */
  public updateValidatorsByIndex(
    validators: ValidatorsConfig,
    index: number,
    replace?: boolean
  ) {
    if (this.controlsInput) {
      let val = {
        ...this.controlsInput[index].validators,
        ...validators,
      };
      if (replace) {
        val = validators;
      }
      this.controlsInput[index] = {
        ...this.controlsInput[index],
        validators: val,
      };

      this.updateForm(this.controlsInput);
      this.cdRef.detectChanges();
    }
  }

  /**
   * Actualiza los la configuracion de un control con el index.
   * @param control - Options.
   * @param index - Index.
   * @param replace - Reemplazar el valor del validador.
   */
  public updateControlByIndex(
    control: ControlConfig,
    index: number,
    replace?: boolean
  ) {
    if (this.controlsInput) {
      let ctrl = {
        ...this.controlsInput[index],
        ...control,
      };
      if (replace) {
        ctrl = control;
      }
      this.controlsInput[index] = ctrl;
      this.updateForm(this.controlsInput);
      this.cdRef.detectChanges();
    }
  }

  /**
   * Enfoca un control.
   * @param control - Control a enfocar.
   */
  public focus(control: string) {
    const elem = this.renderer.selectRootElement(
      `#${this.uniqueIdControlPrefix}${control}`
    );
    elem?.focus();
  }

  /**
   * Enfoca un control.
   * @param event - Evento.
   */
  public onTimeOutOTP(event: TimeOut) {
    this.timeOutOTP.emit(event);
  }

  /**
   * Convierte un abstractControl en un formControl.
   * @param absCtrl - Abstract control.
   * @returns Form Control.
   */
  public convertToFormControl(absCtrl: AbstractControl | null): FormControl {
    const ctrl = absCtrl as FormControl;
    return ctrl;
  }

  /**
   * Progreso del control de archivo.
   * @param index - Index.
   * @param value - Valor.
   */
  public controlFileProgress(index: number, value: number, text?: string) {
    this.controlsInput[index].progressValue = value;
    if (text) {
      this.controlsInput[index].hint = text;
    }
    this.cdRef.detectChanges();
  }

  /**
   * Avisa al usuario que el texto se copio.
   * @param text - Texto a copiar.
   */
  public textCopied(text: string | undefined): void {
    this.dynamicSnackBarService.Open(`Copiado: ${text || ''}`, 'success', 3000);
  }

  /**
   * Avisa al usuario que el texto se copio.
   * @param value - Valor a copiar.
   * @returns String.
   */
  public getValue(value: any | undefined): string {
    let text;
    if (value) {
      if (_.isArray(value)) {
        text = value.map(value => value.description).toString();
      } else {
        text = value?.description || value || '';
      }
    }
    return text;
  }

  /**
   * Modifica el parametro loading del control.
   * @param loading - Bandera de carga.
   * @param index - Indice.
   */
  public setControlLoading(loading: boolean, index: number) {
    if (
      this.controlsInput[index] &&
      this.controlsInput[index].loading !== undefined
    ) {
      this.controlsInput[index].loading = loading;
    }
    this.cdRef.detectChanges();
  }

  /**
   * Poner estado dirty a un formulario.
   */
  public setDirty(): void {
    this.formGroupGeneric.markAsDirty();
    this.formGroupGeneric.markAsTouched();
    this.cdRef.detectChanges();
  }

  /**
   * Evento valueChanges del formulario o de un control del formulario.
   * @param key - Clave del control (En caso de suscripcion a un campo en especifico).
   * @returns Observable<any>.
   */
  public valueChanges(key?: string): Observable<any> {
    if (key) {
      return this.formGroupGeneric.controls[key].valueChanges;
    } else {
      return this.formGroupGeneric.valueChanges;
    }
  }
}
