import { Description } from 'libs/shared/quotation/src/lib/models/quotation-detail.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';
import { delay, map, Observable, tap } from 'rxjs';
import { GroupAWS, GroupMapper } from '../mappers/group.mapper';
import { PermissionMapper, PermissionsAWS } from '../mappers/permission.mapper';
import { ResponseAWS } from '../models/response-aws.model';
import { Market, Office, Room, Seller } from '../models/market.model';
import { ALL } from '@wp-back-office/core/store';
import { UserData } from 'libs/app/access-manager/src/lib/store/reducers';
/**
 * Servicio de control de acceso.
 */
@Injectable()
// eslint-disable-next-line @nrwl/nx/workspace/doc-elements-angular
export class AccessControlService {
  /**
   * Instancia del mapper de grupo.
   */
  public groupMapper = new GroupMapper();
  /**
   * Instancia del mapper de permisos.
   */
  public permissionMapper = new PermissionMapper();
  /**
   * Crea una instancia de WorkflowService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}
  /**
   * Obtiene la lista de usuarios.
   * @param pageSize - Tamaño de la pagina.
   * @param paginationToken - Token para la paginacion.
   * @param typeFilter - Columna a filtrar.
   * @param filter - Valor a filtrar.
   * @returns Observable.
   */
  public getUsers(
    pageSize: number,
    paginationToken?: string,
    typeFilter?: string,
    filter?: string
  ): Observable<any> {
    let params: any = {
      pageSize: pageSize,
    };
    if (paginationToken && !typeFilter) {
      params = {
        pageSize: pageSize,
        paginationToken: paginationToken,
      };
    } else if (typeFilter && !paginationToken) {
      if (!filter) filter = '';
      params = {
        pageSize: pageSize,
        typeFilter: typeFilter,
        filter: filter,
      };
    } else if (typeFilter && paginationToken) {
      if (!filter) filter = '';
      params = {
        pageSize: pageSize,
        paginationToken: paginationToken,
        typeFilter: typeFilter,
        filter: filter,
      };
    }
    return this.http
      .get(
        `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/rba/user`,
        {
          params: params,
        }
      )
      .pipe(delay(1000));
  }

  /**
   * Obtiene la lista de usuarios.
   * @param id - Id del usuario.
   * @returns Observable.
   */
  public getUser(id: string): Observable<UserData> {
    return this.http.get<UserData>(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/rba/user/${id}`
    );
  }

  /**
   * Obtiene la lista de usuarios.
   * @param id - Id del usuario.
   * @returns Observable.
   */
  public getUserGroups(id: string): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/rba/user/${id}/groups`,
      {
        params: {
          pageSize: 100,
        },
      }
    );
  }

  /**
   * Obtiene la lista de usuarios.
   * @param id - Id del usuario.
   * @param groups - Grupos del usuaario.
   * @returns Observable.
   */
  public updateUserGroups(id: string, groups: any): Observable<any> {
    return this.http.put(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/rba/user/${id}/groups`,
      {
        groups: groups,
      }
    );
  }

  /**
   * Obtiene la lista de grupos.
   * @param pageSize - Tamaño de la pagina.
   * @param lastCodePagination - Token para la paginacion según último código.
   * @param typeFilter - Columna a filtrar.
   * @param filter - Valor a filtrar.
   * @returns Observable.
   */
  public getGroups(
    pageSize: number | ALL,
    lastCodePagination?: string,
    typeFilter?: string,
    filter?: string
  ): Observable<any> {
    let params: any = {
      pageSize: pageSize,
    };
    if (lastCodePagination && !typeFilter) {
      params = {
        ...params,
        lastCodePagination: lastCodePagination,
      };
    } else if (typeFilter && !lastCodePagination) {
      params = {
        ...params,
        typeFilter: typeFilter,
        filter: filter,
      };
    } else if (typeFilter && lastCodePagination) {
      params = {
        ...params,
        lastCodePagination: lastCodePagination,
        typeFilter: typeFilter,
        filter: filter,
      };
    }
    return this.http.get(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/rba/group`,
      {
        params: params,
      }
    );
  }
  /**
   * Obtiene la lista de permisos.
   * @returns Observable.
   */
  public getPermissions(): Observable<any> {
    return this.http
      .get<PermissionsAWS>(
        `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/rba/permissions`
      )
      .pipe(map(res => this.permissionMapper.mapFrom(res)));
  }
  /**
   * Obtiene los datos del grupo.
   * @param code - Código del grupo.
   * @returns Observable.
   */
  public getGroupData(code: string): Observable<any> {
    return this.http
      .get<GroupAWS>(
        `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/rba/group/${code}`
      )
      .pipe(
        map(res => {
          const temp22 = this.groupMapper.mapFrom(res);
          return temp22;
        })
      );
  }
  /**
   * Obtiene los datos del grupo.
   * @param code - Código del grupo.
   * @returns Observable.
   */
  public deleteGroup(code: string): Observable<any> {
    return this.http.delete<GroupAWS>(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/rba/group/${code}`
    );
  }
  /**
   * Crea un grupo nuevo.
   * @param data - Data que recibe el DynamicDialogMessageService desde el componente.
   * @returns Observable.
   */
  public createGroup(data: any): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/rba/group`,
      {
        ...this.groupMapper.mapTo(data),
        name: data.name,
        commercialRole: data.commercialRole,
        attributions: data.attributions,
      }
    );
  }

  /**
   * Crea un fromulario nuevo.
   * @param data - Data del componente.
   * @returns Observable.
   */
  public createForm(data: any): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/forms`,
      { ...data }
    );
  }

  /**
   * Acutaliza grupo.
   * @param data - Data que recibe el DynamicDialogMessageService desde el componente.
   * @returns Observable.
   */
  public updateGroup(data: any): Observable<any> {
    const dataMapping = this.groupMapper.mapTo(data);
    return this.http.put(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/rba/group/${data.name}`,
      {
        ...dataMapping,
        permissions: dataMapping.permissions ? dataMapping.permissions : [],
        processes: dataMapping.processes ? dataMapping.processes : [],
        commercialRole: data.commercialRole,
        attributions: data.attributions,
      }
    );
  }
  /**
   * Creacion de usuario.
   * @param user - Datos del usuario a crear.
   * @returns Observable.
   */
  public createUser(user: any): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/rba/user`,
      user
    );
  }

  /**
   * Actualizacion de usuario.
   * @param user - Datos del usuario.
   * @returns Observable.
   * @param id - Id del usuario.
   */
  public UpdateUser(user: any, id: string): Observable<any> {
    return this.http.put(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/rba/user/${id}`,
      user
    );
  }

  /**
   * Actualizacion de usuario.
   * @returns Observable.
   * @param id - Id del usuario.
   */
  public DisableUser(id: string): Observable<any> {
    return this.http.patch(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/rba/user/${id}/disable`,
      {}
    );
  }

  /**
   * Actualizacion de usuario.
   * @returns Observable.
   * @param id - Id del usuario.
   */
  public EnableUser(id: string): Observable<any> {
    return this.http.patch(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/rba/user/${id}/enable`,
      {}
    );
  }

  /**
   * Actualizar la foto.
   * @param formData - Form.
   * @returns - Confirmación.
   */
  public updateUserPhoto(formData: FormData): Observable<any> {
    return this.http.post(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/rba/user/photo`,
      formData
    );
  }

  /**
   * Obtener la imagen de perfil.
   * @param id - Id del usuario.
   * @returns - Imagen.
   */
  public getUserPhoto(id: string): Observable<any> {
    return this.http.get(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/rba/user/photo/${id}`
    );
  }

  /**
   * Obtiene la lista de usuarios.
   * @param pageSize - Tamaño de la pagina.
   * @param page - Columna a filtrar.
   * @param filter - Valor a filtrar.
   * @returns Observable.
   */
  public getMarkets(
    pageSize?: number,
    page?: number,
    filter?: string
  ): Observable<ResponseAWS<Market>> {
    let params: any = {};
    pageSize ? (params = { ...params, pageSize }) : null;
    page ? (params = { ...params, page }) : null;
    filter ? (params = { ...params, description: filter }) : null;
    return this.http.get<ResponseAWS<Market>>(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/market`,
      {
        params: params,
      }
    );
  }

  /**
   * Obtiene la lista de usuarios.
   * @param room - Codigo de la oficina.
   * @param pageSize - Tamaño de la pagina.
   * @param page - Columna a filtrar.
   * @param seller - Vendedor.
   * @returns Observable.
   */
  public getSellerByRoom(
    room: string,
    pageSize: number,
    page?: string,
    seller?: string,
    filter?: string
  ): Observable<ResponseAWS<Seller>> {
    let params = {};
    if (room) {
      params = { ...params, room };
    }
    if (pageSize) {
      params = { ...params, pageSize };
    }
    if (page) {
      params = { ...params, page };
    }
    if (filter) {
      params = { ...params, email: filter };
    }
    if (seller) {
      params = { ...params, seller };
    }
    return this.http.get<ResponseAWS<Seller>>(
      `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/branch-management/room/seller`,
      {
        params: params,
      }
    );
  }
}
