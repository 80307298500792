import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Subject, debounceTime, takeUntil } from 'rxjs';

/**
 * Valida el scroll de un div.
 */
@Component({
  selector: 'wp-back-office-infinite-scroll',
  template: `
    <div
      #scrollDiv
      class="scroll p-relative {{ classes }}"
      [ngStyle]="scrollStyle || {}"
      (scroll)="validate($event)">
      <div [class.scroll-content]="!hidePaddingBottom">
        <ng-container *ngTemplateOutlet="template"></ng-container>
      </div>
      <wp-back-office-dynamic-spinner
        class="loading"
        [class.show]="loading"
        *ngIf="!hideLoader"></wp-back-office-dynamic-spinner>
    </div>
  `,
  styles: [
    `
      .scroll {
        overflow-y: overlay;
        padding: 25px;
        max-height: 100%;
        display: grid;
        position: relative;
        width: 100%;
        scrollbar-gutter: stable both-edges;
      }
      .scroll-content {
        padding-bottom: 10px !important;
      }

      .loading {
        position: sticky;
        bottom: 100px;
        display: block;
        margin: auto;
        left: 0;
        right: 0;
        min-width: 200px;

        min-width: 200px;
        display: block;
        width: 100%;
        height: 115px;
        background: rgb(255, 255, 255);
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgb(255, 255, 255) 34%
        );
        z-index: 8;
        box-shadow: 0px 122px 34px 51px rgba(255, 255, 255, 1);
        opacity: 0;
        transition: all 200ms cubic-bezier(0.25, 0.8, 0.25, 1) !important;
        pointer-events: none;
        &.show {
          opacity: 1;
        }
      }
    `,
  ],
})
export class InfiniteScrollComponent implements AfterViewInit, OnDestroy {
  /**
   * Evento de scroll al final del div.
   */
  @Output()
  private OnInfiniteScroll = new EventEmitter<undefined>();
  /**
   * Observable del evento de scroll al final del div.
   */
  private _infiniteScroll = new Subject<void>();

  /**
   * Evento de scroll al final del div.
   */
  @Output()
  private _boottomDelay = new Subject<void>();

  /**
   * Div al que se le hace scroll.
   */
  @ViewChild('scrollDiv') public scrollDiv!: ElementRef;

  /**
   * Template inicial.
   */
  @Input()
  public template!: TemplateRef<any>;

  /**
   * Estilos del scroll.
   */
  @Input()
  public scrollStyle!: any;

  /**
   * Valor bottom para validar.
   */
  @Input()
  public bottom!: number;

  /**
   * Clases.
   */
  @Input()
  public classes!: string;

  /**
   * Ocultar padding inferior.
   */
  @Input()
  public hidePaddingBottom!: boolean;

  /**
   *  Ocultar loader.
   */
  @Input()
  public hideLoader!: boolean;

  /**
   * Posicion vertical actual del contenido de la tabla.
   */
  private currentVerticalPosition!: any;

  /**
   * Destructor sujeto.
   */
  private destroy$ = new Subject();

  /**
   * Bandera de carga.
   */
  public loading = false;

  /**
   * Evento de scroll al final del div.
   */
  @Output()
  private OnScroll = new EventEmitter<any>();

  /**
   * Se ejecuta al renderizar el componente.
   */
  public ngAfterViewInit(): void {
    this._boottomDelay
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe(() => {
        this.loading = false;
      });

    this._infiniteScroll
      .pipe(debounceTime(600), takeUntil(this.destroy$))
      .subscribe(() => {
        this.OnInfiniteScroll.emit();
      });
  }

  /**
   * Metodo que se ejecuta al destruir el componente.
   */
  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /**
   * A.
   * @param event - Evento scroll.
   */
  public validate(event: any) {
    this.OnScroll.emit(event.srcElement);
    const scrollTop = event.srcElement.scrollTop;
    /**
     * Abajo.
     */
    if (scrollTop > this.currentVerticalPosition) {
      this._valideBottomPosition(event);
    }

    if (event.srcElement.scrollTop == 0) {
      this.scrollDiv.nativeElement.classList.add('scroll-bounce-top');
    } else {
      this.scrollDiv.nativeElement.classList.remove('scroll-bounce-top');
    }

    if (
      this._difference(
        event.srcElement.scrollTop,
        event.srcElement.scrollHeight - event.srcElement.offsetHeight
      ) <= (this.bottom || 5)
    ) {
      this.scrollDiv.nativeElement.classList.add('scroll-bounce-bottom');
    } else {
      this.scrollDiv.nativeElement.classList.remove('scroll-bounce-bottom');
    }

    this.currentVerticalPosition = scrollTop;
  }

  /**
   * Suscripcion al evento infinite scroll.
   * @returns Observable.
   */
  public onInfiniteScroll() {
    return this._infiniteScroll.pipe(debounceTime(600));
  }

  /**
   * Valida si el contenedor de la tabla está abajo.
   * @param event - Evento Scroll.
   */
  private _valideBottomPosition(event: any) {
    const obj = event.srcElement;
    if (
      this._difference(obj.scrollTop, obj.scrollHeight - obj.offsetHeight) <=
      (this.bottom || 5)
    ) {
      if (!this.loading) this.loading = true;
      this._boottomDelay.next();
      this._infiniteScroll.next();
    }
  }

  /**
   * Validar diferencia entre dos numeros.
   * @param num1 - Primer numero.
   * @param num2 - Segundo numero.
   * @returns Number.
   */
  private _difference(num1: number, num2: number) {
    return num1 > num2 ? 0 : num2 - num1;
  }
}
