import { createAction, props } from '@ngrx/store';
import { PendingTaskPost } from '../../../../models/post-data.models';
import { Form } from '../../../../process/models';

export const SchemaAnalysis = createAction(
  '[SchemaAnalysis] Formularios analisis',
  props<{
    /**
     * Parametros para envio del servicio.
     */
    processData: PendingTaskPost;
  }>()
);

export const SchemaAnalysisSuccess = createAction(
  '[SchemaAnalysis] Formularios para analisis exitoso',
  props<{
    /**
     * Formularios para el proceso.
     */
    formsProcess: Form[];
  }>()
);

export const SchemaAnalysisError = createAction(
  '[SchemaAnalysis] Formularios para analisis error',
  props<{
    /**
     * Payload error.
     */
    payload: any;
  }>()
);

export const SchemaAnalysisEditSuccess = createAction(
  '[SchemaAnalysisEdit] Formularios editar para analisis credito exitoso',
  props<{
    /**
     * Formularios para editar en el proceso.
     */
    formsEdit: any[];
  }>()
);

export const SchemaAnalysisEditError = createAction(
  '[SchemaAnalysisEdit] Formularios editar para analisis credito error',
  props<{
    /**
     * Payload error.
     */
    payload: any;
  }>()
);

export const ResetSchemaAnalysis = createAction(
  '[ResetSchemaAnalysis] Reiniciar el estado'
);
