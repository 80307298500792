import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Catalogue, Enviroment } from '@wp-back-office/core/commons-backoffice';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseAWS } from '../models/response-aws.model';
/**
 * Servicio de planes financieros.
 */
@Injectable()
export class TractOptionsService {
  /**
   * Crea una instancia de TractOptionsService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}
  /**
   * Obtiene la lista de planes financieros.
   * @param code_amortization - Codigo de amortización.
   * @param description - Codigo de concesionario.
   * @param page - Pagina.
   * @param pageSize - Tamaño de la pagina.
   * @returns - Observable.
   */
  public getTractOptionsList(
    code_amortization: number,
    description?: string,
    page?: number,
    pageSize?: number | string
  ): Observable<Catalogue[]> {
    let params = {};
    if (description !== undefined) {
      params = { ...params, description };
    }
    if (page !== undefined) {
      params = { ...params, page };
    }
    if (pageSize !== undefined) {
      params = { ...params, pageSize };
    }
    this.enviroment.endpoints.url;
    return this.http
      .get<ResponseAWS<TractOption>>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/amortization-option-tract/amortization/${code_amortization}`,
        {
          params: params,
        }
      )
      .pipe(
        map(response =>
          response.items.map(item => ({
            code: item.code_amortization_options_tracs.toString(),
            description: item.description,
          }))
        )
      );
  }
}
/**
 *{AGREGUE DOCUMENTACION}.
 */
export interface TractOption {
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  code_amortization: number;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  code_amortization_options_tracs: number;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  description: string;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  created_date: Date;
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  updated_date: Date;
}
