import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  Catalogue,
  CatalogueTasksService,
} from '@wp-back-office/core/commons-backoffice';
import { mergeMap, map, catchError, of } from 'rxjs';
import * as actionsEffects from '../../actions';

/**
 * Servicio de catalogue.
 */
@Injectable()
export class KeyValueAmortizationCreditsEffects {
  /**
   * Crea una instancia de .
   * @param actions$ - Acciones.
   * @param catalogueTasksService - Servicio de catalogo.
   */
  constructor(
    private actions$: Actions,
    private catalogueTasksService: CatalogueTasksService
  ) {}

  /**
   *
   */
  public keyValueAmortizationCredits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.KeyValueAmortizationCredits),
      mergeMap(action =>
        this.catalogueTasksService.getCataloguePAS(action.path).pipe(
          map((keyValue: Catalogue[]) =>
            actionsEffects.KeyValueAmortizationCreditsSuccess({ keyValue })
          ),
          catchError(err =>
            of(
              actionsEffects.KeyValueAmortizationCreditsError({
                payload: err,
              })
            )
          )
        )
      )
    )
  );
}
