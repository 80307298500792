import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';
import { AuditList } from 'libs/app/dashboard/src/lib/models/audit-list.model';
import { AuditDetail } from 'libs/app/dashboard/src/lib/models/audit-detail.model';
import { AuditHistory } from 'libs/app/dashboard/src/lib/models/audit-summary.model';
import { map, Observable } from 'rxjs';

/**
 * Servicio para auditar procesos.
 */
@Injectable()
export class AuditProcessesService {
  /**
   * Crea una instancia de AuditProcessesService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) { }
  /**
   * Obtiene procesos para auditoria.
   * @param filterType - Tipo de filtro.
   * @param filter - Valor a filtrar.
   * @param pageSize - Tamaño de la pagina.
   * @param paginationToken - Token para la paginacion.
   * @returns - Observable.
   */
  public getAuditProcesses(
    filterType: 'documentNumber' | 'executionId' | 'externalId',
    filter: string,
    pageSize?: number,
    paginationToken?: string
  ): Observable<AuditList> {
    const url = `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/audit-process/${filterType}/${filter}`;
    let params = {};
    if (pageSize) {
      params = { ...params, pageSize };
    }
    if (paginationToken) {
      params = { ...params, paginationToken };
    }
    return this.http
      .get<AuditList>(url, {
        params,
      })
      .pipe(
        map(response => {
          return {
            ...response,
            filterType: filterType,
            filter: filter,
            items: response.items.map(item => {
              return {
                ...item,
                startDate: item.startDate
                  ? new Date(item.startDate as number)
                  : null,
                stopDate: item.stopDate
                  ? new Date(item.stopDate as number)
                  : null,
              };
            }),
          };
        })
      );
  }
  /**
   * Obtiene el detalle de los procesos para auditoria.
   * @param nameProcess - Nombre del proceso.
   * @param executionId - Id de la ejecución.
   * @returns - Observable.
   */
  public GetAuditDetail(
    nameProcess: string,
    executionId: string
  ): Observable<AuditDetail> {
    const url = `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/audit-process/${nameProcess}/${executionId}/details`;
    return this.http.get<AuditDetail>(url);
  }
  /**
   * Obtiene el histórico de la tarea.
   * @param executionId - Id de la ejecución.
   * @returns - Observable.
   */
  public GetAuditHistory(
    executionId: string
  ): Observable<AuditHistory> {
    const url = `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/audit-process/${executionId}/history`;
    return this.http.get<AuditHistory>(url);
  }
}
