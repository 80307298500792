import { Action, createReducer, on } from '@ngrx/store';
import { Catalogue } from '@wp-back-office/core/commons-backoffice';
import { ErrorHttp } from '../../../../models/errors.models';

import { resetInitialState } from '../../../../root/store/actions/contents/reset-initial-state.actions';
import {
  getCatalogueGeneral,
  getCatalogueGeneralError,
  getCatalogueGeneralSuccess,
} from '../../actions/contents/catalogue-general.actions';
import { jParse, jStringify } from '@wp-back-office/app/global-information';

/**
 * Estado llave valor.
 */
export interface getCatalogueGeneralState {
  /**
   * Catalogos.
   */
  catalogues?: any;
}

export const GetCatalogueGeneralStatusInitialState: getCatalogueGeneralState = {
  catalogues: undefined,
};

const featureReducer = createReducer(
  GetCatalogueGeneralStatusInitialState,
  on(resetInitialState, () => ({
    ...GetCatalogueGeneralStatusInitialState,
  })),
  on(getCatalogueGeneralSuccess, (state, { keyValue, path }) => {
    const obj = jParse(`{"${path}": ${jStringify(keyValue)} }`);
    const _state = {
      ...state,
      catalogues: {
        ...state.catalogues,
        ...obj,
      },
    };
    return _state;
  }),
  on(getCatalogueGeneralError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

/**
 * Reductor de .
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function getCatalogueGeneralStateReducer(
  state: getCatalogueGeneralState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
