import { Action, createReducer, on } from '@ngrx/store';
import {
  PoolUser,
  PoolUserError,
  PoolUserSuccess,
  refreshToken,
  refreshTokenError,
  refreshTokenSuccess,
} from '../../actions';
import { resetInitialState } from '../../../../root/store/actions/contents/reset-initial-state.actions';
import { ErrorHttp } from '../../../../models/errors.models';

/**
 * Estado de usuario actual.
 */
export interface CurrentPoolUserState {
  /**
   * Token.
   */
  token: string;
  /**
   * Bandera de cargado.
   */
  loaded: boolean;
  /**
   * Bandera de cargando.
   */
  loading: boolean;
  /**
   * Errores servicio.
   */
  error?: ErrorHttp;
}

export const currentPoolUserInitialState: CurrentPoolUserState = {
  token: '',
  loaded: false,
  loading: false,
  error: undefined,
};

const featureReducer = createReducer(
  currentPoolUserInitialState,
  on(resetInitialState, () => ({ ...currentPoolUserInitialState })),
  on(PoolUser, state => ({ ...state, loading: true })),
  on(PoolUserSuccess, (state, { token }) => ({
    ...state,
    token,
    loading: false,
    loaded: true,
  })),
  on(PoolUserError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
  on(refreshToken, state => ({ ...state, loading: true })),
  on(refreshTokenSuccess, (state, { token }) => ({
    ...state,
    token,
    loading: false,
    loaded: true,
  })),
  on(refreshTokenError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

/**
 * Reductor de usuario actual.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function CurrentPoolUserReducer(
  state: CurrentPoolUserState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
