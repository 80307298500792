import { Pipe, PipeTransform } from '@angular/core';
/**
 * Pipe ejemplo 2.
 */
@Pipe({
  name: 'pipeExample2',
})
export class PipeExample2Pipe implements PipeTransform {
  /**
   * Metodo transform para un valor del pipeline.
   * @returns Null.
   */
  public transform(): any {
    return null;
  }
}
