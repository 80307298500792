import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedGenericComponentsModule } from '@wp-back-office/shared/generic-components';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DynamicFormService } from './services/dynamic-form.service';
import { TemplateGeographicLocationComponent } from './components/templates/template-geographic-location/template-geographic-location.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TemplateGeographicLocationService } from './services/template-geographic-location.service';
import { ValidatorTemplateGeographicLocationDirective } from './directives/validator-template-geographic-location.directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TemplateControlDependenceComponent } from './components/templates/template-control-dependence/template-control-dependence.component';
import { TemplateAddressLocationComponent } from './components/templates/template-address-location/template-address-location.component';
import { MatCardModule } from '@angular/material/card';
import { TemplateAddressLocationService } from './services/template-address-location.service';
import { FormControlTextComponent } from './components/components/controls/form-control-text/form-control-text.component';
import { FormControlTextAreaComponent } from './components/components/controls/form-control-text-area/form-control-text-area.component';
import { FormControlCodeVerificationComponent } from './components/components/controls/form-control-code-verification/form-control-code-verification.component';
import { FormControlCurrencyComponent } from './components/components/controls/form-control-currency/form-control-currency.component';
import { FormControlDatePickerComponent } from './components/components/controls/form-control-date-picker/form-control-date-picker.component';
import { FormControlFileComponent } from './components/components/controls/form-control-file/form-control-file.component';
import { FormControlDropDownComponent } from './components/components/controls/form-control-drop-down/form-control-drop-down.component';
import { FormControlRadioButtonComponent } from './components/components/controls/form-control-radio-button/form-control-radio-button.component';
import { FormControlCheckBoxComponent } from './components/components/controls/form-control-check-box/form-control-check-box.component';
import { FormControlBottonToggleGroupComponent } from './components/components/controls/form-control-botton-toggle-group/form-control-botton-toggle-group.component';
import { FormControlAutocompleteComponent } from './components/components/controls/form-control-autocomplete/form-control-autocomplete.component';
import { FormControlDateTimeComponent } from './components/components/controls/form-control-date-time/form-control-date-time.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { DynamicButtonModule } from '../dynamic-button';
import { DynamicHeaderTableModule } from '../dynamic-header-table';
import { MatDialogModule } from '@angular/material/dialog';
import { DynamicDialogModule } from '../dynamic-dialog-message';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { DynamicTableCrudComponent } from './components/components/dynamic-table-crud/dynamic-table-crud.component';
import { DialogAddDataComponent } from './components/components/modals/dialog-add-data/dialog-add-data.component';
import { DynamicTableService } from './services/dynamic-table.service';
import { DynamicSnackbarModule } from '../dynamic-snackbar';
import { HighlightPipe } from './pipes/mat-table-highlight-search.pipe';
import { ToggleColumnsPipe } from './pipes/toggle-columns.pipe';
import { FilterArrayPipe } from './pipes/filter-array.pipe';
import { DynamicDialogFormComponent } from './components/components/modals/dynamic-dialog-form/dynamic-dialog-form.component';
import { MatListModule } from '@angular/material/list';
import { FormControlTableComponent } from './components/components/controls/form-control-table/form-control-table.component';
import { CdkTableModule } from '@angular/cdk/table';
import { OnlyNumbersDecimalsDirective } from './directives/only-numbers.directive';
import { InfiniteScrollComponent } from './components/infinite-scroll/infinite-scroll.component';
import { TextTransformInputDirective } from './directives/uppercase-lowercase.directive';

/**
 * Modulo de formularios dinamicos.
 */
@NgModule({
  declarations: [
    DynamicFormComponent,
    TemplateGeographicLocationComponent,
    ValidatorTemplateGeographicLocationDirective,
    OnlyNumbersDecimalsDirective,
    TextTransformInputDirective,
    TemplateControlDependenceComponent,
    TemplateAddressLocationComponent,

    FormControlTextComponent,
    FormControlTextAreaComponent,
    FormControlCodeVerificationComponent,
    FormControlCurrencyComponent,
    FormControlDatePickerComponent,
    FormControlDateTimeComponent,
    FormControlFileComponent,
    FormControlDropDownComponent,
    FormControlRadioButtonComponent,
    FormControlCheckBoxComponent,
    FormControlBottonToggleGroupComponent,
    FormControlAutocompleteComponent,
    FormControlTableComponent,

    DynamicTableComponent,
    HighlightPipe,
    ToggleColumnsPipe,
    FilterArrayPipe,
    DynamicTableCrudComponent,
    DialogAddDataComponent,
    DynamicDialogFormComponent,
    InfiniteScrollComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    NgxSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    SharedGenericComponentsModule,
    MatCardModule,
    CdkTableModule,

    NgxCurrencyModule,
    ScrollingModule,
    MatInputModule,
    MatStepperModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDividerModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatChipsModule,
    ImageCropperModule,
    MatSelectInfiniteScrollModule,
    MatAutocompleteModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    ClipboardModule,
    DynamicButtonModule,
    DynamicSnackbarModule,

    DynamicHeaderTableModule,
    DynamicDialogModule,
  ],
  exports: [
    DynamicFormComponent,
    TemplateGeographicLocationComponent,
    ValidatorTemplateGeographicLocationDirective,
    OnlyNumbersDecimalsDirective,

    TextTransformInputDirective,
    TemplateControlDependenceComponent,
    TemplateAddressLocationComponent,

    FormControlTextComponent,
    FormControlTextAreaComponent,
    FormControlCodeVerificationComponent,
    FormControlCurrencyComponent,
    FormControlDatePickerComponent,
    FormControlDateTimeComponent,
    FormControlFileComponent,
    FormControlDropDownComponent,
    FormControlRadioButtonComponent,
    FormControlCheckBoxComponent,
    FormControlBottonToggleGroupComponent,
    FormControlAutocompleteComponent,
    FormControlTableComponent,

    DynamicTableComponent,
    HighlightPipe,
    ToggleColumnsPipe,
    FilterArrayPipe,
    DynamicTableCrudComponent,
    DialogAddDataComponent,
    DynamicDialogFormComponent,
    InfiniteScrollComponent,
  ],
  providers: [
    DynamicFormService,
    TemplateGeographicLocationService,
    TemplateAddressLocationService,
    DynamicTableService,
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
// eslint-disable-next-line @nrwl/nx/workspace/doc-elements-angular
export class DynamicFormModule {}
