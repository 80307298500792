import { FormatedTask } from '@wp-back-office/app/global-information';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const taskPendingActions = createActionGroup({
  source: 'taskPending',
  events: {
    /**
     * Obtener tareas.
     */
    'task Pending ': props<{
      /**
       * Tamaño.
       */
      size: number;
      /**
       * Objetivo.
       */
      target: string;
      /**
       * Proceso.
       */
      process: string;
      /**
       * Filtro.
       */
      filtro?: string;
      /**
       * Id.
       */
      id?: string;
      /**
       * LastKey.
       */
      lastKey?: string;
    }>(),

    /**
     * Obtener tareas.
     */
    'task Pending Reset': emptyProps,

    /**
     * Obtener tareas pendientes Exitoso.
     */
    'task Pending Success': props<{
      /**
       * Tarea pendiente.
       */
      taskPending: FormatedTask[];
      /**
       * LastKey.
       */
      lastKey?: string;
    }>(),

    /**
     * Obtener tarea pendiente Error.
     */
    'task Pending Error': props<{
      /**
       * Payload.
       */
      payload: any;
    }>(),

    /**
     * Eliminar tarea pendiente.
     */
    'task Pending Delete': props<{
      /**
       * Identificador.
       */
      id: string;
    }>(),

    /**
     * Eliminar tarea pendiente Exitoso.
     */
    'task Pending Delete Success': props<{
      /**
       * Datos.
       */
      data: any;
    }>(),

    /**
     * Eliminar tarea pendiente Error.
     */
    'task Pending Delete Error': props<{
      /**
       * Payload.
       */
      payload: any;
    }>(),

    /**
     * Buscar por id tarea pendiente.
     */
    'task Pending By Id': props<{
      /**
       * Identificador.
       */
      id: string;
    }>(),

    /**
     * Buscar por id tarea pendiente Exitoso.
     */
    'task Pending By Id Success': props<{
      /**
       * Datos.
       */
      data: any;
    }>(),

    /**
     * Buscar por id tarea pendiente Error.
     */
    'task Pending By Id Error': props<{
      /**
       * Payload.
       */
      payload: any;
    }>(),

    /**
     * Obtener tareas de la pagina siguiente.
     */
    'task Pending Next': props<{
      /**
       * Tamaño.
       */
      size: number;
      /**
       * Objetivo.
       */
      target: string;
      /**
       * Proceso.
       */
      process: string;
    }>(),

    /**
     * Obtener tareas de la pagina siguiente Exitoso.
     */
    'task Pending Next Success': props<{
      /**
       * Tareas pendientes.
       */
      taskPending: FormatedTask[];
      /**
       * Siguiente llave.
       */
      nextKey: string;
    }>(),

    /**
     * Obtener tareas de la pagina siguiente Error.
     */
    'task Pending Next Error': props<{
      /**
       * Payload.
       */
      payload: any;
    }>(),

    /**
     * Actualizar por id tarea pendiente.
     */
    'task Pending Update': props<{
      /**
       * Identificador.
       */
      id: string;
      /**
       * Estado,.
       */
      status: string;
    }>(),

    /**
     * Actualizar por id tarea pendiente Exitoso.
     */
    'task Pending Update Success': props<{
      /**
       * Datos.
       */
      data: any;
    }>(),

    /**
     * Actualizar por id tarea pendiente Error.
     */
    'task Pending Update Error': props<{
      /**
       * Payload.
       */
      payload: any;
    }>(),
  },
});
