import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const queqeActions = createActionGroup({
  source: 'Queqe',
  events: {
    'obtener colas': emptyProps(),
    'obtener colas exitoso': props<{
      /**
       * Grupos.
       */
      response: any;
    }>(),
    'obtener colas error': emptyProps(),
  },
});
