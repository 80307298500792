import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { InputDocumentComponent } from './components/input-document/input-document.component';
import { InputDropdownComponent } from './components/input-dropdown/input-dropdown.component';
import { InputTextComponent } from './components/input-text/input-text.component';
import { MatSelectModule } from '@angular/material/select';
import { EditableInputTaskComponent } from './components/editable-input-task/editable-input-task.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { MatCardModule } from '@angular/material/card';
import { NgxCurrencyModule } from 'ngx-currency';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { PaletteGeneratorService } from './services/palette-generator.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

/**
 *{AGREGUE DOCUMENTACION}.
 */
@NgModule({
  declarations: [
    InputDocumentComponent,
    InputDropdownComponent,
    InputTextComponent,
    EditableInputTaskComponent,
    FooterComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgxCurrencyModule,
    FlexLayoutModule,
    ScrollingModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    MatStepperModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatButtonModule,
    MatTooltipModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatFormFieldModule,
    ImageCropperModule,
    MatSelectInfiniteScrollModule,
    MatAutocompleteModule,
  ],
  exports: [
    InputDocumentComponent,
    InputDropdownComponent,
    InputTextComponent,
    EditableInputTaskComponent,
    FooterComponent,
  ],
  providers: [PaletteGeneratorService],
})
export class SharedGenericComponentsModule {}
