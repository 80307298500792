import { Directive, ViewContainerRef } from '@angular/core';

/**
 * Directiva para cracion de componentes dinamicos.
 */
@Directive({
  selector: '[wpBackOfficeFactoryHost]',
})
export class FactoryHostDirective {
  /**
   * Crea una instanica de FactoryHostDirective.
   * @param viewContainerRef - Vista del contenedor.
   */
  constructor(public viewContainerRef: ViewContainerRef) {}
}
