import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Feature, FeatureValue } from '../models/feature.model';
import { ResponseAWS } from '../models/response-aws.model';
/**
 * Servicio de planes financieros.
 */
@Injectable()
export class FeaturesService {
  /**
   * Crea una instancia de WorkflowService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}
  /**
   * Obtiene la lista de planes financieros.
   * @param code - Codigo de amortizacion.
   * @returns - Observable.
   */
  public getFeaturesList(code: number): Observable<FeatureValue[]> {
    this.enviroment.endpoints.url;
    return this.http
      .get<ResponseAWS<FeatureValue>>(
        `${this.enviroment.endpoints.urlPAS}/${this.enviroment.common.tenantId}/financial-plan/characteristics/${code}`
      )
      .pipe(map(response => response.items));
  }
}
