import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Catalogue } from '@wp-back-office/core/commons-backoffice';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';

export const tractOptionsActions = createActionGroup({
  source: 'Tract Options',
  events: {
    Reset: emptyProps(),
    'Get List': props<{
      /**
       *
       */
      code_amortization: number;
      /**
       * Codigo de red concesionario.
       */
      description?: string;
      /**
       * Pagina.
       */
      page?: number;
      /**
       * Tamaño de la pagina.
       */
      pageSize?: number;
    }>(),
    Success: props<{
      /**
       * Tramos.
       */
      catalogue: Catalogue[];
    }>(),
    Error: props<{
      /**
       * Respuesta error Http.
       */
      error: ErrorHttp;
    }>(),
  },
});
