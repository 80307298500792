import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WorkflowService } from '@wp-back-office/core/workflow-sdk';
import { map, catchError, of, switchMap } from 'rxjs';

import * as actionsEffects from '../../actions';

/**
 *
 */
@Injectable()
export class SchemaEffects {
  /**
   * Crea una instancia StartProcessEffects.
   * @param actions$ - Actions.
   * @param workflowService - Servicio work flow.
   */
  constructor(
    private actions$: Actions,
    private workflowService: WorkflowService
  ) {}

  /**
   * Efecto para cargar los formularios del proceso.
   */
  public Schema$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.SchemaAnalysis),
      switchMap(({ processData }) => {
        return this.workflowService.getFormTask(processData).pipe(
          map((object: any) =>
            actionsEffects.SchemaAnalysisSuccess({ formsProcess: object.forms })
          ),
          catchError(error =>
            of(actionsEffects.SchemaAnalysisError({ payload: error }))
          )
        );
      })
    )
  );

  /**
   * Efecto para cargar los formularios de edicion del efecto.
   */
  public SchemaEdited$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.SchemaAnalysis),
      switchMap(({ processData }) => {
        return this.workflowService
          .changesProcessById(processData.process || '', processData.task || '')
          .pipe(
            map((object: any) =>
              actionsEffects.SchemaAnalysisEditSuccess({
                formsEdit: object.items,
              })
            ),
            catchError(error =>
              of(actionsEffects.SchemaAnalysisEditError({ payload: error }))
            )
          );
      })
    )
  );
}
