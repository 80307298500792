/* eslint-disable jsdoc/require-description */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { SignInService } from '@wp-back-office/app/user-authentication';
import {
  mergeMap,
  map,
  catchError,
  of,
  from,
  withLatestFrom,
  filter,
} from 'rxjs';
import * as actions from '../../actions';
import { createSelectorInject } from '../../app.reducers';
import { DashBoardState } from 'apps/back-office/src/app/screens/dashboard/store/dashboard.reducers';

/**
 * Estado.
 */
export interface AuthState {
  /**
   *
   */
  isLoggedIn?: boolean;
  /**
   *
   */
  username?: string | null;
  /**
   *
   */
  id?: string | null;
  /**
   *
   */
  email?: string | null;
}

@Injectable()
export class LogginEffects {
  /**
   * Crea una instancia de LogginEffects.
   * @param actions$ - Actions.
   * @param signInService - Servicio logueo.
   */
  constructor(
    private actions$: Actions,
    private signInService: SignInService,
    private storeDashboard: Store<DashBoardState>
  ) {}

  /**
   *
   */
  public StartLoggin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.Loggin),
      mergeMap(params =>
        from(this.signInService.signInAmplify(params.signInInformation)).pipe(
          map(() => {
            return actions.LogginSuccess({ isLoggin: true });
          }),
          catchError(err => {
            return of(actions.LogginError({ payload: err }));
          })
        )
      )
    );
  });

  /**
   * Efecto para refrescar el Token.
   */
  public refreshToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.refreshToken),
      withLatestFrom(
        this.storeDashboard
          .select(createSelectorInject('session', 'dashboard'))
          .pipe(
            filter(
              state =>
                (state.loading || false) === false &&
                (state.loaded || false) === true
            )
          )
      ),
      mergeMap(([, data]) => {
        data.object.refreshToken.token;
        return of().pipe(
          map(() => {
            return actions.refreshTokenSuccess({
              token: data.object.refreshToken.token,
            });
          }),
          catchError(err => {
            return of(actions.refreshTokenError({ payload: err }));
          })
        );
      })
    );
  });
}
