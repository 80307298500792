<form
  [formGroup]="addressLocationForm"
  class="d-flex flex-wrap"
  autocomplete="off"
  *ngIf="controls">
  <mat-card-subtitle class="subtitle">
    {{ control?.label || '' }}
  </mat-card-subtitle>

  <mat-icon
    class="subtitle"
    [hidden]="control?.validators?.readonly || false"
    [matTooltip]="toolTip"
    >info</mat-icon
  >

  <ng-container class="d-flex" *ngFor="let _control of controls">
    <ng-container *ngIf="!_control.type">
      <b
        [hidden]="control?.validators?.readonly || false"
        class="px-2 h-auto label-address d-flex justify-content-center align-items-center">
        {{ _control.label }}
      </b>
    </ng-container>

    <ng-container *ngIf="_control.type">
      <ng-container [ngSwitch]="_control.type">
        <ng-container *ngSwitchCase="'Text'">
          <div
            [hidden]="control?.validators?.readonly || false"
            [class.input-readonly]="_control.validators?.readonly || false"
            class="input-spacing"
            [class.flex-basis-100]="_control.key === 'complement'"
            [class.input-address]="_control.key !== 'complement'"
            [class.dist]="_control.key === 'dist'">
            <wp-back-office-form-control-text
              [formControlName]="_control.key?.trim() || ''"
              [uniqueIdForm]="_control.key === 'complement' ? uniqueIdForm : ''"
              [control]="_control"
              [typeControl]="'text'">
            </wp-back-office-form-control-text>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'Number'">
          <div
            [hidden]="control?.validators?.readonly || false"
            [class.input-readonly]="_control.validators?.readonly || false"
            class="input-spacing input-address">
            <wp-back-office-form-control-text
              [formControlName]="_control.key?.trim() || ''"
              [control]="_control"
              [typeControl]="'number'">
            </wp-back-office-form-control-text>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'TextArea'">
          <div
            [class.input-readonly]="_control.validators?.readonly || false"
            class="input-spacing flex-basis-100">
            <wp-back-office-form-control-text-area
              [formControlName]="_control.key?.trim() || ''"
              [control]="_control">
            </wp-back-office-form-control-text-area>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'DropDown'">
          <div
            [hidden]="control?.validators?.readonly || false"
            [class.input-readonly]="_control.validators?.readonly || false"
            class="input-spacing flex-basis-100"
            [class.input-via]="_control.key === 'via'"
            [class.flex-fill]="
              _control.key === 'sector' || _control.key === 'sector1'
            "
            [class.input-address]="
              _control.key === 'sector' || _control.key === 'sector1'
            ">
            <wp-back-office-form-control-drop-down
              [formControlName]="_control.key?.trim() || ''"
              [control]="_control">
            </wp-back-office-form-control-drop-down>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'Radio'">
          <div
            [hidden]="control?.validators?.readonly || false"
            [class.input-readonly]="_control.validators?.readonly || false"
            class="input-spacing flex-basis-100">
            <wp-back-office-form-control-radio-button
              [formControlName]="_control.key?.trim() || ''"
              [control]="_control"
              [horizontal]="true">
            </wp-back-office-form-control-radio-button>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</form>
