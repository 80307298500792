import { Action, createReducer, on } from '@ngrx/store';
import { FormatedTask } from '@wp-back-office/app/global-information';
import { userProcessActions } from '../../actions/contents/user-process.actions';
import { resetInitialState } from '@wp-back-office/core/store';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';

/**
 * User tasks state.
 */
export interface UserProcessState {
  /**
   * User tasks.
   */
  userProcess: FormatedTask[];
  /**
   * Paginado.
   */
  firstKey: string;
  /**
   * Paginado.
   */
  lastKey: string;
  /**
   * Bandera de carga.
   */
  loaded: boolean;
  /**
   * Bandera de carga.
   */
  loading: boolean;
  /**
   * Error.
   */
  error?: ErrorHttp;
}

export const userProcessInitialState: UserProcessState = {
  userProcess: [],
  firstKey: '',
  lastKey: '',
  loaded: false,
  loading: false,
  error: undefined,
};

const featureReducer = createReducer(
  userProcessInitialState,
  on(resetInitialState, () => ({ ...userProcessInitialState })),
  on(userProcessActions.userProcessReset, () => ({ ...userProcessInitialState })),
  on(userProcessActions.userProcess, state => ({ ...state, loading: true })),
  on(
    userProcessActions.userProcessSuccess,
    (state, { userProcess, lastKey }) => ({
      ...state,
      userProcess: [...userProcess],
      loading: false,
      loaded: true,
      lastKey: lastKey || '',
      error: undefined,
    })
  ),
  on(userProcessActions.userProcessError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      url: payload?.url,
      name: payload?.error?.internalCode,
      message: payload?.error?.message,
    },
  })),
  on(userProcessActions.userProcessNext, state => ({
    ...state,
    loading: true,
  })),
  on(
    userProcessActions.userProcessNextSuccess,
    (state, { userProcess, nextKey }) => ({
      ...state,
      userProcess: [...userProcess],
      lastKey: nextKey,
      loading: false,
      loaded: true,
      error: undefined,
    })
  ),
  on(userProcessActions.userProcessNextError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
  on(userProcessActions.userProcessDelete, state => ({
    ...state,
    loading: true,
  })),
  on(userProcessActions.userProcessDeleteSuccess, state => ({
    ...state,
    loading: false,
    loaded: true,
    error: undefined,
  })),
  on(userProcessActions.userProcessDeleteError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
  on(userProcessActions.userProcessFindId, state => ({
    ...state,
    loading: true,
  })),
  on(userProcessActions.userProcessFindIdSuccess, state => ({
    ...state,
    loading: false,
    loaded: true,
    error: undefined,
  })),
  on(userProcessActions.userProcessFindIdError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
  on(userProcessActions.userProcessUpdate, state => ({
    ...state,
    loading: true,
  })),
  on(userProcessActions.userProcessUpdateSuccess, state => ({
    ...state,
    loading: false,
    loaded: true,
    error: undefined,
  })),
  on(userProcessActions.userProcessUpdateError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
  on(userProcessActions.userProcessReassign, state => ({
    ...state,
    loading: true,
    loaded: false,
    error: undefined,
  })),
  on(userProcessActions.userProcessReassignSuccess, state => ({
    ...state,
    loading: false,
    loaded: true,
    error: undefined,
  })),
  on(userProcessActions.userProcessReassignError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.internalCode,
      message: payload.message,
    },
  }))
);

/**
 * Reductor de logueo.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function UserProcessReducer(
  state: UserProcessState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
