<mat-card class="mb-4 sale-info dynamic-header-card">
  <mat-card-header class="d-flex component-header" *ngIf="title">
    <mat-card-title> {{ title }} </mat-card-title>
  </mat-card-header>

  <button
    *ngIf="isCreate || disableButton"
    mat-flat-button
    class="button-config my-2"
    [disabled]="disableButton"
    [color]="'accent'"
    onclick="this.blur()"
    (click)="onCreate()">
    <mat-icon class="iconStart"> {{ icon }} </mat-icon>
    {{ titleButtonCreate }}
  </button>

  <button
    *ngIf="isUpload"
    mat-flat-button
    class="button-config my-2"
    [disabled]="disableButton"
    [color]="'accent'"
    onclick="this.blur()"
    (click)="onUpload()">
    <mat-icon class="iconStart"> {{ iconUpload }} </mat-icon>
    {{ titleButtonUpload }}
  </button>

  <button
    *ngIf="updateButton"
    class="p-0"
    mat-fab
    color="accent"
    (click)="update.emit()"
    matTooltip="Actualizar">
    <mat-icon>update</mat-icon>
  </button>

  <mat-card-content class="component-content" *ngIf="subtitle">
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-content>
</mat-card>
