import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AddOptions,
  ColumnValidators,
  EditOption,
  ElementColor,
  ElementTable,
  EventEmit,
  FilterTableEmit,
  InputModel,
  TableButtonAction,
  TableColumns,
  TableConfig,
  TableMargins,
  TableSelection,
} from '../../models/table-config.model';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { debounceTime, fromEvent, Subject, map, takeUntil, first } from 'rxjs';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { FormControl, FormGroup } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatDialog } from '@angular/material/dialog';
import { CurrencyMaskConfig, CurrencyMaskInputMode } from 'ngx-currency';
import { DynamicSnackBarService } from 'libs/shared/dynamic-components/src/dynamic-snackbar';
import { DynamicDialogMessageService } from 'libs/shared/dynamic-components/src/dynamic-dialog-message';
import { DynamicTableService } from '../../services/dynamic-table.service';
import { clone, isEqual } from 'lodash';
import { ThemePalette } from '@angular/material/core';
import { SelectionModel } from '@angular/cdk/collections';
import { InfiniteScrollComponent } from '../infinite-scroll/infinite-scroll.component';
import { MatSelect } from '@angular/material/select';
import {
  hasValue,
  removeDuplicated,
} from '@wp-back-office/app/global-information';

/**
 * Componente de tablas dinamicas.
 */
@Component({
  selector: 'wp-back-office-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
})
export class DynamicTableComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked
{
  /**
   * Identificador unico del formulario.
   */
  public uniqueIdTable: string;

  /**
   * Observable - Controles Base.
   */
  @Input()
  public set tableConfig(value: TableConfig | undefined) {
    if (value) {
      value.columns?.forEach((col: TableColumns, index: number) => {
        if (col.editOptions && col.editValidators?.type === 'autocomplete') {
          if (value.columns && typeof index === 'number' && index > -1) {
            value.columns[index].editValidators = {
              ...(value.columns[index].editValidators || {}),
              controlConfig: {
                type: 'AutoComplete',
                label: value.columns[index].header,
                key: value.columns[index].columnDef,
                options: value.columns[index].editOptions || [],
                col: {
                  xxl: 12,
                  xl: 12,
                  lg: 12,
                  md: 12,
                  sm: 12,
                  xs: 12,
                },
                autocompleteConfig: {
                  internalFilter: true,
                },
              },
            };
          }
        }
      });

      this.tableConfiguration = value;
      this.updateTableData();
    }
  }
  /**
   * Configuracion de de la tabla.
   */
  @Input()
  public tableConfiguration?: TableConfig;
  /**
   * Configuracion de de la tabla.
   */
  @Input()
  public tableFooterRow?: any;

  /**
   * Loader de la tabla.
   */
  @Input()
  public loading: boolean;
  /**
   * Loader de la tabla.
   */
  @Input()
  public inputEdit?: InputModel;
  /**
   * Loader de la tabla.
   */
  @Input()
  public inputView?: InputModel;
  /**
   * Evento acciones de la celda de la tabla.
   */
  @Output()
  public cellAction = new EventEmitter<TableButtonAction>();
  /**
   * Evento enable de los botones de la tabla.
   */
  @Output()
  public enable = new EventEmitter<TableButtonAction>();
  /**
   * Evento disable de los botones de la tabla.
   */
  @Output()
  public disable = new EventEmitter<TableButtonAction>();
  /**
   * Evento create de los botones de la tabla.
   */
  @Output()
  public create = new EventEmitter<TableButtonAction>();
  /**
   * Evento view de los botones de la tabla.
   */
  @Output()
  public view = new EventEmitter<TableButtonAction>();
  /**
   * Evento edit de los botones de la tabla.
   */
  @Output()
  public edit = new EventEmitter<TableButtonAction>();
  /**
   * Evento edit de los botones de la tabla.
   */
  @Output()
  public customAction = new EventEmitter<TableButtonAction>();
  /**
   * Evento cellLink de los botones de la tabla.
   */
  @Output()
  public cellLink = new EventEmitter<TableButtonAction>();

  /**
   * Evento delete de los botones de la tabla.
   */
  @Output()
  public delete = new EventEmitter<TableButtonAction>();
  /**
   * Evento de scroll al final de la tabla.
   */
  @Output()
  public TableScrollBottom = new EventEmitter<undefined>();
  /**
   * Evento de scroll al inicio de la tabla.
   */
  @Output()
  public TableScrollTop = new EventEmitter<undefined>();
  /**
   * Evento de scroll al inicio de la tabla.
   */
  @Output()
  public filter = new EventEmitter<FilterTableEmit>();
  /**
   * Emite la data de la fila seleccionada.
   */
  @Output()
  public selectRow = new EventEmitter<EventEmit>();
  /**
   * Emite la data de la fila editada.
   */
  @Output()
  public editedRow = new EventEmitter<any>();
  /**
   * Emite la data de la fila editada.
   */
  @Output()
  public dataChanged = new EventEmitter<any>();
  /**
   * Evento acciones de la celda de la tabla.
   */
  @Output()
  public tableSelection = new EventEmitter<TableSelection>();
  /**
   * MatTable.
   */
  @ViewChild('table', { static: true })
  public matTable?: MatTable<any>;
  /**
   * MatTable.
   */
  @ViewChild('table')
  public table!: ElementTable;
  /**
   * Contenedor del MatTable.
   */
  @ViewChild('table_content')
  public tableContent!: ElementRef;

  /**
   * Div scroll.
   */
  @ViewChild('scrollDiv', {
    static: false,
    read: InfiniteScrollComponent,
  })
  public infiniteScrollComponent?: InfiniteScrollComponent;

  /**
   * Paginador de angular material.
   */
  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;
  /**
   * Sort de angular material.
   */
  @ViewChild(MatSort)
  public sort!: MatSort;
  /**
   * Input de busqueda.
   */
  @ViewChild('filterinput', { static: false })
  public filterInput!: MatInput;

  /**
   * Filter Select.
   */
  @ViewChild('filterSelect', { static: false })
  public filterSelect!: MatSelect;

  /**
   * Configuracion de de la tabla.
   */
  public dataTable: any[];
  /**
   * Propiedades de tabla angular material.
   */
  public dataSource: MatTableDataSource<any>;
  /**
   * Columnas mostradas.
   */
  public displayedColumns: string[];
  /**
   * Filtros por columna.
   */
  public filterByColumns!: boolean;
  /**
   * Opciones para filtrar.
   */
  public filterOptions!: EditOption[] | undefined;
  /**
   * Posicion vertical actual del contenido de la tabla.
   */
  private currentVerticalPosition!: any;
  /**
   * Posicion horizontal actual del contenido de la tabla.
   */
  private currentHorizontalPosition!: any;
  /**
   * Margen en la tabla modificada por el infinite scroll.
   */
  public tableMargin: TableMargins = {
    'margin-bottom.px': 0,
  };
  /**
   * Tiempo de espera para el evento scroll.
   */
  public scrollEventTimeout!: boolean;
  /**
   * FormGroup del fltro de la tabla.
   */
  public filterGroup!: FormGroup;
  /**
   * Destructor sujeto.
   */
  public destroy$ = new Subject();

  /**
   * Opciones del input currency.
   */
  public inputCurrencyOptins: CurrencyMaskConfig;

  /**
   * Opciones del input currency.
   */
  public inputCurrencyValue: any;

  /**
   * Seleccion.
   */
  public selection = new SelectionModel<any>(true, []);

  /**
   * Emitir la seleccion de la tabla.
   */
  public emitSelection: boolean;

  /**
   * Crea una instancia DynamicTableComponent.
   * @param _MatPaginatorIntl - Configuracion del paginador.
   * @param _tableService - Servicio de las tablas dinamicas.
   * @param dialog - Dialogs de angular material.
   * @param dynamicDialogMessageService - Servicio par la creación de dialogos.
   * @param dynamicSnackBarService - Servicio de snackbar.
   * @param cdRef - Detector de cambios.
   */
  constructor(
    public _MatPaginatorIntl: MatPaginatorIntl,
    public _tableService: DynamicTableService,
    public dialog: MatDialog,
    private dynamicDialogMessageService: DynamicDialogMessageService,
    private dynamicSnackBarService: DynamicSnackBarService,
    public cdRef: ChangeDetectorRef
  ) {
    this.displayedColumns = [];
    this.dataTable = [];
    this.dataSource = new MatTableDataSource();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.scrollEventTimeout = true;
    this.filterByColumns = false;
    this.loading = false;
    this.emitSelection = true;
    this.filterGroup = new FormGroup({
      column: new FormControl(null),
      value: new FormControl(null),
    });

    this.inputCurrencyOptins = {
      align: 'left',
      thousands: '.',
      precision: 0,
      nullable: false,
      decimal: ',',
      prefix: '$',
      allowNegative: false,
      allowZero: true,
      inputMode: CurrencyMaskInputMode.FINANCIAL,
      suffix: '',
    };
    this.uniqueIdTable = `Table_${this.generateTableId()}_`;
  }

  /**
   * Genera un id unico para la.
   * @returns String.
   */
  public generateTableId() {
    const caracteres =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let id = '';
    for (let i = 0; i < 10; i++) {
      const indice = Math.floor(Math.random() * caracteres.length);
      id += caracteres.charAt(indice);
    }
    return id;
  }

  /**
   * Se ejecuta al renderizar el componente.
   */
  public ngOnInit(): void {
    this._MatPaginatorIntl.itemsPerPageLabel = 'Elementos';
    this._MatPaginatorIntl.getRangeLabel = this._tableService.getRangeLabel;
    this.updateTableData();
  }

  /**
   * Se ejecuta al renderizar el componente.
   */
  public ngAfterViewInit(): void {
    this.infiniteScrollComponent
      ?.onInfiniteScroll()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.TableScrollBottom.emit();
      });

    this.filterGroup.controls['column'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const validator =
          this.tableConfiguration?.options?.filter?.filterByColumnSelect ||
          false;
        if (
          (this.filterByColumns && validator) ||
          (validator && !this.filterGroup.controls['column'].value)
        ) {
          this.filterGroup.controls['value'].disable();
        } else {
          if (validator) {
            this.dataSource.filterPredicate = (data: any, filter: string) => {
              const valueValide = `${
                JSON.stringify(
                  data[this.filterGroup.controls['column'].value?.columnDef]
                ) || ''
              }`;

              return (
                !filter ||
                valueValide
                  .trim()
                  .toLowerCase()
                  ?.includes(filter?.trim().toLowerCase())
              );
            };
          }
          this.filterGroup.controls['value'].enable();
        }
      });

    if (this.filterInput) {
      const _input: any = this.filterInput;
      const input$ = fromEvent(_input._inputValueAccessor, 'keyup');
      input$
        .pipe(
          map((x: any) => x.currentTarget.value),
          debounceTime(
            this.tableConfiguration?.options?.filter?.internalFilter ? 0 : 1000
          ),
          takeUntil(this.destroy$)
        )
        .subscribe((x: string) => {
          if (this.tableConfiguration?.options?.filter?.internalFilter) {
            this.dataSource.filter = x.trim().toLowerCase();
          } else {
            if (x.length > 0) {
              this.filterTable({ target: { value: x }, keyCode: 13 });
            } else {
              this.filterTable({ target: { value: '' }, keyCode: 'RESET' });
            }
          }
        });
    }

    if (this.filterSelect) {
      this.filterSelect.valueChange
        .pipe(takeUntil(this.destroy$))
        .subscribe((x: string) => {
          if (this.tableConfiguration?.options?.filter?.internalFilter) {
            this.dataSource.filter = x.trim().toLowerCase();
          } else {
            if (x.length > 0) {
              this.filterTable({ target: { value: x }, keyCode: 13 });
            } else {
              this.filterTable({ target: { value: '' }, keyCode: 'RESET' });
            }
          }
        });
    }

    this.selection.changed
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.emitTableSelection());
  }

  /**
   * Se ejecuta despues de renderizar el componente.
   */
  public ngAfterViewChecked(): void {
    if (this.tableConfiguration?.options?.sizes?.alwaysScrollVertical) {
      const marginBottom =
        this.infiniteScrollComponent?.scrollDiv.nativeElement.offsetHeight -
        this.table._elementRef.nativeElement.offsetHeight +
        30;
      this.tableMargin = {
        'margin-bottom.px': marginBottom >= 0 ? marginBottom : 0,
      };
    }
  }

  /**
   * Valida si estan seleccionadas todas las filas.
   * @returns - Boolean.
   */
  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /**
   * Alternar seleccion de filas.
   */
  public toggleAllRows(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.filteredData);
  }

  /**
   * Retorna el label del checkbox.
   * @param row - Fila.
   * @param index - Indice.
   * @returns String.
   */
  public checkboxLabel(row?: any, index?: number): string {
    if (!row) {
      return `${this.isAllSelected() ? 'Deseleccionar' : 'Seleccionar'} todo`;
    }
    return `${
      this.selection.isSelected(row) ? 'Deseleccionar' : 'Seleccionar'
    } fila ${typeof index === 'number' ? index + 1 : ''}`;
  }

  /**
   *Focus filter input.
   */
  public focusFilter() {
    setTimeout(() => {
      const column: TableColumns = this.filterGroup.controls['column']?.value;
      if (column?.filterOptions && column.filterOptions.length > 0) {
        this.filterOptions = column?.filterOptions;
      } else {
        this.filterOptions = undefined;
      }
      this.filterInput.focus();
    }, 100);
  }

  /**
   * Metodo que se ejecuta al destruir el componente.
   */
  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /**
   * Valida la direccion en la que se hace scroll.
   * @param event - Evento scroll.
   */
  public onScroll(event: any) {
    const scrollTop = event.srcElement.scrollTop;
    const scrollLeft = event.srcElement.scrollLeft;

    /**
     * Abajo con movimiento a la derecha.
     */
    if (
      scrollLeft > this.currentHorizontalPosition &&
      scrollTop > this.currentVerticalPosition
    ) {
      this._valideBottomPosition(event);
    }

    /**
     * Abajo con movimiento a la izquierda.
     */
    if (
      scrollLeft < this.currentHorizontalPosition &&
      scrollTop > this.currentVerticalPosition
    ) {
      this._valideBottomPosition(event);
    }

    /**
     * Abajo sin movimiento horizontal.
     */
    if (
      scrollLeft === this.currentHorizontalPosition &&
      scrollTop > this.currentVerticalPosition
    ) {
      this._valideBottomPosition(event);
    }

    /**
     * Arriba con movimiento a la derecha.
     */
    if (
      scrollLeft > this.currentHorizontalPosition &&
      scrollTop < this.currentVerticalPosition
    ) {
      this._valideTopPosition(event);
    }

    /**
     * Arriba con movimiento a la izquierda.
     */
    if (
      scrollLeft < this.currentHorizontalPosition &&
      scrollTop < this.currentVerticalPosition
    ) {
      this._valideTopPosition(event);
    }

    /**
     * Arriba sin movimiento horizontal.
     */
    if (
      scrollLeft === this.currentHorizontalPosition &&
      scrollTop < this.currentVerticalPosition
    ) {
      this._valideTopPosition(event);
    }
    this.currentHorizontalPosition = scrollLeft;
    this.currentVerticalPosition = scrollTop;
  }

  /**
   * Validar diferencia entre dos numeros.
   * @param num1 - Primer numero.
   * @param num2 - Segundo numero.
   * @returns Number.
   */
  public difference(num1: number, num2: number) {
    return num1 > num2 ? num1 - num2 : num2 - num1;
  }

  /**
   * Valida si el contenedor de la tabla está abajo.
   * @param event - Evento Scroll.
   */
  private _valideBottomPosition(event: any): void {
    const obj = event.srcElement;
    if (
      this.difference(obj.scrollTop, obj.scrollHeight - obj.offsetHeight) <= 200
    ) {
      if (this.scrollEventTimeout) {
        this.scrollEventTimeout = false;
        setTimeout(() => {
          this.scrollEventTimeout = true;
        }, 600);
        this.TableScrollBottom.emit();
      }
    }
  }

  /**
   * Valida si el contenedor de la tabla está arriba.
   * @param event - Evento Scroll.
   */
  private _valideTopPosition(event: any) {
    if (event.srcElement.scrollTop == 0) {
      this.TableScrollTop.emit();
    }
  }

  /**
   * Actualiza la tabla.
   */
  private updateTableData(): void {
    if (this.tableConfiguration) {
      this.displayedColumns = [];
      if (this.tableConfiguration.options?.selection) {
        this.displayedColumns.push('selection');
      }
      this.displayedColumns = [
        ...this.displayedColumns,
        ...(this.tableConfiguration.columns?.map(c => c.columnDef) || []),
      ];
      if (
        this.tableConfiguration.options?.actions ||
        this.tableConfiguration.options?.editableColumn
      ) {
        this.displayedColumns.push('actions');
      }
      this.setDataSource(this.tableConfiguration.data || [], true);
    }
  }

  /**
   * Metodo para filtrar la tabla.
   * @param event - Evento del input que contiene el valor del filtro.
   */
  public filterTable(event: any): void {
    const filterValue = this.filterGroup.value['value'];
    this.filter.emit({
      filterValue: event.keyCode === 'RESET' ? '' : filterValue,
      selectedColumn: this.filterGroup.value['column'] || null,
      keyCode: event.keyCode,
    });
  }

  /**
   * Activar y desactivar filtros por columna.
   */
  public toggleFilterByColumns(): void {
    this.filterByColumns = this.filterByColumns ? false : true;
  }

  /**
   * Metodo retablecer la tabla con nueva informacion sin conservar la vieja.
   * @param arrayObject - Arreglo de valores nuevos para la tabla.
   * @param emitChange - Emitir el cambio.
   * @param options - Opciones para agregar elementos a la tabla.
   */
  public onReplace<T>(
    arrayObject: T[],
    emitChange: boolean = true,
    options?: AddOptions
  ): void {
    const array = arrayObject.map(elem => clone(elem));
    this.dataTable = clone(array);
    this.tableConfiguration = {
      ...this.tableConfiguration,
      data: this.dataTable,
    };

    this.setDataSource(this.dataTable, emitChange, this.getAddOptions(options));
  }

  /**
   * Validar fila existente.
   * @param arrayObject - Arreglo.
   * @returns Arreglo.
   */
  private existingRow<T>(arrayObject: T[]): T[] {
    let dataAdd: T[] = [];
    if (
      this.tableConfiguration?.options?.uniqueColumnDef &&
      this.dataTable.length > 0
    ) {
      let message = ``;
      let existArray: T[] = [];
      arrayObject.forEach((newRow: any) => {
        const exist = this.dataTable.find((currentRow: any) => {
          const currentValue =
            currentRow[`${this.tableConfiguration?.options?.uniqueColumnDef}`];
          const newValue =
            newRow[`${this.tableConfiguration?.options?.uniqueColumnDef}`];

          return (
            (currentValue &&
              typeof currentValue === 'object' &&
              hasValue(currentValue?.code) &&
              isEqual(newValue?.code, currentValue?.code)) ||
            (hasValue(currentValue) && isEqual(newValue, currentValue))
          );
        });

        if (!exist) {
          dataAdd.push(newRow);
        } else {
          const newValue =
            newRow[`${this.tableConfiguration?.options?.uniqueColumnDef}`];

          if (typeof newValue === 'object' && hasValue(newValue?.description)) {
            existArray.push(newValue?.description);
          } else if (typeof newValue === 'object' && hasValue(newValue?.code)) {
            existArray.push(newValue?.code);
          } else {
            existArray.push(newValue);
          }
        }
      });

      if (existArray.length > 0) {
        existArray = removeDuplicated(existArray);
        const column =
          this.tableConfiguration?.columns?.find(
            col =>
              col.columnDef ===
              this.tableConfiguration?.options?.uniqueColumnDef
          )?.header || this.tableConfiguration?.options?.uniqueColumnDef;

        if (existArray.length === 1) {
          message = `El valor <b>${existArray}</b> <br> ya existe en la columna <b>${column}</b>.`;
        } else {
          let values = '';
          existArray.forEach((val, i) => {
            if (i === 0) {
              values += `${val}`;
            } else if (i === existArray.length - 1) {
              values += ` y ${val}`;
            } else {
              values += `, ${val}`;
            }
          });
          message = `Los valores <b>${values}</b> <br> ya existen en la columna <b>${column}</b>.`;
        }
        this.dynamicSnackBarService.Open(message, 'warning');
      }
    } else {
      dataAdd = arrayObject;
    }
    return dataAdd;
  }

  /**
   * Reotorna las opciones para agregar items en la tabla.
   * @param options - AddOptions.
   * @returns AddOptions.
   */
  private getAddOptions(options?: AddOptions): AddOptions {
    let keppPosition = true;
    let scrollDown = false;

    if (options?.scrollDown) {
      scrollDown = true;
      keppPosition = false;
    }

    if (
      typeof options?.keppPosition === 'boolean' &&
      options?.keppPosition === false
    ) {
      keppPosition = false;
    }

    return {
      keppPosition,
      scrollDown,
    };
  }

  /**
   * Metodo para agregar varios valores a la tabla.
   * @param arrayObject - Valores a agregar en la tabla.
   * @param emitChange - Emitir el cambio.
   * @param options - Opciones para agregar elementos a la tabla.
   */
  public onAdd<T>(
    arrayObject: T[],
    emitChange: boolean = true,
    options?: AddOptions
  ): void {
    const array = arrayObject.map(elem => clone(elem));

    const newArray = this.existingRow(array);

    this.dataTable
      ? (this.dataTable = [...(this.dataTable || []), ...(newArray || [])])
      : (this.dataTable = [...newArray]);
    this.tableConfiguration = {
      ...this.tableConfiguration,
      data: this.dataTable,
    };

    this.setDataSource(this.dataTable, emitChange, this.getAddOptions(options));
  }

  /**
   * Metodo para agregar un solo valor a la tabla.
   * @param object - Valor a agregar en la tabla.
   * @param emitChange - Emitir el cambio.
   * @param options - Opciones para agregar elementos a la tabla.
   */
  public onAddOne<T>(
    object: T,
    emitChange: boolean = true,
    options?: AddOptions
  ): void {
    const newArray = this.existingRow([object]);
    const array = newArray.map(elem => clone(elem));

    this.dataTable
      ? (this.dataTable = [...(this.dataTable || []), ...(array || [])])
      : (this.dataTable = [...array]);
    this.tableConfiguration = {
      ...this.tableConfiguration,
      data: this.dataTable,
    };
    this.setDataSource(this.dataTable, emitChange, this.getAddOptions(options));
  }

  /**
   * Metodo para agregar valores a tabla.
   * @param index - Valores a agregar en la tabla.
   * @param emitChange - Emitir el cambio.
   */
  public onDelete(index: number, emitChange: boolean = true): void {
    const newData = [...(this.dataSource.data || [])];
    newData.splice(index, 1);
    this.dataTable = newData;
    this.tableConfiguration = {
      ...this.tableConfiguration,
      data: this.dataTable,
    };
    this.setDataSource(this.dataTable || [], emitChange, {
      keppPosition: true,
    });
  }

  /**
   * Metodo para editar valores a tabla.
   * @param index - Indice a modificar.
   * @param row - Valores a agregar en la tabla.
   * @param emitChange - Emitir el cambio.
   */
  public onEdit(index: number, row: any, emitChange: boolean = true) {
    this.dataTable = this.dataSource.data;
    if (this.dataTable) {
      const newData = [...(this.dataSource.data || [])];
      newData[index] = row;
      this.dataTable = newData;
      this.tableConfiguration = {
        ...this.tableConfiguration,
        data: this.dataTable,
      };
      this.setDataSource(this.dataTable, emitChange, { keppPosition: true });
    }
  }

  /**
   * Asigna datos a la tabla y actializa el sort y paginador.
   * @param data - Datos a la tabla.
   * @param emitChange - Emitir el cambio.
   * @param options - Emitir el cambio.
   */
  public setDataSource(
    data: any[],
    emitChange: boolean,
    options?: AddOptions
  ): void {
    const scrollTop = options?.keppPosition
      ? this.infiniteScrollComponent?.scrollDiv.nativeElement.scrollTop || 0
      : options?.scrollDown
      ? this.infiniteScrollComponent?.scrollDiv.nativeElement.scrollHeight || 0
      : 0;
    if (this.tableFooterRow) {
      const i = data.findIndex(el => el.matFooterRow);
      if (i >= 0) data.splice(i, 1);
      data.push({ ...this.tableFooterRow, matFooterRow: true });
    }

    this.dataSource = new MatTableDataSource(
      clone(Object.assign(JSON.parse(JSON.stringify(data)))) || []
    );
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataTable = clone(data);

    if (emitChange) this.dataChanged.emit(this.dataTable);
    this.matTable?.renderRows();
    this.matTable?.updateStickyColumnStyles();
    if (this.infiniteScrollComponent?.scrollDiv.nativeElement) {
      this.infiniteScrollComponent.scrollDiv.nativeElement.scrollTo({
        top: scrollTop,
        behavior: 'instant',
      });
    }
    this.cdRef.detectChanges();
  }

  /**
   * Metodo para devolver los valores de la tabla.
   * @returns - Valores actuales de la tabla.
   */
  public getData(): any[] {
    return this.dataSource.data;
  }

  /**
   * Emite el evento click del boton, retorna al componente MouseEvent.
   * @param event - MouseEvent.
   * @param data - Row de la tabla.
   */
  public clickedRow(event: MouseEvent, data: any): void {
    this.selectRow.emit({ event, data });
  }

  /**
   * Metodo para enviar la accion enable.
   * @param row - Valor de la fila seleccionada.
   */
  public onButtonEnable(row: any): void {
    this.enable.emit({
      row: row,
    });
  }

  /**
   * Metodo para enviar la accion disable.
   * @param row - Valor de la fila seleccionada.
   */
  public onButtonDisable(row: any): void {
    this.disable.emit({
      row: row,
    });
  }

  /**
   * Metodo para enviar la accion disable.
   * @param row - Valor de la fila seleccionada.
   * @param action - Identificador del boton.
   * @param index - Index de la fila seleccion.
   * @param column - Columna de la tabla.
   */
  public onButtonCustomAction<T>(
    row: T,
    action: string,
    index: number,
    column?: TableColumns
  ): void {
    this.customAction.emit({
      row: row,
      action: action,
      index: index,
      columnDef: column?.columnDef,
    });
  }

  /**
   * Metodo para enviar la accion view.
   * @param row - Valor de la fila seleccionada.
   * @param index - Index de la fila seleccionada.
   */
  public onButtonView<T>(row: T, index: number): void {
    if (this.inputView) {
      const dialogRef = this.dialog.open(this.inputView.component, {
        disableClose: true,
        ...this.inputView.dialogConfig,
        data: this.inputView.dialogConfig.data
          ? {
              ...this.inputView.dialogConfig.data,
              row: row,
            }
          : row,
      });
      dialogRef.componentInstance.key = this.inputView.key;
      dialogRef.componentInstance.index = index;
    } else {
      this.view.emit({
        row: row,
        index: index,
      });
    }
  }

  /**
   * Metodo para enviar la accion edit.
   * @param row - Valor de la fila seleccionada.
   * @param index - Index de la fila seleccionada.
   */
  public onButtonEdit<T>(row: T, index: number): void {
    if (this.inputEdit) {
      const dialogRef = this.dialog.open(this.inputEdit.component, {
        disableClose: true,
        ...this.inputEdit.dialogConfig,
        data: this.inputEdit.dialogConfig.data
          ? {
              ...this.inputEdit.dialogConfig.data,
              row: row,
            }
          : row,
      });
      dialogRef.componentInstance.key = this.inputEdit.key;
      dialogRef.componentInstance.index = index;
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          if (
            !this.tableConfiguration?.options?.actions?.edit?.external &&
            this.tableConfiguration?.data &&
            result.row
          ) {
            this.onEdit(index, result.row);
            this.edit.emit({
              edited: result.itemCreated || null,
              row: result.row || null,
              index: index,
            });
          } else {
            this.edit.emit({
              edited: result.itemCreated || null,
              row: result.row || null,
              index: index,
            });
          }
        }
      });
    } else {
      this.edit.emit({
        row: row,
        index: index,
      });
    }
  }

  /**
   * Metodo para enviar la accion delete.
   * @param row - Valor de la fila seleccionada.
   * @param index - Index de la fila seleccionada.
   */
  public onButtonDelete<T>(row: T, index: number): void {
    if (this.tableConfiguration?.options?.removableColumn) {
      if (this.tableConfiguration?.options?.removableColumn?.confirmation) {
        this.dynamicDialogMessageService
          .open({
            type: 'question',
            header: {
              text:
                this.tableConfiguration?.options?.removableColumn
                  .confirmationText || '¿Desea eliminar esta fila?',
            },
          })
          .afterClosed()
          .pipe(first(), takeUntil(this.destroy$))
          .subscribe(result => {
            if (result) {
              if (this.tableConfiguration?.options?.removableColumn?.external) {
                this.delete.emit({
                  row,
                  index,
                });
              } else {
                this.onDelete(index);
                this.delete.emit({
                  row,
                  index,
                });
              }
            }
          });
      } else {
        this.delete.emit({
          row,
          index,
        });
      }
    } else {
      this.delete.emit({
        row,
        index,
      });
    }
  }

  /**
   * Emite evento cellLink de la tabla.
   * @param row - Fila.
   * @param index - Indice de la fila.
   */
  public onButtonCellLink(row: any, index: number) {
    this.cellLink.emit({
      row,
      index,
    });
  }

  /**
   * Cambia el estado de edicion de la fila.
   * @param index - Indice.
   * @param value - Valor a cambiar.
   * @param _row - Fila.
   */
  public onToggleEdit(index: number, value: boolean, _row: any): void {
    const row = clone(Object.create(_row));
    if (!value) {
      delete row.edit;
    }
    this.tableConfiguration?.columns?.forEach(column => {
      if (
        column.editValidators?.type === 'float' ||
        column.editValidators?.type === 'number'
      ) {
        row[column.columnDef || ''] = Number(row[column.columnDef || ''] || 0);
      }
    });
    const data: any[] = [...this.dataSource.data];
    data[index] = { ...data[index], ...row, edit: value };
    if (!value) {
      delete data[index].edit;
    }

    if (!value) {
      this.editedRow.emit({ i: index, row: data[index] });
    }
    this.onEdit(index, data[index]);
  }

  /**
   * Metodo para copíar el texto de una celda.
   * @param header - Cabecera valor copiado.
   */
  public onCopiedCell(header: string) {
    this.dynamicSnackBarService.Open(
      'Texto de ' + header + ' copiado al portapapeles',
      'success'
    );
  }

  /**
   * Valida si un boton se debe mostrar.
   * @param columnValidator - Validador.
   * @param row - Fila de la tabla.
   * @returns Boolean.
   */
  public columnValidator(
    columnValidator: ColumnValidators | undefined,
    row: any
  ): boolean {
    if (
      Object.prototype.hasOwnProperty.call(columnValidator || {}, 'equalsTo')
    ) {
      return isEqual(
        this.getValue(columnValidator?.columnDef || '', row),
        columnValidator?.equalsTo
      );
    } else if (
      Object.prototype.hasOwnProperty.call(
        columnValidator || {},
        'differentFrom'
      )
    ) {
      return !isEqual(
        this.getValue(columnValidator?.columnDef || '', row),
        columnValidator?.differentFrom
      );
    } else {
      return true;
    }
  }

  /**
   * Retorna el color del elemento.
   * @param elementColor - Validador.
   * @param row - Fila.
   * @returns ThemePalette.
   */
  public setColorIf(
    elementColor: ElementColor | undefined,
    row: any
  ): ThemePalette {
    if (elementColor?.primary) {
      if (this.columnValidator(elementColor?.primary, row)) {
        return 'primary';
      }
    }

    if (elementColor?.accent) {
      if (this.columnValidator(elementColor?.accent, row)) {
        return 'accent';
      }
    }

    if (elementColor?.warn) {
      if (this.columnValidator(elementColor?.warn, row)) {
        return 'warn';
      }
    }

    if (elementColor?.custom) {
      if (this.columnValidator(elementColor?.warn, row)) {
        return undefined;
      }
    }

    if (elementColor?.customClass) {
      if (this.columnValidator(elementColor?.warn, row)) {
        return undefined;
      }
    }
    return 'primary';
  }

  /**
   * Obtiene el valor de la fila al que se apunta, con puntos para acceser a un subValor, sin punto para acceder un valor.
   * Ejemplo 'type' obtiene el valor de type, 'type.code' obtiene el valor de code.
   * @param columnDef - Columna a la que se va a apuntar.
   * @param row - Fila a la que se va a apuntar.
   * @returns Any.
   */
  public getValue(columnDef: string, row: any): any {
    let returnValue: any;
    if (columnDef.includes('.')) {
      returnValue = row;
      columnDef.split('.').forEach((val: string) => {
        returnValue = returnValue[val];
      });
    } else {
      returnValue = row[columnDef];
    }
    return returnValue;
  }
  /**
   * Modifica la celda de una tabla.
   * @param cell - Celda.
   * @param index - Index.
   * @param value - Valor nuevo.
   * @param currency - Verifica si el valor es currency.
   * @param float - Verifica si el valor es float.
   */
  public updateTableCell(
    cell: string,
    index: number,
    value?: any,
    currency: boolean = false,
    float: boolean = false
  ) {
    if (this.dataTable) {
      let cellValue = value;
      if (currency && typeof cellValue !== 'object') {
        cellValue = Number(cellValue.replace(/[$.]+/g, ''));
      }
      if (float) {
        if (cellValue.charAt(cellValue.length - 1) === '.') {
          return;
        }
        cellValue = parseFloat(cellValue);
      }
      const row = { ...this.dataTable[index] };

      row[cell] = cellValue;
      this.dataTable[index] = row;
      this.dataSource.data[index] = row;
      this.matTable?.renderRows();
      this.cdRef.detectChanges();
      const element = document.getElementById(
        this.uniqueIdTable + cell + '_' + index
      );

      if (element) {
        element.focus();
      }
    }
  }

  /**
   * Retorna la seleccion de la tabla.
   */
  public emitTableSelection() {
    if (this.emitSelection) {
      this.tableSelection.emit({
        selected: this.selection.selected,
        deselected: this.selection['_deselectedToEmit'],
      });
    }
  }
}
