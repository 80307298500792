import { Action, createReducer, on } from '@ngrx/store';
import { ErrorHttp } from '../../../../models/errors.models';
import { resetInitialState } from '../../../../root/store/actions';
import { ResponseStart } from '../../../models';

import {
  ChangesProcessById,
  ChangesProcessByIdError,
  ChangesProcessByIdSuccess,
  Process,
  StartProcess,
  StartProcessError,
  StartProcessSuccess,
} from '../../actions';

/**
 *
 */
export interface StartProcessState {
  /**
   *
   */
  name?: string;
  /**
   *
   */
  object?: ResponseStart;
  /**
   *
   */
  changesProcess: any;
  /**
   *
   */
  loaded: boolean;
  /**
   *
   */
  loading: boolean;
  /**
   *
   */
  error?: ErrorHttp;
}

export const startProcessInitialState: StartProcessState = {
  name: undefined,
  object: undefined,
  loaded: false,
  loading: false,
  changesProcess: undefined,
  error: undefined,
};

const featureReducer = createReducer(
  startProcessInitialState,
  on(resetInitialState, () => ({ ...startProcessInitialState })),
  on(Process, (state, { name }) => ({ ...state, name })),
  on(StartProcess, state => ({
    ...state,
    loading: true,
    loaded: false,
    error: undefined,
  })),
  on(StartProcessSuccess, (state, { object }) => ({
    ...state,
    loading: false,
    loaded: true,
    object,
  })),
  on(StartProcessError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
  on(ChangesProcessById, state => ({
    ...state,
    loading: true,
    loaded: false,
    error: undefined,
  })),
  on(ChangesProcessByIdSuccess, (state, { object }) => ({
    ...state,
    loading: false,
    loaded: true,
    changesProcess: object,
  })),
  on(ChangesProcessByIdError, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: error,
  }))
);

/**
 * Reductor iniciar proceso.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function StartProcessReducer(
  state: StartProcessState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
