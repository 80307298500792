import { DialogConfig } from "../models/dynamic-dialog.model";

export const dialogMessageDefaultConfig: DialogConfig = {
    type: 'info',
    config: {
        width: '400px',
    },
    actions: [
        {
            text: 'Cerrar',
            actionValue: true,
            principal: true,
        },
    ],
}