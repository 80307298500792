/* eslint-disable jsdoc/require-jsdoc */
import { Action, createReducer, on } from '@ngrx/store';
import { sessionActions } from '../../actions/contents/session.actions';

/**
 * Estado de la session.
 */
export interface SessionState {
  signInUserSession: {
    idToken: string;
    refreshToken: string;
    accessToken: string;
  };
  attributes?: any;
  isAuth: boolean | undefined;
}

export const initialSessionState: SessionState = {
  isAuth: false,
  signInUserSession: {
    idToken: '',
    accessToken: '',
    refreshToken: '',
  },
};

const featureReducer = createReducer(
  initialSessionState,
  on(sessionActions.actualizarSesion, state => ({
    ...state,
  })),
  on(sessionActions.actualizarSesionExitoso, (state, { response }) => ({
    ...state,
    ...response,
  })),
  on(sessionActions.obtenerSesionExitoso, (state, { response }) => ({
    ...state,
    ...response,
  })),
  on(sessionActions.cargarSesion, state => ({
    ...state,
  }))
);

/**
 * Reductor autenticación.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function SessionReducer(
  state: SessionState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
