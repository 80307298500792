import {
  AfterViewInit,
  ComponentRef,
  Directive,
  Input,
  OnInit,
  Type,
  ViewContainerRef,
} from '@angular/core';

/**
 * Directiva que controla la creacion de componentes general.
 */
@Directive({
  selector: '[wpBackOfficeFactoryTabGeneral]',
})
export class FactoryTabGeneralDirective implements OnInit, AfterViewInit {
  /**
   * Entrada de componente.
   */
  @Input()
  public component?: Type<any>;

  /**
   * Componente creado.
   */
  public componentRefFactory!: ComponentRef<any>;

  /**
   * Crea una instanacia de FactoryHostDirectiveTwo.
   * @param viewContainer - Vista contenedor.
   */
  constructor(private viewContainer: ViewContainerRef) {}

  /**
   *A callback method that is invoked immediately after the default change detector has checked the directive's data-bound properties for the first time.
   */
  public ngOnInit() {
    this.viewContainer.clear();
  }

  /**
   * A callback method that is invoked immediately after Angular has completed initialization of a component's view.
   */
  public ngAfterViewInit(): void {
    if (this.component) {
      this.createComponent(this.component);
    }
  }

  /**
   * Crear un componenten en el contenido.
   * @param componente - Componente de entrada.
   * @returns ComponentRef.
   */
  public createComponent(componente: Type<any>) {
    const componentRef = this.viewContainer.createComponent(componente);
    this.componentRefFactory = componentRef;
    return componentRef;
  }
}
