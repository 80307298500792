import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WorkflowService } from '@wp-back-office/core/workflow-sdk';
import { map, catchError, of, mergeMap } from 'rxjs';
import * as actionsEffects from '../../actions';

/**
 *
 */
@Injectable()
export class PayloadEffects {
  /**
   * Crea una instancia StartProcessEffects.
   * @param actions$ - Actions.
   * @param workflowService - Servicio work flow.
   */
  constructor(
    private actions$: Actions,
    private workflowService: WorkflowService
  ) {}

  /**
   * Efecto para cargar los formularios del proceso.
   */
  public Payload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.PayloadAnalysis),
      mergeMap(({ processData }) =>
        this.workflowService.getFormTask(processData).pipe(
          map((object: any) =>
            actionsEffects.PayloadAnalysisSuccess({ response: object })
          ),
          catchError(error => of(actionsEffects.PayloadAnalysisError({ error })))
        )
      )
    )
  );
}
