import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueTypeOfReferences = createAction(
  '[KeyValueTypeOfReferences] Cargar Catalogos',
  props<{
    /**
     * Ruta.
     */
    path: CatalogueType;
  }>()
);

export const KeyValueTypeOfReferencesSuccess = createAction(
  '[KeyValueTypeOfReferences] Crear Catalogos Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueTypeOfReferencesError = createAction(
  '[KeyValueTypeOfReferences] Cargar Catalogos Error',
   props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
