import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorHttp } from '@wp-back-office/process/mvp-origination';
import { Feature, FeatureValue } from 'libs/core/workflow-sdk/src/lib/models/feature.model';

export const featuresByAmortizationActions = createActionGroup({
  source: 'Feature by Amortization',
  events: {
    Reset: emptyProps(),
    'Get List': props<{
      /**
       * Codigo de amortización.
       */
      code: number;
    }>(),
    Success: props<{
      /**
       * Caracteristicas.
       */
      object: FeatureValue[];
    }>(),
    Error: props<{
      /**
       * Respuesta error Http.
       */
      error: ErrorHttp;
    }>(),
  },
});
