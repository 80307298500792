<div
  class="dynamic-table-container"
  [id]="uniqueIdTable"
  class="{{ tableConfiguration?.options?.classes || 'table-default' }}"
  [ngClass]="{
    'paginator-top':
      !tableConfiguration?.options?.paginator?.disabled &&
      tableConfiguration?.options?.paginator?.position === 'top',
    'paginator-bottom':
      !tableConfiguration?.options?.paginator?.disabled &&
      tableConfiguration?.options?.paginator?.position === 'bottom'
  }">
  <form
    class="row"
    *ngIf="tableConfiguration?.options?.filter"
    [formGroup]="filterGroup">
    <div
      class="my-2 col-sm-12 col-md-4 col-lg-3"
      *ngIf="
        tableConfiguration?.options?.filter?.filterByColumnSelect || false
      ">
      <mat-form-field appearance="fill" table table-select>
        <mat-label>Columnas</mat-label>
        <mat-select formControlName="column" (selectionChange)="focusFilter()">
          <ng-container *ngFor="let column of tableConfiguration?.columns">
            <mat-option [value]="column" *ngIf="column?.filter !== false">
              {{ column.header }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      class="my-2 col-sm-12 col-md-8 col-lg-9 flex-inputs"
      [ngClass]="{
        'col-md-12 col-lg-12':
          !tableConfiguration?.options?.filter?.filterByColumnSelect
      }">
      <mat-form-field
        [hidden]="filterOptions"
        appearance="fill"
        table
        [ngStyle]="
          (tableConfiguration?.options?.filter?.filterByColumnSelect ||
            false) &&
          !this.filterGroup.value['column']
            ? { opacity: 0.5 }
            : {}
        "
        [matTooltip]="
          (tableConfiguration?.options?.filter?.filterByColumnSelect ||
            false) &&
          !this.filterGroup.value['column']
            ? 'Seleccione una columna'
            : ''
        ">
        <mat-icon matPrefix>search</mat-icon>
        <input
          matInput
          type="text"
          formControlName="value"
          placeholder="Buscar"
          #filterinput="matInput"
          onfocus="this.select()" />

        <button
          class="select-button"
          matSuffix
          mat-icon-button
          type="reset"
          (click)="
            tableConfiguration?.options?.filter?.internalFilter
              ? (dataSource.filter = '')
              : filterTable({ target: { value: '' }, keyCode: 'RESET' });
            this.filterOptions = undefined
          ">
          <mat-icon>backspace</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field
        [hidden]="!filterOptions"
        appearance="fill"
        table
        [ngStyle]="
          (tableConfiguration?.options?.filter?.filterByColumnSelect ||
            false) &&
          !this.filterGroup.value['column']
            ? { opacity: 0.5 }
            : {}
        "
        [matTooltip]="
          (tableConfiguration?.options?.filter?.filterByColumnSelect ||
            false) &&
          !this.filterGroup.value['column']
            ? 'Seleccione una columna'
            : ''
        ">
        <mat-icon matPrefix>search</mat-icon>

        <mat-label>Seleccione una opción</mat-label>

        <mat-select #filterSelect="matSelect" formControlName="value">
          <ng-container *ngFor="let option of filterOptions">
            <mat-option [value]="option.code">
              {{ option.description }}
            </mat-option>
          </ng-container>
        </mat-select>

        <button
          class="select-button"
          matSuffix
          mat-icon-button
          type="reset"
          (click)="
            tableConfiguration?.options?.filter?.internalFilter
              ? (dataSource.filter = '')
              : filterTable({ target: { value: '' }, keyCode: 'RESET' });
            this.filterOptions = undefined
          ">
          <mat-icon>backspace</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </form>

  <div class="table-container">
    <div class="table-background"></div>

    <div [class.loader-appear]="loading" class="loader-table">
      <div class="la-3x la-ball-fall loader">
        <div style="margin: 10px !important"></div>
        <div style="margin: 10px !important"></div>
        <div style="margin: 10px !important"></div>
      </div>
    </div>

    <wp-back-office-infinite-scroll
      [scrollStyle]="tableConfiguration?.options?.sizes?.ngStyle || {}"
      [classes]="'table-content d-block p-0'"
      [bottom]="0"
      [hidePaddingBottom]="
        !this.tableConfiguration?.options?.sizes?.alwaysScrollVertical
      "
      [hideLoader]="true"
      #scrollDiv
      [template]="template">
    </wp-back-office-infinite-scroll>
    <ng-template #template>
      <mat-table
        #table
        [dataSource]="dataSource"
        matSort
        [ngStyle]="tableMargin">
        <ng-container
          matColumnDef="selection"
          sticky
          *ngIf="tableConfiguration?.options?.selection">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="h-fit-content justify-content-center">
            <div>
              <mat-checkbox
                (change)="$event ? toggleAllRows() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [matTooltip]="checkboxLabel()">
              </mat-checkbox>
            </div>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row; let i = index"
            [ngClass]="'justify-content-center'"
            [ngStyle]="tableConfiguration?.options?.actionNgStyle || {}">
            <div>
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
                [matTooltip]="checkboxLabel(row, i)">
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container
          *ngFor="let column of tableConfiguration?.columns || []; index as i"
          [matColumnDef]="column.columnDef"
          [sticky]="column.stickyStart || false"
          [stickyEnd]="column.stickyEnd || false">
          <mat-header-cell
            *matHeaderCellDef
            [ngClass]="'justify-content-center'"
            [ngStyle]="column.diableHeaderNgStyle ? {} : column.ngStyle || {}">
            <div>
              {{ column.header }}
            </div>
          </mat-header-cell>

          <mat-cell
            [ngClass]="'justify-content-center'"
            [ngClass.xs]="'justify-content-between'"
            [ngClass.sm]="'justify-content-between'"
            [ngClass.md]="'justify-content-between'"
            *matCellDef="let row; let i = index"
            [attr.data-label]="column.header"
            [class.d-flex]="
              _tableService.getValue(row[column.columnDef]) === 'check_circle'
            "
            [ngStyle]="column.ngStyle || {}"
            [matTooltip]="
              column.copy
                ? _tableService.getValue(row[column.columnDef]) ||
                  '' + '\n(Clic para copiar)'
                : ''
            "
            matTooltipClass="text-center"
            (click)="column.copy ? onCopiedCell(column.header) : null"
            [cdkCopyToClipboard]="
              column.copy
                ? _tableService.getValue(row[column.columnDef]) || ''
                : null
            ">
            <div class="d-flex">
              <ng-container
                *ngIf="
                  column?.customActions &&
                  (column?.customActions?.length || 0) > 0
                ">
                <ng-container *ngFor="let action of column?.customActions">
                  <button
                    mat-icon-button
                    *ngIf="columnValidator(action?.ngIf, row)"
                    type="submit"
                    [matTooltip]="action?.toolTip || ''"
                    matTooltipClass="text-center"
                    [color]="action?.color || 'primary'"
                    (click)="
                      onButtonCustomAction(row, action.action, i, column)
                    ">
                    <mat-icon>
                      {{ action?.icon ? action?.icon : 'more_vert' }}
                    </mat-icon>
                  </button>
                </ng-container>
              </ng-container>

              <ng-container
                *ngIf="!row.edit || !column.isEditable"
                &&
                column.isEditable
                ||
                !column.isEditable>
                <ng-container
                  *ngIf="
                    _tableService.typeOf(row[column.columnDef]) !== 'array' &&
                    !column?.cell
                  ">
                  <ng-container *ngIf="!column.mask?.html">
                    {{
                      column.mask?.booleanChips?.trueValue ||
                      column.mask?.booleanChips?.falseValue ||
                      column.link?.enable
                        ? ''
                        : _tableService.getValue(
                            row[column.columnDef],
                            column.mask || undefined
                          ) === 'true' ||
                          _tableService.getValue(
                            row[column.columnDef],
                            column.mask || undefined
                          ) === 'false'
                        ? ''
                        : _tableService.getValue(
                            row[column.columnDef],
                            column.mask || undefined
                          )
                    }}
                  </ng-container>

                  <ng-container *ngIf="column.mask?.html">
                    <div
                      class="{{ column.mask?.html?.classes || '' }}"
                      [innerHTML]="row[column.columnDef]"></div>
                  </ng-container>

                  <mat-icon
                    class="boolean-icon"
                    [ngStyle]="
                      _tableService.getValue(
                        row[column.columnDef],
                        column.mask || undefined
                      ) === 'true'
                        ? {
                            color: 'var(--color-success)'
                          }
                        : {
                            color: ' var(--color-error)'
                          }
                    "
                    *ngIf="
                      (!column.mask?.booleanChips &&
                        _tableService.getValue(
                          row[column.columnDef],
                          column.mask || undefined
                        ) === 'true') ||
                      (!column.mask?.booleanChips &&
                        _tableService.getValue(
                          row[column.columnDef],
                          column.mask || undefined
                        ) === 'false')
                    ">
                    {{
                      _tableService.getValue(
                        row[column.columnDef],
                        column.mask || undefined
                      ) === 'true'
                        ? 'done'
                        : 'close'
                    }}
                  </mat-icon>

                  <a
                    (click)="onButtonCellLink(row, i)"
                    *ngIf="column.link?.enable">
                    {{
                      column.link?.text
                        ? column.link?.text
                        : _tableService.getValue(
                            row[column.columnDef],
                            column.mask || undefined
                          )
                    }}</a
                  >

                  <ng-container
                    *ngIf="
                      column.mask?.booleanChips?.trueValue ||
                      column.mask?.booleanChips?.falseValue
                    ">
                    <mat-chip-list>
                      <mat-chip
                        [ngStyle]="{
                          'background-color': 'var(--color-success)',
                          color: 'white'
                        }"
                        *ngIf="row[column.columnDef] === true"
                        selected>
                        {{ column.mask?.booleanChips?.trueValue }}
                      </mat-chip>
                      <mat-chip
                        [ngStyle]="{
                          'background-color': 'var(--color-error)',
                          color: 'white'
                        }"
                        *ngIf="row[column.columnDef] === false"
                        selected>
                        {{ column.mask?.booleanChips?.falseValue }}
                      </mat-chip>
                    </mat-chip-list>
                  </ng-container>
                </ng-container>

                <ng-container
                  *ngIf="
                    _tableService.typeOf(row[column.columnDef]) === 'array'
                  ">
                  <mat-chip-list>
                    <mat-chip
                      [color]="
                        setColorIf(column.mask?.chipsColor, row) || 'primary'
                      "
                      class="{{ column.mask?.chipsColor?.customClass || '' }}"
                      [ngStyle]="
                        column.mask?.chipsColor?.custom
                          ? {
                              'background-color':
                                column.mask?.chipsColor?.custom
                            }
                          : {}
                      "
                      [ngStyle]="
                        chip?.color
                          ? {
                              'background-color': chip?.color
                            }
                          : {}
                      "
                      selected
                      *ngFor="
                        let chip of _tableService.getValue(
                          row[column.columnDef]
                        )
                      ">
                      {{ chip?.description || chip?.code || chip }}
                    </mat-chip>
                  </mat-chip-list>
                </ng-container>

                <ng-container *ngIf="column?.cell && !column.link?.enable">
                  {{ _tableService.getValue(column?.cell(row)) }}
                </ng-container>

                <a
                  (click)="onButtonCellLink(row, i)"
                  *ngIf="column?.cell && column.link?.enable">
                  {{
                    column.link?.text
                      ? column.link?.text
                      : _tableService.getValue(column?.cell(row))
                  }}</a
                >
              </ng-container>

              <ng-container
                *ngIf="
                  (row.edit &&
                    column.isEditable &&
                    !column.editValidators?.canBeHidden &&
                    !row.canBeHidden) ||
                  (row.edit &&
                    column.isEditable &&
                    column.editValidators?.canBeHidden &&
                    !row.canBeHidden) ||
                  (row.edit &&
                    column.isEditable &&
                    !column.editValidators?.canBeHidden &&
                    row.canBeHidden)
                ">
                <ng-container
                  *ngIf="
                    column.editValidators?.type !== 'autocomplete' &&
                    column.editOptions
                  ">
                  <mat-form-field
                    appearance="fill"
                    class="full-width"
                    *ngIf="column.editOptions">
                    <mat-label>
                      Editar
                      <span class="lowercase">{{ column.header }}</span>
                    </mat-label>
                    <mat-select
                      [multiple]="column.editValidators?.multiple"
                      [(value)]="row[column.columnDef]"
                      matNativeControl
                      (selectionChange)="
                        updateTableCell(
                          column.columnDef,
                          i,
                          _tableService.getInputValue($event, 'select')
                        )
                      "
                      [required]="column.editValidators?.required">
                      <mat-option
                        *ngFor="let opt of column.editOptions"
                        [value]="opt"
                        >{{ opt.description }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>

                <ng-container
                  *ngIf="
                    column.editValidators?.type === 'autocomplete' &&
                    column.editOptions
                  ">
                  <wp-back-office-form-control-autocomplete
                    *ngIf="column.editValidators?.controlConfig"
                    [table]="true"
                    [value]="row[column.columnDef]"
                    [control]="column.editValidators?.controlConfig"
                    (selectInput)="
                      updateTableCell(column.columnDef, i, $event.value, true)
                    "></wp-back-office-form-control-autocomplete>
                </ng-container>

                <ng-container *ngIf="!column.editOptions">
                  <mat-form-field
                    appearance="fill"
                    table
                    cell
                    *ngIf="
                      _tableService.typeOf(row[column.columnDef]) ===
                        'undefined' ||
                      _tableService.typeOf(row[column.columnDef]) ===
                        'string' ||
                      (_tableService.typeOf(row[column.columnDef]) ===
                        'number' &&
                        !column.editOptions)
                    ">
                    <mat-label>
                      Editar
                      <span class="lowercase">{{ column.header }}</span>
                    </mat-label>

                    <ng-container
                      *ngIf="column.editValidators?.type === 'currency'">
                      <input
                        matInput
                        currencyMask
                        [options]="inputCurrencyOptins"
                        [type]="'text'"
                        [id]="uniqueIdTable + column.columnDef + '_' + i"
                        [(ngModel)]="row[column.columnDef]"
                        [required]="column.editValidators?.required || false"
                        [min]="column.editValidators?.min"
                        [max]="column.editValidators?.max" />
                    </ng-container>

                    <ng-container
                      *ngIf="
                        column.editValidators?.type === 'number' ||
                        column.editValidators?.type === 'float'
                      ">
                      <input
                        matInput
                        [type]="'text'"
                        [wpBackOfficeSoloNumerosDecimales]="
                          column.editValidators?.type === 'float'
                        "
                        [value]="row[column.columnDef]"
                        [id]="uniqueIdTable + column.columnDef + '_' + i"
                        [(ngModel)]="row[column.columnDef]"
                        [required]="column.editValidators?.required || false"
                        [min]="column.editValidators?.min"
                        [max]="column.editValidators?.max" />
                    </ng-container>

                    <ng-container
                      *ngIf="
                        column.editValidators?.type !== 'currency' &&
                        column.editValidators?.type !== 'number' &&
                        column.editValidators?.type !== 'float' &&
                        column.editValidators?.type !== 'autocomplete'
                      ">
                      <input
                        matInput
                        [type]="column.editValidators?.type || 'text'"
                        [value]="row[column.columnDef]"
                        [id]="uniqueIdTable + column.columnDef + '_' + i"
                        [(ngModel)]="row[column.columnDef]"
                        [required]="column.editValidators?.required || false" />
                    </ng-container>
                  </mat-form-field>

                  <mat-checkbox
                    [checked]="row[column.columnDef]"
                    *ngIf="
                      _tableService.typeOf(row[column.columnDef]) ===
                        'boolean' && !column.editOptions
                    "
                    (change)="
                      updateTableCell(
                        column.columnDef,
                        i,
                        _tableService.getInputValue($event, 'checkbox'),
                        true
                      )
                    ">
                    Editar <span class="lowercase">{{ column.header }}</span>
                  </mat-checkbox>
                </ng-container>
              </ng-container>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container
          *ngIf="
            tableConfiguration?.options?.actions ||
            tableConfiguration?.options?.editableColumn
          "
          matColumnDef="actions"
          [stickyEnd]="tableConfiguration?.options?.stickyActions || false">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="h-fit-content justify-content-center">
            <div>Acciones</div>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row; let i = index"
            [ngClass]="'justify-content-center'"
            [ngStyle]="tableConfiguration?.options?.actionNgStyle || {}">
            <div class="d-flex w-100 justify-content-evenly">
              <ng-container
                *ngIf="tableConfiguration?.options?.actions?.delete">
                <button
                  mat-icon-button
                  type="submit"
                  [color]="tableConfiguration?.options?.actions?.delete?.color"
                  (click)="
                    tableConfiguration?.options?.removableColumn
                      ? onButtonDelete(row, i)
                      : onButtonDelete(row, i)
                  "
                  [matTooltip]="
                    tableConfiguration?.options?.actions?.delete?.toolTip || ''
                  "
                  matTooltipClass="text-center"
                  *ngIf="
                    columnValidator(
                      tableConfiguration?.options?.actions?.delete?.ngIf,
                      row
                    )
                  ">
                  <mat-icon>
                    {{
                      tableConfiguration?.options?.actions?.delete?.icon
                        ? tableConfiguration?.options?.actions?.delete?.icon
                        : 'delete'
                    }}
                  </mat-icon>
                </button>
              </ng-container>

              <ng-container
                *ngIf="tableConfiguration?.options?.actions?.enable">
                <button
                  mat-icon-button
                  type="submit"
                  [color]="tableConfiguration?.options?.actions?.enable?.color"
                  [matTooltip]="
                    tableConfiguration?.options?.actions?.enable?.toolTip || ''
                  "
                  matTooltipClass="text-center"
                  (click)="onButtonEnable(row)"
                  *ngIf="
                    columnValidator(
                      tableConfiguration?.options?.actions?.enable?.ngIf,
                      row
                    )
                  ">
                  <mat-icon>
                    {{
                      tableConfiguration?.options?.actions?.enable?.icon
                        ? tableConfiguration?.options?.actions?.enable?.icon
                        : 'toggle_off'
                    }}
                  </mat-icon>
                </button>
              </ng-container>

              <ng-container
                *ngIf="tableConfiguration?.options?.actions?.disable">
                <button
                  mat-icon-button
                  type="submit"
                  [color]="tableConfiguration?.options?.actions?.disable?.color"
                  [matTooltip]="
                    tableConfiguration?.options?.actions?.disable?.toolTip || ''
                  "
                  matTooltipClass="text-center"
                  (click)="onButtonDisable(row)"
                  *ngIf="
                    columnValidator(
                      tableConfiguration?.options?.actions?.disable?.ngIf,
                      row
                    )
                  ">
                  <mat-icon>
                    {{
                      tableConfiguration?.options?.actions?.disable?.icon
                        ? tableConfiguration?.options?.actions?.disable?.icon
                        : 'toggle_on'
                    }}
                  </mat-icon>
                </button>
              </ng-container>

              <ng-container *ngIf="tableConfiguration?.options?.actions?.view">
                <button
                  mat-icon-button
                  type="submit"
                  [color]="tableConfiguration?.options?.actions?.view?.color"
                  [matTooltip]="
                    tableConfiguration?.options?.actions?.view?.toolTip || ''
                  "
                  matTooltipClass="text-center"
                  (click)="onButtonView(row, i)"
                  *ngIf="
                    columnValidator(
                      tableConfiguration?.options?.actions?.view?.ngIf,
                      row
                    )
                  ">
                  <mat-icon>
                    {{
                      tableConfiguration?.options?.actions?.view?.icon
                        ? tableConfiguration?.options?.actions?.view?.icon
                        : 'visibility'
                    }}
                  </mat-icon>
                </button>
              </ng-container>

              <ng-container *ngIf="tableConfiguration?.options?.actions?.edit">
                <button
                  mat-icon-button
                  type="submit"
                  [color]="tableConfiguration?.options?.actions?.edit?.color"
                  [matTooltip]="
                    tableConfiguration?.options?.actions?.edit?.toolTip || ''
                  "
                  matTooltipClass="text-center"
                  (click)="onButtonEdit(row, i)"
                  *ngIf="
                    columnValidator(
                      tableConfiguration?.options?.actions?.edit?.ngIf,
                      row
                    )
                  ">
                  <mat-icon>
                    {{
                      tableConfiguration?.options?.actions?.edit?.icon
                        ? tableConfiguration?.options?.actions?.edit?.icon
                        : 'edit'
                    }}
                  </mat-icon>
                </button>
              </ng-container>

              <ng-container *ngIf="tableConfiguration?.options?.editableColumn">
                <button
                  mat-icon-button
                  type="submit"
                  color="accent"
                  (click)="onToggleEdit(i, true, row); onButtonEdit(row, i)"
                  *ngIf="!row.edit">
                  <mat-icon> edit </mat-icon>
                </button>

                <button
                  mat-icon-button
                  class="pulse success"
                  type="submit"
                  color="primary"
                  (click)="onToggleEdit(i, false, row)"
                  *ngIf="row.edit">
                  <mat-icon> done </mat-icon>
                </button>
              </ng-container>

              <button
                *ngIf="
                  tableConfiguration?.options?.removableColumn &&
                  !tableConfiguration?.options?.actions?.delete
                "
                mat-icon-button
                type="submit"
                [color]="'warn'"
                (click)="onButtonDelete(row, i)">
                <mat-icon> delete </mat-icon>
              </button>

              <ng-container
                *ngIf="tableConfiguration?.options?.actions?.customActions">
                <ng-container
                  *ngFor="
                    let action of tableConfiguration?.options?.actions
                      ?.customActions
                  ">
                  <button
                    mat-icon-button
                    type="submit"
                    [matTooltip]="action?.toolTip || ''"
                    matTooltipClass="text-center"
                    [color]="action?.color || 'primary'"
                    *ngIf="columnValidator(action?.ngIf, row)"
                    (click)="onButtonCustomAction(row, action.action, i)">
                    <mat-icon>
                      {{ action?.icon ? action?.icon : 'more_vert' }}
                    </mat-icon>
                  </button>
                </ng-container>
              </ng-container>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row
          *matHeaderRowDef="
            displayedColumns;
            sticky: tableConfiguration?.options?.stickyHeader || false
          ">
        </mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          [class.mat-footer-row]="row.matFooterRow"
          (dblclick)="clickedRow($event, row)"></mat-row>
      </mat-table>
    </ng-template>

    <!-- TEMPORAL -->
    <div
      class="table-content"
      hidden
      #table_content
      [ngStyle]="tableConfiguration?.options?.sizes?.ngStyle || {}"
      (scroll)="onScroll($event)"></div>
    <!-- TEMPORAL -->

    <div
      class="noData p-3"
      *ngIf="!filterGroup?.value.value && dataSource.data.length === 0">
      {{ tableConfiguration?.options?.noDataText || 'Tabla vacía' }}
    </div>
    <div
      class="noData p-3"
      *ngIf="filterGroup?.value.value && dataSource.data.length === 0">
      No hay resultados
    </div>
  </div>

  <ng-container
    *ngIf="
      !(tableConfiguration?.options?.paginator === undefined
        ? true
        : tableConfiguration?.options?.paginator?.disabled)
    ">
    <mat-paginator
      [pageSize]="
        !tableConfiguration?.options?.paginator?.sizeOptions
          ? tableConfiguration?.options?.paginator?.size || 10
          : null
      "
      [pageSizeOptions]="
        tableConfiguration?.options?.paginator?.sizeOptions || []
      "
      [showFirstLastButtons]="true">
    </mat-paginator>
  </ng-container>
</div>
