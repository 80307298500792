import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  Catalogue,
  CatalogueTasksService,
} from '@wp-back-office/core/commons-backoffice';
import { mergeMap, map, catchError, of } from 'rxjs';
import * as actionsEffects from '../../actions';

/**
 * Servicio de catalogue.
 */
@Injectable()
export class KeyValueCitiesEffects {
  /**
   * Crea una instancia de .
   * @param actions$ - Acciones.
   * @param catalogueTasksService - Servicio de catalogo.
   */
  constructor(
    private actions$: Actions,
    private catalogueTasksService: CatalogueTasksService
  ) {}

  /**
   *
   */
  public keyValueCities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.KeyValueCities),
      mergeMap(actions =>
        this.catalogueTasksService
          .getCatalogueTasks(
            actions.path + '/' + actions.code + '/' + actions.path1
          )
          .pipe(
            map((keyValue: Catalogue[]) =>
              actionsEffects.KeyValueCitiesSuccess({ keyValue })
            ),
            catchError(err =>
              of(actionsEffects.KeyValueCitiesError({ payload: err }))
            )
          )
      )
    )
  );
}
