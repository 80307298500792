import { Action, createReducer, on } from '@ngrx/store';
import { ErrorHttp } from '../../../../models/errors.models';
import { resetInitialState } from '../../../../root/store/actions';

import {
  PayloadAnalysis,
  PayloadAnalysisSuccess,
  PayloadAnalysisError,
  ResetAnalysis,
  TaskAnalysisResult,
} from '../../actions';

/**
 * Estado de tareas exitosas.
 */
export interface ProcessEntity {
  /**
   * Bandera de cargado.
   */
  id?: string;
  /**
   * Objecto.
   */
  process?: any;
  /**
   * Objecto.
   */
  taskName?: any;
  /**
   * Bandera de cargado.
   */
  dateCreated?: string;
  /**
   * Bandera de cargado.
   */
  taskToken?: string;
}

/**
 * Estado de tareas exitosas.
 */
export interface EntityState {
  /**
   * Objecto.
   */
  data?: any;
  /**
   * Bandera de cargado.
   */
  loaded: boolean;
  /**
   * Bandera de cargando.
   */
  loading: boolean;
  /**
   * Errores.
   */
  error?: ErrorHttp;
}

/**
 * Estado de tareas exitosas.
 */
export interface PayloadAnalysisState {
  /**
   * Objecto.
   */
  process?: ProcessEntity;
  /**
   * Objecto.
   */
  applicant?: any;
  /**
   * Objecto.
   */
  product?: any;
  /**
   * Objecto.
   */
  response?: EntityState;
  /**
   * Bandera de cargado.
   */
  loaded: boolean;
  /**
   * Bandera de cargando.
   */
  loading: boolean;
  /**
   * Errores.
   */
  error?: ErrorHttp;
}

export const payloadAnalysisInitialState: PayloadAnalysisState = {
  process: undefined,
  applicant: undefined,
  product: undefined,
  response: undefined,
  loaded: false,
  loading: false,
};

const featureReducer = createReducer(
  payloadAnalysisInitialState,
  on(ResetAnalysis, () => ({ ...payloadAnalysisInitialState })),
  on(resetInitialState, () => ({ ...payloadAnalysisInitialState })),
  on(TaskAnalysisResult, (state, { processData }) => ({
    ...state,
    process: {
      ...state.process,
      taskToken: processData.taskToken,
    },
  })),
  on(PayloadAnalysis, state => ({
    ...state,
    loading: true,
  })),
  on(PayloadAnalysisSuccess, (state, { response }) => ({
    ...state,
    applicant:
      response.taskInformation?.applicationData?.applicationFiling?.applicants,
    product:
      response.taskInformation?.applicationData?.applicationFiling
        ?.productInformation,
    process: {
      id: response.id,
      process: response.process,
      taskName: response.taskName,
      dateCreated: response.dateCreated,
      userName: response?.taskInformation?.userCreator?.username,
      saleChannel: response?.taskInformation?.saleChannel,
      ...state.process,
    },
    response,
    loaded: true,
    loading: false,
  })),
  on(PayloadAnalysisError, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: error,
  }))
);

/**
 * Reductor tareas exitosas.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function PayloadAnalysisReducer(
  state: PayloadAnalysisState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
