/* eslint-disable @typescript-eslint/no-empty-function */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Catalogue } from '@wp-back-office/core/commons-backoffice';
import { DynamicSnackBarService } from 'libs/shared/dynamic-components/src/dynamic-snackbar';
import { delay, Subject, takeUntil } from 'rxjs';
import { AddressValue, ControlConfig } from '../../../models/form-config.model';
import { DynamicFormService } from '../../../services/dynamic-form.service';
import { TemplateAddressLocationService } from '../../../services/template-address-location.service';

/**
 * Componente de address location.
 */
@Component({
  selector: 'wp-back-office-template-address-location',
  templateUrl: './template-address-location.component.html',
  styleUrls: ['./template-address-location.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TemplateAddressLocationComponent),
      multi: true,
    },
  ],
})
export class TemplateAddressLocationComponent
  implements AfterViewInit, OnDestroy
{
  /**
   * Id unico del formulario.
   */
  @Input()
  public uniqueIdForm!: string;
  /**
   * Evento de formulario valido.
   */
  @Input()
  public control!: ControlConfig | undefined;
  /**
   * Evento de formulario valido.
   */
  @Output()
  public submitValid: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Valor de controles direccion.
   */
  public controls!: ControlConfig[] | undefined;
  /**
   * Valor ingresado.
   */
  public patchvalue?: any;
  /**
   * Destructor sujeto.
   */
  private destroy$ = new Subject();

  /**
   * Componente formulario dinamico.
   */
  public addressLocationForm = new FormGroup<any>({});

  /**
   * Tooltip.
   */
  public toolTip: string;

  /**
   * Crea una instancia TemplateAddressLocationComponent.
   * @param cdRef - Deteccion de cambios.
   * @param templateAddressLocationService - Servico para obtener los formularios.
   * @param dynamicFormService - Servicio de formularios dinamicos.
   */
  constructor(
    private cdRef: ChangeDetectorRef,
    public templateAddressLocationService: TemplateAddressLocationService,
    public dynamicFormService: DynamicFormService
  ) {
    this.toolTip =
      'Ingresa la nomenclatura de residencia actual del participante. Urbana: Ciudades, municipios, etc. Rural: Campo, veredas, etc.';
    const controls = templateAddressLocationService.getAddress();
    this.buildForm(controls);
  }

  /**
   * Crea el formulario inicial del componente.
   * @param controls - Controles del formulario.
   */
  public buildForm(controls: ControlConfig[]) {
    this.addressLocationForm = new FormGroup<any>({});
    if (controls)
      controls.map(control => {
        control.placeholder = '';
        if (control.key === 'nomenclature') {
          control.validators = {
            ...control.validators,
            required: false,
          };
        }
        if (control && control.key) {
          const formControl = this.dynamicFormService.setValidators(control);
          this.addressLocationForm.addControl(control.key.trim(), formControl);
          const validadors = this.dynamicFormService.getValidators(control);
          this.addressLocationForm
            .get(control.key.trim())
            ?.setValidators(validadors);
        }
      });
    this.controls = controls;
  }

  /**
   * Genera el texto de la direccion y lo asigna al campo de nomenclatura.
   */
  private generteAddress(): void {
    const value: AddressValue = this.addressLocationForm.value;
    let nomenclature = '';
    if (value?.type?.code === 'urbana' && value.via?.description) {
      nomenclature = `${value.via?.description || ''} ${value.num || ''} ${
        value.ref || ''
      } ${value.sector?.description || ''} # ${value.crossing || ''} ${
        value.ref1 || ''
      } ${value.sector1?.description || ''} - ${value.dist || ''}. ${
        value.complement || ''
      }`;
    } else if (value?.type?.code === 'rural') {
      nomenclature = `${value.complement || ''}`;
    }
    this.addressLocationForm?.controls['nomenclature'].patchValue(nomenclature);
  }

  /**
   * Valida el tipo de direccion.
   * @param value - Value.
   */
  private validateType(value: Catalogue) {
    if (value.code === 'urbana') {
      this.addressLocationForm.controls['via'].enable();
      this.addressLocationForm.controls['num'].enable();
      this.addressLocationForm.controls['ref'].enable();
      this.addressLocationForm.controls['sector'].enable();
      this.addressLocationForm.controls['crossing'].enable();
      this.addressLocationForm.controls['ref1'].enable();
      this.addressLocationForm.controls['sector1'].enable();
      this.addressLocationForm.controls['dist'].enable();
      this.modifyRequired('complement', false, 27);
    } else if (value.code === 'rural') {
      this.addressLocationForm.controls['via'].disable();
      this.addressLocationForm.controls['num'].disable();
      this.addressLocationForm.controls['ref'].disable();
      this.addressLocationForm.controls['sector'].disable();
      this.addressLocationForm.controls['crossing'].disable();
      this.addressLocationForm.controls['ref1'].disable();
      this.addressLocationForm.controls['sector1'].disable();
      this.addressLocationForm.controls['dist'].disable();
      this.modifyRequired('complement', true, 100);
    }
  }

  /**
   * Modifica los validadores del campo.
   * @param key - Key del campo.
   * @param required - Campo requerido.
   * @param maxLength - Max Length.
   */
  private modifyRequired(
    key: string,
    required: boolean,
    maxLength: 27 | 100
  ): void {
    if (this.controls) {
      let index;
      const currentControl = this.controls.filter((control, i) => {
        if (control.key === key) {
          index = i;
        }
        return control.key === key;
      })[0];
      const newControl: ControlConfig = {
        ...currentControl,
        validators: {
          ...currentControl.validators,
          required,
          maxLength,
        },
      };

      if (index) {
        this.controls[index] = newControl;
      }
      const validators = this.dynamicFormService.setValidators(newControl);
      this.addressLocationForm.controls[key].setValidators(
        validators.validator
      );
    }
  }

  /**
   * Se ejecuta al renderizar el componente.
   */
  public ngAfterViewInit(): void {
    // Me suscribo al cambio de todos los campos excepto el de nomenclatura para evitar un bucle en el valueChanges, debido a que modifico el valor de nomenclatura.
    this.addressLocationForm.controls['type'].valueChanges
      .pipe(delay(500), takeUntil(this.destroy$))
      .subscribe(value => {
        this.validateType(value);
        this.generteAddress();
      });

    this.addressLocationForm.controls['via'].valueChanges
      .pipe(delay(500), takeUntil(this.destroy$))
      .subscribe(() => this.generteAddress());

    this.addressLocationForm.controls['num'].valueChanges
      .pipe(delay(500), takeUntil(this.destroy$))
      .subscribe(() => this.generteAddress());

    this.addressLocationForm.controls['ref'].valueChanges
      .pipe(delay(500), takeUntil(this.destroy$))
      .subscribe(() => this.generteAddress());

    this.addressLocationForm.controls['sector'].valueChanges
      .pipe(delay(500), takeUntil(this.destroy$))
      .subscribe(() => this.generteAddress());

    this.addressLocationForm.controls['crossing'].valueChanges
      .pipe(delay(500), takeUntil(this.destroy$))
      .subscribe(() => this.generteAddress());

    this.addressLocationForm.controls['ref1'].valueChanges
      .pipe(delay(500), takeUntil(this.destroy$))
      .subscribe(() => this.generteAddress());

    this.addressLocationForm.controls['sector1'].valueChanges
      .pipe(delay(500), takeUntil(this.destroy$))
      .subscribe(() => this.generteAddress());

    this.addressLocationForm.controls['dist'].valueChanges
      .pipe(delay(500), takeUntil(this.destroy$))
      .subscribe(() => this.generteAddress());

    this.addressLocationForm.controls['complement'].valueChanges
      .pipe(delay(500), takeUntil(this.destroy$))
      .subscribe(() => this.generteAddress());

    this.addressLocationForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.onChangeFormAdress();
      });

    this.cdRef.detectChanges();
  }

  /**
   * A callback method that performs custom clean-up, invoked immediately before a directive, pipe, or service instance is destroyed.
   */
  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /**
   * Verirfica si es cambiado.
   * @param obj - Callback.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onChange = (obj: any) => {};

  /**
   * Verirfica si es tocado.
   */
  public onTouch = () => {};

  /**
   * Verirfica si es escrito.
   * @param obj - Callback.
   * @throws Error.
   */
  public writeValue(obj: any): void {
    if (obj && obj !== '' && typeof obj == 'object') {
      this.patchvalue = obj;
      setTimeout(() => {
        this.addressLocationForm.patchValue({
          ...obj,
        });
        this.onChangeFormAdress();
      }, 100);
    }
  }

  /**
   * Envia el  valor del formulario.
   */
  public onChangeFormAdress() {
    if (this.addressLocationForm.valid) {
      this.onChange(this.addressLocationForm.getRawValue());
    } else {
      this.onChange('');
    }
  }

  /**
   * Verirfica si es cambiado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Verirfica si es tocado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /**
   * Estado.
   * @param isDisabled - Bandera.
   */
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.addressLocationForm.disable();
    } else {
      this.addressLocationForm.enable();
    }
  }
}
