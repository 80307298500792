/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Attribute,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import {
  ControlConfig,
  TextTransform,
} from '@wp-back-office/shared/dynamic-components';
import { MatChipInputEvent } from '@angular/material/chips';
import { DynamicFormService } from '../../../../services/dynamic-form.service';

/**
 * Controles de entrada del formulario.
 */
@Component({
  selector: 'wp-back-office-form-control-text',
  templateUrl: './form-control-text.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlTextComponent),
      multi: true,
    },
  ],
})
export class FormControlTextComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  /**
   * Id unico del formulario.
   */
  @Input()
  public uniqueIdForm!: string;

  /**
   * Configuracion del control.
   */
  @Input()
  public set control(value: ControlConfig | undefined) {
    if (value) {
      this.controlConfig = value;
      this._formControl = this.dynamicFormService.setValidators(value);
    }
  }

  /**
   * Tipo de entrada que se va utilizar.
   */
  @Input()
  public typeControl: string;

  /**
   * Evento focus.
   */
  @Output()
  public controlFocus: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Evento focusOut.
   */
  @Output()
  public controlFocusOut: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Form control del campo.
   */
  public _formControl: FormControl;
  /**
   * Destructor observables.
   */
  private destroy$: Subject<boolean>;

  /**
   * Configuracion del control variable.
   */
  public controlConfig!: ControlConfig;

  /**
   * Valida si el campo es de chips.
   */
  public chipInput: any;

  /**
   * Mostrar el ojo.
   */
  public showEye: boolean;

  /**
   * Crea una instancia de FormControlTextComponent.
   * @param dynamicFormService - Servicio de los formularios.
   * @param cdRef - Detector de cambios.
   */
  constructor(
    public dynamicFormService: DynamicFormService,
    public cdRef: ChangeDetectorRef
  ) {
    this.typeControl = 'text';
    this._formControl = new FormControl('');
    this.destroy$ = new Subject();
    this.showEye = false;
  }

  /**
   * Se ejecuta cuando se inicia el componente.
   */
  public ngOnInit() {
    if (this.controlConfig)
      this._formControl = this.dynamicFormService.setValidators(
        this.controlConfig
      );
    this._formControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.onChange(data);
        this.cdRef.detectChanges();
        if (this._formControl.touched) {
          this.onTouch(data);
          this.cdRef.detectChanges();
        }
      });
  }

  /**
   * Se ejecuta al destruir el componente.
   */
  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * Ejecuta el evento focus del input.
   * @param event - Evento focus.
   */
  public onFocus(event: FocusEvent): void {
    this.controlFocus.emit(event);
  }

  /**
   * Ejecuta el evento focusOut del input.
   * @param event - Evento focusOut.
   */
  public onFocusOut(event: FocusEvent): void {
    this.controlFocusOut.emit(event);
  }

  /**
   * Verirfica si es cambiado.
   * @param obj - Callback.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onChange = (obj: any) => {};

  /**
   * Verirfica si es tocado.
   */
  public onTouch: any = () => {};

  /**
   * Verirfica si es escrito.
   * @param obj - Callback.
   * @throws Error.
   */
  public writeValue(obj: any): void {
    this._formControl.patchValue(obj);
  }

  /**
   * Verirfica si es cambiado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Verirfica si es tocado.
   * @param fn - Callback.
   * @throws Error.
   */
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /**
   * Deshabilitar control.
   * @param isDisabled - Bandera.
   */
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this._formControl.disable();
    } else {
      this._formControl.enable();
    }
  }

  /**
   * Agrega un valor al chip list.
   * @param event - Evento del input.
   */
  public addKeywordFromInput(event: any) {
    if (event.value) {
      this._formControl.patchValue([
        ...this.valideArray(this._formControl.value),
        event.value,
      ]);
      event.chipInput?.clear();
      this.chipInput = null;
    }
  }

  /**
   * Agrega un valor al chip list.
   * @param index - Index a eliminar.
   */
  public removeKeyword(index: number) {
    const value = this.valideArray(this._formControl.value);
    value?.splice(index, 1);
    this._formControl.patchValue(value);
  }

  /**
   * Valida que el valor del formcontrol sea array y lo retorna.
   * @param value - Valor.
   * @returns - Array.
   */
  public valideArray(value: any): any[] {
    if (typeof value === 'object' && Array.isArray(value)) {
      return value;
    } else {
      return [];
    }
  }
}
