import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import {
  ConstantsLogin,
  SignInInformation,
} from '@wp-back-office/app/global-information';
import {
  DynamicDialogMessageService,
  dialogMessageDefaultConfig,
} from '@wp-back-office/shared/dynamic-components';
import {
  constantsLogin,
  Utils,
  TextDashboard,
} from '@wp-back-office/app/global-information';
import { combineLatest, filter, first, Subject, take, takeUntil } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState, createSelectorInject } from '@wp-back-office/core/store';
import { PaletteGeneratorService } from '@wp-back-office/shared/generic-components';
import { authActions } from '../../../store/actions/contents/auth.actions';
import {
  selectPasswordReset,
  selectSendOtp,
  selectVerifyOtp,
} from '../../../store/selectors/auth.selectors';

/**
 * Componente de logueo.
 */
@Component({
  selector: 'wp-back-office-app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  /**
   * Constante de valores login.
   */
  public CUSTOMER_CONSTANTS: ConstantsLogin;
  /**
   * Constante metodo reutilizable.
   */
  public NOT_OVERWIRTE = this.utils.notOverwrite;
  /**
   * Constante textos login.
   */
  public LOGIN = TextDashboard.screens.login;
  /**
   * Constante titulo boton.
   */
  public TITTLE_BUTTON: string = TextDashboard.screens.login.button;
  /**
   * Constante identificador id tenant.
   */
  private TENANT_ID: string = environment.common.tenantId;
  /**
   * Formulario de logueo.
   */
  public signInForm: FormGroup;
  /**
   * Modelo información formulario.
   */
  public signInInformation: SignInInformation;
  /**
   * Bandera para mostras o ocultar.
   */
  public showEye: boolean;
  /**
   * Destructor sujeto.
   */
  private destroy$ = new Subject<void>();
  /**
   * Destructor sujeto.
   */
  private email!: string;

  /**
   * Logo del footer.
   */
  public footerLogo: string = TextDashboard.screens.login.footerLogo;

  /**
   * Crea uan instancia de LoginComponent.
   *
   * @param store - Store de redux.
   * @param formBuilder - Formulario de logueo.
   * @param recaptchaV3Service - Servicio de recaptcha.
   * @param router - Ruteador.
   * @param utils - Utils.
   * @param dynamicDialogMessageService - DynamicDialogMessageService.
   * @param paletteGeneratorService - Palette generator.
   */
  constructor(
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private recaptchaV3Service: ReCaptchaV3Service,
    private router: Router,
    private utils: Utils,
    private dynamicDialogMessageService: DynamicDialogMessageService,
    private paletteGeneratorService: PaletteGeneratorService
  ) {
    dynamicDialogMessageService.openLoaderStack('login');

    const emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
    const passwordPattern = /^(?=.*[0-9])(?=.*[a-z]).{8,}$/;
    this.showEye = false;
    this.signInForm = this.formBuilder.group({
      password: [
        '',
        [Validators.required, Validators.pattern(passwordPattern)],
      ],
      username: ['', [Validators.required, Validators.pattern(emailPattern)]],
      tokenCaptcha: [''],
    });

    this.signInInformation = {};
    this.CUSTOMER_CONSTANTS = constantsLogin;
    this.paletteGeneratorService.applyTheme(undefined);
    this.dynamicDialogMessageService.matDialog.closeAll();
  }

  /**
   * Se ejecuta al iniciar el componente.
   */
  public ngOnInit(): void {
    setTimeout(() => {
      this.dynamicDialogMessageService.closeStack('login');
    }, 5000);

    if (localStorage.getItem('exipredSesion')) {
      setTimeout(() => {
        this.dynamicDialogMessageService.open({
          ...dialogMessageDefaultConfig,
          type: 'warning',
          header: {
            text: localStorage.getItem('invalidToken')
              ? 'El token no es válido.'
              : localStorage.getItem('negativeTokenTime')
              ? 'La una sesión previamente activa ha expirado'
              : 'La sesión ha expirado por inactividad',
            textAlign: 'text-center',
          },
          body: {
            text: 'Por favor vuelva a ingresar a la plataforma.',
            textAlign: 'text-center',
          },
          config: {
            disableClose: true,
            width: '370px',
          },
          actions: [
            {
              principal: true,
              actionValue: true,
              text: 'Aceptar',
            },
          ],
        });
        localStorage.clear();
      }, 2000);
    } else {
      localStorage.clear();
    }
  }

  /**
   * Metodo para loguearse.
   */
  public signIn() {
    this.recaptchaV3Service
      .execute('importantAction')
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        const formValue = this.signInForm.value;

        this.signInInformation = {
          username: formValue.username,
          tokenCaptcha: data,
          password: formValue.password,
          tenantId: this.TENANT_ID,
        };
        this.TITTLE_BUTTON = TextDashboard.screens.login.textButtonSignInlog;
        combineLatest([
          this.store.select(createSelectorInject('signInUserSession', 'auth')),
        ])
          .pipe(
            filter(session => session[0]),
            first(),
            takeUntil(this.destroy$)
          )
          .subscribe(() => {
            this.navigate();
          });

        this.store.dispatch(
          authActions.inciarSesion({
            signInInformation: this.signInInformation,
          })
        );
      });
  }

  /**
   * Se ejecuta al destruir los componentes.
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Metodo para navegar.
   */
  public navigate() {
    this.TITTLE_BUTTON = TextDashboard.screens.login.button;
    this.router.navigate(['dashboard']);
  }

  /**
   * Metodo para mostrar o ocultar contraseña.
   */
  public showPassword() {
    const tipo: any = document.getElementById('password');
    if (tipo['type'] == 'password') {
      tipo['type'] = 'text';
      this.showEye = true;
    } else {
      tipo['type'] = 'password';
      this.showEye = false;
    }
  }

  /**
   * Recuperar contrasena.
   */
  public forgotPassword() {
    this.dynamicDialogMessageService
      .openForm({
        title: 'Recuperación de Contraseña',
        subtitle:
          'Ingresa la dirección de correo electrónico asociada a tu cuenta y te enviaremos un código de recuperación.',
        controls: this.getForm('FORGOT_PASSWORD_FORM'),
        buttonAddText: 'Enviar',
        disableConfirmation: true,
        value: {
          email: this.signInForm?.value?.username || '',
        },
        config: {
          maxWidth: '450px',
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value?.row?.email) {
          this.email = value.row.email;
          this.sendOtpCode();
        }
      });
  }

  /**
   * Enviar el codigo OTP.
   */
  private sendOtpCode() {
    this.store.dispatch(
      authActions.sendEmailOtpCode({
        email: this.email,
      })
    );

    this.store
      .pipe(
        select(selectSendOtp),
        filter(response => response.loaded && !response.loading),
        takeUntil(this.destroy$),
        take(1)
      )
      .subscribe(response => {
        if (response.object) {
          this.openOtpForm();
        }
      });
  }

  /**
   * Abrir el formulario de otp.
   */
  public openOtpForm() {
    this.dynamicDialogMessageService
      .openForm({
        title: 'Confirmación de Código de Recuperación',
        subtitle: `Ingresa el código de recuperación que hemos enviado a tu correo electrónico para restablecer tu contraseña.`,
        controls: this.getForm('OTP_FORM'),
        closeOnOtpVerification: true,
        buttonAddText: 'Confirmar',
        disableConfirmation: true,
        config: {
          maxWidth: '450px',
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value?.row?.otpCode) {
          this.store.dispatch(
            authActions.verifyOtpCode({
              email: this.email,
              otp: value?.row?.otpCode,
            })
          );

          this.store
            .pipe(
              select(selectVerifyOtp),
              filter(response => response.loaded && !response.loading),
              takeUntil(this.destroy$),
              take(1)
            )
            .subscribe(response => {
              if (response.object) {
                this.openPasswordChangeForm(response.object.tokenPassword);
              }
            });
        } else if (value.resendOtp) {
          this.sendOtpCode();
        }
      });
  }

  /**
   * Abrir el formulario de otp.
   * @param token - Token.
   */
  protected openPasswordChangeForm(token: string) {
    this.dynamicDialogMessageService
      .openForm({
        title: 'Código Confirmado',
        subtitle: `El código de recuperación ha sido confirmado con éxito. Ahora puedes establecer una nueva contraseña para tu cuenta.`,
        controls: this.getForm('PASSWORD_RESET'),
        buttonAddText: 'Confirmar',
        disableConfirmation: true,
        config: {
          maxWidth: '550px',
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value?.row?.password) {
          this.store.dispatch(
            authActions.passwordReset({
              email: this.email,
              password: value?.row?.password,
              token,
            })
          );

          this.store
            .pipe(
              select(selectPasswordReset),
              filter(response => response.loaded && !response.loading),
              takeUntil(this.destroy$),
              take(1)
            )
            .subscribe(response => {
              if (response.object) {
                this.dynamicDialogMessageService.open({
                  type: 'success',
                  header: {
                    text: 'Contraseña actualizada',
                    textAlign: 'text-left',
                  },
                  body: {
                    text: 'Ya puedes iniciar sesión con tu nueva contraseña.',
                  },
                });
              }
            });
        }
      });
  }

  /**
   * Retorna el formulario.
   * @param type - Tipo de formulario.
   * @returns Formulario.
   */
  private getForm(
    type: 'FORGOT_PASSWORD_FORM' | 'OTP_FORM' | 'PASSWORD_RESET'
  ): any {
    return TextDashboard.screens.login.forms.find(form => form.type === type)
      ?.schema;
  }
}
