import { QueryParams } from '@ngrx/data';

/**
 *
 * @param params
 */
export function mapToQueryParams<T>(params: T): QueryParams {
  const mappedParams: QueryParams = {};

  for (const key in params) {
    if (typeof params[key] === 'number') {
      mappedParams[key] = String(params[key]);
    } else if (typeof params[key] === 'boolean') {
      mappedParams[key] = params[key] ? 'true' : 'false';
    } else if (Array.isArray(params[key])) {
      mappedParams[key] = (params[key] as any[]).map(item => item.toString());
    } else if (typeof params[key] === 'object') {
      mappedParams[key] = JSON.stringify(params[key]); // O cualquier conversión que necesites
    } else {
      mappedParams[key] = params[key] as string;
    }
  }

  return mappedParams;
}
