import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';
import { Observable } from 'rxjs';

/**
 * Servicio de plantillas de documentos.
 */
@Injectable()
export class CreditOriginationTemplateManagementService {
  /**
   * Crea una instancia de CreditOriginationTemplateManagementService.
   * @param http - Servicio cliente.
   * @param enviroment - Variables de entorno.
   */
  constructor(private http: HttpClient, private enviroment: Enviroment) {}

  /**
   * Obtener plantillas de documentos.
   * @param pageSize - Tamano de la paginación.
   * @param lastKey - Ultimo elemento.
   * @returns Observable<any>.
   */
  public GetdocumentTemplate(
    pageSize: number,
    lastKey?: string
  ): Observable<any> {
    let params = {};
    params = lastKey
      ? {
          pageSize: pageSize.toString(),
          lastKey: encodeURIComponent(lastKey),
        }
      : {
          pageSize: pageSize.toString(),
        };
    const url = `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/documents/template`;
    return this.http.get(url, { params: params });
  }

  /**
   * Obtener docuento por id.
   * @param id - Documento a consultar.
   * @returns Observable<any>.
   */
  public GetdocumentTemplateVars(id: string): Observable<any> {
    const url = `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/documents/document/${id}`;
    return this.http.get(url);
  }

  /**
   * Obtener docuento.
   * @param url - Url del documento a consultar.
   * @returns Observable<any>.
   */
  public getDocument(url: string): Observable<any> {
    return this.http.get(url, { responseType: 'text' });
  }

  /**
   * Actualizar documento.
   * @param template - FormData con el archivo html.
   * @returns Observable<any>.
   */
  public UpdatedocumentTemplate(template: FormData): Observable<any> {
    const url = `${this.enviroment.endpoints.url}/${this.enviroment.common.tenantId}/documents/template`;
    return this.http.put(url, template);
  }
}
