import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Servicio de actualizar la tarea.
 */
@Injectable()
export class UpdateDataTaskService {
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  public dataUpdateTask = new Subject();
  /**
   *{AGREGUE DOCUMENTACION}.
   */
  public updateInputs: string[] = [];

  /**
   * Crea una instancia de UpdateDataTaskService.
   */
  constructor() {}

  /**
   * Enviar entradas actualizadas.
   * @param updates - Cadena.
   */
  public setUpdateInputs(updates: string[]) {
    this.updateInputs = updates;
  }

  /**
   * Buscar llave a actualizar.
   * @param key - Llave.
   * @returns Boolean.
   */
  public searchUpdateKey(key: string) {
    const search = this.updateInputs.find((input: string) =>
      key.startsWith(input)
    );

    return search !== undefined ? true : false;
  }
}
