import { createAction, props } from '@ngrx/store';
import { Catalogue, CatalogueType } from '../../../models/contents/catalogue.models';

export const KeyValueTypePerson2 = createAction(
  '[KeyValueTypePerson2] Cargar tipo de personas 2 por id',
  props<{
    /**
     * Identificador.
     */
    parentId: string;
    /**
     * Identificador.
     */
    childEntity: CatalogueType;
    /**
     * Identificador.
     */
    id: CatalogueType;
  }>()
);

export const KeyValueTypePerson2Success = createAction(
  '[KeyValueTypePerson2] Crear tipo de personas 2 Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueTypePerson2Error = createAction(
  '[KeyValueTypePerson2] Cargar tipo de personas 2 Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
