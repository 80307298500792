import { Catalogue } from '@wp-back-office/core/commons-backoffice';
import {
  FinancialPlan,
  FinancialPlanDetail,
  FinancialPlanInput,
} from '../models/financial-plan.model';
import * as moment from 'moment';

export class FinancialPlanMapper {
  /**
   * Mapea desde el formato AWS a nuestro modelo.
   * @param param - Parametros desde el backend.
   * @returns - Objeto de nuestro modelo.
   */
  public mapFrom(param: FinancialPlanDetail): FinancialPlan {
    return {
      features: {
        is_valid: true,
        items: param.characteristics.map(item => ({
          code_amoritization_characteristics:
            item?.code_amoritization_characteristics,
          code_type_options: item?.code_type_options,
          characteristic_description: item?.characteristic_description,
          value: item?.value?.toString(),
        })),
      },
      segments: param.segmentations.map(item => ({
        code_segmentation: item.code_segmentation,
        description: item.segmentation_description,
        name: item.segmentation_name,
      })),
      services: param.services.map(item => {
        return {
          code: item.code_services,
          description: item.description,
          subvention:
            item.services_subvention && item.services_subvention.length > 0
              ? item.services_subvention[0].subvention
              : undefined,
          service_type: item.service_type,
          type_financing_service: {
            code: item.code_type_financing_service.toString(),
            description: '',
          } as Catalogue,
          edit: false,
        };
      }),
      tracts: param.tracts.map((item, index) => ({
        ...item,
        client_rate:
          typeof item.client_rate === 'number'
            ? item.client_rate.toString()
            : undefined,
        final_term: item.final_term.toString(),
        initial_term: item.initial_term.toString(),
        description:
          item.description !== null && item.description !== undefined
            ? {
                code: item.code_option_tract?.toString() || '',
                description: item.description.toString(),
              }
            : undefined,
        code_tract: item.code_tract,
        id: index + 1,
        edit: false,
      })),
      amortization: {
        code: param.code_amortization.toString(),
        description:
          param.characteristics?.length > 0
            ? param.characteristics[0].amortization_description
            : '',
      },
      detail: {
        date_disbursement_limit: moment(
          param.date_disbursement_limit,
          'YYYY-MM-DD'
        ).toDate(),
        date_limit: moment(param.date_limit, 'YYYY-MM-DD').toDate(),
        date_start: moment(param.date_start, 'YYYY-MM-DD').toDate(),
        description: param.description,
        is_valid: true,
        name: param.name,
        priority_financial_plan: {
          code: param.code_priority_financial_plan.toString(),
          description: '',
        },
        rbp_options_value: param.rbp_option_assignment.value,
        rbp_options: {
          code: param.rbp_option_assignment.code_rbp_options.toString(),
          description: param.rbp_option_assignment.description
            ? param.rbp_option_assignment.description
            : '',
        },
      },
      type_price: {
        code: param.code_type_price.toString(),
        description: param.type_price_description,
      },
      models: param.models,
      vh_type_services: param.vh_type_services,
      families: param.families,
      versions: param.versions,
    };
  }
  /**
   * Mapea desde el formato AWS a nuestro modelo.
   * @param param - Parametros desde el backend.
   * @returns - Objeto de nuestro modelo.
   */
  public mapTo(param: FinancialPlan): FinancialPlanInput {
    return {
      characteristics: param.features
        ? param.features.items.map(item => ({
            code_amoritization_characteristics:
              item.code_amoritization_characteristics
                ? item.code_amoritization_characteristics
                : undefined,
            code_type_options: item.code_type_options,
            required: true,
            value: Number(
              typeof item.value === 'string'
                ? item.value
                : (item.value as Catalogue).code
            ),
            code_options: item.code_options,
          }))
        : [],
      code_amortization: param.amortization
        ? Number(param.amortization.code)
        : 0,
      code_priority_financial_plan: param.detail
        ? Number(param.detail.priority_financial_plan.code)
        : 0,
      code_rbp_options: param.detail
        ? Number(param.detail.rbp_options.code)
        : 0,
      code_type_price: param.type_price ? Number(param.type_price.code) : 0,
      date_disbursement_limit: param.detail
        ? param.detail.date_disbursement_limit
        : new Date(),
      date_limit: param.detail ? param.detail.date_limit : new Date(),
      date_start: param.detail ? param.detail.date_start : new Date(),
      description: param.detail ? param.detail.description : '',
      name: param.detail ? param.detail.name : '',
      rbp_option_value: param.detail ? param.detail.rbp_options_value : 0,
      segmentations: param.segments.map(item => ({
        code_segmentation: item.code_segmentation,
      })),
      services: param.services.map((_item: any) => {
        const amortizacion: any = param.amortization;
        const services_subvention = [];
        for (
          let index = 0;
          index < (amortizacion.subventions_services_count || 0);
          index++
        ) {
          const key: string = 'subvention' + (index === 0 ? '' : index);
          const key1: string = 'rate' + (index === 0 ? '' : index);

          if (_item[key]) {
            services_subvention.push({
              subvention: _item[key],
            });
          }

          if (_item[key1]) {
            services_subvention.push({
              subvention: _item[key1],
            });
          }
        }

        if (_item.subvention) {
          services_subvention.push({
            subvention: _item.subvention,
          });
        }

        return {
          code: _item.code,
          code_type_financing_service: Number(
            (_item.type_financing_service as Catalogue).code
          ),
          subvention:
            _item.subvention !== undefined
              ? Number(_item.subvention)
              : undefined,
          services_subvention: services_subvention,
        };
      }),
      tracts: param.tracts.map(item => {
        if ('description' in item && 'code_option_tract' in item) {
          return {
            client_rate: item.client_rate
              ? Number(item.client_rate)
              : undefined,
            final_term: Number(item.final_term),
            initial_term: Number(item.initial_term),
            code_tract: item.code_tract,
            code_option_tract: item.code_option_tract || undefined,
            description:
              typeof item.description === 'string'
                ? item.description
                : item.description?.description,
          };
        } else {
          return {
            client_rate: item.client_rate
              ? Number(item.client_rate)
              : undefined,
            final_term: Number(item.final_term),
            initial_term: Number(item.initial_term),
            code_tract: item.code_tract,
          };
        }
      }),
      models: param.models,
      vh_type_services: param.vh_type_services,
      families: param.families,
      versions: param.versions,
    };
  }
}
