import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuditProcessesService } from '@wp-back-office/core/workflow-sdk';
import { mergeMap, map, catchError, of } from 'rxjs';
import * as actionsEffects from '../../actions';

/**
 *
 */
@Injectable()
export class AuditDetailEffects {
  /**
   * Crea una instancia GetAuditDetailEffects.
   * @param actions$ - Actions.
   * @param auditProcessesService - Servicio.
   */
  constructor(
    private actions$: Actions,
    private auditProcessesService: AuditProcessesService
  ) {}

  /**
   * Efecto para obtener detalle del proceso a auditar.
   */
  public AuditDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsEffects.auditDetail),
      mergeMap(params =>
        this.auditProcessesService
          .GetAuditDetail(params.nameProcess, params.executionId)
          .pipe(
            map(object => actionsEffects.auditDetailSuccess({ object })),
            catchError(error => {
              return of(actionsEffects.auditDetailError({ error }));
            })
          )
      )
    )
  );
}
