import { Action, createReducer, on } from '@ngrx/store';
import { Catalogue } from '@wp-back-office/core/commons-backoffice';
import { ErrorHttp } from '../../../../models/errors.models';

import {
  KeyValueVerificationkycApprovalStatus,
  KeyValueVerificationkycApprovalStatusSuccess,
  KeyValueVerificationkycApprovalStatusError,
} from '../../actions';

import { resetInitialState } from '../../../../root/store/actions/contents/reset-initial-state.actions';

/**
 * Estado llave valor.
 */
export interface KeyValueVerificationkycApprovalStatusState {
  /**
   * Catalogo.
   */
  catalogue?: Catalogue[];
  /**
   * Bandera de cargado.
   */
  loaded: boolean;
  /**
   * Bandera de cargando.
   */
  loading: boolean;
  /**
   * Errores.
   */
  error?: ErrorHttp;
}

export const VerificationkycApprovalStatusInitialState: KeyValueVerificationkycApprovalStatusState =
  {
    catalogue: undefined,
    loaded: false,
    loading: false,
    error: undefined,
  };

const featureReducer = createReducer(
  VerificationkycApprovalStatusInitialState,
  on(resetInitialState, () => ({
    ...VerificationkycApprovalStatusInitialState,
  })),
  on(KeyValueVerificationkycApprovalStatus, state => ({
    ...state,
    loading: true,
  })),
  on(KeyValueVerificationkycApprovalStatusSuccess, (state, { keyValue }) => ({
    ...state,
    loading: false,
    loaded: true,
    catalogue: [...keyValue],
  })),
  on(KeyValueVerificationkycApprovalStatusError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  }))
);

/**
 * Reductor de .
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function keyValueVerificationkycApprovalStatusReducer(
  state: KeyValueVerificationkycApprovalStatusState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
