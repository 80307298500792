import { createAction, props } from '@ngrx/store';
import {
  Catalogue,
  CatalogueType,
} from '../../../models/contents/catalogue.models';
export const KeyValueDefaultValuesInsurers = createAction(
  '[KeyValueDefaultValuesInsurers] Cargar Catalogos por id',
  props<{
    /**
     * Ruta.
     */
    path: CatalogueType;
    /**
     * Ruta 2.
     */
    path2: CatalogueType;
    /**
     * Codigo.
     */
    code: string;
  }>()
);

export const KeyValueDefaultValuesInsurersSuccess = createAction(
  '[KeyValueDefaultValuesInsurers] Crear Catalogos Exitoso',
  props<{
    /**
     * Llave Valor.
     */
    keyValue: Catalogue[];
  }>()
);

export const KeyValueDefaultValuesInsurersError = createAction(
  '[KeyValueDefaultValuesInsurers] Cargar Catalogos Error',
  props<{
    /**
     * Payload.
     */
    payload: any;
  }>()
);
