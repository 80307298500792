<button
  *ngIf="buttonSelector && buttonSelector === 'mat-button'"
  mat-button
  class="{{ class ? class : '' }}"
  type="submit"
  color="{{ color ? color : 'primary' }}"
  [disabled]="disabled"
  [matTooltip]="tooltip"
  [matTooltipHideDelay]="tooltipHideDelay ? tooltipHideDelay : 0"
  [matTooltipShowDelay]="tooltipShowDelay ? tooltipShowDelay : 0"
  [ngStyle]="ngStyle ? ngStyle : null"
  [type]="type"
  [matBadgeDisabled]="!badge"
  [matBadge]="badge ? badge : ''"
  [matBadgePosition]="badgePosition ? badgePosition : 'after'"
  [matBadgeColor]="badgeColor!"
  (click)="clickedButton($event)">
  <div class="d-flex justify-content-center align-items-center">
    <mat-icon class="iconStart" *ngIf="iconStart">
      {{ iconStart }}
    </mat-icon>
    {{ text }}
    <mat-icon class="iconEnd" *ngIf="iconEnd">
      {{ iconEnd }}
    </mat-icon>
  </div>
</button>

<button
  *ngIf="buttonSelector && buttonSelector === 'mat-raised-button'"
  mat-raised-button
  class="{{ class ? class : '' }}"
  type="submit"
  color="{{ color ? color : 'primary' }}"
  [disabled]="disabled"
  [matTooltip]="tooltip"
  [matTooltipHideDelay]="tooltipHideDelay ? tooltipHideDelay : 0"
  [matTooltipShowDelay]="tooltipShowDelay ? tooltipShowDelay : 0"
  [ngStyle]="ngStyle ? ngStyle : null"
  [type]="type"
  [matBadgeDisabled]="!badge"
  [matBadge]="badge ? badge : ''"
  [matBadgePosition]="badgePosition ? badgePosition : 'after'"
  [matBadgeColor]="badgeColor!"
  (click)="clickedButton($event)">
  <div class="d-flex justify-content-center align-items-center">
    <mat-icon class="iconStart" *ngIf="iconStart">
      {{ iconStart }}
    </mat-icon>
    {{ text }}
    <mat-icon class="iconEnd" *ngIf="iconEnd">
      {{ iconEnd }}
    </mat-icon>
  </div>
</button>

<button
  *ngIf="buttonSelector && buttonSelector === 'mat-stroked-button'"
  mat-stroked-button
  class="{{ class ? class : '' }}"
  type="submit"
  color="{{ color ? color : 'primary' }}"
  [disabled]="disabled"
  [matTooltip]="tooltip"
  [matTooltipHideDelay]="tooltipHideDelay ? tooltipHideDelay : 0"
  [matTooltipShowDelay]="tooltipShowDelay ? tooltipShowDelay : 0"
  [ngStyle]="ngStyle ? ngStyle : null"
  [type]="type"
  [matBadgeDisabled]="!badge"
  [matBadge]="badge ? badge : ''"
  [matBadgePosition]="badgePosition ? badgePosition : 'after'"
  [matBadgeColor]="badgeColor!"
  (click)="clickedButton($event)">
  <div class="d-flex justify-content-center align-items-center">
    <mat-icon class="iconStart" *ngIf="iconStart">
      {{ iconStart }}
    </mat-icon>
    {{ text }}
    <mat-icon class="iconEnd" *ngIf="iconEnd">
      {{ iconEnd }}
    </mat-icon>
  </div>
</button>

<button
  *ngIf="!buttonSelector || buttonSelector === 'mat-flat-button'"
  mat-flat-button
  class="{{ class ? class : '' }}"
  type="submit"
  color="{{ color ? color : 'primary' }}"
  [disabled]="disabled"
  [matTooltip]="tooltip"
  [matTooltipHideDelay]="tooltipHideDelay ? tooltipHideDelay : 0"
  [matTooltipShowDelay]="tooltipShowDelay ? tooltipShowDelay : 0"
  [ngStyle]="ngStyle ? ngStyle : null"
  [type]="type"
  [matBadgeDisabled]="!badge"
  [matBadge]="badge ? badge : ''"
  [matBadgePosition]="badgePosition ? badgePosition : 'after'"
  [matBadgeColor]="badgeColor!"
  (click)="clickedButton($event)">
  <div class="d-flex justify-content-center align-items-center">
    <mat-icon class="iconStart" *ngIf="iconStart">
      {{ iconStart }}
    </mat-icon>
    {{ text }}
    <mat-icon class="iconEnd" *ngIf="iconEnd">
      {{ iconEnd }}
    </mat-icon>
  </div>
</button>

<button
  *ngIf="buttonSelector && buttonSelector === 'mat-icon-button'"
  mat-icon-button
  class="{{ class ? class : '' }}"
  type="submit"
  color="{{ color ? color : 'primary' }}"
  [disabled]="disabled"
  [matTooltip]="tooltip"
  [matTooltipHideDelay]="tooltipHideDelay ? tooltipHideDelay : 0"
  [matTooltipShowDelay]="tooltipShowDelay ? tooltipShowDelay : 0"
  [ngStyle]="ngStyle ? ngStyle : null"
  [type]="type"
  [matBadgeDisabled]="!badge"
  [matBadge]="badge ? badge : ''"
  [matBadgePosition]="badgePosition ? badgePosition : 'after'"
  [matBadgeColor]="badgeColor!"
  (click)="clickedButton($event)">
  <mat-icon *ngIf="iconStart">
    {{ iconStart }}
  </mat-icon>
</button>

<button
  *ngIf="buttonSelector && buttonSelector === 'mat-fab'"
  mat-fab
  class="{{ class ? class : '' }}"
  type="submit"
  color="{{ color ? color : 'primary' }}"
  [disabled]="disabled"
  [matTooltip]="tooltip"
  [matTooltipHideDelay]="tooltipHideDelay ? tooltipHideDelay : 0"
  [matTooltipShowDelay]="tooltipShowDelay ? tooltipShowDelay : 0"
  [ngStyle]="ngStyle ? ngStyle : null"
  [type]="type"
  [matBadgeDisabled]="!badge"
  [matBadge]="badge ? badge : ''"
  [matBadgePosition]="badgePosition ? badgePosition : 'after'"
  [matBadgeColor]="badgeColor!"
  (click)="clickedButton($event)">
  <mat-icon *ngIf="iconStart">
    {{ iconStart }}
  </mat-icon>
</button>

<button
  *ngIf="buttonSelector && buttonSelector === 'mat-mini-fab'"
  mat-mini-fab
  class="{{ class ? class : '' }}"
  type="submit"
  color="{{ color ? color : 'primary' }}"
  [disabled]="disabled"
  [matTooltip]="tooltip"
  [matTooltipHideDelay]="tooltipHideDelay ? tooltipHideDelay : 0"
  [matTooltipShowDelay]="tooltipShowDelay ? tooltipShowDelay : 0"
  [ngStyle]="ngStyle ? ngStyle : null"
  [type]="type"
  [matBadgeDisabled]="!badge"
  [matBadge]="badge ? badge : ''"
  [matBadgePosition]="badgePosition ? badgePosition : 'after'"
  [matBadgeColor]="badgeColor!"
  (click)="clickedButton($event)">
  <mat-icon *ngIf="iconStart">
    {{ iconStart }}
  </mat-icon>
</button>
