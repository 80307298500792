import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogFormConfig } from '../../../../models/form-config.model';
import { ControlConfig } from 'libs/shared/dynamic-components/src/dynamic-form/models/form-config.model';

import { first, Subject, takeUntil } from 'rxjs';
import { DynamicFormComponent } from '../../../dynamic-form/dynamic-form.component';
import {
  dialogMessageDefaultConfig,
  DynamicDialogMessageService,
} from '@wp-back-office/shared/dynamic-components';

/**
 * Dialog dinamico de formularios.
 */
@Component({
  selector: 'wp-back-office-dynamic-dialog-form',
  templateUrl: './dynamic-dialog-form.component.html',
})
export class DynamicDialogFormComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  /**
   * Tipo de componente.
   */
  public title: string;
  /**
   * Formulario.
   */
  @ViewChild('formID', {
    static: false,
    read: DynamicFormComponent,
  })
  public formPepComponent?: DynamicFormComponent;
  /**
   * Valor de controles de operaciones exteriores.
   */
  public controls!: ControlConfig[];

  /**
   * Destructor sujeto.
   */
  private destroy$ = new Subject();

  /**
   * Crea una isntancia AssociatesRelativesListFormComponent.
   * @param dialogRef - Referencia del dialog actual.
   * @param dialogData - Data del dialog.
   * @param cdRef - Detector de cambios.
   * @param dynamicDialogMessageService - Servicio de dialogs dinamicos.
   */
  public constructor(
    public dialogRef: MatDialogRef<DynamicDialogFormComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogFormConfig,
    private cdRef: ChangeDetectorRef,
    public dynamicDialogMessageService: DynamicDialogMessageService
  ) {
    this.title = '';
    this.controls = [];
  }

  /**
   * A callback method that is invoked immediately first time.
   */
  public ngOnInit(): void {
    this.controls = this.dialogData.controls;
    this.title = this.dialogData.title;
  }

  /**
   * Se ejecuta renderizar el componente.
   */
  public ngAfterViewInit(): void {
    if (this.dialogData.value) {
      this.formPepComponent?.formGroupGeneric.patchValue(this.dialogData.value);
    }

    if (this.dialogData.row) {
      this.formPepComponent?.formGroupGeneric.patchValue(this.dialogData.row);
    }
    this.cdRef.detectChanges();
  }

  /**
   * A callback method that performs custom clean-up, invoked immediately before a directive, pipe, or service instance is destroyed.
   */
  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /**
   * Crear el documento.
   */
  public submit() {
    this.dynamicDialogMessageService
      .open({
        ...dialogMessageDefaultConfig,
        type: 'question',
        header: {
          text: '¿Deseas agregar esta información?',
          textAlign: 'text-center',
        },
        actions: [
          {
            text: 'No',
            actionValue: false,
            principal: false,
          },
          {
            text: 'Si',
            actionValue: true,
            principal: true,
          },
        ],
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(res =>
        res
          ? this.dialogRef.close({
              value: this.formPepComponent?.formGroupGeneric.value,
              itemCreated: true,
            })
          : null
      );
  }

  /**
   * Cerrar modal.
   */
  public close(): void {
    const hasValue = Object.values(
      this.formPepComponent?.formGroupGeneric?.value
    ).find(values => (values ? true : false));
    if (hasValue) {
      this.dynamicDialogMessageService
        .open({
          type: 'question',
          header: {
            text: '¿Quieres descartar los cambios?',
          },
        })
        .afterClosed()
        .pipe(first(), takeUntil(this.destroy$))
        .subscribe(result => {
          if (result) {
            this.dialogRef.close();
          }
        });
    } else {
      this.dialogRef.close();
    }
  }
}
