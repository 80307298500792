import { Action, createReducer, on } from '@ngrx/store';
import { ErrorHttp } from '../../../../models/errors.models';
import { resetInitialState } from '../../../../root/store/actions';
import { TaskInformation } from '../../../models/contents/task-form.models';

import {
  ResetTaskSchema,
  TaskSchema,
  TaskSchemaError,
  TaskSchemaSuccess,
} from '../../actions';

/**
 * Estado de tareas exitosas.
 */
export interface TaskSchemaState {
  /**
   * Objecto.
   */
  taskPendingInfo?: TaskInformation;
  /**
   * Bandera de cargado.
   */
  loaded: boolean;
  /**
   * Bandera de cargando.
   */
  loading: boolean;
  /**
   * Errores.
   */
  error?: ErrorHttp;
}

export const taskSchemaInitialState: TaskSchemaState = {
  taskPendingInfo: undefined,
  loaded: false,
  loading: false,
  error: undefined,
};

const featureReducer = createReducer(
  taskSchemaInitialState,
  on(resetInitialState, () => ({ ...taskSchemaInitialState })),
  on(ResetTaskSchema, () => ({ ...taskSchemaInitialState })),
  on(TaskSchema, state => ({ ...state, params: undefined, loading: true })),
  on(TaskSchemaSuccess, (state, { taskPendingInfo }) => ({
    ...state,
    loading: false,
    loaded: true,
    taskPendingInfo,
  })),
  on(TaskSchemaError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
    params: undefined,
  }))
);

/**
 * Reductor tareas formularios exitosas.
 * @param state - Estado.
 * @param action - Accion.
 * @returns FeatureReducer.
 */
export function TaskSchemaReducer(
  state: TaskSchemaState | undefined,
  action: Action
) {
  return featureReducer(state, action);
}
